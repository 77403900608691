import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonModal,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonContent,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import {
  calendarClearOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeSvgIcon from "../../images/close-icon.svg";
import Calendar from "react-calendar";
import "../checkoutPrescription/checkoutPrescription.scss";
import {
  convertTimeStampToDateYYYYMMDD,
  convertTimestampToMMDDYYYY,
  isEmpty,
  maskPhoneNumber,
} from "../../Utils/helper";
import {
  profileActions,
  productAction
} from "../../Redux/actions/index";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { checkoutAction } from "../../Redux/actions";
import PlaneImage from "../../images/plane.png";
import planIcon from "../../images/plan.png";
import './index.scss'

const RequestPriceModal = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData || {});
  const commonData = useSelector((state) => state.commonReducer);
  const [isButtonDisabled, setButtonDisable] = useState(true);
  const [errorState, setErrorState] = useState({
    firstName: [],
    lastName: [],
    dob: [],
    zip: [],
    securityCode: [],
    doctorName: [],
  });

  const [errorStates, setErrorStates] = useState("");
  const [profileFlag, setProfileFlag] = useState(false);
  const [profile, setProfile] = useState({
    firstName: "",
    dob: "",
    lastName: "",
    doctorName: "",
    zip: "",
    securityCode: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: ""
    }
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [cartState, setCartState] = useState([]);
  const [cartItem, setCartItem] = useState([]);
  const [showCartItemDropDown, setShowCartItemDropDown] = useState([]);
  const [spinnerState, setSpinnerState] = useState("");
  const [successState, setSuccessState] = useState("");
  const [searchResultState, setSearchResultState] = useState([]);
  const [prescriberOptionState, setPrescriberOptionState] = useState({
    default: {},
    other: [],
  });
  const [pharmacyOptionState, setPharmacyOptionState] = useState([]);
  const [isAddNewPrescriber, setIsAddNewPrescriber] = useState(false);
  const [isAddNewPharmacy, setIsAddNewPharmacy] = useState(false);
  const [errorFormState, setErrorFormState] = useState([]);
  const [errorRxState, setErrorRxState] = useState([
    {
      last_name: [],
      dob: [],
      phone: [],
      verify: [],
    },
  ]);

  const { isOpen, onClose, onSuccessRequested, cartDetails } = props;
  const handleChange = (event, name) => {
    const { value } = event.detail;
    if (name === "dob") {
      setShowCalendar(false);
    }
    setProfile((prevState) => ({ ...prevState, [name]: value }));
  };

  React.useEffect(() => {
    setButtonDisable(!validateForm())
    setProfileFlag(true);
  }, [profile]);

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      firstName: [],
      lastName: [],
      dob: [],
      zip: [],
      securityCode: [],
      doctorName: [],
    };
    if (profile.firstName.trim().length === 0) {
      isFormValid = false;
      testError.firstName = ["First Name cannot be empty!"];
    }
    if (profile.lastName.trim().length === 0) {
      isFormValid = false;
      testError.lastName = ["Last Name cannot be empty!"];
    }
    if (profile.dob.toString().trim().length === 0) {
      isFormValid = false;
      testError.dob = ["DOB cannot be empty!"];
    }
    if (profile.zip.toString().trim().length === 0) {
      isFormValid = false;
      testError.zip = ["Zipcode cannot be empty!"];
    } else if (isNaN(Number(profile.zip))) {
      isFormValid = false;
      testError.zip = ["Zipcode must be numeric!"];
    }
    if (profile.securityCode.toString().trim().length === 0) {
      isFormValid = false;
      testError.securityCode = ["Social Security cannot be empty!"];
    } else if (isNaN(Number(profile.securityCode))) {
      isFormValid = false;
      testError.securityCode = ["Social Security must be numeric!"];
    } else if (profile.securityCode.toString().trim().length !== 4) {
      isFormValid = false;
      testError.securityCode = ["Security Code must be 4 digit numeric value!"];
    }
    if (profile.doctorName.toString().trim().length === 0) {
      isFormValid = false;
      testError.doctorName = ['Doctor name cannot be empty!'];
    }

    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return null;
    }

    /* const response = await dispatch(productAction.requestInsurancePrice({
      drug_name: cartDetails?.medicineName || "",
      strength: cartDetails?.medicineStrength || "",
      product_id: cartDetails?.product_id || 0,
      price: "0"
    })); */
    try {
      const name = `${profile.firstName} ${profile.lastName}`;
      const nameRes = dispatch(profileActions.updateProfile(authData.id, "patientName", { patientName: name }));

      const zipRes = dispatch(profileActions.updateProfile(authData.id, "address", {
        address: {
          zip: profile.zip.toString(),
          addressLine1: profile.address.addressLine1,
          addressLine2: profile.address.addressLine2,
          city: profile.address.city,
          state: profile.address.state,
          id: profile.address.id,
        }
      }));

      const dobRes = dispatch(profileActions.updateProfile(authData.id, "dob", { dob: profile.dob }));
      const socialSecurityRes = dispatch(profileActions.updateProfile(authData.id, "last_4_of_social_security", { last_4_of_social_security: profile.securityCode }));
      const doctorRes = dispatch(profileActions.updateProfile(authData.id, "doctor_name", { doctor_name: profile.doctorName }));

      await Promise.all([nameRes, zipRes, dobRes, socialSecurityRes, doctorRes]);

      setCurrentPage(2);
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }
  };

  const onSubmitStep2 = async () => {
    if (!(await validateForm())) {
      return null;
    }

    const option = cartState[0];
    const response = await dispatch(productAction.requestInsurancePrice({
      quantity: cartDetails?.data?.activeQuantity || null,
      product_strength_id: cartDetails?.data?.activeMedicineStrengthId || null,
      product_id: cartDetails?.data?.product_id || 0,
      price: cartDetails?.data?.price,
      option_id: option.optionId,
      option_data_id: option?.optionData?.id ?? null,
    }));


    if (response && response.status === 200) {
      setCurrentPage(3);
      // onSuccessRequested()
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }
  };

  const loadProfileData = async () => {
    if (!authData.id) return null;

    const response = await dispatch(
      profileActions.getUserProfileData(authData.id)
    );
    if (!response || !response.data) {
      return null;
    }

    const profileName = response.data.patientName.split(" ");
    setProfile({
      firstName: profileName[0] || "",
      lastName: profileName[1] || "",
      dob: response.data.dob !== "0000-00-00" ? response.data.dob : new Date(),
      zip: response.data.address?.zip || "",
      securityCode: '',
      address: response.data.address,
      doctorName:response.data.doctor_name || ''
    });
  };

  React.useEffect(() => {
    loadProfileData();
  }, [authData.id]);

  const setListStyle = (v) => {
    return ({
      color: currentPage >= v ? "#24a0ed" : "#6D6D6D"
    });
  };

  const setModelHeader = (v) => {
    switch (v) {
      case 1: {
        return {
          title: "Enter profile information",
          value: "Your information is always safe with us. We need this information to look up your insurance plan and coverage",
        };
      }
      case 2: {
        return {
          title: "Transfer your prescriptions",
          value: "Let us know where you have been getting these medications filled and we'll get them moved over to Qwark.",
        };
      }
      case 3: {
        return {
          title: "Thank you!",
          value: "You will get a notification when the insurance price is available. Please note that not all insurance plans provide this information digitally, so we may not be able to provide the insurance coverage details in some cases.",
        };
      }
      default: {
        return "";
      }
    }
  };

  React.useEffect(() => {
    menuController.enable(false);
    dispatch({ type: "CLEAN_ERROR" });
    loadData();
  }, []);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [cartDetails]);

  React.useEffect(() => {
    loadData();
    setCartItem(props.cartItem);
  }, [cartItems]);

  const loadData = () => {
    loadPrescriberData();
    // if (isEmpty(cartItems) || cartItems === "null") {
    //   return null;
    // }
    let newCartItems = cartItems.filter((cartItem) =>
      !cartItem.optionId
        ? {
          ...cartItem,
          optionId: "",
          optionData: {},
          isSaved: false,
        }
        : cartItem
    );
    if (newCartItems.length === 0) {
      newCartItems = [{
        optionId: "",
        optionData: {},
        isSaved: false,
      }];
    }

    setCartState(
      [newCartItems[0]]
    );
    setShowCartItemDropDown(cartItems.map((c) => true));
    setSearchResultState(cartItems.map((c) => []));
  };

  const handleOptionCheck = (event, optionIndex, cartIndex) => {
    const { checked } = event.detail;
    setSearchResultState(cartItems.map((c) => []));
    let allCartItems = cartState;
    if (!checked) {
      allCartItems[cartIndex].optionId = "";
      allCartItems[cartIndex].isSaved = false;
      allCartItems[cartIndex].optionData = {};
    } else {
      if (optionIndex === 2) {
        const prescriberOptionData = {
          prescriber_name: isEmpty(prescriberOptionState.default) ? "" : prescriberOptionState.default.prescriber_name,
          phone: isEmpty(prescriberOptionState.default) ? "" : prescriberOptionState.default.phone,
          fax: isEmpty(prescriberOptionState.default) ? "" : prescriberOptionState.default.fax,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = prescriberOptionData;
      } else if (optionIndex === 3) {
        const pharmacyOptionData = {
          pharmacy_name: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.pharmacy_name,
          address: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.address,
          npi: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.npi,
          phone: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.phone,
          fax: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.fax,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = pharmacyOptionData;
      } else if (optionIndex === 4) {
        const rxOptionData = {
          last_name: "",
          dob: "",
          phone: "",
          isVerified: false,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = rxOptionData;
      } else {

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = { isEmailMsgSent: false };
      }
    }
    setCartState([...allCartItems]);
  };

  const sendQwarkInfoToUser = async (type, cartIndex) => {
    setSpinnerState(type);
    const response = await dispatch(
      checkoutAction.sendQwarkInfoToUser(authData.id, type)
    );
    if (response.success) {
      setSuccessState(response.message);

      let allCartItems = cartState;
      if (cartIndex === 0) {
        allCartItems = cartState.map((c) => ({
          ...c,
          optionData: { isEmailMsgSent: true },
        }));
      } else {
        allCartItems[cartIndex].optionData.isEmailMsgSent = true;
      }
      setCartState([...allCartItems]);
    } else {
      setErrorStates(response.message);
    }
    setTimeout(() => {
      setSuccessState("");
      setErrorStates("");
    }, 3000);
    setSpinnerState("");
  };

  const handleChanges = (event, name, index) => {
    let { value } = event.detail;
    if (name === "phone") {
      value = value.replace(/\D/g, "")
      if (isNaN(+value)) {
        setSearchResultState((prevState) => (prevState[index] = []));
        return null;
      }
    } else if (name === "dob") {
      setShowCalendar(false);
    }
    let allCartItems = [...cartState];
    allCartItems[index].optionData[name] = value;
    setCartState([...allCartItems]);
  };

  const onSelectFromResult = (object, name, index) => {
    let cartStateInstance = cartState;

    if (name === "prescriber") {
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          optionData: {
            prescriber_name: object.name,
            phone: object.phone,
            fax: object.fax,
          },
        }));
      } else {
        cartStateInstance[index].optionData = {
          prescriber_name: object.name,
          phone: object.phone,
          fax: object.fax,
        };
      }
    } else if (name === "pharmacy") {
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          isSaved: true,
          optionData: {
            pharmacy_name: object.name,
            address: object.address,
            npi: object.npi,
            phone: object.phone,
            fax: object.fax,
          },
        }));
      } else {
        cartStateInstance[index].optionData = {
          pharmacy_name: object.name,
          address: object.address,
          npi: object.npi,
          phone: object.phone,
          fax: object.fax,
        };
        cartStateInstance[index].isSaved = true;
      }
    }
    setCartState([...cartStateInstance]);
    setSearchResultState((prevState) => (prevState[index] = []));
  };

  const onSave = async (optionId, index, ifSaveForFuturePurpose) => {
    if (optionId === 2 && (isEmpty(cartState[index].optionData.prescriber_name) || isEmpty(cartState[index].optionData.phone))) {
      return null;
    } else if (optionId === 3 && isEmpty(cartState[index].optionData.pharmacy_name)) {
      return null;
    }
    let cartStateInstance = cartState;
    if (index === 0) {
      cartStateInstance = cartState.map((c) => ({ ...c, isSaved: !cartState[index].isSaved }));
    } else {
      cartStateInstance[index].isSaved = !cartState[index].isSaved;
    }

    if (ifSaveForFuturePurpose) {
      if (optionId === 2) {
        const res = await dispatch(profileActions.savePrescriber(authData.id, { ...cartState[index].optionData, is_default: 0 }));
        cartStateInstance[index].optionData.id = res.data
      } else if (optionId === 3) {
        const res = await dispatch(profileActions.savePharmacy(authData.id, { ...cartState[index].optionData, is_default: 0 }));
        cartStateInstance[index].optionData.id = res.data
      }
    }

    setCartState([...cartStateInstance]);
  };

  const loadPrescriberData = async () => {
    const response = await dispatch(
      profileActions.getPrescriberAndPharmacyData(authData.id)
    );
    if (response && response.success) {
      const { prescriber, pharmacy } = response.data;
      setPrescriberOptionState({
        default: prescriber.filter((p) => p.is_default)[0],
        other: prescriber.filter((p) => !p.is_default),
      });
      setPharmacyOptionState({
        default: pharmacy.filter((p) => p.is_default)[0],
        other: pharmacy.filter((p) => !p.is_default),
      });
    }
  };

  const onPrescriberChange = (event, index) => {
    const { value: id } = event.detail;
    let prescriber = {};
    if (
      !isEmpty(prescriberOptionState.default) &&
      prescriberOptionState.default.id === id
    ) {
      prescriber = prescriberOptionState.default;
    } else {
      prescriber = prescriberOptionState.other.filter(
        (p) => p.id === id
      )[0];
    }

    if (!prescriber.prescriber_name) {
      return;
    }
    let allCartItems = cartState;
    // if (index === 0) {
    //   allCartItems = cartState.map((c) => ({
    //     ...c,
    //     optionId: 2,
    //     optionData: prescriber,
    //   }));
    // } else {
    allCartItems[index].optionData = prescriber;

    // }
    setCartState([...allCartItems]);
  };

  const onPharmacyChange = (event, index) => {
    const { value: id } = event.detail;
    let pharmacy = {};
    if (
      !isEmpty(pharmacyOptionState.default) &&
      pharmacyOptionState.default.id === id
    ) {
      pharmacy = pharmacyOptionState.default;
    } else {
      pharmacy = pharmacyOptionState.other.filter(
        p => p.id === id
      )[0];
    }
    if (!pharmacy.pharmacy_name) {
      return;
    }
    let allCartItems = cartState;
    // if (index === 0) {
    //   allCartItems = cartState.map((c) => ({
    //     ...c,
    //     optionId: 3,
    //     optionData: pharmacy,
    //   }));
    // } else {
    allCartItems[index].optionData = pharmacy;
    // }
    setCartState([...allCartItems]);
  };

  const onAddPrescriberPharmacyClick = (name, index) => {
    name === "prescriber"
      ? setIsAddNewPrescriber(true)
      : setIsAddNewPharmacy(true);
    let allCartItems = cartState;
    allCartItems[index].optionData =
      name === "prescriber"
        ? { prescriber_name: "", phone: "", fax: "" }
        : {
          pharmacy_name: "",
          address: "",
          npi: "",
          phone: "",
          fax: "",
        };
    setCartState([...allCartItems]);
  };

  const validateForms = () => {
    let isFormValid = true;
    let testError = [];
    let isQwarkInfoSent = false;

    for (let item of cartState) {
      const index = cartState.indexOf(item);
      let itemError = [];
      if (isEmpty(item.optionId)) {
        isFormValid = false;
        itemError = [`Prescription Required!`];
      } else if (
        item.optionId === 2 &&
        (isEmpty(item.optionData) || isEmpty(item.optionData.prescriber_name))
      ) {
        isFormValid = false;
        itemError = [`Prescriber name cannot be empty!`];
      } else if (
        item.optionId === 3 &&
        (isEmpty(item.optionData) || isEmpty(item.optionData.pharmacy_name))
      ) {
        isFormValid = false;
        itemError = [`Pharmacy name cannot be empty!`];
      } else if (
        item.optionId === 4 &&
        (isEmpty(item.optionData) || !item.optionData.isVerified)
      ) {
        isFormValid = false;
        itemError = [`Rx not verified!`];
      } else if (
        item.optionId === 1 &&
        (isEmpty(item.optionData) || !item.optionData.isEmailMsgSent)
      ) {
        if (!isQwarkInfoSent) {
          sendQwarkInfoToUser("email", index);
          sendQwarkInfoToUser("phone", index);
          isQwarkInfoSent = true;
        }
        // isFormValid = false;
        // itemError = [`Email/Text has not been sent yet!`];
      }
      testError.push(itemError);
    }
    return isFormValid;
  };

  const verifyRx = async (index) => {
    if (!(await validateRxForm(index))) {
      return null;
    }
    setSpinnerState(`verify${index}`);
    const response = await dispatch(
      checkoutAction.verifyRx(authData.id, cartState[index].optionData)
    );
    if (!response || !response.success) {
      let testError = errorRxState;
      testError[index].verify.push(`Verification Failed!`);
      setErrorRxState([...testError]);
    } else {
      let cartStateInstance = cartState;
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          optionData: { ...cartState[0].optionData, isVerified: true },
        }));
      } else {
        cartStateInstance[index].optionData.isVerified = true;
      }
      setCartState([...cartStateInstance]);
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 4000);
    }
    setSpinnerState(``);
  };

  const validateRxForm = (index) => {
    let isFormValid = true;
    let testError = cartState.map((c) => ({
      last_name: [],
      dob: [],
      phone: [],
      verify: [],
    }));

    if (isEmpty(cartState[index].optionData.last_name)) {
      isFormValid = false;
      testError[index].last_name.push(`This Field is Required!`);
    }
    if (isEmpty(cartState[index].optionData.phone)) {
      isFormValid = false;
      testError[index].phone.push(`This Field is Required!`);
    }
    if (isEmpty(cartState[index].optionData.dob)) {
      isFormValid = false;
      testError[index].dob.push(`This Field is Required!`);
    }
    setErrorRxState(testError);
    return isFormValid;
  };

  const onPrevious = () => {
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderErrorContent = (field) => {
    const error = errorState[field]
    return error.length !== 0 && error[0] && (
      <p className="errormsg">
        {error[0]}
      </p>
    )
  }

  const renderOption1 = (cartIndex) => {
    return (
      <div className="check-dropbox-body">
        <div className="doctors-info">
          <div className="doctors-info-top">
            <p>
              Provide this info to your doctor's office as your new preferred pharmacy
            </p>
          </div>
          <div className="doctors-info-body">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <div className="doctors-detail">
                    <IonLabel>
                      Address
                    </IonLabel>
                    <p>
                      1751 Grasselli Street Irvine, CA 92618
                    </p>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="6">
                  <div className="doctors-detail">
                    <IonLabel>NPI</IonLabel>
                    <p>1234567890</p>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="6">
                  <div className="doctors-detail">
                    <IonLabel>Phone</IonLabel>
                    <p>1(800)874-5881</p>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="6">
                  <div className="doctors-detail">
                    <IonLabel>FAX</IonLabel>
                    <p>1(800)874-5881</p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {!isEmpty(errorState) && <p className="errormsg">{errorStates}</p>}
          {!isEmpty(successState) && <p className="successmsg">{successState}</p>}
          <div className="doctors-info-bottom">
            <IonButton
              className="theme-btn button-grey"
              onClick={() => sendQwarkInfoToUser("email", cartIndex)}
              disabled={spinnerState === "email"}
            >
              <span>Email</span>
            </IonButton>
            <IonButton
              className="theme-btn button-grey"
              onClick={() => sendQwarkInfoToUser("phone", cartIndex)}
            >
              <span>Text</span>
            </IonButton>
          </div>
        </div>
      </div>
    );
  }

  const renderOption2WithIsSaved = (cartItem, cartIndex) => {
    return <div className="pharmacySearch-result">
      <div className="pharmacySearch-result-item">
        <h3>
          {cartItem.optionData.prescriber_name}
        </h3>
        <p>
          Phone : {isEmpty(cartItem.optionData.phone) ? "--" : cartItem.optionData.phone}
        </p>
        <p>
          Fax :{" "}{isEmpty(cartItem.optionData.fax) ? "--" : cartItem.optionData.fax}
        </p>
        <a
          href="javascript:;"
          onClick={() => onSave(2, cartIndex, false)}
        >
          Change Prescriber
        </a>
      </div>
    </div>;
  }

  const renderNewPrescriberForm = (cartItem, cartIndex) => {
    return <div className="check-dropbox-body">
      <div className="form-group search-drop">
        <IonLabel>
          Prescriber Name
        </IonLabel>
        <IonInput
          className="form-control"
          type="text"
          placeholder="e.g. John Smith"
          value={
            cartItem.optionData.prescriber_name ? cartItem.optionData.prescriber_name : ""
          }
          onIonChange={(e) =>
            handleChanges(e, "prescriber_name", cartIndex)
          }
        />

        {/*<CreatableSelect
          placeholder="e.g. John Smith"
          styles={customStyles}
          onChange={handleSelectChange(cartIndex)}
          options={options}
        />*/}

        {!isEmpty(searchResultState[cartIndex]) && (
          <div className="search-drop-list">
            {searchResultState[cartIndex].map(
              (prescriber, indexP) => (
                <a
                  href="javascript:;"
                  key={indexP}
                  onClick={() => onSelectFromResult(prescriber, "prescriber", cartIndex)}
                >
                  {prescriber.name}
                </a>
              )
            )}
          </div>
        )}
      </div>

      <div className="form-group">
        <IonLabel>
          Prescriber Number
        </IonLabel>
        <IonInput
          type="text"
          className="form-control"
          placeholder="e.g. (123)456-7890"
          value={cartItem.optionData.phone ? cartItem.optionData.phone : ""}
          onIonChange={(e) =>
            handleChanges(
              e,
              "phone",
              cartIndex
            )
          }
        />
      </div>
      <div className="form-group">
        <IonLabel>
          Prescriber Fax Number (optional)
        </IonLabel>
        <IonInput
          type="text"
          className="form-control"
          placeholder="e.g. (123)456-7890"
          value={cartItem.optionData.fax ? cartItem.optionData.fax : ""}
          onIonChange={(e) => handleChanges(e, "fax", cartIndex)}
        />
      </div>

      <div className="check-dropbox-bottom">
        <IonButton
          className="theme-btn button-grey"
          onClick={() => onSave(2, cartIndex, true)}
        >
          <span>Save</span>
        </IonButton>
      </div>
    </div>;
  }

  const renderSelectPharmacy = (cartItem, cartIndex) => {
    return (
      <div className="check-dropbox-body">
        <div className="form-group search-drop">
          <IonLabel>
            Pharmacy Search{" "}
          </IonLabel>
          <IonSelect
            interface="popover"
            placeholder="Pharmacy"
            className="form-control"
            onIonChange={(e) => {onPharmacyChange(e, cartIndex)}}
          >
            {!isEmpty(pharmacyOptionState.default) && (
              <IonSelectOption className="form-control" value={pharmacyOptionState.default.id}>
                {
                  pharmacyOptionState.default.pharmacy_name
                }
              </IonSelectOption>
            )}
            {pharmacyOptionState.other.map(
              (p, pIndex) => (
                <IonSelectOption value={p.id} key={pIndex} className="form-control">
                  {p.pharmacy_name}
                </IonSelectOption>
              )
            )}
          </IonSelect>
        </div>
        <div className="check-dropbox-bottom">
          <IonButton
            className="theme-btn button-grey"
            onClick={() =>
              onAddPrescriberPharmacyClick(
                "pharmacy",
                cartIndex
              )
            }
          >
            <span>Add a Pharmacy</span>
          </IonButton>
        </div>
      </div>
    );
  }

  const renderSelectPrescribers = (cartIndex, cartItem) => {
    return <div className="check-dropbox-body">
      <div className="form-group">
        <IonLabel>
          Prescriber Name
        </IonLabel>
        <IonSelect
          interface="popover"
          placeholder="Prescriber"
          className="form-control"
          onIonChange={(e) => {onPrescriberChange(e, cartIndex)}}
        >
          {!isEmpty(prescriberOptionState.default) && (
            <IonSelectOption
              className="form-control"
              value={prescriberOptionState.default.prescriber_name}
            >
              {
                prescriberOptionState.default.prescriber_name
              }
            </IonSelectOption>
          )}
          {prescriberOptionState.other.map(
            (p, pIndex) => (
              <IonSelectOption
                className="form-control"
                value={p.id}
                key={pIndex}
              >
                {p.prescriber_name} {"hree"}
              </IonSelectOption>
            )
          )}
        </IonSelect>
      </div>

      <div className="form-group">
        <IonLabel>
          Prescriber Number
        </IonLabel>
        <IonInput
          type="text"
          className="form-control"
          placeholder="e.g. (123)456-7890"
          value={
            cartItem.optionData.phone
              ? cartItem.optionData
                .phone
              : ""
          }
          onIonChange={(e) =>
            handleChanges(
              e,
              "phone",
              cartIndex
            )
          }
        />
      </div>
      <div className="form-group">
        <IonLabel>
          Prescriber Fax Number
          (optional)
        </IonLabel>
        <IonInput
          type="text"
          className="form-control"
          placeholder="e.g. (123)456-7890"
          value={
            cartItem.optionData.fax
              ? cartItem.optionData.fax
              : ""
          }
          onIonChange={(e) =>
            handleChanges(
              e,
              "fax",
              cartIndex
            )
          }
        />
      </div>

      <div className="check-dropbox-bottom">
        <IonButton
          className="theme-btn button-grey"
          onClick={() =>
            onAddPrescriberPharmacyClick("prescriber", cartIndex)
          }
        >
          <span>Add a Prescriber</span>
        </IonButton>
      </div>
    </div>;
  }

  const renderFinishComponent = () => {
    return (
      <IonCol size="12" size-md="5" size-lg="5" className="checkoutPrescription-page tex-center">
        <div className="prescriptions-box">
          <div className="prescriptions-body alert-body">
            <img alt="finish" src={PlaneImage} />
            <div className="alert-title">We are working on it! You will receive an alert as soon as we have your
              information ready.
            </div>
          </div>
        </div>
      </IonCol>
    );
  }

  const renderOption3Saved = (cartItem) => {
    return <div className="pharmacySearch-result">
      <div className="pharmacySearch-result-item">
        <h3>
          {cartItem.optionData.pharmacy_name}
        </h3>
        <p>
          {cartItem.optionData.address}
        </p>
      </div>
    </div>;
  }

  const renderAddPharmacy = (cartIndex, cartItem) => {
    return <div className="check-dropbox-body">
      <div className="form-group search-drop">
        <IonLabel>Pharmacy</IonLabel>
        {!isEmpty(searchResultState[cartIndex]) && (
          <div className="search-drop-list">
            {searchResultState[cartIndex].map(
              (pharmacy, indexP) => (
                <a
                  href="javascript:;"
                  key={indexP}
                  onClick={() => onSelectFromResult(pharmacy, "pharmacy", cartIndex)}
                >
                  {pharmacy.name}
                </a>
              )
            )}
          </div>
        )}
        <IonGrid className="p-0">
          <IonRow>
            <IonCol
              className="p-0"
              size="12"
              size-md="12"
            >
              <IonGrid className="p-0">
                <IonRow className="p-0">
                  <IonCol
                    size="12"
                    size-md="12"
                  >
                    <div className="form-group search-drop">
                      <IonLabel>Name</IonLabel>
                      <IonInput
                        className="form-control"
                        type="text"
                        value={cartItem.optionData.pharmacy_name ? cartItem.optionData.pharmacy_name : ""}
                        onIonChange={(e) => handleChanges(e, "pharmacy_name", cartIndex)}
                      />
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>
                        Address
                      </IonLabel>
                      <GooglePlacesAutocomplete
                        autocompletionRequest={{ componentRestrictions: { country: ["us"], }}}
                        selectProps={{
                          onChange: (selectedPlace) => {
                            handleChanges(
                              { detail: { value: selectedPlace.label } },
                              "address",
                              cartIndex
                            );
                          },
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              color: "black",
                            }),
                            option: (provided) => ({
                              ...provided,
                              color: "black",
                              zIndex: 1,
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "black",
                            }),
                            container: (provided) => ({
                              ...provided,
                              zIndex: 1051,
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderColor: "rgba(139,139,169,.3)",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              opacity: "0%",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#c0c1d4",
                              fontFamily: "gothamlight",
                              fontSize: 13,
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none"
                            }),
                          },
                        }}
                        apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                      />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>NPI</IonLabel>
                      <IonInput
                        className="form-control"
                        type="number"
                        value={cartItem.optionData.npi ? cartItem.optionData.npi : ""}
                        onIonChange={(e) => handleChanges(e, "npi", cartIndex)}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        Phone
                      </IonLabel>
                      <IonInput
                        className="form-control"
                        type="text"
                        value={cartItem.optionData.phone ? maskPhoneNumber(cartItem.optionData.phone.toString()) : ""}
                        onIonChange={(e) => handleChanges(e, "phone", cartIndex)}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>FAX</IonLabel>
                      <IonInput
                        className="form-control"
                        type="text"
                        value={cartItem.optionData.fax ? maskPhoneNumber(cartItem.optionData.fax.toString()) : ""}
                        onIonChange={(e) => handleChanges(e, "fax", cartIndex)}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonButton
                className="theme-btn button-grey"
                onClick={() =>
                  onSave(3, cartIndex, true)
                }
              >
                <span>Save</span>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>;
  }

  return (profileFlag &&
    <IonModal
      isOpen={isOpen}
      cssClass="theme-modal request-price-modal send-request-popup ion-custom"
      onDidDismiss={() => {
        if (currentPage === 3) {
          onSuccessRequested();
        }
        onClose();
      }}>
      <IonContent>
        <div className="modal-body">
          <div className={`request-price-content ${currentPage === 3 && "thanyou-wrapper"}`}>
            <div className="login-page-inner">
              <div className="theme-card">
                <div className="card-body">
                  <div className="cart-closer">
                    <img
                      onClick={onClose}
                      className="close-icon"
                      src={closeSvgIcon}
                      alt="Close"
                    />
                  </div>
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-md="7" size-lg="7">
                          <div className="signin-left-img">
                            {currentPage !== 3 && (<span className="step-title">{currentPage} OF 2</span>)}
                            <h3>{setModelHeader(currentPage).title}</h3>
                            <p className="sub-title">{setModelHeader(currentPage).value}</p>
                            <ul>
                              <li style={setListStyle(1)}>
                                <span className="circle"><img src={planIcon} alt="plan" className="plan-icon" /></span>
                                Step 1 : Enter your profile information
                              </li>
                              <li style={setListStyle(2)}>
                                <span className="circle"><img src={planIcon} alt="plan" className="plan-icon" /></span>
                                Step 2 :Send your prescription to Qwark.
                              </li>
                            </ul>
                          </div>
                          <p className="footer-title">
                            Once we have your information, we will send you an alert when insurance price is ready
                          </p>
                        </IonCol>
                        {currentPage === 1 ?
                          <IonCol size="12" size-md="5" size-lg="5">
                            <div className="parentform-right">
                              <div className="parentform-right-head">
                              </div>
                              {commonData.errorMsg.error && (<p className="errormsg">{commonData.errorMsg.error}</p>)}
                              <IonRow>
                                <IonCol size="12" size-md="6">
                                  <div className="form-group">
                                    <IonLabel>First Name</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. John"
                                      disabled={profile.firstName.length}
                                      value={profile.firstName}
                                      onIonChange={(e) =>
                                        handleChange(e, "firstName")
                                      }
                                    />
                                    { renderErrorContent('firstName') }
                                  </div>
                                </IonCol>
                                <IonCol size="12" size-md="6">
                                  <div className="form-group">
                                    <IonLabel>Last Name</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. Smith"
                                      disabled={profile.lastName.length}
                                      value={profile.lastName}
                                      onIonChange={(e) => handleChange(e, "lastName")}
                                    />
                                    { renderErrorContent('lastName') }
                                  </div>
                                </IonCol>
                                <IonCol size="12" size-md="12">
                                  <div className="form-group">
                                    <IonLabel>Date of Birth</IonLabel>
                                    {showCalendar ? (
                                      <div className="commonCalendar">
                                        <Calendar
                                          value={profile.dob ? new Date(profile.dob) : new Date()}
                                          maxDate={new Date()}
                                          calendarType="US"
                                          onChange={(e) => {handleChange({ detail: { value: convertTimeStampToDateYYYYMMDD(e) }}, "dob")}}
                                        />
                                      </div>
                                    ) : (
                                      <div className="icon-input">
                                        <IonInput
                                          className="form-control input-dob"
                                          placeholder="Select the date"
                                          type="text"
                                          disabled={profile.dob.length}
                                          value={profile.dob && profile.dob.toString().length
                                          && convertTimestampToMMDDYYYY(profile.dob)
                                          }
                                          onClick={() => profile.dob.length ? null : setShowCalendar(true)}
                                        >
                                          <a href="javascript:void(0);" onClick={() => profile.dob.length ? null : setShowCalendar(true)}>
                                            <IonIcon icon={calendarClearOutline} />
                                          </a>
                                        </IonInput>
                                      </div>
                                    )}
                                    { renderErrorContent('dob') }
                                  </div>
                                </IonCol>
                                <IonCol size="12" size-md="12">
                                  <div className="form-group">
                                    <IonLabel>Zip Number</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. 94016 "
                                      value={profile.zip}
                                      onIonChange={(e) => handleChange(e, "zip")}
                                    />
                                    { renderErrorContent('zip') }
                                  </div>
                                </IonCol>
                                <IonCol size="12" size-md="12">
                                  <div className="form-group">
                                    <IonLabel>Last 4 of Social Security</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. 1233 "
                                      value={profile.securityCode}
                                      onIonChange={(e) => {handleChange(e, "securityCode")}}
                                    />
                                    { renderErrorContent('securityCode') }
                                  </div>
                                </IonCol>
                              </IonRow>
                              <IonCol size="12" size-md="12">
                                <div className="form-group">
                                  <IonLabel>Doctor's Name</IonLabel>
                                  <IonInput
                                    className="form-control"
                                    type="text"
                                    placeholder="e.g. Doctor Who"
                                    defaultValue={""}
                                    value={profile.doctorName}
                                    onIonChange={(e) => {handleChange(e, "doctorName")}}
                                  />
                                  { renderErrorContent('doctorName') }
                                </div>
                              </IonCol>
                              <div className="parentform-button">
                                <IonButton
                                  className="theme-btn button-blue"
                                  disabled={isButtonDisabled}
                                  onClick={() => onSubmit()}
                                >
                                  Continue
                                </IonButton>
                              </div>
                            </div>
                          </IonCol> :
                          currentPage === 2 ?
                            <>
                              <IonCol size="12" size-md="5" size-lg="5" className="checkoutPrescription-page">
                                <div className="prescriptions-box">
                                  <div className="prescriptions-body">
                                    {cartState.map((cartItem, cartIndex) => (
                                      <div className="prescriptions-dropbox" key={cartIndex}>
                                        <div className="prescriptions-dropbox-heading">
                                          <div className="prescriptions-dropbox-body">
                                            <div className="check-dropbox">
                                              <div className="check-dropbox-head" style={{ backgroundColor: "#4c78ff1a" }}>
                                                <div className="check-dropbox-head-inner">
                                                  <IonCheckbox
                                                    checked={cartItem.optionId === 1}
                                                    onIonChange={(e) => handleOptionCheck(e, 1, cartIndex)}
                                                  />
                                                  <p>
                                                    Tell your doctor to send it to Qwark{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              { cartItem.optionId === 1 && renderOption1(cartIndex) }
                                            </div>
                                            <div className="check-dropbox">
                                              <div className="check-dropbox-head" style={{ backgroundColor: "#4c78ff1a" }}>
                                                <div className="check-dropbox-head-inner">
                                                  <IonCheckbox
                                                    checked={cartItem.optionId === 2}
                                                    onIonChange={(e) =>handleOptionCheck(e, 2, cartIndex) }
                                                  />
                                                  <p> Tell us your doctor and we will work with them </p>
                                                </div>
                                                {
                                                  cartItem.optionId === 2 && (
                                                    cartItem.isSaved
                                                      ? renderOption2WithIsSaved(cartItem, cartIndex)
                                                      : (isAddNewPrescriber || (isEmpty(prescriberOptionState.default) && isEmpty(prescriberOptionState.other)))
                                                      ? renderNewPrescriberForm(cartItem, cartIndex) : renderSelectPrescribers(cartIndex, cartItem)
                                                  )
                                                }
                                              </div>
                                            </div>
                                            <div className="check-dropbox">
                                              <div className="check-dropbox-head" style={{ backgroundColor: "#4c78ff1a" }}>
                                                <div className="check-dropbox-head-inner">
                                                  <IonCheckbox
                                                    checked={cartItem && cartItem.optionId === 3}
                                                    onIonChange={(e) => handleOptionCheck(e, 3, cartIndex)}
                                                  />
                                                  <p>Transfer from my old pharmacy</p>
                                                </div>
                                                {cartItem.optionId === 3 && (
                                                  <div>
                                                    {
                                                      cartItem.isSaved
                                                      ? renderOption3Saved(cartItem)
                                                      : isAddNewPharmacy || (isEmpty(pharmacyOptionState.default) && isEmpty(pharmacyOptionState.other))
                                                        ? renderAddPharmacy(cartIndex, cartItem)
                                                        : renderSelectPharmacy(cartItem, cartIndex)
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div className="check-dropbox">
                                              <div className="check-dropbox-head" style={{ backgroundColor: "#4c78ff1a" }}>
                                                <div className="check-dropbox-head-inner">
                                                  <IonCheckbox
                                                    checked={cartItem.optionId === 4}
                                                    onIonChange={(e) => handleOptionCheck(e, 4, cartIndex)}
                                                  />
                                                  <p>Qwark already has my Rx</p>
                                                </div>
                                              </div>
                                              {cartItem.optionId === 4 && (
                                                <div className="check-dropbox-body">
                                                  <div className="form-group">
                                                    <IonLabel>Your Last Name </IonLabel>
                                                    <IonInput
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="e.g. Smilth"
                                                      value={
                                                        cartItem.optionData &&
                                                        cartItem.optionData.last_name
                                                      }
                                                      onIonChange={(e) => handleChanges(e, "last_name", cartIndex)}
                                                    />
                                                    {!isEmpty(errorRxState[cartIndex]) &&
                                                    !isEmpty(
                                                      errorRxState[cartIndex].last_name
                                                    ) && (
                                                      <p className="errormsg">
                                                        {errorRxState[cartIndex].last_name[0]}
                                                      </p>
                                                    )}
                                                  </div>

                                                  <div className="form-group">
                                                    <IonLabel>
                                                      Your Date of Birth{" "}
                                                    </IonLabel>
                                                    {showCalendar ? (
                                                      <div className="commonCalendar">
                                                        <Calendar
                                                          value={cartItem.optionData.dob ? new Date(cartItem.optionData.dob) : new Date()}
                                                          maxDate={new Date()}
                                                          calendarType="US"
                                                          onChange={(e) => {
                                                            handleChanges(
                                                              {
                                                                detail: {
                                                                  value: convertTimeStampToDateYYYYMMDD(
                                                                    e
                                                                  ),
                                                                },
                                                              },
                                                              "dob",
                                                              cartIndex
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="icon-input icon-input--dob">
                                                        <IonInput
                                                          className="form-control"
                                                          type="text"
                                                          placeholder="e.g. 01/11/2020"
                                                          value={cartItem.optionData.dob}
                                                          disabled
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            onClick={() =>
                                                              setShowCalendar(true)
                                                            }
                                                          >
                                                            <IonIcon
                                                              icon={calendarClearOutline}
                                                            />
                                                          </a>
                                                        </IonInput>
                                                      </div>
                                                    )}
                                                    {!isEmpty(errorRxState[cartIndex]) &&
                                                    !isEmpty(
                                                      errorRxState[cartIndex].dob
                                                    ) && (
                                                      <p className="errormsg">
                                                        {errorRxState[cartIndex].dob[0]}
                                                      </p>
                                                    )}
                                                    <p className="perta-brif">
                                                      Please enter the phone number that
                                                      your doctor’s office has on file for
                                                      you. We will send you a verification
                                                      code via text to confirm identity
                                                    </p>
                                                  </div>

                                                  <div className="form-group">
                                                    <IonLabel>
                                                      Your Phone number{" "}
                                                    </IonLabel>
                                                    <IonInput
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="e.g. (123)456-7890"
                                                      value={
                                                        cartItem.optionData &&
                                                        cartItem.optionData.phone
                                                      }
                                                      onIonChange={(e) =>
                                                        handleChanges(e, "phone", cartIndex)
                                                      }
                                                    />
                                                    {!isEmpty(errorRxState[cartIndex]) &&
                                                    !isEmpty(
                                                      errorRxState[cartIndex].phone
                                                    ) && (
                                                      <p className="errormsg">
                                                        {errorRxState[cartIndex].phone[0]}
                                                      </p>
                                                    )}
                                                  </div>

                                                  {!isEmpty(errorRxState[cartIndex]) &&
                                                  !isEmpty(
                                                    errorRxState[cartIndex].verify
                                                  ) && (
                                                    <p className="errormsg">
                                                      {errorRxState[cartIndex].verify[0]}
                                                    </p>
                                                  )}
                                                  <div className="check-dropbox-bottom">
                                                    <IonButton
                                                      className="theme-btn button-grey"
                                                      onClick={() => verifyRx(cartIndex)}
                                                      disabled={cartItem.isVerified || spinnerState === `verify${cartIndex}`}
                                                    >
                                                      <span>Confirm</span>
                                                    </IonButton>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="btn-wrap">
                                  <div className="request-insurance-btn">
                                    <IonButton
                                      className="theme-btn button-blue btn-full-width"
                                      disabled={isButtonDisabled}
                                      onClick={() => onSubmitStep2()}
                                    >
                                      Continue
                                    </IonButton>
                                  </div>

                                  <div className="request-insurance-btn">
                                    <IonButton className="theme-btn button-blue-outline button-blue-outline-no-margin btn-full-width" onClick={() => onSubmitStep2()}>
                                      <span>Skip</span>
                                    </IonButton>
                                  </div>

                                  <div className="request-insurance-btn">
                                    <IonButton className="theme-btn button-blue-outline btn-full-width" onClick={onPrevious}>
                                      <span>Previous</span>
                                    </IonButton>
                                  </div>
                                </div>
                              </IonCol>
                            </>
                            :
                            currentPage === 3 && renderFinishComponent()
                        }
                      </IonRow>
                    </IonGrid>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
}

export default RequestPriceModal
