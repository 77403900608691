import {
  IonIcon,
} from "@ionic/react";
import { addOutline ,removeOutline} from "ionicons/icons";
import React, { useState } from "react";
import "./landingFaq.scss";
import {getFaqList}  from "../../Redux/actions/landingFaqAction"
import { useDispatch } from "react-redux";


const LandingFaq = (props) => {
  const dispatch = useDispatch();
  const [DataArray, setDataArray] = useState([]);
  const [itemsList, setItemsList] = useState([]);

  const onClickQuestion =  (e, index, itemsList2) => {
    e.preventDefault();
    console.log('index', index, DataArray, itemsList, itemsList2);
    if(!DataArray[index]?.open)
    DataArray[index]['open']=true;
    else
    delete(DataArray[index]?.open)
    setDataArray([...DataArray]);
  };

  React.useEffect(  () => {
    let itemsListRaw = DataArray.map((value,index) => {
      return (
        <div class="faq-accordion" key={index}>
                <div  onClick={(e) => onClickQuestion(e,index, DataArray)} className="faq-accordion-btn">
                  <a href="javascript:;">
                    {/* Question{index+1}: {value.name} */}
                    {value.name}
                    <span>
                      <IonIcon icon={(DataArray[index]?.open) ? `${removeOutline}` : `${addOutline}`} />
                    </span>
                  </a>
                </div>
                <div className={(DataArray[index]?.open)?'open_class faq-accordion-content':'faq-accordion-content'} >
                  <p >
                  {value.description}
                  </p>
                </div>
              </div>


      )
    });
    setItemsList(itemsListRaw);
  },[DataArray]);
  const getFaqData = async ()=>{
    const responseData =  await dispatch(getFaqList(0));
    let dataArrayRaw=[
      {name:'What is Qwark?', description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'},
      {name:'What is Qwark?', description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'},
      {name:'What is Qwark?', description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'},
      {name:'What is Qwark?', description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'}
    ];

    if(responseData.data?.data.length){
      setDataArray(responseData.data?.data);
    }
  }
  React.useEffect(() => {

    getFaqData();

  }, []);











  return (




    <div className="common-faq">

      <div className="space-container">
        <div className="common-faq-heading">
          <h3>Frequently Asked Questions</h3>
        </div>
        <div className="common-faq-inner">
          {itemsList}
       </div>
      </div>
    </div>
  );
};

export default LandingFaq;
