import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonContent,
  IonCheckbox,
  IonModal,
} from "@ionic/react";
import {
  closeOutline,
  trashOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./shoppingCart.scss";
import { menuController } from "@ionic/core";
import { useDispatch, useSelector } from "react-redux";
import { checkoutAction, productAction } from "../../Redux/actions";
import { getSubtotalPrice, isEmpty } from "../../Utils/helper";
import closeSvgIcon from "../../images/close-icon.svg";
import LoginPopup from "../loginPopup";
import RequestPricePopup from "../requestPricePopup";

const ShoppingCart = (props) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const authData = useSelector((state) => state.authReducer.authData);
  const [state, setState] = useState([]);
  const [productState, setProductState] = useState({});
  const [allProduct, setAllProduct] = useState({});
  const [changeTimeNumber, setChangeTimeNumber] = useState(0);
  const [editCartIndex, setEditCartIndex] = useState(0);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [requestPriceModal, setRequestPriceModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState({});
  const [promoCodeState, setPromoCodeState] = useState({
    isApplied: false,
    promoCode: "",
    isApplySuccessful: false,
    errorMessage: "",
    discountPercent: null,
  });

  //modal
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [priceMatchModal, setPriceMatchModal] = useState(false);
  const [priceMatchSucceed, setPriceMatchSucceed] = useState(false);

  const [priceMatchState, setPriceMatchState] = useState({
    id: '',
    name: "",
    price: "",
    address: "",
    email: "",
  });

  const [errorState, setErrorState] = useState({
    price: [],
    address: [],
    email: [],
  });

  React.useEffect(() => {
    menuController.enable(false);
    loadData();
    loadProductData();
  }, []);

  React.useEffect(() => {
    loadData();
  }, [cartItems]);


  useEffect(() => {
    loadProductData();
  }, [cartItems.length]);

  useEffect(() => {
    const product = state[editCartIndex];
    if (!product) return;

    let { activeMedicineFormId, medicineId, activeQuantityIndex, activeMedicineStrengthId } = product;
    if (!allProduct[medicineId]) return;

    const form = allProduct[medicineId].find(i => i.form_id === activeMedicineFormId && i.product_id === medicineId);
    if (!form) return;

    let strength = form.value.find(i => i.Strength_Id === activeMedicineStrengthId);
    if (!strength) {
      strength = form.value[0]
      activeQuantityIndex = 0
      const quantityDefault = strength.day_supply[0].quantity
      state[editCartIndex]["medicineStrength"] = getStrengthName(strength);
      state[editCartIndex]["activeMedicineStrengthIndex"] = 0;
      state[editCartIndex]["activeMedicineStrengthId"] = strength.Strength_Id;
      state[editCartIndex]["activeQuantityIndex"] = 0;
      state[editCartIndex]["activeQuantity"] = quantityDefault;
    }

    let { price } = strength.day_supply[activeQuantityIndex];

    if (parseInt(price) === 0) {
      price = ((strength.cost_per_dose * strength.day_supply[activeQuantityIndex].qauntity + strength.shipping_cost) * (1 + strength.cc_cost_variable / 100) + strength.cc_cost_fixed) * (1 + strength.margin / 100);
    }

    state[editCartIndex]['price'] = parseFloat(price).toFixed(2)
    state[editCartIndex]['unitStrengthCode'] = strength.unit_strength_code
    state[editCartIndex]['unitStrengthQty'] = strength.unit_strength_qty

    setState([...state])
  }, [changeTimeNumber])


  const handleChange = async (event, name) => {
    const { value } = event.detail;
    setPriceMatchState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      price: [],
      address: [],
      email: [],
    };
    if (priceMatchState.price.trim().length === 0) {
      isFormValid = false;
      testError.price = ["Competitor's Price must be inputed!"];
    }
    if (priceMatchState.price === 0) {
      isFormValid = false;
      testError.price = ["Competitor's Price cannot be zero!"];
    }
    if(priceMatchState.address.trim().length === 0) {
      isFormValid = false;
      testError.address = ["Website Address cannot be empty!"];
    }
    if (
      priceMatchState.address.trim().length > 0 &&
      /^(ftp|http|https):\/\/[^ "]+$/.test(
        priceMatchState.address
      ) === false
    ) {
      isFormValid = false;
      testError.address = ["Website Address must be a valid address"];
    }
    if (priceMatchState.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["Email cannot be empty!"];
    }
    if (
      priceMatchState.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        priceMatchState.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }

    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    if (priceMatchSucceed) {
      setPriceMatchModal(false);
      setTimeout(() => setPriceMatchSucceed(false), 500)
      return;
    }

    if (!validateForm()) {
      return null;
    }
    const response = await dispatch(productAction.insertPriceMatch(priceMatchState));
    if (response && response.success) {
      // resendCode(priceMatchState.email, priceMatchModal.name);
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }

    setPriceMatchSucceed(true);

    await cleanPriceMatchData();
  };

  const resendCode = async (email, name) => {
    await dispatch(productAction.resendCode(email, name))
    setTimeout(() => {
        dispatch({type: "CLEAN_ERROR"})
    }, 8000)
  }
  const cleanPriceMatchData = () => {
    priceMatchState.id = '';
    priceMatchState.price = '';
    priceMatchState.address = "";
    priceMatchState.email = "";
  }
  const loadProductData = async () => {
    if (!isEmpty(cartItems) && cartItems !== "null") {
      cartItems.map(async (item, index) => {
        const response = await dispatch(
          productAction.getFormAndStrengthDetailsById(parseInt(item.medicineId))
        );

        if (response && response.success) {
          let productItem = [];
          //Set quantity and price of product
          response.data.map((item, index) => {
            let qtyPriceDetails = [];
            let data = item.daysupply_detail;

            let priceData = data.split(",");
            priceData.map((qtyPrice) => {
              let item = qtyPrice.split("_");
              qtyPriceDetails.push({ qauntity: item[0], price: item[1] });
            });
            response.data[index]["qtyPriceDetails"] = qtyPriceDetails;

            // Check form exists in productItem or not
            let checkFormExistsIndex = productItem.findIndex(function (info) {
              if (info !== undefined) {
                return info.form_id === item.form_id;
              }
            });

            if (checkFormExistsIndex !== -1) {
              productItem[checkFormExistsIndex].value.push({
                strength_mapping_id: item.strength_mapping_id,
                Strength_Id: item.Strength_Id,
                unit_strength_code: item.unit_strength_code,
                unit_strength_qty: item.unit_strength_qty,
                cost_per_dose: item.cost_per_dose,
                shipping_cost: item.shipping_cost,
                cc_cost_variable: item.cc_cost_variable,
                cc_cost_fixed: item.cc_cost_fixed,
                margin: item.margin,
                day_supply: qtyPriceDetails,
              });
            } else {
              productItem[index] = {
                product_id: item.product_id,
                formName: item.formName,
                form_id: item.form_id,
                value: [
                  {
                    strength_mapping_id: item.strength_mapping_id,
                    Strength_Id: item.Strength_Id,
                    unit_strength_code: item.unit_strength_code,
                    unit_strength_qty: item.unit_strength_qty,
                    cost_per_dose: item.cost_per_dose,
                    shipping_cost: item.shipping_cost,
                    cc_cost_variable: item.cc_cost_variable,
                    cc_cost_fixed: item.cc_cost_fixed,
                    margin: item.margin,
                    day_supply: qtyPriceDetails,
                  },
                ],
              };
            }
          });
          const productId = productItem.length && productItem[0].product_id
          setAllProduct(state => ({...state, [productId]: productItem }));
        }
      });
    }
  };

  const loadData = async () => {
    if (!isEmpty(cartItems) && cartItems !== "null") {
      setState(
        cartItems.map((item, index) => ({
          ...item,
          isEdit: false,
          cartIndex: index,
          price_selected: !Boolean(item.insurance_price_selected),
          insurance_price_selected: Boolean(item.insurance_price_selected)
        }))
      );

      if (
        !isEmpty(cartItems[0].promoCode) &&
        cartItems[0].promoCode !== "null"
      ) {
        setPromoCodeState((prevState) => ({
          ...prevState,
          promoCode: cartItems[0].promoCode,
          discountPercent: cartItems[0].discountPercent,
          isApplySuccessful: true,
          errorMessage: "",
        }));
      }
    }
  };

  const saveCardChanges = async (newCartState) => {
    await localStorage.setItem("cart", JSON.stringify(newCartState));
    await dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
    await dispatch(productAction.updateDeliveryCost());
  };

  const updateCartItem = async (newCartState) => {
    await localStorage.setItem("cart", JSON.stringify(newCartState));
    await dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
    await dispatch(productAction.updateDeliveryCost());
    await dispatch(productAction.saveSessionCartIntoDB(authData.id));
  };

  const openModal = (type, index) => {
    if (type === "edit") {
      setEditModal(true);
    } else if(type === "priceMatch") {
      if(state != undefined) {
        priceMatchState.id = state[index].medicineId;
        priceMatchState.name = state[index].medicineName;
      }
      setPriceMatchModal(true);
    } else {
      setDeleteModal(true);
    }
    setEditCartIndex(index);
  };

  const onConfirmEditDeleteCart = async (type) => {
    if (type === "edit") {
      const medicineId = state[editCartIndex].medicineId
      const newState = state.map((item, index) => {
          if (index === editCartIndex) {
            return { ...item, isEdit: true };
          } else {
            delete item.isEdit
            return item
          }
        }
      )
      setState(newState);
      setEditModal(false);
      setProductState(allProduct[medicineId])
    } else if (type === "delete") {
      let newCartState = state;
      await dispatch(productAction.deleteFromCart(newCartState[editCartIndex], authData.id || null));
      newCartState.splice(editCartIndex, 1);
      saveCardChanges(newCartState);
      setDeleteModal(false);
    }
  };

  const handleClick = (name, value, index, id, cartIndex) => {
    let allCartItems = state;
    if (name === "price") {
      // let priceValue = value.split("-")[1];
      // allCartItems[cartIndex][name] = parseFloat(priceValue);
      allCartItems[cartIndex]["activeQuantityIndex"] = index;
      allCartItems[cartIndex]["activeQuantity"] = parseInt(value.split("-")[0]);
    } else if (name === "medicineStrength") {
      const cartDetail = allCartItems[cartIndex]
      const { activeMedicineFormId } = cartDetail
      const productDetail = productState.find(i => i.form_id === activeMedicineFormId)
      const strength = productDetail.value.find(i => i.Strength_Id === id)
      const quantityDefault = strength.day_supply[0].qauntity
      allCartItems[cartIndex][name] = value;
      allCartItems[cartIndex]["activeMedicineStrengthIndex"] = index;
      allCartItems[cartIndex]["activeMedicineStrengthId"] = id;
      allCartItems[cartIndex]["activeQuantityIndex"] = 0;
      allCartItems[cartIndex]["activeQuantity"] = +quantityDefault;
    } else if (name === "medicineForm") {
      allCartItems[cartIndex][name] = value;
      allCartItems[cartIndex]["activeMedicineFormIndex"] = index;
      allCartItems[cartIndex]["activeMedicineFormId"] = id;
    }

    setState([...allCartItems]);
    setChangeTimeNumber(changeTimeNumber + 1)
  };

  const checkInsurancePriceClick = (product) => {
    setSelectedCart({ data: product });
    if (authData.id === undefined)
      setShouldLogin(true);
    else {
      setRequestPriceModal(true);
    }
  };

  const requestLoginSuccess = () => {
    setShouldLogin(false)
    setRequestPriceModal(true)
  }

  const getCartSubtotal = () => {
    const subtotal = getSubtotalPrice(state);
    return parseFloat(subtotal.toString()).toFixed(2);
  };

  const applyDiscount = () => {
    let subtotal = getCartSubtotal();
    let discount = promoCodeState.discountPercent;
    discount = (subtotal * discount) / 100;
    return parseFloat(discount.toString()).toFixed(2);
  };

  const getTotal = () => {
    const subtotal = getCartSubtotal();
    const appliedDiscount = applyDiscount();
    const total = subtotal - appliedDiscount;
    return total.toFixed(2);
  };

  const showRequestInsuranceButton = (productState) => {
    const { request_insurance_price, display_for_user, insurance_price } = productState
    if (request_insurance_price && display_for_user && insurance_price) {
      return renderInsurancePrice(productState)
    }

    if (request_insurance_price) {
      return (
        <div className="shopping-price">
          <div className="request-insurance-btn">
            <IonButton className="theme-btn button-blue-outline">
              <span>Insurance Price Requested</span>
            </IonButton>
          </div>
        </div>
      )
    }

    return (
      <div className="shopping-price">
        <div className="request-insurance-btn">
          <IonButton className="theme-btn button-blue-outline" onClick={() => checkInsurancePriceClick(productState)}>
            <span>Request Insurance Price</span>
          </IonButton>
        </div>
      </div>
    );
  }

  const proceedToCheckOut = async () => {
    setReminderModal(false);
    if (authData.id !== undefined) {
      if (promoCodeState.isApplySuccessful) {
        let orderTotal = getCartSubtotal();
        const response = await dispatch(
          checkoutAction.validateAppliedCoupon(
            orderTotal,
            promoCodeState.promoCode
          )
        );

        if (!response.data.isValid) {
          setPromoCodeState((prevState) => ({
            ...prevState,
            isApplySuccessful: false,
            isApplied: true,
            discountPercent: 0,
            errorMessage: response.data?.message,
          }));

          //Set discount on each item in the cart
          const updatedCart = cartItems.map((item, index) => ({
            ...item,
            discountPercent: 0,
            promoCode: null,
          }));
          setState(updatedCart);
          saveCardChanges(updatedCart);
        } else {
          props.history.push("/checkout_conditions");
        }
      } else {
        props.history.push("/checkout_conditions");
      }
    } else {
      props.history.push("/welcome");
    }
  };

  const applyCoupon = async () => {
    let orderTotal = getCartSubtotal();
    const response = await dispatch(
      checkoutAction.validateAppliedCoupon(orderTotal, promoCodeState.promoCode)
    );

    if (response.data?.isValid) {
      setPromoCodeState((prevState) => ({
        ...prevState,
        discountPercent: response.data.discountPercent,
        isApplySuccessful: true,
        errorMessage: "",
      }));

      //Set discount on each item in the cart
      const updatedCart = cartItems.map((item, index) => ({
        ...item,
        discountPercent: response.data.discountPercent,
        promoCode: promoCodeState.promoCode,
      }));
      setState(updatedCart);
      saveCardChanges(updatedCart);
    } else
      setPromoCodeState((prevState) => ({
        ...prevState,
        isApplySuccessful: false,
        errorMessage: response.data?.message,
      }));
  };

  const removeCoupon = async () => {
    const updatedCart = cartItems.map((item, index) => ({
      ...item,
      discountPercent: null,
      promoCode: "",
    }));
    setState(updatedCart);
    saveCardChanges(updatedCart);

    setPromoCodeState((prevState) => ({
      ...prevState,
      isApplied: false,
      promoCode: "",
      isApplySuccessful: false,
      errorMessage: "",
      discountPercent: null,
    }));
  };

  const getStrengthName = (cartInfo) => {
    if (cartInfo.unit_strength_code.includes('/')) {
      const quantity = cartInfo.unit_strength_qty.toString().split('/')
      const code = cartInfo.unit_strength_code.toString().split('/')

      if (quantity.length === 2 && code.length === 2) {
        return `${quantity[0]} ${code[0]} / ${quantity[1]} ${code[1]}`
      }

      return (
        <>
          {cartInfo.unit_strength_qty}
          <br/>
          {cartInfo.unit_strength_code}
        </>
      )
    }

    return `${cartInfo.unit_strength_qty} ${cartInfo.unit_strength_code}`
  }

  function renderEmptyCart() {
    return <div className="theme-card">
      <div className="card-body">
        <p>Your cart is empty!</p>
      </div>
    </div>;
  }

  const renderInsurancePrice = (cartItem) => {
    const { insurance_price = '0', insurance_price_selected } = cartItem
    return (
      <div className="shopping-price">
        <div className="shopping-price-box bg-light-blue">
          <IonCheckbox checked={insurance_price_selected} onIonChange={(e) => priceChange(e, 'insurance-price', cartItem)}/>
          <p>
            ${parseFloat(insurance_price).toFixed(2)}
            <span>/ Insurance Price</span>
          </p>
        </div>
        <div className="shopping-price-info"/>
      </div>
    )
  }

  const priceChange = (e, name, cartItem) => {
    const { target: { checked } } = e
    const { request_insurance_price, id, insurance_price_selected } = cartItem
    const hasInsurancePrice = Boolean(request_insurance_price)
    const index = state.findIndex(i => i.id === id)

    if (checked) {
      if (name === 'insurance-price' && !insurance_price_selected) {
        cartItem.price_selected = false
        cartItem.insurance_price_selected = true
        state[index] = {...state[index], ...cartItem}
      }

      if (name === 'price' && hasInsurancePrice) {
        cartItem.price_selected = true
        cartItem.insurance_price_selected = false
        state[index] = {...state[index], ...cartItem}
      }
    }
    dispatch(productAction.updateCartById({ id: cartItem.id, data: { insurance_price_selected: cartItem.insurance_price_selected }}));
    localStorage.setItem('cart', JSON.stringify(state))
    setState([...state])
  }

  function renderCashPrice(cartItem, index) {
    const { request_insurance_price, display_for_user, insurance_price, price_selected } = cartItem
    const hasInsurancePrice = request_insurance_price && display_for_user && insurance_price
    return (
      <div className="shopping-price">
        <div className={ hasInsurancePrice ? 'shopping-price-box bg-light-green' : 'shopping-price-box bg-light-blue' }>
          <IonCheckbox checked={price_selected} onIonChange={(e) => priceChange(e, 'price', cartItem)} />
          <p>
            ${parseFloat(cartItem.price).toFixed(2)}
            <span>/ Cash Price</span>
          </p>
        </div>
        <div className="shopping-price-info">
          <p> Want price match? Click{" "} <a onClick={() => openModal("priceMatch", index)}>here.</a></p>
        </div>
      </div>
    );
  }

  return (
    <div className="shoppingCart">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="7">
              <div className="shoppingCart-left">
                <div className="shoppingCart-left-parent">
                  <div className="shoppingCart-left-heading">
                    <h2>Your cart</h2>
                  </div>
                </div>

                <div className="shoppingCart-left-inner">
                  {isEmpty(state) ? renderEmptyCart() : (
                    state.map((cartItem, index) => (
                      <div className="theme-card" key={index}>
                        <div className="card-body">
                          <IonGrid>
                            <IonRow>
                              <IonCol size="12" size-md="12" size-lg="6">
                                <div className="shopping-item">
                                  <h3>{cartItem.medicineName}</h3>
                                  <p>
                                    {cartItem.medicineForm} •{" "}
                                    {cartItem.medicineStrength}{" "}
                                  </p>
                                </div>

                                {/* shopping-item-form start*/}
                                {cartItem.isEdit ? (
                                  <>
                                    {/* Latest card start */}
                                    <div className="shopping-item-form">
                                      {/* test */}
                                      <div className="medicine-item">
                                        {/* item */}
                                        <div className="form-group">
                                          <IonLabel>Form</IonLabel>
                                          <div className="medicine-item-list">
                                            {!isEmpty(productState) &&
                                            productState.map(
                                              (productInfo, index) => (
                                                <a
                                                  onClick={() =>
                                                    handleClick(
                                                      "medicineForm",
                                                      productInfo.formName,
                                                      index,
                                                      productInfo.form_id,
                                                      cartItem.cartIndex
                                                    )
                                                  }
                                                  className={productInfo.form_id == cartItem.activeMedicineFormId
                                                    ? "active"
                                                    : ""
                                                  }
                                                >
                                                  {productInfo.formName}
                                                </a>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        {/* item */}
                                        <div className="form-group">
                                          <IonLabel>Strength</IonLabel>
                                          <div className="medicine-item-list">
                                            {!isEmpty(productState) &&
                                            cartItem.activeMedicineFormIndex >
                                            -1 &&
                                            productState[
                                              cartItem.activeMedicineFormIndex
                                              ].value.map(
                                              (productInfo, index) => (
                                                <a
                                                  onClick={() =>
                                                    handleClick(
                                                      "medicineStrength",
                                                      productInfo.unit_strength_qty +
                                                      productInfo.unit_strength_code,
                                                      index,
                                                      productInfo.Strength_Id,
                                                      cartItem.cartIndex
                                                    )
                                                  }
                                                  className={productInfo.Strength_Id == cartItem.activeMedicineStrengthId
                                                    ? "active"
                                                    : ""
                                                  }
                                                >
                                                  {getStrengthName(productInfo)}
                                                </a>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        {/* item */}
                                        <div className="form-group">
                                          <IonLabel>Package Size</IonLabel>
                                          <div className="medicine-item-list">
                                            {!isEmpty(productState) &&
                                            cartItem.activeMedicineFormIndex > -1 &&
                                            cartItem.activeMedicineStrengthIndex > -1 &&
                                            productState[cartItem.activeMedicineFormIndex].value.map((cartInfo, index) => {
                                              return (
                                                cartItem.activeMedicineStrengthId ===
                                                cartInfo.Strength_Id &&
                                                cartInfo.day_supply.map(
                                                  (qtyPriceInfo, index) => (
                                                    <a
                                                      onClick={() =>
                                                        handleClick(
                                                          "price",
                                                          qtyPriceInfo.qauntity +
                                                          "-" +
                                                          qtyPriceInfo.price,
                                                          index,
                                                          cartItem.activeMedicineFormIndex,
                                                          cartItem.cartIndex
                                                        )
                                                      }
                                                      className={index === cartItem.activeQuantityIndex && "active"}
                                                    >
                                                      {qtyPriceInfo.qauntity}
                                                    </a>
                                                  )
                                                )
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="shopping-item-form-btn">
                                        <IonButton className="theme-btn button-blue" onClick={() => updateCartItem(state, cartItem)}>
                                          <span>Save</span>
                                        </IonButton>
                                        <IonButton className="theme-btn button-grey" onClick={() => loadData()}>
                                          <span>Cancel</span>
                                        </IonButton>
                                      </div>
                                    </div>
                                    {/* Latest card end */}
                                  </>
                                ) : (
                                  <div className="shopping-item-button">
                                    {
                                      cartItem.request_insurance_price
                                      ? (
                                          <div className="tooltip theme-btn button-blue-outline">
                                            Edit
                                            <span className="tooltiptext">Please delete the product and add the right product and request insurance price</span>
                                          </div>
                                        )
                                        : (
                                          <IonButton
                                            className="theme-btn button-blue-outline"
                                            onClick={() => openModal("edit", index)}
                                          >
                                            <span>Edit</span>
                                          </IonButton>
                                        )
                                    }
                                    <a
                                      className="item-delete-icon"
                                      onClick={() => openModal("delete", index)}
                                    >
                                      <IonIcon icon={trashOutline} />
                                    </a>
                                  </div>
                                )}

                                {/* shopping-item-form end*/}
                              </IonCol>
                              <IonCol size="12" size-md="12" size-lg="6">
                                {renderCashPrice(cartItem, index)}
                                {Boolean(cartItem.prescriptionRequired) && showRequestInsuranceButton(cartItem)}
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      </div>
                    ))
                  )}
                  {(isEmpty(state) || state.length === 1)&&<div className="cart-info-pera">
                    <p>
                      Add 2 or more items and upgrade to USPS priority shipping
                      or Next Day home delivery, where available, for $0.99.
                    </p>
                  </div>}
                </div>
              </div>
            </IonCol>
            <IonCol size="12" size-md="4" offset-md="1">
              <div className="shopping-summary">
                <div className="shopping-summary-head">
                  <h2>Summary</h2>
                </div>
                <div className="shopping-summary-body">
                  <table>
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td>${getCartSubtotal()}</td>
                      </tr>
                      <tr>
                        <td className="green">Discount</td>
                        <td className="green">-${applyDiscount()}</td>
                      </tr>
                      <tr>
                        <td>Shipping</td>
                        <td>$0.00</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Order total</td>
                        <td>${getTotal()}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div className="shopping-summary-bottom">
                  {/* promocode start */}
                  <div className="promocode-detail">
                    {
                      /* when promo code is applied successfully */
                      promoCodeState.isApplySuccessful ? (
                        <div>
                          <div className="promocode-apply-detail">
                            <p>Code applied!</p>
                          </div>
                          <div className="promocode-remove">
                            <a onClick={() => removeCoupon()}>Remove</a>
                          </div>
                        </div>
                      ) : /* when promo code not applied : input field for promo code*/
                      promoCodeState.isApplied ? (
                        <div className="promocode-input">
                          <div className="form-group">
                            <IonLabel>Promo/Invite Code</IonLabel>
                            <IonInput
                              type="text"
                              className="form-control"
                              placeholder="e.g. 03/12"
                              value={promoCodeState.promoCode}
                              onIonChange={(e) =>
                                setPromoCodeState((prevState) => ({
                                  ...prevState,
                                  promoCode: e.detail.value,
                                }))
                              }
                            />
                            {!isEmpty(promoCodeState.errorMessage) && (
                              <p className="errormsg">
                                <IonIcon icon={closeOutline} />
                                {promoCodeState.errorMessage}
                              </p>
                            )}
                          </div>
                          <div className="promocode-apply-btns">
                            <IonButton
                              className="theme-btn button-blue"
                              disabled={isEmpty(promoCodeState.promoCode)}
                              onClick={() => applyCoupon()}
                            >
                              <span>Apply</span>
                            </IonButton>
                            <IonButton
                              className="theme-btn button-grey"
                              onClick={() =>
                                setPromoCodeState((prevState) => ({
                                  ...prevState,
                                  promoCode: "",
                                  errorMessage: "",
                                  isApplied: false,
                                }))
                              }
                            >
                              <span>Cancel</span>
                            </IonButton>
                          </div>
                        </div>
                      ) : (
                        /* button for input promo code ===> default */
                        <IonButton
                          expand="block"
                          className="theme-btn button-blue-outline"
                          disabled={isEmpty(state)}
                          onClick={() =>
                            setPromoCodeState((prevState) => ({
                              ...prevState,
                              isApplied: true,
                            }))
                          }
                        >
                          <span>Apply promo/invite code</span>
                        </IonButton>
                      )
                    }
                  </div>

                  {/* promocode end */}
                  <IonButton
                    expand="block"
                    className="theme-btn button-blue"
                    disabled={isEmpty(state)}
                    onClick={() => setReminderModal(true)}
                  >
                    <span>Proceed to checkout</span>
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      {/* modal delete start */}
      <IonModal
        isOpen={deleteModal}
        cssClass="theme-modal delete-medicine-modal"
      >
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>Are you sure you want to delete?</h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-blue"
              onClick={() => setDeleteModal(false)}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-grey"
              onClick={() => onConfirmEditDeleteCart("delete")}
            >
              <span>Delete</span>
            </IonButton>
          </div>
        </div>
      </IonModal>
      {/* modal delete end */}

      {/* modal edit start */}
      <IonModal isOpen={editModal} cssClass="theme-modal delete-medicine-modal">
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>Are you sure you want to edit?</h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-blue"
              onClick={() => setEditModal(false)}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-grey"
              onClick={() => onConfirmEditDeleteCart("edit")}
            >
              <span>Edit</span>
            </IonButton>
          </div>
        </div>
      </IonModal>

      {/* modal priceMatch start */}
      <IonModal
        isOpen={priceMatchModal}
        cssClass={`theme-modal price-match-modal ${priceMatchSucceed ? 'thank-you' : ''}`}
        onDidDismiss={() => setPriceMatchModal(false)}
      >
        <IonContent>
          <div className="modal-body">
            <div className="price-match-content">
              <h3>{priceMatchSucceed ? 'Thank you!' : 'Price Match'}</h3>
              <div className="price-match-explanation">
                <p>
                  {priceMatchSucceed ?
                    'We will get back to you shortly!' :
                    `Please enter the competitor's price and the website where you see the price, and you will get back to you shortly.`
                  }
                </p>
              </div>
              {!priceMatchSucceed && <>
                <div className="form-group">
                  <IonLabel className="Med-name">Medicine Name</IonLabel>
                  <IonInput
                    className="form-control"
                    type={"text"}
                    onIonChange={(e) => handleChange(e, "name")}
                    value={priceMatchState.name}
                  />
                </div>
                <div className="form-group">
                  <IonLabel>Competitor's Price</IonLabel>
                  <IonInput
                    className="form-control"
                    type={"text"}
                    placeholder={"e.g. $123"}
                    onIonChange={(e) => handleChange(e, "price")}
                    value={priceMatchState.price}
                  />
                  {errorState.price.length !== 0 &&
                  errorState.price[0] && (
                    <p className="errormsg">{errorState.price[0]}</p>
                  )}
                </div>
                <div className="form-group">
                  <IonLabel>Website Address</IonLabel>
                  <IonInput
                    className="form-control"
                    type={"text"}
                    placeholder={"e.g. https://qwark.com"}
                    onIonChange={(e) => handleChange(e, "address")}
                    value={priceMatchState.address}
                  />
                  {errorState.address.length !== 0 &&
                  errorState.address[0] && (
                    <p className="errormsg">{errorState.address[0]}</p>
                  )}
                </div>
                <div className="form-group">
                  <IonLabel>Your Email Address</IonLabel>
                  <IonInput
                    className="form-control"
                    type={"text"}
                    placeholder={"e.g. 123@gmail.com"}
                    onIonChange={(e) => handleChange(e, "email")}
                    value={priceMatchState.email}
                  />
                  {errorState.email.length !== 0 &&
                  errorState.email[0] && (
                    <p className="errormsg">{errorState.email[0]}</p>
                  )}
                </div>
              </>}
            </div>
            <div className="price-match-submit-btns">
              <IonButton
                size="large"
                className="theme-btn button-blue"
                onClick={() => onSubmit()}
              >
                {!priceMatchSucceed ? 'Submit' : 'Got it!'}
              </IonButton>
            </div>
            <img
              onClick={() => setPriceMatchModal(false)}
              className="close-icon"
              src={closeSvgIcon}
              alt="Close"
            />
          </div>
        </IonContent>
      </IonModal>
      {/* modal priceMatch end */}

      {/* <IonModal isOpen={editModal} cssClass="theme-modal delete-edit-modal">
        <div className="modal-body">
          <div className="edit-medicine-content">
            <h3>Are you sure you want to edit?</h3>
            <p>
              If you edit drug details, the insurance price will be refreshed,
              may take up to 2 hours; cash price will update instantly.
            </p>
          </div>
          <div className="edit-medicine-btns">
            <IonButton
              className="theme-btn button-blue"
              onClick={() => setEditModal(false)}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-grey"
              onClick={() => onConfirmEditDeleteCart("edit")}
            >
              <span>Edit</span>
            </IonButton>
          </div>
        </div>
      </IonModal> */}
      {/* modal edit end */}

      {/* modal reminder start */}
      <IonModal
        isOpen={reminderModal}
        cssClass="theme-modal quick-reminder-modal"
      >
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>
              Quick reminder, Qwark delivers to only California, Arizona, and
              Massachusetts at this time. We are coming to more states very
              soon!
            </h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-grey"
              onClick={() => setReminderModal(false)}
            >
              <span>Go Back</span>
            </IonButton>
            <IonButton
              className="theme-btn button-blue"
              onClick={() => proceedToCheckOut()}
            >
              <span>Continue</span>
            </IonButton>
          </div>
        </div>
      </IonModal>
      <LoginPopup isOpen={shouldLogin} onClose={() => setShouldLogin(false)} onSuccessLogin={() => requestLoginSuccess()}/>
      <RequestPricePopup
        isOpen={requestPriceModal}
        onClose={() => setRequestPriceModal(false)}
        onSuccessRequested={() => {
          setRequestPriceModal(false);
          dispatch(productAction.getCartItems(authData.id));
        }}
        cartDetails={selectedCart}
        cartIndexx={selectedCart.cartIndex}
      />
    </div>
  );
};

export default React.memo(ShoppingCart);
