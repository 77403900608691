import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonCheckbox,
  IonButton,
} from '@ionic/react';
import {
  calendarClearOutline, searchOutline, closeOutline
} from "ionicons/icons";
import React, { useState } from 'react';
import Calendar from "react-calendar";
import './switchToQwark.scss';
import { Link } from 'react-router-dom';
import { menuController } from "@ionic/core";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import {  convertTimeStampToDateYYYYMMDD, maskPhoneNumber } from "../../Utils/helper";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { profileActions } from "../../Redux/actions";

const Welcome = (props) => {
  const dispatch = useDispatch();  
  const authData = useSelector((state) => state.authReducer.authData);
  const [state, setState] = useState({
    userId: authData.id,
    firstName: '',
    lastName:'',
    dob: '',
    phone:'',
    email: '',
    pharmacy:'',
    isPolicyAccepted: false,
  })
  const [errorState, setErrorState] = useState({
      firstName: [],
      lastName:[],
      dob: [],
      phone:[],
      email: [],
      pharmacy:[],
      isPolicyAccepted: [],
  })  
  const [showCalendar, setShowCalendar] = useState(false);

  React.useEffect(() => {
      menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
      clearErrors(name)      
      let value = (name === 'isPolicyAccepted') ? event.detail.checked : event.detail.value 
      if (name === "phone") {
        value = value.replace(/\D/g, "")
        if (isNaN(value)) {
            setErrorState((prevState) => ({
                ...prevState,
                phone: ["Phone number can only contain numbers !"],
            }));
        } else {
            setErrorState((prevState) => ({ ...prevState, phone: [] }));
        }
      }
      setState((prevState) => ({ ...prevState, [name]: value }));      
      if (name === "dob") {
        setShowCalendar(false);
      }
  }  

  const clearErrors = (name) => {
      dispatch({ type: "CLEAN_ERROR" })
      if (errorState[name].length !== 0) {
          setErrorState((prevState) => ({ ...prevState, [name]: [] }));
      }
  }  

  const validateForm = () => {
      let isFormValid = true;
      let testError = {
        firstName: [],
        lastName:[],
        dob: [],
        phone:[],
        email: [],
        pharmacy:[],
        isPolicyAccepted: [],
      };
      if (state.firstName.trim().length === 0) {
        isFormValid = false;
        testError.firstName = ["First Name cannot be empty!"];
      }
      if (state.lastName.trim().length === 0) {
        isFormValid = false;
        testError.lastName = ["Last Name cannot be empty!"];
      }
      if (state.dob.trim().length === 0) {
        isFormValid = false;
        testError.dob = ["Date of birth cannot be empty!"];
      }
      if (state.email.trim().length === 0) {
          isFormValid = false;
          testError.email = ["Email cannot be empty!"];
      }
      if (state.phone.trim().length === 0) {
        isFormValid = false;
        testError.phone = ["Phone number cannot be empty!"];
      }
      if (state.pharmacy.trim().length === 0) {
        isFormValid = false;
        testError.pharmacy = ["Pharmacy cannot be empty!"];
      }
      if (state.email.trim().length > 0 &&
          /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(state.email) === false) {
          isFormValid = false;
          testError.email = ["Email must be a valid email address"];
      }     

      if (!state.isPolicyAccepted) {
          isFormValid = false;
          testError.isPolicyAccepted = ['You must accept the Policies before proceeding']
      }
     
      console.error('testError ', testError)
      setErrorState(testError);
      return isFormValid;
  }

  const onSubmit = async () => {    
    if (!await validateForm()) {
        console.error('form validation failed')
        return null
    }
    
    const response = await dispatch(profileActions.switchToQwark(state))
    if (response.success) {
        props.history.push({ pathname: "/switch-welcome", state })
    } else {
        setTimeout(() => {
            dispatch({ type: "CLEAN_ERROR" })
        }, 5000)
    }
  }


  return (
      <div className="switchQwarkPage">
          <div className="space-container">
              <IonGrid>
                  <IonRow>                     
                      <IonCol size="12" size-md="12" size-lg="12">
                          <div className="theme-card">
                              <div className="card-body">
                                  <div className="parentform">
                                      <IonGrid>
                                          <IonRow>
                                              <IonCol size="12" size-lg="7">
                                                  <div className="switchQwarkPage__content">
                                                      <h2 className="switchQwarkPage__contentTitle">
                                                      Thanks for giving Qwark a try!
                                                      </h2>
                                                      <div className="switchQwarkPage__contentDesc">
                                                        Please fill out the details, and we will take care of the rest. We will reach out to you via email and text once the prescriptions have been transferred.
                                                      </div>
                                                      <ul className="switchQwarkPage__contentList">
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={wallet} alt="Qwark" />
                                                              </span>
                                                              Get up to 80% off your medications.
                                                          </li>
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={medicine} alt="Qwark" />
                                                              </span>
                                                              Thousands of meds, fullfilled by a licensed & accredited US-based pharmacy.
                                                          </li>
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={time} alt="Qwark" />
                                                              </span>
                                                              Order online and get FREE delivery.
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </IonCol>

                                              <IonCol size="12" size-lg="5">
                                                  <div className="parentform-right switchQwarkPage__form">
                                                      <div className="switchQwarkPage__formRow">
                                                          <div className="switchQwarkPage__formCol6">
                                                            <div className="form-group">
                                                                <IonLabel>First Name</IonLabel>
                                                                <IonInput className="form-control" type="text"
                                                                    placeholder="e.g. john"
                                                                    value={state.firstName}
                                                                    onIonChange={(e) => handleChange(e, 'firstName')}
                                                                />
                                                                {
                                                                    errorState.firstName.length !== 0 && errorState.firstName[0] &&
                                                                    <p className="errormsg">{errorState.firstName[0]}</p>
                                                                }
                                                            </div>
                                                          </div>
                                                          <div className="switchQwarkPage__formCol6">
                                                            <div className="form-group">
                                                                <IonLabel>Last Name</IonLabel>
                                                                <IonInput className="form-control" type="text"
                                                                    placeholder="e.g. Smith"
                                                                    value={state.lastName}
                                                                    onIonChange={(e) => handleChange(e, 'lastName')}
                                                                />
                                                                {
                                                                    errorState.lastName.length !== 0 && errorState.lastName[0] &&
                                                                    <p className="errormsg">{errorState.lastName[0]}</p>
                                                                }                                                               
                                                            </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group form-group--dob">
                                                        <IonLabel>Date of Birth</IonLabel>
                                                        <div className="icon-input">
                                                          <IonInput
                                                              className="form-control dob-input"
                                                              type="date"
                                                              placeholder="mm/dd/yyyy"
                                                              value={state.dob}
                                                              onIonChange={(e) => handleChange(e, 'dob')}
                                                          >
                                                            <a onClick={() => setShowCalendar(!showCalendar)} className="calendar-icon">
                                                              <IonIcon icon={showCalendar ? closeOutline : calendarClearOutline }/>
                                                            </a>
                                                          </IonInput>
                                                        </div>
                                                        {showCalendar && (
                                                          <div className="commonCalendar" style={{position: 'absolute', zIndex:99}}>
                                                            <Calendar
                                                              value={ state.dob ? new Date(state.dob) : new Date() }
                                                              maxDate={new Date()}
                                                              calendarType="US"
                                                              onChange={(e) => {
                                                                handleChange(
                                                                  {
                                                                    detail: {
                                                                      value: convertTimeStampToDateYYYYMMDD(e),
                                                                    },
                                                                  },
                                                                  "dob"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {
                                                            errorState.dob.length !== 0 && errorState.dob[0] &&
                                                            <p className="errormsg">{errorState.dob[0]}</p>
                                                        }
                                                      </div>
                                                      <div className="form-group">
                                                          <IonLabel>Phone Number</IonLabel>
                                                          <IonInput
                                                            className="form-control"
                                                            placeholder="e.g. (123)-456-7890"
                                                            value={maskPhoneNumber(state.phone.toString())}
                                                            onIonChange={(e) => handleChange(e, "phone")}
                                                        />                                                        
                                                          {
                                                            errorState.phone.length !== 0 && errorState.phone[0] &&
                                                            <p className="errormsg">{errorState.phone[0]}</p>
                                                          }
                                                      </div>
                                                      <div className="form-group">
                                                          <IonLabel>Email</IonLabel>
                                                          <IonInput className="form-control" type="text"
                                                              placeholder="e.g. johnsmilth@gmail.com"
                                                              value={state.email}
                                                              onIonChange={(e) => handleChange(e, 'email')}
                                                          />
                                                          {
                                                              errorState.email.length !== 0 && errorState.email[0] &&
                                                              <p className="errormsg">{errorState.email[0]}</p>
                                                          }                                                         
                                                      </div>
                                                      <div className="form-group form-group--search">
                                                          <IonLabel>Pharmacy Search</IonLabel>
                                                        <div className="icon-input">                                                           
                                                            <GooglePlacesAutocomplete
                                                                autocompletionRequest={{
                                                                  componentRestrictions: {
                                                                    country: ['us'],
                                                                  }
                                                                }}
                                                                selectProps={{
                                                                  onChange: (selectedPlace) => {
                                                                    handleChange(
                                                                      { detail: { value: selectedPlace.label } },
                                                                      "pharmacy"                                                                    )
                                                                  },
                                                                  styles: {
                                                                    input: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    option: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                      zIndex:1,
                                                                    }),
                                                                    singleValue: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    container: (provided) => ({
                                                                      ...provided,
                                                                      zIndex:1051,
                                                                    }),
                                                                    control: (provided) => ({
                                                                      ...provided,
                                                                      borderColor: 'rgba(139,139,169,.3)',
                                                                      paddingLeft: 25
                                                                    }),
                                                                    dropdownIndicator: (provided) => ({
                                                                      ...provided,
                                                                      opacity:'0%',
                                                                    }),
                                                                    placeholder: (provided) => ({
                                                                      ...provided,
                                                                      color: '#c0c1d4',
                                                                      fontFamily: 'gothamlight',
                                                                      fontSize: 13,
                                                                    }),
                                                                    indicatorSeparator: (provided) => ({
                                                                      ...provided,
                                                                      display: 'none'
                                                                    }),
                                                                  },
                                                                }}
                                                                apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                                                                className="form-control"
                                                              />
                                                              <a href="#" onClick={e => e.preventDefault()}>
                                                                <IonIcon icon={searchOutline} />
                                                                </a>                                                                
                                                            {
                                                              errorState.pharmacy.length !== 0 && errorState.pharmacy[0] &&
                                                              <p className="errormsg">{errorState.pharmacy[0]}</p>
                                                            }
                                                        </div>
                                                      </div>
                                                      <div className="parentform-check">
                                                          <IonCheckbox checked={state.isPolicyAccepted}
                                                              onIonChange={(e) => handleChange(e, 'isPolicyAccepted')} />
                                                          <p>I accept our
                                                          <Link to={'/page/terms_of_service'} target="_blank"> Terms of Service</Link>
                                                              {/* <a target='_blank' href="javascript:;" onClick={() => props.history.push("/page/terms_of_service")}>Terms of Service</a>, */}
                                                              ,<a target='_blank' href={'https://support.qwarkhealth.com/hc/en-us'}> Communication </a> and                                                               
                                                              <Link to={'/page/privacy_policy'} target="_blank"> Privacy Policy</Link>
                                                          </p>
                                                      </div>
                                                      {
                                                          errorState.isPolicyAccepted.length !== 0 && errorState.isPolicyAccepted[0] &&
                                                          <p className="errormsg">{errorState.isPolicyAccepted[0]}</p>
                                                      }

                                                      <div className="parentform-button">
                                                          <IonButton
                                                              className="theme-btn button-blue"
                                                              onClick={() => onSubmit()}><span>Submit</span>
                                                              {/*{commonData.loading && <IonSpinner />}*/}
                                                          </IonButton>

                                                      </div>
                                                  </div>
                                              </IonCol>
                                          </IonRow>
                                      </IonGrid>
                                  </div>
                              </div>
                          </div>

                      </IonCol>
                  </IonRow>
              </IonGrid>
          </div>
      </div>
  );
};

export default Welcome;
