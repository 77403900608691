import {
    IonList,
    IonItem,
} from '@ionic/react';
import React from 'react';
import './../commonSidebar/commonSidebar.scss';
import { useSelector } from 'react-redux';

const CheckoutSidebar = (props) => {
    const {pageIndex} = props
    const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);
    return (
        <div className="sidebar-content">
            <IonList>
                <IonItem>
                    <a className="active">Conditions</a>
                </IonItem>
                {
                    prescriptionRequired && (
                      <IonItem>
                          <a className={pageIndex > 1 ? 'active' : ''}> Prescription</a>
                      </IonItem>
                    )
                }
                <IonItem>
                    <a className={pageIndex > 2 ? 'active' : ''}>Delivery</a>
                </IonItem>
                <IonItem>
                    <a className={pageIndex > 3 ? 'active' : ''}>Payment</a>
                </IonItem>
                <IonItem>
                    <a className={pageIndex > 4 ? 'active' : ''}>Review</a>
                </IonItem>
            </IonList>
        </div>
    );
};

export default CheckoutSidebar;