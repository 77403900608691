import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonIcon,
  IonCheckbox,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonToggle,
  IonSpinner,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { calendarClearOutline, eyeOutline } from "ionicons/icons";
import React, { useState } from "react";
import "./checkoutPrescription.scss";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import {
  atOutline,
  informationCircleOutline,
  chevronUpOutline,
  chevronDownOutline,
  searchOutline,
} from "ionicons/icons";
import CheckoutSidebar from "../../components/checkoutSideBar/checkOutSideBar";
import MyCart from "../../components/myCart/myCart";
import {
  convertTimeStampToDateYYYYMMDD,
  handlePharmacySearchResponse,
  handlePrescriberSearchResponse,
  isEmpty,
  maskPhoneNumber,
} from "../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { profileActions, checkoutAction } from "../../Redux/actions";
import * as registrationAction from "../../Redux/actions/registrationAction";
import Calendar from "react-calendar";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const CheckoutPrescription = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [cartState, setCartState] = useState([]);
  const [showCartItemDropDown, setShowCartItemDropDown] = useState([]);
  const [spinnerState, setSpinnerState] = useState("");
  const [errorState, setErrorState] = useState("");
  const [successState, setSuccessState] = useState("");
  const [pharmacyErrorState, setPharmacyErrorState] = useState(false);
  let enableSearch = true;
  const [searchResultState, setSearchResultState] = useState([]);
  const [prescriberOptionState, setPrescriberOptionState] = useState({
    default: {},
    other: [],
  });
  const [pharmacyOptionState, setPharmacyOptionState] = useState([]);
  const [isAddNewPrescriber, setIsAddNewPrescriber] = useState(false);
  const [isAddNewPharmacy, setIsAddNewPharmacy] = useState(false);
  const [errorFormState, setErrorFormState] = useState([]);
  const [errorRxState, setErrorRxState] = useState([
    {
      last_name: [],
      dob: [],
      phone: [],
      verify: [],
    },
  ]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);

  React.useEffect(() => {
    menuController.enable(false);
    dispatch({ type: "CLEAN_ERROR" });
    loadData();
  }, []);

  React.useEffect(() => {
    loadData();
  }, [cartItems]);

  React.useEffect(() => {
    !prescriptionRequired && props.history.push({
      pathname: "/checkout_delivery",
      state: { prescriptionData: cartState },
    });
  }, [prescriptionRequired]);

  const loadData = () => {
    loadPrescriberData();
    // if (isEmpty(cartItems) || cartItems === "null") {
    //   return null;
    // }
    setCartState(
      cartItems.map((cartItem) =>
        !cartItem.optionId
          ? {
              ...cartItem,
              optionId: "",
              optionData: {},
              isSaved: false,
            }
          : cartItem
      )
    );
    setShowCartItemDropDown(cartItems.map((c) => true));
    setSearchResultState(cartItems.map((c) => []));
  };

  const toggleCartDetailDropDown = (index) => {
    let dropDownArray = showCartItemDropDown;
    dropDownArray[index] = !showCartItemDropDown[index];
    setShowCartItemDropDown([...dropDownArray]);
  };

  const handleOptionCheck = (event, optionIndex, cartIndex) => {
    const { checked } = event.detail;
    setSearchResultState(cartItems.map((c) => []));

    const allCartItems = getCartData();

    if (!checked) {
        allCartItems[cartIndex].optionId = "";
        allCartItems[cartIndex].optionData = {};
    } else {
      if (optionIndex === 2) {
        const prescriberOptionData = {
          prescriber_name: isEmpty(prescriberOptionState.default)
            ? ""
            : prescriberOptionState.default.prescriber_name,
          phone: isEmpty(prescriberOptionState.default)
            ? ""
            : prescriberOptionState.default.phone,
          fax: isEmpty(prescriberOptionState.default)
            ? ""
            : prescriberOptionState.default.fax,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = prescriberOptionData;
      } else if (optionIndex === 3) {
        const pharmacyOptionData = {
          pharmacy_name: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.pharmacy_name,
          address: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.address,
          npi: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.npi,
          phone: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.phone,
          fax: isEmpty(pharmacyOptionState.default)
            ? ""
            : pharmacyOptionState.default.fax,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = pharmacyOptionData;
      } else if (optionIndex === 4) {
        const rxOptionData = {
          last_name: "",
          dob: "",
          phone: "",
          isVerified: false,
        };

        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = rxOptionData;
      } else {
        allCartItems[cartIndex].optionId = checked ? optionIndex : "";
        allCartItems[cartIndex].optionData = { isEmailMsgSent: false };
      }
    }
    setCartState([...allCartItems]);
  };

  const sendQwarkInfoToUser = async (type, cartIndex) => {
    setSpinnerState(type);
    const response = await dispatch(
      checkoutAction.sendQwarkInfoToUser(authData.id, type)
    );
    if (response.success) {
      setSuccessState(response.message);

      let allCartItems = cartState;
      if (cartIndex === 0) {
        allCartItems = cartState.map((c) => ({
          ...c,
          optionData: { isEmailMsgSent: true },
        }));
      } else {
        allCartItems[cartIndex].optionData.isEmailMsgSent = true;
      }
      setCartState([...allCartItems]);
    } else {
      setErrorState(response.message);
    }
    setTimeout(() => {
      setSuccessState("");
      setErrorState("");
    }, 3000);
    setSpinnerState("");
  };

  const handleChange = (event, name, index) => {
    let { value } = event.detail;
    if (name === "phone") {
      value = value.replace(/\D/g, "")
      if (isNaN(+value)) {
        setSearchResultState((prevState) => (prevState[index] = []));
        return null;
      }
    } else if (name === "dob") {
      setShowCalendar(false);
    }
    if (name === 'pharmacy_name') {
      if (value.length) {
        setPharmacyErrorState(false)
      } else {
        setPharmacyErrorState(true)
      }
    }
    let allCartItems = cartState;
    allCartItems[index].optionData[name] = value;
    setCartState([...allCartItems]);
  };

  const searchName = async (value, name, index) => {
    setSpinnerState(name);
    if (!enableSearch) {
      return null;
    }
    let searchResult = [];
    if (name === "prescriber_name") {
      const response = await dispatch(
        registrationAction.searchPrescriber(authData.id, value, {
          first_name: value,
          city: true,
          userId: authData.id,
        })
      );
      searchResult = await handlePrescriberSearchResponse(response);
    } else if (name === "pharmacy_name") {
      value = value.detail.value;
      if (isEmpty(value)) {
        return setSearchResultState([]);
      }
      const response = await dispatch(
        profileActions.searchPharmacy(authData.id, value, {
          pharmacyName: value,
          city: true,
        })
      );

      if (!response.Errors) {
        searchResult = await handlePharmacySearchResponse(response);
      }
    }
    let searchState = searchResultState;
    searchState[index] = searchResult;
    setSearchResultState([...searchState]);
  };

  const onSelectFromResult = (object, name, index) => {
    let cartStateInstance = cartState;

    if (name === "prescriber") {
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          optionData: {
            prescriber_name: object.name,
            phone: object.phone,
            fax: object.fax,
          },
        }));
      } else {
        cartStateInstance[index].optionData = {
          prescriber_name: object.name,
          phone: object.phone,
          fax: object.fax,
        };
      }
    } else if (name === "pharmacy") {
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          isSaved: true,
          optionData: {
            pharmacy_name: object.name,
            address: object.address,
            npi: object.npi,
            phone: object.phone,
            fax: object.fax,
          },
        }));
      } else {
        cartStateInstance[index].optionData = {
          pharmacy_name: object.name,
          address: object.address,
          npi: object.npi,
          phone: object.phone,
          fax: object.fax,
        };
        cartStateInstance[index].isSaved = true;
      }
    }
    setCartState([...cartStateInstance]);
    setSearchResultState((prevState) => (prevState[index] = []));
  };

  const onSave = (optionId, index, ifSaveForFuturePurpose) => {
    if (optionId === 2 && (isEmpty(cartState[index].optionData.prescriber_name) || isEmpty(cartState[index].optionData.phone))){
      return null
    } else if(optionId === 3 && isEmpty(cartState[index].optionData.pharmacy_name)) {
      setPharmacyErrorState(true)
      return null
    }
    let cartStateInstance = cartState;
    if (index === 0) {
      cartStateInstance = cartState.map((c) => ({...c, isSaved: !cartState[index].isSaved}));
    } else {
      cartStateInstance[index].isSaved = !cartState[index].isSaved;
    }
    setCartState([...cartStateInstance]);

    if(ifSaveForFuturePurpose){
      if(optionId === 2){
        dispatch(profileActions.savePrescriber(authData.id, {...cartState[index].optionData, is_default: 0}))
      }else if(optionId === 3){
        dispatch(profileActions.savePharmacy(authData.id, {...cartState[index].optionData, is_default: 0}));
      }
    }
  };

  const loadPrescriberData = async () => {
    const response = await dispatch(
      profileActions.getPrescriberAndPharmacyData(authData.id)
    );
    if (response && response.success) {
      const { prescriber, pharmacy } = response.data;
      setPrescriberOptionState({
        default: prescriber.filter((p) => p.is_default)[0],
        other: prescriber.filter((p) => !p.is_default),
      });
      setPharmacyOptionState({
        default: pharmacy.filter((p) => p.is_default)[0],
        other: pharmacy.filter((p) => !p.is_default),
      });
    }
  };

  const onPrescriberChange = (event, id, index) => {
    const { value } = event.detail;
    let prescriber = {};
    if (
      !isEmpty(prescriberOptionState.default) &&
      prescriberOptionState.default.id === id
    ) {
      prescriber = prescriberOptionState.default;
    } else {
      prescriber = prescriberOptionState.other.filter(
        (p) => p.id === id
      )[0];
    }
    if (!prescriber.prescriber_name) {
      return;
    }
    let allCartItems = cartState;
    // if (index === 0) {
    //   allCartItems = cartState.map((c) => ({
    //     ...c,
    //     optionId: 2,
    //     optionData: prescriber,
    //   }));
    // } else {
      allCartItems[index].optionData = prescriber;
    // }
    setCartState([...allCartItems]);
  };

  const onPharmacyChange = (event, id, index) => {
    const { value } = event.detail;
    let pharmacy = {};
    if (
      !isEmpty(pharmacyOptionState.default) &&
      pharmacyOptionState.default.id === id
    ) {
      pharmacy = pharmacyOptionState.default;
    } else {
      pharmacy = pharmacyOptionState.other.filter(
        p => p.id === id
      )[0];
    }
    if (!pharmacy.pharmacy_name) {
      return;
    }
    let allCartItems = cartState;
    // if (index === 0) {
    //   allCartItems = cartState.map((c) => ({
    //     ...c,
    //     optionId: 3,
    //     optionData: pharmacy,
    //   }));
    // } else {
      allCartItems[index].optionData = pharmacy;
    // }
    setCartState([...allCartItems]);
  };

  const onAddPrescriberPharmacyClick = (name, index) => {
    name === "prescriber"
      ? setIsAddNewPrescriber(true)
      : setIsAddNewPharmacy(true);
    let allCartItems = cartState;
    allCartItems[index].optionData =
      name === "prescriber"
        ? { prescriber_name: "", phone: "", fax: "" }
        : {
            pharmacy_name: "",
            address: "",
            npi: "",
            phone: "",
            fax: "",
          };
    setCartState([...allCartItems]);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = [];
    let isQwarkInfoSent = false;

    for (let item of cartState) {
      const index = cartState.indexOf(item);
      let itemError = [];
      if (isEmpty(item.optionId)) {
        isFormValid = false;
        itemError = [`Prescription Required!`];
      } else if (
        item.optionId === 2 &&
        (isEmpty(item.optionData) || isEmpty(item.optionData.prescriber_name))
      ) {
        isFormValid = false;
        itemError = [`Prescriber name cannot be empty!`];
      } else if (
        item.optionId === 3 &&
        (isEmpty(item.optionData) || isEmpty(item.optionData.pharmacy_name))
      ) {
        isFormValid = false;
        itemError = [`Pharmacy name cannot be empty!`];
      } else if (
        item.optionId === 4 &&
        (isEmpty(item.optionData) || !item.optionData.isVerified)
      ) {
        isFormValid = false;
        itemError = [`Rx not verified!`];
      } else if (
        item.optionId === 1 &&
        (isEmpty(item.optionData) || !item.optionData.isEmailMsgSent)
      ) {
        if (!isQwarkInfoSent) {
          sendQwarkInfoToUser("email", index);
          sendQwarkInfoToUser("phone", index);
          isQwarkInfoSent = true;
        }
        // isFormValid = false;
        // itemError = [`Email/Text has not been sent yet!`];
      }
      testError.push(itemError);
    }
    setErrorFormState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    setDisableSubmitButton(true);
    let cartItem = JSON.parse(localStorage.getItem("cart"));
    if (isEmpty(cartItem) || cartItem == "null" || cartItem.length == 0) {
      props.history.push("/shoppingCart");
    } else {
      if (!(await validateForm())) {
        setDisableSubmitButton(false);
        return;
      }
      localStorage.setItem("cart", JSON.stringify(cartState));
      dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
      props.history.push({
        pathname: "/checkout_delivery",
        state: { prescriptionData: cartState },
      });
    }
    setDisableSubmitButton(false);
  };

  const verifyRx = async (index) => {
    if (!(await validateRxForm(index))) {
      return null;
    }
    setSpinnerState(`verify${index}`);
    const response = await dispatch(
      checkoutAction.verifyRx(authData.id, cartState[index].optionData)
    );
    if (!response || !response.success) {
      let testError = errorRxState;
      testError[index].verify.push(`Verification Failed!`);
      setErrorRxState([...testError]);
    } else {
      let cartStateInstance = cartState;
      if (index === 0) {
        cartStateInstance = cartState.map((c) => ({
          ...c,
          optionData: { ...cartState[0].optionData, isVerified: true },
        }));
      } else {
        cartStateInstance[index].optionData.isVerified = true;
      }
      setCartState([...cartStateInstance]);
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 4000);
    }
    setSpinnerState(``);
  };

  const validateRxForm = (index) => {
    let isFormValid = true;
    let testError = cartState.map((c) => ({
      last_name: [],
      dob: [],
      phone: [],
      verify: [],
    }));

    if (isEmpty(cartState[index].optionData.last_name)) {
      isFormValid = false;
      testError[index].last_name.push(`This Field is Required!`);
    }
    if (isEmpty(cartState[index].optionData.phone)) {
      isFormValid = false;
      testError[index].phone.push(`This Field is Required!`);
    }
    if (isEmpty(cartState[index].optionData.dob)) {
      isFormValid = false;
      testError[index].dob.push(`This Field is Required!`);
    }
    setErrorRxState(testError);
    return isFormValid;
  };

  const getCartData = () => {
    return prescriptionRequired ? cartState.filter(i => i.prescriptionRequired) : cartState;
  }

  return (
    <div className="checkoutPrescription-page">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="2">
              <CheckoutSidebar pageIndex={2} />
            </IonCol>
            <IonCol size="12" size-md="10">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="parentform-heading">
                        <span>2 OF 5</span>
                        <h2>Help us help you get your meds!</h2>
                        <p>Great. Now let's get your valid prescription info.</p>
                      </div>

                      {/* prescriptions item start */}
                      <div className="prescriptions-box">
                        <div className="prescriptions-heading">
                          <h3>
                            How would you like us to get your prescriptions?
                          </h3>
                        </div>
                        <div className="prescriptions-body">
                          {getCartData().map((cartItem, index) => (
                            <div className="prescriptions-dropbox" key={index}>
                              <div className="prescriptions-dropbox-heading">
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    toggleCartDetailDropDown(index)
                                  }
                                >
                                  <h3>
                                    {index + 1}. {cartItem.medicineName}
                                    <span>
                                      {cartItem.medicineForm} •{" "}
                                      {cartItem.medicineStrength}{" "}
                                    </span>
                                  </h3>
                                  <IonIcon
                                    icon={
                                      showCartItemDropDown[index]
                                        ? chevronUpOutline
                                        : chevronDownOutline
                                    }
                                  />
                                </a>
                              </div>
                              {showCartItemDropDown[index] && (
                                <div className="prescriptions-dropbox-body">
                                  {/* OPTION 1 : Tell your doctor to send it to Qwark, start checkbox*/}
                                  <div className="check-dropbox">
                                    <div className="check-dropbox-head">
                                      <div className="check-dropbox-head-inner">
                                        <IonCheckbox
                                          checked={cartItem.optionId === 1}
                                          onIonChange={(e) =>
                                            handleOptionCheck(e, 1, index)
                                          }
                                        />
                                        <p>Tell your doctor to send it to Qwark</p>
                                      </div>
                                    </div>
                                    {
                                      // OPTION 1 : Tell your doctor to send it to Qwark, start dropdown
                                      cartItem.optionId === 1 && (
                                        <div className="check-dropbox-body">
                                          <div className="doctors-info">
                                            <div className="doctors-info-top">
                                              <p>
                                                Provide this info to your doctor's office as your new
                                                preferred pharmacy
                                              </p>
                                            </div>
                                            <div className="doctors-info-body">
                                              <IonGrid>
                                                <IonRow>
                                                  <IonCol size="12" size-md="6">
                                                    <div className="doctors-detail">
                                                      <IonLabel>
                                                        Address
                                                      </IonLabel>
                                                      <p>
                                                        1287 Hammerwood Ave Suite #B, Sunnyvale, CA 94089
                                                      </p>
                                                    </div>
                                                  </IonCol>
                                                  <IonCol size="12" size-md="6">
                                                    <div className="doctors-detail">
                                                      <IonLabel>NPI</IonLabel>
                                                      <p>1932706546</p>
                                                    </div>
                                                  </IonCol>
                                                  <IonCol size="12" size-md="6">
                                                    <div className="doctors-detail">
                                                      <IonLabel>Phone</IonLabel>
                                                      <p>(650) 206-9343</p>
                                                    </div>
                                                  </IonCol>
                                                  <IonCol size="12" size-md="6">
                                                    <div className="doctors-detail">
                                                      <IonLabel>FAX</IonLabel>
                                                      <p>(650) 900-8223</p>
                                                    </div>
                                                  </IonCol>
                                                </IonRow>
                                              </IonGrid>
                                            </div>
                                            {!isEmpty(errorState) && (
                                              <p className="errormsg">
                                                {errorState}
                                              </p>
                                            )}
                                            {!isEmpty(successState) && (
                                              <p className="successmsg">
                                                {successState}
                                              </p>
                                            )}
                                            <div className="doctors-info-message">
                                              <p>Send this information to your email/text</p>
                                            </div>
                                            <div className="doctors-info-bottom">
                                              <IonButton
                                                className="theme-btn button-grey"
                                                onClick={() => sendQwarkInfoToUser("email", index)}
                                                disabled={spinnerState === "email"}
                                              >
                                                <span>Email</span>
                                                {/*{commonData.loading &&
                                                  spinnerState === "email" && (
                                                    <IonSpinner />
                                                  )}*/}
                                              </IonButton>
                                              <IonButton
                                                className="theme-btn button-grey"
                                                onClick={() =>
                                                  sendQwarkInfoToUser(
                                                    "phone",
                                                    index
                                                  )
                                                }
                                              >
                                                <span>Text</span>
                                                {/*{commonData.loading &&
                                                  spinnerState === "phone" && (
                                                    <IonSpinner />
                                                  )}*/}
                                              </IonButton>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                      // OPTION 1 : Tell your doctor to send it to Qwark, end dropdown
                                    }
                                  </div>
                                  {/* OPTION 1 : Tell your doctor to send it to Qwark, end checkbox*/}

                                  {/* OPTION 2 : Tell us your doctor and we will work with them, start checkbox*/}
                                  <div className="check-dropbox">
                                    <div className="check-dropbox-head">
                                      <div className="check-dropbox-head-inner">
                                        <IonCheckbox
                                          checked={cartItem.optionId === 2}
                                          onIonChange={(e) =>
                                            handleOptionCheck(e, 2, index)
                                          }
                                        />
                                        <p>Give us your doctor's info and we will work with them</p>
                                      </div>
                                      {
                                        cartItem.optionId === 2 &&
                                        cartItem.isSaved ? (
                                          <div className="pharmacySearch-result">
                                            <div className="pharmacySearch-result-item">
                                              <h3>
                                                {
                                                  cartItem.optionData
                                                    .prescriber_name
                                                }
                                              </h3>
                                              <p>
                                                Phone :{" "}
                                                {isEmpty(
                                                  cartItem.optionData.phone
                                                )
                                                  ? "--"
                                                  : cartItem.optionData.phone}
                                              </p>
                                              <p>
                                                Fax :{" "}
                                                {isEmpty(
                                                  cartItem.optionData.fax
                                                )
                                                  ? "--"
                                                  : cartItem.optionData.fax}
                                              </p>
                                              <a
                                                href="javascript:;"
                                                onClick={() => onSave(2, index, false)}
                                              >
                                                Change Prescriber
                                              </a>
                                            </div>
                                          </div>
                                        ) : cartItem.optionId === 2 &&
                                          (isAddNewPrescriber ||
                                            (isEmpty(
                                              prescriberOptionState.default
                                            ) &&
                                              isEmpty(
                                                prescriberOptionState.other
                                              ))) ? (
                                          /* add a new prescriber : start */
                                          <div className="check-dropbox-body">
                                            <div className="form-group search-drop">
                                              <IonLabel>
                                                Prescriber Name
                                              </IonLabel>
                                              <IonInput
                                                className="form-control"
                                                type="text"
                                                placeholder="e.g. John Smith"
                                                value={
                                                  cartItem.optionData
                                                    .prescriber_name
                                                    ? cartItem.optionData
                                                        .prescriber_name
                                                    : ""
                                                }
                                                onIonChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    "prescriber_name",
                                                    index
                                                  )
                                                }
                                              />
                                              {/*</div>*/}
                                              {!isEmpty(
                                                searchResultState[index]
                                              ) && (
                                                <div className="search-drop-list">
                                                  {searchResultState[index].map(
                                                    (prescriber, indexP) => (
                                                      <a
                                                        href="javascript:;"
                                                        key={indexP}
                                                        onClick={() =>
                                                          onSelectFromResult(
                                                            prescriber,
                                                            "prescriber",
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {prescriber.name}
                                                      </a>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>

                                            <div className="form-group">
                                              <IonLabel>
                                                Prescriber Number
                                              </IonLabel>
                                              <IonInput
                                                type="text"
                                                className="form-control"
                                                placeholder="e.g. (123)456-7890"
                                                value={
                                                  cartItem.optionData.phone
                                                    ? cartItem.optionData.phone
                                                    : ""
                                                }
                                                onIonChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    "phone",
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="form-group">
                                              <IonLabel>
                                                Prescriber Fax Number (optional)
                                              </IonLabel>
                                              <IonInput
                                                type="text"
                                                className="form-control"
                                                placeholder="e.g. (123)456-7890"
                                                value={
                                                  cartItem.optionData.fax
                                                    ? cartItem.optionData.fax
                                                    : ""
                                                }
                                                onIonChange={(e) =>
                                                  handleChange(e, "fax", index)
                                                }
                                              />
                                            </div>

                                            <div className="check-dropbox-bottom">
                                              <IonButton
                                                className="theme-btn button-grey"
                                                onClick={() => onSave(2, index, true)}
                                              >
                                                <span>Save</span>
                                              </IonButton>
                                            </div>
                                          </div>
                                        ) : (
                                          /* add a new prescriber : end */
                                          /* default view : select prescriber from existing added prescribers : start */
                                          cartItem.optionId === 2 && (
                                            <div className="check-dropbox-body">
                                              <div className="form-group">
                                                <IonLabel>
                                                  Prescriber Name
                                                </IonLabel>
                                                <IonSelect
                                                  interface="popover"
                                                  placeholder="Prescriber"
                                                  className="form-control"
                                                  value={"Chose prescriber"}
                                                  value={
                                                    cartItem.optionData
                                                      .prescriber_name
                                                  }
                                                >
                                                  {!isEmpty(
                                                    prescriberOptionState.default
                                                  ) && (
                                                    <IonSelectOption
                                                      onIonChange={(e) =>
                                                        onPrescriberChange(
                                                          e,
                                                          prescriberOptionState.default.id,
                                                          index
                                                        )
                                                      }
                                                      value={
                                                        prescriberOptionState.default.id
                                                      }
                                                    >
                                                      {
                                                        prescriberOptionState
                                                          .default.prescriber_name
                                                      }
                                                    </IonSelectOption>
                                                  )}
                                                  {prescriberOptionState.other.map(
                                                    (p, pIndex) => (
                                                      <IonSelectOption
                                                        onIonChange={(e) =>
                                                          onPrescriberChange(
                                                            e,
                                                            p.id,
                                                            index
                                                          )
                                                        }
                                                        value={p.id}
                                                        key={pIndex}
                                                      >
                                                        {p.prescriber_name}
                                                      </IonSelectOption>
                                                    )
                                                  )}
                                                </IonSelect>
                                              </div>

                                              <div className="form-group">
                                                <IonLabel>
                                                  Prescriber Number
                                                </IonLabel>
                                                <IonInput
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="e.g. (123)456-7890"
                                                  value={
                                                    cartItem.optionData.phone
                                                      ? cartItem.optionData
                                                          .phone
                                                      : ""
                                                  }
                                                  onIonChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "phone",
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="form-group">
                                                <IonLabel>
                                                  Prescriber Fax Number
                                                  (optional)
                                                </IonLabel>
                                                <IonInput
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="e.g. (123)456-7890"
                                                  value={
                                                    cartItem.optionData.fax
                                                      ? cartItem.optionData.fax
                                                      : ""
                                                  }
                                                  onIonChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "fax",
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>

                                              <div className="check-dropbox-bottom">
                                                <IonButton
                                                  className="theme-btn button-grey"
                                                  onClick={() =>
                                                    onAddPrescriberPharmacyClick(
                                                      "prescriber",
                                                      index
                                                    )
                                                  }
                                                >
                                                  <span>Add a Prescriber</span>
                                                </IonButton>
                                              </div>
                                            </div>
                                          )
                                        )
                                        /* default view : select prescriber from existing added prescribers : end */
                                      }
                                    </div>
                                  </div>
                                  {/* OPTION 2 : Tell us your doctor and we will work with them, end checkbox*/}

                                  {/* OPTION 3 : Transfer from my old pharmacy, start checkbox*/}
                                  <div className="check-dropbox">
                                    <div className="check-dropbox-head">
                                      <div className="check-dropbox-head-inner">
                                        <IonCheckbox
                                          checked={cartItem.optionId === 3}
                                          onIonChange={(e) =>
                                            handleOptionCheck(e, 3, index)
                                          }
                                        />
                                        <p>Transfer from my old pharmacy</p>
                                      </div>

                                      {cartItem.optionId === 3 && (
                                        <div>
                                          {cartItem.isSaved ? (
                                            <div className="pharmacySearch-result">
                                              <div className="pharmacySearch-result-item">
                                                <h3>
                                                  {
                                                    cartItem.optionData
                                                      .pharmacy_name
                                                  }
                                                </h3>
                                                <p>
                                                  {cartItem.optionData.address}
                                                </p>
                                                {/* <a
                                                  href="javascript:;"
                                                  onClick={() =>
                                                    onSave(3, index, false)
                                                  }
                                                >
                                                  Transfer from another pharmacy
                                                </a> */}
                                              </div>
                                            </div>
                                          ) : isAddNewPharmacy ||
                                            (isEmpty(
                                              pharmacyOptionState.default
                                            ) &&
                                              isEmpty(
                                                pharmacyOptionState.other
                                              )) ? (
                                            <div className="check-dropbox-body">
                                              <div className="form-group search-drop">
                                                <IonLabel>Pharmacy</IonLabel>
                                                {/*<div className="left-icon-input">
                                                                                                    <a href="javascript:;">
                                                                                                        {commonData.loading &&
                                                                                                        spinnerState ===
                                                                                                        "pharmacy_name" ? (
                                                                                                            <IonSpinner/>
                                                                                                        ) : (
                                                                                                            <IonIcon
                                                                                                                icon={searchOutline}
                                                                                                            />
                                                                                                        )}
                                                                                                    </a>*/}
                                                {/*<IonInput
                                                                                                        className="form-control"
                                                                                                        type="text"
                                                                                                        placeholder="Search pharmacy"
                                                                                                        value={cartItem.optionData.pharmacy_name ? cartItem.optionData.pharmacy_name : ""}
                                                                                                        onIonChange={(e) => handleChange(e, "pharmacy_name", index)}
                                                                                                    />*/}
                                                {/*</div>*/}
                                                {!isEmpty(
                                                  searchResultState[index]
                                                ) && (
                                                  <div className="search-drop-list">
                                                    {searchResultState[
                                                      index
                                                    ].map(
                                                      (pharmacy, indexP) => (
                                                        <a
                                                          href="javascript:;"
                                                          key={indexP}
                                                          onClick={() =>
                                                            onSelectFromResult(
                                                              pharmacy,
                                                              "pharmacy",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {pharmacy.name}
                                                        </a>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                                <IonGrid className="p-0">
                                                  <IonRow>
                                                    <IonCol
                                                      className="p-0"
                                                      size="12"
                                                      size-md="12"
                                                    >
                                                      <IonGrid className="p-0">
                                                        <IonRow className="p-0">
                                                          <IonCol
                                                            size="12"
                                                            size-md="12"
                                                          >
                                                            <div className="form-group search-drop">
                                                              <IonLabel>
                                                                Name
                                                              </IonLabel>
                                                              <IonInput
                                                                className={pharmacyErrorState ? 'form-control errorInput' : 'form-control'}
                                                                type="text"
                                                                value={cartItem.optionData.pharmacy_name ? cartItem.optionData.pharmacy_name : ""}
                                                                onIonChange={(e) => handleChange(e, "pharmacy_name", index)}
                                                              />
                                                              {
                                                                pharmacyErrorState && (
                                                                  <span className="errormsg">
                                                                    Please enter a name for this pharmacy
                                                                  </span>
                                                                )
                                                              }
                                                            </div>

                                                          </IonCol>

                                                          <IonCol
                                                            size="12"
                                                            size-md="12"
                                                          >
                                                            <div className="form-group">
                                                              <IonLabel>
                                                                Address
                                                              </IonLabel>
                                                              <GooglePlacesAutocomplete
                                                                autocompletionRequest={{
                                                                  componentRestrictions: {
                                                                    country: ['us'],
                                                                  }
                                                                }}
                                                                selectProps={{
                                                                  onChange: (selectedPlace) => {
                                                                    handleChange(
                                                                      { detail: { value: selectedPlace.label } },
                                                                      "address",
                                                                      index
                                                                    )
                                                                  },
                                                                  styles: {
                                                                    input: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    option: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                      zIndex:1,
                                                                    }),
                                                                    singleValue: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    container: (provided) => ({
                                                                      ...provided,
                                                                      zIndex:1051,
                                                                    }),
                                                                    control: (provided) => ({
                                                                      ...provided,
                                                                      borderColor: 'rgba(139,139,169,.3)',
                                                                    }),
                                                                    dropdownIndicator: (provided) => ({
                                                                      ...provided,
                                                                      opacity:'0%',
                                                                    }),
                                                                    placeholder: (provided) => ({
                                                                      ...provided,
                                                                      color: '#c0c1d4',
                                                                      fontFamily: 'gothamlight',
                                                                      fontSize: 13,
                                                                    }),
                                                                    indicatorSeparator: (provided) => ({
                                                                      ...provided,
                                                                      display: 'none'
                                                                    }),
                                                                  },
                                                                }}
                                                                apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                                                              />
                                                            </div>
                                                          </IonCol>
                                                          <IonCol size="12" size-md="12">
                                                            <div className="form-group">
                                                              <IonLabel>
                                                                Phone
                                                              </IonLabel>
                                                              <IonInput
                                                                className="form-control"
                                                                type="text"
                                                                value={
                                                                  cartItem.optionData.phone
                                                                    ? maskPhoneNumber(cartItem.optionData.phone.toString())
                                                                    : ""
                                                                }
                                                                onIonChange={(e) => handleChange(e, "phone", index)}
                                                              />
                                                            </div>
                                                          </IonCol>
                                                        </IonRow>
                                                      </IonGrid>

                                                      <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => onSave(3, index, true)}
                                                      >
                                                        <span>Save</span>
                                                      </IonButton>
                                                    </IonCol>
                                                  </IonRow>
                                                </IonGrid>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="check-dropbox-body">
                                              <div className="form-group search-drop">
                                                <IonLabel>
                                                  Pharmacy Search{" "}
                                                </IonLabel>
                                                <IonSelect
                                                  interface="popover"
                                                  placeholder="Pharmacy"
                                                  className="form-control"
                                                  value={
                                                    cartItem.optionData
                                                      .pharmacy_name
                                                  }
                                                >
                                                  {!isEmpty(
                                                    pharmacyOptionState.default
                                                  ) && (
                                                    <IonSelectOption
                                                      onIonClick={(e) =>
                                                        onPharmacyChange(
                                                          e,
                                                          pharmacyOptionState
                                                          .default.id,
                                                          index
                                                        )
                                                      }
                                                      value={
                                                        pharmacyOptionState
                                                          .default.id
                                                      }
                                                    >
                                                      {
                                                        pharmacyOptionState
                                                          .default.pharmacy_name
                                                      }
                                                    </IonSelectOption>
                                                  )}
                                                  {pharmacyOptionState.other.map(
                                                    (p, pIndex) => (
                                                      <IonSelectOption
                                                        onIonClick={(e) =>
                                                          onPharmacyChange(
                                                            e,
                                                            p.id,
                                                            index
                                                          )
                                                        }
                                                        value={p.id}
                                                        key={pIndex}
                                                      >
                                                        {p.pharmacy_name}
                                                      </IonSelectOption>
                                                    )
                                                  )}
                                                </IonSelect>
                                              </div>
                                              <div className="check-dropbox-bottom">
                                                <IonButton
                                                  className="theme-btn button-grey"
                                                  onClick={() =>
                                                    onAddPrescriberPharmacyClick(
                                                      "pharmacy",
                                                      index
                                                    )
                                                  }
                                                >
                                                  <span>Add a Pharmacy</span>
                                                </IonButton>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/* OPTION 3 : Transfer from my old pharmacy, end checkbox*/}

                                  {/* OPTION 4 : Qwark already has my Rx, start checkbox*/}
                                  <div className="check-dropbox">
                                    <div className="check-dropbox-head">
                                      <div className="check-dropbox-head-inner">
                                        <IonCheckbox
                                          checked={cartItem.optionId === 4}
                                          onIonChange={(e) =>
                                            handleOptionCheck(e, 4, index)
                                          }
                                        />
                                        <p>Qwark already has my Rx</p>
                                      </div>
                                    </div>
                                    {cartItem.optionId === 4 && (
                                      <div className="check-dropbox-body">
                                        <div className="form-group">
                                          <IonLabel>Your Last Name </IonLabel>
                                          <IonInput
                                            type="text"
                                            className="form-control"
                                            placeholder="e.g. Smilth"
                                            value={
                                              cartItem.optionData &&
                                              cartItem.optionData.last_name
                                            }
                                            onIonChange={(e) =>
                                              handleChange(
                                                e,
                                                "last_name",
                                                index
                                              )
                                            }
                                          />
                                          {!isEmpty(errorRxState[index]) &&
                                            !isEmpty(
                                              errorRxState[index].last_name
                                            ) && (
                                              <p className="errormsg">
                                                {
                                                  errorRxState[index]
                                                    .last_name[0]
                                                }
                                              </p>
                                            )}
                                        </div>

                                        <div className="form-group">
                                          <IonLabel>
                                            Your Date of Birth{" "}
                                          </IonLabel>
                                          {/*<IonInput
                                                                                        type="date"
                                                                                        className="form-control"
                                                                                        placeholder="e.g. 01/11/2020"
                                                                                        value={cartItem.optionData.dob}
                                                                                        onIonChange={(e) =>
                                                                                            handleChange(e, "dob", index)
                                                                                        }
                                                                                    />*/}

                                          {showCalendar ? (
                                            <div className="commonCalendar">
                                              <Calendar
                                                value={
                                                  cartItem.optionData.dob
                                                    ? new Date(
                                                        cartItem.optionData.dob
                                                      )
                                                    : new Date()
                                                }
                                                maxDate={new Date()}
                                                calendarType="US"
                                                onChange={(e) => {
                                                  handleChange(
                                                    {
                                                      detail: {
                                                        value: convertTimeStampToDateYYYYMMDD(
                                                          e
                                                        ),
                                                      },
                                                    },
                                                    "dob",
                                                    index
                                                  );
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="icon-input">
                                              <IonInput
                                                className="form-control"
                                                type="text"
                                                placeholder="e.g. 01/11/2020"
                                                value={cartItem.optionData.dob}
                                                disabled
                                              >
                                                <a
                                                  href="javascript:;"
                                                  onClick={() =>
                                                    setShowCalendar(true)
                                                  }
                                                >
                                                  <IonIcon
                                                    icon={calendarClearOutline}
                                                  />
                                                </a>
                                              </IonInput>
                                            </div>
                                          )}
                                          {!isEmpty(errorRxState[index]) &&
                                            !isEmpty(
                                              errorRxState[index].dob
                                            ) && (
                                              <p className="errormsg">
                                                {errorRxState[index].dob[0]}
                                              </p>
                                            )}
                                          <p className="perta-brif">
                                            Please enter the phone number that
                                            your doctor’s office has on file for
                                            you. We will send you a verification
                                            code via text to confirm identity
                                          </p>
                                        </div>

                                        <div className="form-group">
                                          <IonLabel>
                                            Your Phone number{" "}
                                          </IonLabel>
                                          <IonInput
                                            type="text"
                                            className="form-control"
                                            placeholder="e.g. (123)456-7890"
                                            value={
                                              cartItem.optionData &&
                                              cartItem.optionData.phone
                                            }
                                            onIonChange={(e) =>
                                              handleChange(e, "phone", index)
                                            }
                                          />
                                          {!isEmpty(errorRxState[index]) &&
                                            !isEmpty(
                                              errorRxState[index].phone
                                            ) && (
                                              <p className="errormsg">
                                                {errorRxState[index].phone[0]}
                                              </p>
                                            )}
                                        </div>

                                        {!isEmpty(errorRxState[index]) &&
                                          !isEmpty(
                                            errorRxState[index].verify
                                          ) && (
                                            <p className="errormsg">
                                              {errorRxState[index].verify[0]}
                                            </p>
                                          )}
                                        {commonData.successMsg &&
                                          commonData.successMsg.length !==
                                            0 && (
                                            <p className="successmsg">
                                              {commonData.successMsg}
                                            </p>
                                          )}
                                        <div className="check-dropbox-bottom">
                                          <IonButton
                                            className="theme-btn button-grey"
                                            onClick={() => verifyRx(index)}
                                            disabled={cartItem.isVerified || spinnerState === `verify${index}`}
                                          >
                                            <span>Confirm</span>
                                            {/* {commonData.loading && spinnerState === `verify${index}` && (<IonSpinner/>)} */}
                                          </IonButton>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {/* OPTION 4 : Qwark already has my Rx, end checkbox*/}
                                </div>
                              )}
                              {!isEmpty(errorFormState[index]) && (
                                <p className="errormsg">
                                  {errorFormState[index][0]}
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* prescriptions item end */}
                      <div className="parentform-button">
                        <IonButton
                          className="theme-btn button-blue"
                          onClick={() => onSubmit()}
                          disabled={disableSubmitButton}
                        >
                          <span>Next - Delivery Info</span>
                        </IonButton>
                        <IonButton
                          className="theme-btn button-blue-outline"
                          onClick={() => props.history.goBack()}
                        >
                          <span>Previous - Conditions Info</span>
                        </IonButton>
                      </div>
                    </IonCol>

                    <IonCol size="12" size-md="5" offset-md="1">
                      {/* my cart */}
                      <MyCart />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default CheckoutPrescription;
