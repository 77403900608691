import React from 'react';
import { menuController } from "@ionic/core";
import './unauthorizedUser.scss';
import UnauthorizedIcon from "../../images/unauthorized-icon.png";
import { IonImg } from '@ionic/react';
import {useHistory} from "react-router";


const NotFound = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        menuController.enable(false);
    }, []);

    return (
        <div className="unauthorizedUser-page">
            <div className="space-container">
                <div className="theme-card">
                    <div className="card-body">
                        <div className="unauthorizedUser-inner">
                            {/*<div className="unauthorizedUser-img">
                                <IonImg src={UnauthorizedIcon}/>
                            </div>*/}
                            <h2> 404 : Not Found!</h2>
                            <p>Please the check the url</p>
                            {/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>*/}
                            {/*<a href="javascript:;" onClick={()=>history.push('/')}>Please try again.</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;