import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonIcon,
  IonButton,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  chevronDownOutline,
  chevronUpOutline,
  informationCircleOutline,
  callOutline,
  closeOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./medicineDetail.scss";
import { menuController } from "@ionic/core";
import productImg from "../../images/product-img1.png";
import Truck from "../../images/truck.png";
import Ticket from "../../images/ticket.png";
import StarCheck from "../../images/star-check.png";
import qrImg from "../../images/QR-code-with-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../Redux/actions";
import { isEmpty } from "../../Utils/helper";
import { useLocation } from "react-router";
import LoginPopup from "../loginPopup";
import RequestPricePopup from "../requestPricePopup";

const MedicineDetail = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const productSlug = props.match.params.productId;
  const [productState, setProductState] = useState({});
  const [formSelected, setFormSelected] = useState(null);
  const [productAboutState, setProductAboutState] = useState({});
  const [cartState, setCartState] = useState({});
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [cartInfoState, setCartInfoState] = useState({});
  const [requestPriceModal, setRequestPriceModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState({});
  const [shouldLogin, setShouldLogin] = useState(false);
  //cart modal
  const [cartModal, setCartModal] = useState(false);
  const [state, setState] = useState({
    showAll: false,
    descriptionLimit: 160,
  });
  const [errorState, setErrorState] = useState({
    medicineForm: [],
    medicineStrength: [],
    price: [],
  });

  useEffect(() => {
    menuController.enable(false);
    loadProductDetail();
    //loadProductCartDetails();
  }, [location]);

  useEffect(() => {
    return function cleanup() {
      setFormSelected(null)
    };
  }, [])

  useEffect(() => {
    const { activeMedicineStrengthIndex, medicineId, activeMedicineFormIndex : index = 0 } = cartState
    const formSelected = cartInfoState[index]
    if (medicineId === formSelected?.product_id) {
      setFormSelected(formSelected)
    } else {
      setFormSelected(null)
    }

    if (formSelected && formSelected.value[activeMedicineStrengthIndex]) {
      const activeMedicineStrengthId = formSelected.value[activeMedicineStrengthIndex].Strength_Id

      setCartState(state => ({...state, activeMedicineStrengthId, activeMedicineStrengthIndex }))
    }
  }, [cartState.medicineForm])

  useEffect(() => {
    const { medicineId } = cartState
    const medicineStrength = getStrengthName()
    if (!formSelected || medicineId !== formSelected?.product_id) return;
    let { activeMedicineStrengthIndex } = cartState
    const strength = formSelected.value[activeMedicineStrengthIndex]
    if (!strength) return;
    const activeQuantity = +(strength.day_supply[0].qauntity)

    setCartState(state => ({ ...state, medicineStrength, activeQuantityIndex: 0, activeQuantity }))
  }, [cartState.activeMedicineStrengthId, cartState.activeMedicineStrengthIndex])

  useEffect(() => {
    const { medicineId, activeMedicineStrengthIndex, activeQuantityIndex } = cartState
    if (!formSelected || medicineId !== formSelected?.product_id || activeMedicineStrengthIndex === undefined) return;
    let price = formSelected.value[activeMedicineStrengthIndex].day_supply[activeQuantityIndex]?.price;
    if (parseInt(price) === 0) {
      const product = formSelected.value[activeMedicineStrengthIndex]
      price = ((product.cost_per_dose * product.day_supply[activeQuantityIndex].qauntity + product.shipping_cost) * (1 + product.cc_cost_variable / 100) + product.cc_cost_fixed) * (1 + product.margin / 100);
    }

    setCartState(state => ({...state, price }))

  }, [cartState.activeMedicineStrengthId, cartState.activeMedicineStrengthIndex, cartState.medicineForm, cartState.activeQuantityIndex, cartState.activeQuantity])

  const getCartSubtotal = () => {
    let subtotal = 0;
    if (isEmpty(cartItems) || cartItems === "null") {
      return parseFloat(subtotal).toFixed(2);
    }
    for (let item of cartItems) {
      subtotal = subtotal + parseFloat(item.price);
    }
    return parseFloat(subtotal).toFixed(2);
  };

  const loadProductDetail = async () => {
    const response = await dispatch(
      productAction.getProductDetail(productSlug)
    );
    if (response && response.success) {
      loadProductCartDetails(response.data.Product_Id);

      const {
        why_prescribed,
        how_to_use,
        other_uses,
        precautions_to_follow,
        special_dietary_restrictions,
        if_forget_a_dose,
        side_effects,
        storage_and_disposal,
        emergency_case,
        other_information,
      } = response.data;
      let productAbout = [
        { why_prescribed },
        { how_to_use },
        { other_uses },
        { precautions_to_follow },
        { special_dietary_restrictions },
        { if_forget_a_dose },
        { side_effects },
        { storage_and_disposal },
        { emergency_case },
        { other_information },
      ];
      setProductAboutState(
        productAbout.map((product, index) => ({ ...product, open: false }))
      );
      setProductState(response.data);
      setCartState({
        isAddedToCart: false,
        medicineName: response.data.title,
        medicineId: response.data.Product_Id,
        price: response.data.price,
        defaultPrice: response.data.price,
        medicineForm: "",
        medicineStrength: "",
        medicineDescription: response.data.description,
        prescriptionRequired: response.data.prescription_required,
        product_id: response.data.Product_Id,
        request_insurance_price: response.data.request_insurance_price
      });
    }
  };

  const loadProductCartDetails = async (productId) => {
    const response = await dispatch(
      productAction.getFormAndStrengthDetailsById(productId)
    );

    if (response && response.success) {
      let productItem = [];
      //Set quantity and price of product
      response.data.map((item, index) => {
        let qtyPriceDetails = [];
        let data = item.daysupply_detail;

        let priceData = data.split(",");
        priceData.map(qtyPrice => {
          let item = qtyPrice.split("_");
          qtyPriceDetails.push({ qauntity: item[0], price: item[1] });
        });
        response.data[index]["qtyPriceDetails"] = qtyPriceDetails;

        //Check form exists in productItem or not
        let checkFormExistsIndex = productItem.findIndex(function (info) {
          if (info != undefined) {
            return info.form_id === item.form_id;
          }
        });

        if (checkFormExistsIndex != -1) {
          productItem[checkFormExistsIndex].value.push({
            strength_mapping_id: item.strength_mapping_id,
            Strength_Id: item.Strength_Id,
            unit_strength_code: item.unit_strength_code,
            unit_strength_qty: item.unit_strength_qty,
            cost_per_dose: item.cost_per_dose,
            shipping_cost: item.shipping_cost,
            cc_cost_variable: item.cc_cost_variable,
            cc_cost_fixed: item.cc_cost_fixed,
            margin: item.margin,
            day_supply: qtyPriceDetails,
          });
        } else {
          productItem[index] = {
            product_id: item.product_id,
            formName: item.formName,
            form_id: item.form_id,
            value: [
              {
                strength_mapping_id: item.strength_mapping_id,
                Strength_Id: item.Strength_Id,
                unit_strength_code: item.unit_strength_code,
                unit_strength_qty: item.unit_strength_qty,
                cost_per_dose: item.cost_per_dose,
                shipping_cost: item.shipping_cost,
                cc_cost_variable: item.cc_cost_variable,
                cc_cost_fixed: item.cc_cost_fixed,
                margin: item.margin,
                day_supply: qtyPriceDetails,
              },
            ],
          };
        }
      });
      setCartInfoState(productItem);

      //Set default Form, Strength and Package Size with price---
      if (productItem.length > 0) {
        let defaultPrice = productItem[0].value[0].day_supply[0].price;
        if (defaultPrice == 0) {
          defaultPrice =
            ((productItem[0].value[0].cost_per_dose *
              parseInt(productItem[0].value[0].day_supply[0].qauntity) +
              productItem[0].value[0].shipping_cost) *
              (1 + productItem[0].value[0].cc_cost_variable / 100) +
              productItem[0].value[0].cc_cost_fixed) *
            (1 + productItem[0].value[0].margin / 100);
        }

        setCartState((prevState) => ({
          ...prevState,
          activeMedicineFormIndex: 0,
          activeMedicineFormId: productItem[0].form_id,
          medicineForm: productItem[0].formName,
          activeMedicineStrengthIndex: 0,
          activeMedicineStrengthId: productItem[0].value[0].Strength_Id,
          medicineStrength:
            productItem[0].value[0].unit_strength_qty +
            productItem[0].value[0].unit_strength_code,
          unitStrengthQty: productItem[0].value[0].unit_strength_qty,
          unitStrengthCode: productItem[0].value[0].unit_strength_code,
          activeQuantityIndex: 0,
          price: parseFloat(defaultPrice).toFixed(2),
          activeQuantity: parseInt(
            productItem[0].value[0].day_supply[0].qauntity
          ),
        }));
      }
    }
  };

  const handleClick = (value, index, id) => {
    clearErrors('price');
    setCartState((prevState) => ({
      ...prevState,
      activeQuantityIndex: index,
      activeQuantity: parseInt(value.split("-")[0]),
    }));
  };

  const handleFormMedicineClick = (name, index, id) => {
    clearErrors('medicineForm');
    setCartState((prevState) => ({
      ...prevState,
      medicineForm: name,
      activeMedicineFormIndex: index,
      activeMedicineFormId: id,
    }));
  }

  const changeStrengthClick = (unitStrengthCode, unitStrengthQty, index, id, formIndex) => {
    setCartState((prevState) => ({
      ...prevState,
      unitStrengthCode,
      unitStrengthQty,
      activeMedicineStrengthIndex: index,
      activeMedicineStrengthId: id,
      activeQuantityIndex: 0,
      activeQuantity: cartInfoState[formIndex].value[index].day_supply[0].qauntity,
    }));
  }

  const clearErrors = (name) => {
    dispatch({ type: "CLEAN_ERROR" });
    if (errorState[name].length !== 0) {
      setErrorState((prevState) => ({ ...prevState, [name]: [] }));
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      medicineForm: [],
      medicineStrength: [],
      price: [],
    };

    if (cartState.medicineForm.trim().length === 0) {
      isFormValid = false;
      testError.medicineForm = ["This field is required!"];
    }
    if (cartState.medicineStrength.trim().length === 0) {
      isFormValid = false;
      testError.medicineStrength = ["This field is required!"];
    }
    if (
      !cartState.hasOwnProperty("activeQuantity") ||
      cartState?.activeQuantity?.length === 0
    ) {
      isFormValid = false;
      testError.price = ["This field is required!"];
    } else if (
      cartState.hasOwnProperty("activeQuantity") &&
      cartState.activeQuantity == null
    ) {
      isFormValid = false;
      testError.price = ["This field is required!"];
    }

    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    if (!(await validateForm())) {
      return null;
    }

    await dispatch(productAction.addToCart(cartState, authData.id));
    setCartState((prevState) => ({
      ...prevState,
      isAddedToCart: true,
    }));

    await dispatch(productAction.updateDeliveryCost())

    setCartModal(true);

    setTimeout(() => {
      setCartState((prevState) => ({
        ...prevState,
        isAddedToCart: false,
      }));
    }, 2000);
  };

  const onClickQuestion = (e, index) => {
    e.preventDefault();
    let data = [...productAboutState];
    data[index].open = !productAboutState[index].open;
    setProductAboutState([...data]);
  };

  const showMore = () => {
    setState((prevState) => ({
      ...prevState,
      showAll: true,
    }));
  };

  const showLess = () => {
    setState((prevState) => ({
      ...prevState,
      showAll: false,
    }));
  };

  const getRequiresPrescription =() => {
    return productState?.prescription_required ? 'Requires Prescription' : 'No Prescription Required'
  }

  const getGenericLipitor =() => {
    if (productState.is_brand) {
      return 'Brand'
    } else {
      return productState?.brand_name ? 'Generic ' + productState?.brand_name?.split(",", 1): 'Brand'
    }
  }

  const handleCheckout = () => {
    if (localStorage.getItem('token')) {
      props.history.push('/checkout_conditions')
    } else {
      props.history.push('/welcome')
    }
  }

  const showProductDetailWithQuantityAndStrengthCode = (cartInfo) => {
    if (cartInfo.unit_strength_code.includes('/')) {
      const quantity = cartInfo.unit_strength_qty.toString().split('/')
      const code = cartInfo.unit_strength_code.toString().split('/')

      if (quantity.length === 2 && code.length === 2) {
        return `${quantity[0]} ${code[0]} / ${quantity[1]} ${code[1]}`
      }

      return (
        <>
          {cartInfo.unit_strength_qty}
          <br/>
          {cartInfo.unit_strength_code}
        </>
      )
    }

    return `${cartInfo.unit_strength_qty} ${cartInfo.unit_strength_code}`
  }

  const getStrengthName = () => {
    if (!cartState.unitStrengthCode) {
      return null;
    }

    if (cartState?.unitStrengthCode?.includes('/')) {
      const quantity = cartState.unitStrengthQty.toString().split('/')
      const code = cartState.unitStrengthCode.toString().split('/')

      if (quantity.length === 2 && code.length === 2) {
        return `${quantity[0]} ${code[0]} / ${quantity[1]} ${code[1]}`
      }

      return `${cartState.unitStrengthQty} ${cartState.unitStrengthCode}`
    }

    return `${cartState.unitStrengthQty} ${cartState.unitStrengthCode}`
  }

  const getMedicineTitle = () => {
    const name = getStrengthName()

    return `${cartState.medicineForm} ${name}`
  }

  return (
    <div className="medicineDetail pagecard-height">
      <div className="space-container">
        <div className="top-back">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <a className="back-link" onClick={() => props.history.goBack()}>
                  Back
                </a>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        {commonData.loading && <IonSpinner />}
        {!isEmpty(productState) && (
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="7" size-lg="7">
                <div className="medicineInfo">
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" size-md="4" size-lg="4">
                        <div className="medicine-img">
                          <IonImg
                            src={
                              productState.image == null
                                ? productImg
                                : `${productState.image}?${Date.now()}`
                            }
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="8" size-lg="8">
                        <div className="parentform">
                          <div className="parentform-heading">
                            <h2>{productState.title}</h2>
                            <p>{ getGenericLipitor() } | { getRequiresPrescription() }</p>
                            {productState.description.length <=
                              state.descriptionLimit && (
                                <p>{productState.description}</p>
                              )}
                            {state.showAll && (
                              // We show the extended text and a link to reduce it
                              <div>
                                <p>{productState.description}</p>
                                <a onClick={showLess}>Show less</a>
                              </div>
                            )}

                            {!state.showAll &&
                              !(
                                productState.description.length <=
                                state.descriptionLimit
                              ) && (
                                // In the final case, we show a text with ellipsis and a `Read more` button
                                <div>
                                  <p>
                                    {productState.description.substring(
                                      0,
                                      state.descriptionLimit
                                    ) + "..."}
                                  </p>
                                  <a onClick={showMore}>Show more</a>
                                </div>
                              )}
                            {/* <p>{productState.description}</p> */}
                            {/* <a href="javascript:;">Show more</a> */}
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={Truck} />
                                  </span>
                                  <p>Free shipping </p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={Ticket} />
                                  </span>
                                  <p>No membership fee</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={StarCheck} />
                                  </span>
                                  <div>
                                    <p>Qwark price promise</p>
                                    <a className="info-icon">
                                      <IonIcon
                                        icon={informationCircleOutline}
                                      />
                                      <p className="icon-tooltip">
                                          Qwark is committed to lowering your
                                          prescription prices. We will always
                                          recommend the best price we can find.
                                          If you find a lower price on an
                                          identical, in-stock product, tell us
                                          and we'll match it.
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonCol>

              <IonCol size="12" size-md="5" size-lg="5">

                {/* Latest card start */}
                <div className="theme-card">
                  <div className="card-body">
                    <div className="parentform">
                      <div className="parentform-right">
                        <div className="medicine-count">
                          <div className="medicine-countdetail">
                            <h3>
                              { getMedicineTitle() }
                            </h3>
                            <span className='clear-fix'/>
                            <p>In Stock.</p>
                          </div>

                          <div className="medicine-countprice">
                            <p>${parseFloat(cartState.price).toFixed(2)}</p>
                          </div>
                        </div>

                        <div className="medicine-item">
                          {/* item */}
                          <div className="form-group">
                            <IonLabel>Form</IonLabel>
                            <div className="medicine-item-list">
                              {!isEmpty(cartInfoState) &&
                                cartInfoState.map((cartInfo, index) => (
                                  <a
                                    key={index}
                                    onClick={() => handleFormMedicineClick(cartInfo.formName, index, cartInfo.form_id)}
                                    className={ cartInfo.form_id == cartState.activeMedicineFormId ? "active" : ""}
                                  >
                                    {cartInfo.formName}
                                  </a>
                                ))}
                              {errorState?.medicineForm &&
                                errorState.medicineForm.length !== 0 &&
                                errorState.medicineForm[0] && (
                                  <p className="errormsg">
                                    {errorState.medicineForm[0]}
                                  </p>
                                )}
                            </div>
                          </div>

                          {/* item */}
                          <div className="form-group">
                            <IonLabel>Strength</IonLabel>
                            <div className="medicine-item-list">
                              {!isEmpty(cartInfoState) &&
                                cartState.activeMedicineFormIndex > -1 &&
                                cartInfoState[cartState.activeMedicineFormIndex].value.map((cartInfo, index) => (
                                  <a key={index}
                                    onClick={() =>
                                      changeStrengthClick(
                                        cartInfo.unit_strength_code,
                                        cartInfo.unit_strength_qty,index,
                                        cartInfo.Strength_Id,
                                        cartState.activeMedicineFormIndex)
                                    }
                                    className={ cartInfo.Strength_Id == cartState.activeMedicineStrengthId
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    { showProductDetailWithQuantityAndStrengthCode(cartInfo) }
                                  </a>
                                ))}
                              {errorState?.medicineStrength &&
                                errorState.medicineStrength.length !== 0 &&
                                errorState.medicineStrength[0] && (
                                  <p className="errormsg">
                                    {errorState.medicineStrength[0]}
                                  </p>
                                )}
                            </div>
                          </div>

                          {/* item */}
                          <div className="form-group">
                            <IonLabel>Quantity</IonLabel>
                            <div className="medicine-item-list">
                              {!isEmpty(cartInfoState) &&
                                cartState.activeMedicineFormIndex > -1 &&
                                cartState.activeMedicineStrengthIndex > -1 &&
                                cartInfoState[
                                  cartState.activeMedicineFormIndex
                                ].value.map((cartInfo, index) => {
                                  return (
                                    cartState.activeMedicineStrengthId ===
                                    cartInfo.Strength_Id &&
                                    cartInfo.day_supply.map(
                                      (qtyPriceInfo, index) => (
                                        <a key={index}
                                          onClick={() =>
                                            handleClick(
                                              qtyPriceInfo.qauntity + "-" + qtyPriceInfo.price,
                                              index,
                                              cartState.activeMedicineFormIndex
                                            )
                                          }
                                          className={
                                            index == cartState.activeQuantityIndex
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {qtyPriceInfo.qauntity}
                                        </a>
                                      )
                                    )
                                  );
                                })}

                              {errorState?.price &&
                                errorState?.price.length !== 0 &&
                                errorState.price[0] && (
                                  <p className="errormsg">
                                    {errorState.price[0]}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="parentform-button">
                          <IonButton
                            className="theme-btn button-blue"
                            onClick={() => onSubmit()}
                          >
                            <span>
                              {cartState.isAddedToCart ? (
                                <IonIcon icon={checkmarkCircleOutline} />
                              ) : null}{" "}
                              Add to cart
                            </span>
                          </IonButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Latest card end */}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {/* How to buy from Qwark? */}
        <div className="buyQwark">
          <div className="buyQwark-heading">
            <p>How to buy from Qwark?</p>
          </div>
          <div className="buyQwark-list">
            <div className="buyQwark-item">
              <p>
                <span>1</span> Select the form, strength, and count
              </p>
              <p>
                <span>2</span> Add to cart and checkout
              </p>
              <p>
                <span>3</span> Add your prescription or let Qwark handle the
                transfer
              </p>
              <p>
                <span>4</span> Track your order
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Need help section start */}
      <div className="help-section">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="8">
                <div className="help-detail">
                  <h2>Need help?</h2>
                  <p className="help-content">
                    Our patient support team is available Monday through Friday
                    8AM - 6PM PST, and Saturday 9AM - 12PM PST.
                  </p>
                  <div className="help-info">
                    <a href="tel:(844)-931-2345">
                      <IonIcon icon={callOutline} /> Call (844)-931-2345
                    </a>
                    <IonButton className="theme-btn button-blue-outline">
                      <a href="https://support.qwarkhealth.com/hc/en-us/requests/new" target="_blank">
                        <span>Submit a request</span>
                      </a>
                    </IonButton>
                  </div>
                </div>
              </IonCol>
              <IonCol size="12" size-md="4">
                <div className="qr-detail">
                  <div className="qr-img">
                    <IonImg src={qrImg} />
                  </div>
                  <a>Scan to start a text chat with support</a>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      {/* Need help section end */}
      {/* about medicine section start */}
      <div className="about-medicine">
        <div className="space-container">
          <div className="about-medicine-head">
            <h2>
              About {productState.title} {productState.gcn ? "(Generic)" : ""}
            </h2>
            {/* <p>
              IMPORTANT: The FAQ answers below do NOT contain all the
              information about this particular drug. These answers are not
              substitutes for a medication guide, pharmacist consultation or the
              advice of your health care professional. For the official
              medication guide or further questions please call our pharmacists
              at XXXXXXXXX.
            </p> */}
          </div>
          <div className="about-medicine-body">
            <div className="accordion-about">
              {(() => {
                let productAboutStateJsx = [];
                if (productAboutState.length > 0) {
                  productAboutStateJsx = productAboutState.map(
                    (value, index) => {
                      let keyName = Object.keys(value);
                      let titleKey = keyName[0];
                      if (keyName[0] == "open") {
                        titleKey = keyName[0];
                      }
                      let sectionTitle = titleKey.replace(/_/g, " ");
                      sectionTitle =
                        sectionTitle.charAt(0).toUpperCase() +
                        sectionTitle.slice(1);
                      if (value[titleKey])
                        return (
                          <div className="accordion-item" key={index}>
                            <div
                              onClick={(e) => onClickQuestion(e, index)}
                              className="accordion-item-top"
                            >
                              <a>
                                <h3>{sectionTitle}</h3>
                                <IonIcon
                                  icon={
                                    value.open
                                      ? chevronUpOutline
                                      : chevronDownOutline
                                  }
                                />
                              </a>
                            </div>
                            {value.open && (
                              <div className="accordion-item-detail">
                                <p>{value[titleKey]}</p>
                              </div>
                            )}
                          </div>
                        );
                    }
                  );
                  return productAboutStateJsx;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      {/* about medicine section end */}

      {/* Customers also ordered section start */}
      {/*<div className="customers-ordered">
        <div className="space-container">
          <div className="customers-ordered-head">
            <h2>Customers also ordered</h2>
          </div>
          <div className="customers-ordered-body">
            <div className="product-item">
              <div className="product-img">
                <IonImg src={productImg} />
              </div>
              <div className="product-detail">
                <h3>
                  <a href="javascript:;">Atorvastatin</a>
                </h3>
                <p>$84</p>
              </div>
            </div>

            <div className="product-item">
              <div className="product-img">
                <IonImg src={productImg} />
              </div>
              <div className="product-detail">
                <h3>
                  <a href="javascript:;">Atorvastatin</a>
                </h3>
                <p>$84</p>
              </div>
            </div>

            <div className="product-item">
              <div className="product-img">
                <IonImg src={productImg} />
              </div>
              <div className="product-detail">
                <h3>
                  <a href="javascript:;">Atorvastatin</a>
                </h3>
                <p>$84</p>
              </div>
            </div>

            <div className="product-item">
              <div className="product-img">
                <IonImg src={productImg} />
              </div>
              <div className="product-detail">
                <h3>
                  <a href="javascript:;">Atorvastatin</a>
                </h3>
                <p>$84</p>
              </div>
            </div>

            <div className="product-item">
              <div className="product-img">
                <IonImg src={productImg} />
              </div>
              <div className="product-detail">
                <h3>
                  <a href="javascript:;">Atorvastatin</a>
                </h3>
                <p>$84</p>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* Customers also ordered section start */}

      {/* cart modal start */}
      <IonModal isOpen={cartModal} onDidDismiss={() => setCartModal(false)} cssClass="theme-modal cart-modal">
        <div className="modal-body">
          <div className="cartdrop-card">
            <div className="cart-closer">
              <IonButton
                className="cart-closer-btn theme-icon-btn"
                type="btn"
                onClick={() => setCartModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </IonButton>
            </div>
            <div className="cartdrop-mid">
              <div className="order-detail-box-list">
                {/* Medicines card start */}
                {cartItems && cartItems.length !== 0 && (
                  cartItems.map((item, index) => (
                      <div className="order-detail-box" key={index}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="order-item-detail">
                                  <h3>{item.medicineName}</h3>
                                  <p>{item.medicineForm} •{" "}
                                    {item.medicineStrength}
                                    </p>
                                  <p>Package Size: {item.activeQuantity}</p>
                                </div>
                              </td>
                              <td>
                                <div className="order-item-price">
                                  <p>${parseFloat(item.price).toFixed(2)}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  ))
                )}

                {/* Medicines card end */}

              </div>
              <div className="subtotal">
                <div className="subtotal-left">
                  <h3>Subtotal</h3>
                </div>
                <div className="subtotal-right">
                  <p>${getCartSubtotal()}</p>
                </div>
              </div>
            </div>

            <div className="cartdrop-bottom">
              <IonButton className="theme-btn button-blue-outline"
              onClick={() =>
                props.history.push("/shoppingCart")
              }>
                <span>View Cart</span>
              </IonButton>
              <IonButton className="theme-btn button-blue"
              onClick={() => handleCheckout()}>
                <span>Checkout Now</span>
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>
      {/* cart modal end */}
    </div>
  );
};

export default MedicineDetail;
