import { IonInput, IonIcon, IonSpinner } from "@ionic/react";
import { notificationsOutline, searchOutline } from "ionicons/icons";
import React, { useEffect, useState, useRef } from "react";
import "./medication.scss";
import { menuController } from "@ionic/core";
import DashboardTab from "../../components/dashboardTab/dashboardTab";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../Utils/helper";
import { productAction } from "../../Redux/actions";
import Accordion from "../../components/accordion/Accordion";

const Medication = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  const [productName, setProductName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [spinnerState, setSpinnerState] = useState("");
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const wrapperRef = useRef(null);
  const [timeOutState, setTimeOutState] = useState(null);
  const [searchEmpty, setSearchEmpty] = useState(false);
  useOutsideAlerter(wrapperRef);

  React.useEffect(() => {
    menuController.enable(false);
    loadData();
  }, []);

  useEffect(() => {
    if (searchEmpty) {
      setSearchEmpty(true);
    } else {
      setSearchEmpty(false);
    }
  }, [searchEmpty]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult([]);
          setSearchEmpty(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const loadData = async () => {
    const response = await dispatch(productAction.getSuggestion(authData.id));
    if (response && response.data && response.data.length) {
      setSuggestions(response.data);
    }
  };

  const handleChange = (event, name) => {
    const { value } = event.detail;
    setProductName(value);
    if (timeOutState) {
      clearInterval(timeOutState);
    }
    let result = setTimeout(async () => {
      setSpinnerState("searchProduct");
      await searchProductByName(value);
    }, 800);
    setTimeOutState(result);
  };

  const searchProductByName = async (value) => {
    setSearchEmpty(false);
    if (isEmpty(value)) {
      setSearchResult([]);
      setSpinnerState("");
      return null;
    }
    const response = await dispatch(productAction.searchProduct(value));
    if (response && response.success) {
      setSearchResult(response.data);
      setSearchEmpty(false);
    } else {
      setSearchResult([]);
      setSearchEmpty(true);
    }
    setSpinnerState("");
  };

  const onProductSelected = async (product) => {
    if (isEmpty(product.id)) {
      return null;
    }
    props.history.push(`/medicine_detail/${product.slug}`);
  };

  return (
    <div className="medication-page">
      {/* notification-bar start */}
      {!isEmpty(cartItems) && cartItems != "null" && (
        <div className="notification-bar">
          <div className="space-container">
            <p>
              <span>
                <IonIcon icon={notificationsOutline} />
              </span>
              You have a new prescription. Please click{" "}
              <a onClick={() => props.history.push("/checkout_conditions")}>
                here
              </a>{" "}
              to start the checkout process.
            </p>
          </div>
        </div>
      )}
      {/* notification-bar end */}

      <div className="medication-page-inner">
        <div className="space-container">
          {/* DashboardTab */}
          <DashboardTab />
          {/* DashboardTab */}

          {/* theme-card start */}
          <div className="theme-card">
            <div className="card-body">
              <div className="medication-search">
                <div className="medication-search-sidespace">
                  <div className="medication-search-head">
                    <IonIcon icon={searchOutline} />
                    <h2>Add your medications to start saving</h2>
                    <p>
                      Get started by searching for your medication. After you’ve
                      purchased a medication, it will appear on this page.
                    </p>
                  </div>
                  <div className="medication-search-input" ref={wrapperRef}>
                    <div className="form-group search-drop">
                      <div className="icon-input">
                        <IonInput
                          className="form-control"
                          type="text"
                          placeholder="Search your medication like Atorvastatin etc."
                          value={productName}
                          onIonChange={(e) => handleChange(e, "productName")}
                        />
                        <a>
                          {spinnerState == "searchProduct" ? (
                            <IonSpinner />
                          ) : (
                            <IonIcon icon={searchOutline} />
                          )}
                        </a>
                      </div>
                      {!isEmpty(searchResult) ? (
                        <div className="search-drop-list">
                          {searchResult.map((product, index) => (
                            <a
                              key={index}
                              onClick={() => onProductSelected(product)}
                            >
                              {product.title}
                            </a>
                          ))}
                        </div>
                      ) : (
                        searchEmpty && (
                          <div className="search-drop-list">
                            <p>
                              Sorry we can't find this drug. But please text us
                              at
                              <a
                                href="tel:844-931-2345"
                                className="phone-or-email"
                              >
                                {" "}
                                844-931-2345{" "}
                              </a>
                              or{" "}
                              <a
                                href="https://support.qwarkhealth.com/hc/en-us/requests/new"
                                className="phone-or-email"
                              >
                                {" "}
                                email{" "}
                              </a>
                              and we would be happy to help
                            </p>
                          </div>
                        )
                      )}
                      {suggestions.length !== 0 && (
                        <div className="medication-search-inputpera">
                          {suggestions.map((product, productIndex) => (
                            <a
                              key={product.id}
                              onClick={() =>
                                props.history.push(
                                  `/medicine_detail/${product.slug}`
                                )
                              }
                            >
                              {productIndex !== 0 ? ", " : ""}
                              {product.title}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion />
          {/* theme-card end */}
        </div>
      </div>
    </div>
  );
};

export default Medication;
