import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonInput,
  IonIcon,
  IonButton,
  IonSpinner,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import {
  searchOutline,
  chevronBackOutline,
  chevronForwardOutline,
  chevronDownOutline,
} from "ionicons/icons";
import React, { useEffect, useState, useRef } from "react";
import "./landing.scss";
import { Link } from 'react-router-dom';
import { menuController } from "@ionic/core";
import landingImg from "../../images/landing-img.png";
import searchMedications from "../../images/search-medications.png";
import cashAndInsurance from "../../images/cash-and-insurance.png";
import getFreeDelivery from "../../images/get-free-delivery.png";
import whyQwark from "../../images/why-qwark.png";
import certificate from "../../images/certificate-label.png";
import AskToJoin from "../../components/askToJoin/askToJoin";
import LandingFaq from "../../components/landingFaq/landingFaq";
import { useHistory, useLocation, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../Utils/helper";
import { productAction } from "../../Redux/actions";

const Landing = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [spinnerState, setSpinnerState] = useState("");
  const [searchEmpty, setSearchEmpty] = useState(false);
  const authData = useSelector((state) => state.authReducer.authData);
  //remove hide
  const [conditionList, setConditionList] = useState([]);
  const faqRef = useRef(null);

  const slidesRef = useRef(null);
  const handleNext = () => slidesRef.current?.slideNext();
  const handlePreview = () => slidesRef.current?.slidePrev();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  React.useEffect(() => {
    menuController.enable(false);
    getShopByCondition();
  }, []);

  useEffect(() => {
    if (searchEmpty) {
      setSearchEmpty(true)
    } else {
      setSearchEmpty(false)
    }
  }, [searchEmpty])

  React.useEffect(() => {
    if (props?.location?.state?.scrollToFaq) {
      setTimeout(() => goToFaqSection(), 800)
    }
  }, [location]);

  function goToFaqSection() {
    if (faqRef && faqRef.current) {
      faqRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult([]);
          setSearchEmpty(false)
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getShopByCondition = async () => {
    const response = await dispatch(productAction.shopByCondition());
    let conditionData = [];
    if (response.data?.length) {
      let indexLoop = 0;
      for (let condition of response.data) {
        if (conditionData[indexLoop]) conditionData[indexLoop].push(condition);
        else conditionData[indexLoop] = [condition];

        if (conditionData[indexLoop].length >= 9) {
          indexLoop++;
        }
      }
    }
    await setConditionList(conditionData);

    if (props?.location?.state?.scrollToFaq) {
      setTimeout(() => {
        goToFaqSection();
      }, 200);
    }
  };

  const handleSearchChange = async (event) => {
    const { value } = event.detail;
    setSearchText(value);
    setSearchEmpty(false)
    setSearchResult([]);
    if (isEmpty(value) || isEmpty(searchText)) {
      setSearchResult([]);
      setSpinnerState('');
      return null;
    }

    setSpinnerState("searchProduct");
    const response = await dispatch(productAction.searchProduct(value));
    if (response && response.success) {
      setSearchResult(response.data);
      setSearchEmpty(false)
    } else {
      setSearchResult([]);
      setSearchEmpty(true)
    }
    setSpinnerState("");
  };

  const onProductSelected = async (product) => {
    if (isEmpty(product.id)) {
      return null;
    }
    setSearchText("");
    setSearchResult([]);
    history.push(`/medicine_detail/${product.slug}`);
  };

  const toggleConditionShow = (e, index, colIndex) => {
    let conditionData = conditionList;
    if (conditionData[index][colIndex].open !== undefined) {
      delete conditionData[index][colIndex].open;
    } else {
      conditionData[index][colIndex]["open"] = true;
    }

    setConditionList([...conditionData]);
  };

  const onSignUp = () => {
    props.history.push("/welcome");
  };

  //Condition Slide
  const conditionSlideOpts = {};

  return (
    <div className="landing-page">
      {/* landing-banner start */}
      <div className="landing-banner">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="6">
                <div className="landing-banner-detail">
                  <div className="landing-banner-head">
                    <h2>The Smarter Way to Pharmacy. <br/>Built Around You. For you.</h2>
                    <p>
                      Qwark puts you in control of your prescriptions. Transparency. Low Prices. Personal.
                    {" "}
                    </p>
                  </div>
                  <div className="landing-banner-search" ref={wrapperRef}>
                    <div className="form-group search-drop">
                      <div className="icon-input">
                        <IonInput
                          className="form-control"
                          type="text"
                          placeholder="Search Medication"
                          value={searchText}
                          onIonChange={(e) =>
                            handleSearchChange(e, "searchText")
                          }
                        />
                        <a href="javascript:;">
                          {spinnerState == "searchProduct" ? (
                            <IonSpinner />
                          ) : (
                            <IonIcon icon={searchOutline} />
                          )}
                        </a>
                      </div>
                      <IonButton
                          className="theme-btn button-blue btn-switch"
                          onClick={() =>
                            props.history.push("/switch")
                          }
                        >
                          Switch to Qwark
                        </IonButton>
                      {!isEmpty(searchResult) ? (
                        <div className="search-drop-list">
                          {searchResult.map((product, index) => (
                            <a
                              key={index}
                              onClick={() => onProductSelected(product)}
                            >
                              {product.title}
                            </a>
                          ))}
                        </div>
                      ) : searchEmpty &&(
                        <div className="search-drop-list">
                          <p>
                            Sorry we can't find this drug. But please text us at
                            <a href="tel:844-931-2345" className="phone-or-email"> 844-931-2345 </a>
                            or <a href="https://support.qwarkhealth.com/hc/en-us/requests/new" className="phone-or-email"> email </a>
                            and we would be happy to help
                          </p>
                        </div>
                        )}
                    </div>
                  </div>
                  {/* <div className="landing-banner-search" ref={wrapperRef}>
                    <div className="form-group search-drop">
                      <div className="icon-input">
                        <GooglePlacesAutocomplete
                          placeholder='Search'
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ['us'],
                            }
                          }}
                          onPress={(data, details = null) => {
                            // 'details' is provided when fetchDetails = true
                            console.log(data, details);
                          }}
                          selectProps={{
                            placeholder:'Search Pharmacy',
                            styles: {
                              input: (provided) => ({
                                ...provided,
                                color: 'black',
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: 'black',
                                zIndex:1,
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: 'black',
                              }),
                              container: (provided) => ({
                                ...provided,
                                zIndex:1051,
                              }),
                              control: (provided) => ({
                                ...provided,
                                borderColor: 'rgba(139,139,169,.3)',
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                opacity:'0%',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#c0c1d4',
                                fontFamily: 'gothamlight',
                                fontSize: 13,
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: 'none'
                              }),
                            },
                          }}
                          apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                        />
                        <a href="javascript:;" style={{zIndex:1052}}>
                          {spinnerState == "searchProduct" ? (
                            <IonSpinner />
                          ) : (
                            <IonIcon icon={searchOutline} />
                          )}
                        </a>
                      </div>
                      {!isEmpty(searchResult) && (
                        <div className="search-drop-list">
                          {searchResult.map((product, index) => (
                            <a
                              key={index}
                              onClick={() => onProductSelected(product)}
                            >
                              {product.title}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </IonCol>
              <IonCol size="12" size-md="6">
                <div className="landing-banner-img">
                  <IonImg src={landingImg} />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      {/* landing-banner end */}

      {/* shopby-condition start */}
      <div className="shopby-condition">
        <div className="space-container">
          <div className="shopby-condition-head">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <div className="shopby-condition-heading">
                    <h2>Shop by Condition</h2>
                  </div>
                </IonCol>
                {conditionList.length > 1 ? (
                  <IonCol size="12" size-md="6">
                    <div className="condition-pagination">
                      <IonButton
                        onClick={handlePreview}
                        className="theme-icon-btn button-dark-grey-icon"
                        type="button"
                      >
                        <IonIcon icon={chevronBackOutline} />
                      </IonButton>
                      <IonButton
                        onClick={handleNext}
                        className="theme-icon-btn button-dark-grey-icon active"
                        type="button"
                      >
                        <IonIcon icon={chevronForwardOutline} />
                      </IonButton>
                    </div>
                  </IonCol>
                ) : null}
              </IonRow>
            </IonGrid>
          </div>
          <div className="shopby-condition-body">
            {/* Condition slider start */}

            {(() => {
              if (conditionList.length) {
                return (
                  <IonSlides ref={slidesRef} options={conditionSlideOpts}>
                    {conditionList.map((value, index) => {
                      return (
                        <IonSlide key={index}>
                          <IonGrid>
                            <IonRow>
                              {(() => {
                                return (value.map(
                                  (colVal, colIndex) => {
                                    return (
                                      <IonCol
                                        size="12"
                                        size-md="4"
                                        key={colIndex}
                                      >
                                        <div className="condition-dropdown">
                                          <div className="condition-dropdown-btn">
                                            <a
                                              href="javascript:;"
                                              onClick={(e) => toggleConditionShow(e, index, colIndex)}
                                              className={colVal.open === undefined ? "condition-droup-show" : "condition-droup-hide"}
                                            >
                                              <span>{colVal.catName}</span>
                                              <IonIcon
                                                icon={chevronDownOutline}
                                              />
                                            </a>
                                          </div>

                                          <div className="condition-dropdown-list">
                                            {(() => {
                                              if (colVal.productList) {
                                                const { productList } = colVal
                                                const data = JSON.parse(productList)

                                                return (data.map(({title, slug}) => {
                                                    if (colVal.open !== undefined) {
                                                      return <Link to={`medicine_detail/${slug}`}>{title}</Link>
                                                    }
                                                  }
                                                ))
                                              }
                                            })()}
                                          </div>
                                        </div>
                                      </IonCol>
                                    );
                                  }
                                ));
                              })()}
                            </IonRow>
                          </IonGrid>
                        </IonSlide>
                      );
                    })}
                  </IonSlides>
                );
              }
            })()}
            {/* Condition slider end */}
          </div>
        </div>
      </div>
      {/* shopby-condition end */}

      {/* Here is How it works start */}
      <div className="landing-howitworks">
        <div className="space-container">
          <div className="howitworks-head">
            <h2>How Qwark works</h2>
            <p>
              We created Qwark to be different: a modern yet human-first pharmacy.
            </p>
          </div>
          <div className="howitworks-body">
            <IonGrid>
              <IonRow>
                {/* item */}
                <IonCol size="12" size-md="3" offset-md="0.5">
                  <div className="howitworks-box">
                    <div className="howitworks-point">
                      <span>1</span>
                    </div>
                    <div className="howitworks-img">
                      <IonImg src={searchMedications} />
                    </div>
                    <div className="howitworks-box-detail">
                      <h3>Search Medications</h3>
                      <p>
                        Start with everyday $2.99 meds or search for any other medication
                        you need. Our team is always available via text/chat if you need help finding a medication.
                      </p>
                    </div>
                  </div>
                </IonCol>
                {/* item */}
                <IonCol size="12" size-md="3" offset-md="1">
                  <div className="howitworks-box">
                    <div className="howitworks-point">
                      <span>2</span>
                    </div>
                    <div className="howitworks-img">
                      <IonImg src={cashAndInsurance} />
                    </div>
                    <div className="howitworks-box-detail">
                      <h3>Know Your Options, Cash and Insurance</h3>
                      <p>
                        Request insurance price or simply purchase with cash. We are committed to finding you the best price.
                      </p>
                    </div>
                  </div>
                </IonCol>
                {/* item */}
                <IonCol size="12" size-md="3" offset-md="1">
                  <div className="howitworks-box">
                    <div className="howitworks-point">
                      <span>3</span>
                    </div>
                    <div className="howitworks-img">
                      <IonImg src={getFreeDelivery} />
                    </div>
                    <div className="howitworks-box-detail">
                      <h3>Get Free Delivery or Expedited Home Delivery</h3>
                      <p>
                        Order and sit back. We fill your prescriptions with care and make sure your meds reach you safely and on time. All orders ship free. Rush delivery is available for just $0.99 for 2 or more items.
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>
      {/* Here is How it works end */}

      {/* ask-to-join */}
      <div className="bg-white">
        <AskToJoin onlogin={onSignUp} />
      </div>
      {/* ask-to-join */}

      {/* Why Qwark start */}
      <div className="landing-whyqwark">
        <div className="space-container">
          <div className="whyqwark-head">
            <h2>Why Qwark?</h2>
          </div>
          <div className="whyqwark-mid-img">
            <IonImg src={whyQwark} />
            <div className="whyqwark-midimg-content">
              <h3>
                Never wait in line at the pharmacy again. And know your options
                before you order!
              </h3>
            </div>
          </div>

          <div className="whyqwark-body">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="3" offset-md="1">
                  <div className="whyqwark-box">
                    <h3>Save Money</h3>
                    <p>
                      Cash prices that are much lower than insurance out of pocket costs.
                      $2.99/month for everyday meds.
                    </p>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="3" offset-md="1">
                  <div className="whyqwark-box">
                    <h3>Save Time</h3>
                    <p>
                      We fill and ship most prescriptions the same day, free. Expedited shipping is
                      available for only $0.99 with 2 or more items.
                    </p>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="3" offset-md="1">
                  <div className="whyqwark-box">
                    <h3>No Stress</h3>
                    <p>
                      We handle prescription transfers, reach out to your doctor, remind you when
                      refills are due, and offer comprehensive medication reviews - for free.
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>

          <div className="certificate">
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  size-md="3"
                  offset-md="1"
                  size-lg="2"
                  offset-lg="2"
                >
                  <div className="certificate-img">
                    <IonImg src={certificate} />
                  </div>
                </IonCol>
                <IonCol size="12" size-md="7" size-lg="6">
                  <div className="certificate-content">
                    <p>
                      Qwark is committed to serving customers and community in a sustainable
                      manner. That’s why we have partnered with the ‘One Tree Planted’ organization. We donate a
                      percentage of our operating profits to plant more trees. And you help us in our cause by making
                      Qwark your preferred pharmacy{" "}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>
      {/* Why Qwark end */}

      {/* Take back your time start */}
      {/* <div
        className="landing-taketime"  ref={faqRef}
        // style={{ backgroundImage: `url(${taketimeImg})` }}
      >
        <div className="space-container">
          <div className="landing-taketime-inner">
            <h3>
              Take back your time.<br></br> Never wait in line at the pharmacy
              again.<br></br>
              (statement 3)
            </h3>
          </div>
        </div>
      </div> */}
      {/* Take back your time end */}

      {/* ask-to-join */}

      <div className="bg-dark-grey">
        <div className="ask-to-join">
          <div className="space-container">
            <h3>
              Experience the Qwark Difference!
              {!authData.id ? (
                " Sign up now."
              ) : (
                <>
                  <br />
                  See what our happy customers are saying
                </>
              )}
            </h3>

            {/** TrustBox widget - Micro Review Count */}
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="5419b6a8b0d04a076446a9ad"
              data-businessunit-id="607ee94a8140510001c85eaa"
              data-style-height="24px"
              data-style-width="100%"
              data-theme="light"
            >
              <a
                href="https://www.trustpilot.com/review/qwarkhealth.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>

            {/** End TrustBox widget */}

            {/* !authData.id && <IonButton
              type="button"
              className="theme-btn button-blue"
              onClick={() => props.history.push("/welcome")}
            >
              Sign up
            </IonButton>*/}
          </div>
        </div>
      </div>

      {/* ask-to-join */}

      {/* Frequently Asked Questions start */}
      <div className="landing-faq" ref={faqRef}>
        <LandingFaq />
      </div>
      {/* Frequently Asked Questions end */}

      {/* ask-to-join */}
      <div className="bg-light-grey">
        <AskToJoin onlogin={onSignUp} />
      </div>
      {/* ask-to-join */}
    </div>
  );
};

export default withRouter(Landing);
