import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getPageContent } from "../../Redux/actions/pageAction";
import { menuController } from "@ionic/core";
import "./pageDetail.scss";
import {IonSpinner} from "@ionic/react";

class pageDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };

    //menu hide
    menuController.enable(false);
    this.contentRef = React.createRef();
    
  }

  async componentDidMount() {
  
    this.fetchPageContent(this.props.match.params.pageSlug);
  }

  fetchPageContent = async (pageSlug) => {
  
    const result = await this.props.getPageContent(pageSlug);
    console.log("result", result);
    if (result?.data?.data) {
      this.setState({
        content: result.data.data.content,
        title: result.data.data.title,
      });

      
      this.contentRef.current.scrollIntoView({behavior: "auto", block: "start", inline: "start"});
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
      this.fetchPageContent(nextProps.match.params.pageSlug);
    }
  }

  render() {
    return (
      <div className="static-page" ref={this.contentRef}>
        <div className="space-container">
          <div className="static-page-heading">
            <h2>{this.state.title}</h2>
          </div>
          {
            this.state.content ?
                <div
                    className="static-page-inner"
                    dangerouslySetInnerHTML={{__html: this.state.content}}
                ></div>
                :
                <IonSpinner/>
          }

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPageContent }, dispatch);
};

export default connect(null, mapDispatchToProps)(pageDetail);
