import {
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonIcon,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonInput,
} from "@ionic/react";
import {closeOutline} from "ionicons/icons";
import React, {useState} from "react";
import "./checkoutConditions.scss";
import {menuController} from "@ionic/core";
import {useDispatch, useSelector} from "react-redux";
import {checkoutAction, profileActions} from "../../Redux/actions";
import {isEmpty} from "../../Utils/helper";
import CheckoutSidebar from "../../components/checkoutSideBar/checkOutSideBar";
import MyCart from "../../components/myCart/myCart";

const CheckoutConditions = (props) => {
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.authReducer.authData);
    const [state, setState] = useState({});
    const [radioButtonState, setRadioButtonState] = useState({
        allergies: false,
        medicalConditions: false,
        medications: false,
    });
    const [searchState, setSearchState] = useState({
        allergies: null,
        medicalConditions: null,
        medications: null,
    });
    const [errorState, setErrorState] = useState({
        allergies: "",
        medicalConditions: "",
        medications: "",
    })
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);

    React.useEffect(() => {
        menuController.enable(false);
        loadData();
    }, []);

    const loadData = async () => {
        const response = await dispatch(checkoutAction.getMedicalData(authData.id));
        if (response && response.success) {
            setState(response.data);
            setRadioButtonState({
                allergies: response.data.allergies.length !== 0,
                medicalConditions: response.data.medicalConditions.length !== 0,
                medications: response.data.medications.length !== 0,
            });
        }
    };

    const removeItem = (object, name) => {
        let newState = state;
        newState[name].splice(state[name].indexOf(object), 1);
        setState({...newState});
    };

    const handleRadioChange = (event, name) => {
        const {value} = event.detail;
        setRadioButtonState((prevState) => ({...prevState, [name]: value}));
        if (!value) {
            let newState = state;
            newState[name] = [];
            setState({...newState});
        }
    };

    const handleChange = (event, name) => {
        const {value} = event.detail;
        setSearchState((prevState) => ({...prevState, [name]: value}));
    };


    const onItemSelected = (object, name) => {
        if (!isEmpty(object)) {
            let newState = state;
            newState[name].push(object.toString().trim());
            setState({...newState});
        }
        setSearchState((prevState) => ({...prevState, [name]: null}));
        // setSearchResultState((prevState) => ({...prevState, [name]: []}));
    };

    const onSubmit = async () => {
        if (!await validateButtons()) {
            return null;
        }
        setDisableSubmitButton(true);
        let cartItem = JSON.parse(localStorage.getItem("cart"));
        if (isEmpty(cartItem) || cartItem == "null" || cartItem.length == 0) {
            props.history.push("/shoppingCart");
        }

        const response = await dispatch(
          profileActions.updateProfile(authData.id, "additionalHealthInfo", {
              additionalHealthInfo: state,
          })
        );
        if (response && response.success) {
            props.history.push("/checkout_prescription");
        }
        setDisableSubmitButton(false);
    };

    const validateButtons= async ()=>{
        let isFormValid = true;
        let testError = {
            allergies: "",
            medicalConditions: "",
            medications: ""
        }
        if(radioButtonState.allergies && isEmpty(state.allergies)){
            isFormValid = false;
            testError.allergies = `Please enter your allergy`
        }
        if(radioButtonState.medicalConditions && isEmpty(state.medicalConditions)){
            isFormValid = false;
            testError.medicalConditions = `Please enter your medical conditions`
        }
        if(radioButtonState.medications && isEmpty(state.medications)){
            isFormValid = false;
            testError.medications = `Please enter your medication`
        }
        setErrorState(testError)
        return isFormValid;
    }

    const handleKeyCheck = (event, name) => {
        if (event.key === "Enter") {
            onItemSelected(searchState[name], name);
        }
    };

    return (
        <div className="checkoutConditions-page">
            <div className="space-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" size-md="2">
                            <CheckoutSidebar pageIndex={1}/>
                        </IonCol>
                        <IonCol size="12" size-md="10">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6">
                                            <div className="parentform-heading">
                                                <span>
                                                    { prescriptionRequired ? '1 OF 5' : '1 OF 4' }
                                                </span>
                                                <h2>Now, let's get personal</h2>
                                                <p>
                                                    To better serve the patient, please answer the
                                                    following health questions below
                                                </p>
                                            </div>

                                            {/*Allergy listing start*/}
                                            <div className="theme-card">
                                                <div className="card-body">
                                                    {/*Allergy listing start*/}
                                                    <div className="form-group">
                                                        <IonLabel>Do you have any allergies?</IonLabel>
                                                        <div className="form-group">
                                                            <IonRadioGroup
                                                                value={radioButtonState.allergies}
                                                                onIonChange={(e) =>
                                                                    handleRadioChange(e, "allergies")
                                                                }
                                                            >
                                                                <div className="radio-item">
                                                                    <IonRadio value={true}/>
                                                                    <IonLabel>Yes</IonLabel>
                                                                </div>
                                                                <div className="radio-item">
                                                                    <IonRadio value={false}/>
                                                                    <IonLabel>No</IonLabel>
                                                                </div>
                                                            </IonRadioGroup>
                                                        </div>
                                                        {radioButtonState.allergies && (
                                                            <div>
                                                                <div className="allergy-list">
                                                                    {!isEmpty(state.allergies) &&
                                                                    state.allergies.map((allergy, index) => (
                                                                        <a
                                                                            key={index}
                                                                            onClick={() =>
                                                                                removeItem(allergy, "allergies")
                                                                            }
                                                                        >
                                                                            {allergy}
                                                                            <IonIcon icon={closeOutline}/>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                                <div className="form-group">
                                                                    {searchState.allergies !== null && (
                                                                        <>
                                                                            <div className="form-group">
                                                                                <IonLabel>Allergy Name</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={searchState.allergies}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "allergies")
                                                                                    }
                                                                                    onKeyDown={(e) =>
                                                                                        handleKeyCheck(e, "allergies")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <IonButton
                                                                                className="theme-btn button-blue"
                                                                                onClick={() =>
                                                                                    onItemSelected(
                                                                                        searchState.allergies,
                                                                                        "allergies"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                            </IonButton>
                                                                        </>
                                                                    )}
                                                                    {/*{searchResultState.allergies.length !== 0 && (
                                                                        <div className="search-drop-list">
                                                                            {searchResultState.allergies.map(
                                                                                (allergy, index) => (
                                                                                    <a
                                                                                        href="javascript:;"
                                                                                        key={index}
                                                                                        onClick={() => onItemSelected(allergy.allergy, "allergies")}
                                                                                    >
                                                                                        {allergy.allergy}
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}*/}
                                                                </div>
                                                                {
                                                                    searchState.allergies === null && <IonButton
                                                                        className="theme-btn button-grey"
                                                                        onClick={() =>
                                                                            setSearchState((prevState) => ({
                                                                                ...prevState,
                                                                                allergies: "",
                                                                            }))
                                                                        }
                                                                    >
                                                                        <span>+ Add Allergy</span>
                                                                    </IonButton>
                                                                }
                                                            </div>
                                                        )}
                                                        {
                                                            !isEmpty(errorState.allergies) && <p className="errormsg">{errorState.allergies}</p>
                                                        }
                                                    </div>
                                                    {/*Allergy listing end*/}

                                                    {/*Medical Conditions listing start*/}
                                                    <div className="form-group">
                                                        <IonLabel>
                                                            Do you have any medical conditions?
                                                        </IonLabel>
                                                        <div className="form-group">
                                                            <IonRadioGroup
                                                                value={radioButtonState.medicalConditions}
                                                                onIonChange={(e) =>
                                                                    handleRadioChange(e, "medicalConditions")
                                                                }
                                                            >
                                                                <div className="radio-item">
                                                                    <IonRadio value={true}/>
                                                                    <IonLabel>Yes</IonLabel>
                                                                </div>
                                                                <div className="radio-item">
                                                                    <IonRadio value={false}/>
                                                                    <IonLabel>No</IonLabel>
                                                                </div>
                                                            </IonRadioGroup>
                                                        </div>
                                                        {radioButtonState.medicalConditions && (
                                                            <div>
                                                                <div className="allergy-list">
                                                                    {!isEmpty(state.medicalConditions) &&
                                                                    state.medicalConditions.map(
                                                                        (allergy, index) => (
                                                                            <a
                                                                                key={index}
                                                                                onClick={() =>
                                                                                    removeItem(
                                                                                        allergy,
                                                                                        "medicalConditions"
                                                                                    )
                                                                                }
                                                                            >
                                                                                {allergy}
                                                                                <IonIcon icon={closeOutline}/>
                                                                            </a>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="form-group">
                                                                    {searchState.medicalConditions !== null && (
                                                                        <>
                                                                            <div className="form-group">
                                                                                <IonLabel>Medical Condition</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={searchState.medicalConditions}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "medicalConditions")
                                                                                    }
                                                                                    onKeyDown={(e) =>
                                                                                        handleKeyCheck(
                                                                                            e,
                                                                                            "medicalConditions"
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <IonButton
                                                                                className="theme-btn button-blue"
                                                                                onClick={() =>
                                                                                    onItemSelected(
                                                                                        searchState.medicalConditions,
                                                                                        "medicalConditions"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                            </IonButton>
                                                                        </>
                                                                    )}
                                                                    {/*{searchResultState.medicalConditions.length !== 0 && (
                                                                        <div className="search-drop-list">
                                                                            {searchResultState.medicalConditions.map(
                                                                                (medicalCondition, index) => (
                                                                                    <a
                                                                                        href="javascript:;"
                                                                                        key={index}
                                                                                        onClick={() => onItemSelected(medicalCondition.medical_condition, "medicalConditions")}
                                                                                    >
                                                                                        {medicalCondition.medical_condition}
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}*/}
                                                                </div>
                                                                {
                                                                    searchState.medicalConditions === null && <IonButton
                                                                        className="theme-btn button-grey"
                                                                        onClick={() =>
                                                                            setSearchState((prevState) => ({
                                                                                ...prevState,
                                                                                medicalConditions: "",
                                                                            }))
                                                                        }
                                                                    >
                                                                        <span>+ Add Medical Condition</span>
                                                                    </IonButton>
                                                                }
                                                            </div>
                                                        )}
                                                        {
                                                            !isEmpty(errorState.medicalConditions) && <p className="errormsg">{errorState.medicalConditions}</p>
                                                        }
                                                    </div>
                                                    {/*Medical Conditions listing end*/}

                                                    {/*Medications listing start*/}
                                                    <div className="form-group">
                                                        <IonLabel>Do you take other medications?</IonLabel>
                                                        <div className="form-group">
                                                            <IonRadioGroup
                                                                value={radioButtonState.medications}
                                                                onIonChange={(e) =>
                                                                    handleRadioChange(e, "medications")
                                                                }
                                                            >
                                                                <div className="radio-item">
                                                                    <IonRadio value={true}/>
                                                                    <IonLabel>Yes</IonLabel>
                                                                </div>
                                                                <div className="radio-item">
                                                                    <IonRadio value={false}/>
                                                                    <IonLabel>No</IonLabel>
                                                                </div>
                                                            </IonRadioGroup>
                                                        </div>
                                                        {radioButtonState.medications && (
                                                            <div>
                                                                <div className="allergy-list">
                                                                    {!isEmpty(state.medications) &&
                                                                    state.medications.map(
                                                                        (medication, index) => (
                                                                            <a
                                                                                key={index}
                                                                                onClick={() =>
                                                                                    removeItem(
                                                                                        medication,
                                                                                        "medications"
                                                                                    )
                                                                                }
                                                                            >
                                                                                {medication}
                                                                                <IonIcon icon={closeOutline}/>
                                                                            </a>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="form-group">
                                                                    {searchState.medications !== null && (
                                                                        <>
                                                                            <div className="form-group">
                                                                                <IonLabel>Medications Name</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={searchState.medications}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "medications")
                                                                                    }
                                                                                    onKeyDown={(e) =>
                                                                                        handleKeyCheck(e, "medications")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <IonButton
                                                                                className="theme-btn button-blue"
                                                                                onClick={() =>
                                                                                    onItemSelected(
                                                                                        searchState.medications,
                                                                                        "medications"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                            </IonButton>
                                                                        </>
                                                                    )}
                                                                </div>
                                                              {
                                                                searchState.medications === null &&<IonButton
                                                                  className="theme-btn button-grey"
                                                                  onClick={() =>
                                                                      setSearchState((prevState) => ({
                                                                        ...prevState,
                                                                        medications: "",
                                                                      }))
                                                                  }
                                                              >
                                                                <span>+ Add Medication</span>
                                                              </IonButton>
                                                              }
                                                            </div>
                                                        )}
                                                        {
                                                            !isEmpty(errorState.medications) && <p className="errormsg">{errorState.medications}</p>
                                                        }
                                                    </div>
                                                    {/*Medications listing end*/}
                                                </div>
                                            </div>
                                            {/* theme-card form box end*/}

                                            <div className="parentform-button">
                                                <IonButton
                                                    className="theme-btn button-blue"
                                                    onClick={() => onSubmit()}
                                                    disabled={disableSubmitButton}
                                                >
                                                    <span>
                                                        {
                                                            prescriptionRequired
                                                              ? 'Next - Prescription Info'
                                                              : 'Next - Delivery Info'
                                                        }
                                                    </span>
                                                </IonButton>
                                            </div>
                                        </IonCol>

                                        <IonCol size="12" size-md="5" offset-md="1">
                                            <MyCart/>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default CheckoutConditions;
