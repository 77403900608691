import Api from "../../Utils/Api";
import {handleError, handleSuccess} from "./commonAction";
import axios from "axios";

export const setWelcomeRegistrationData = (data) => async (dispatch) => {
  await dispatch({
    type: "SET_REGISTRATION_DATA",
    payload : data
  })
}

export const signUp = (body) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/signUp`, body);
    await dispatch({ type: "LOADING_COMPLETED" });
    if(!res.data.success){
      dispatch(handleError({message : res.data.message}))
    }
    return res.data
  } catch (error) {
    await dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("user")
        localStorage.removeItem("userData")
        // window.location.reload();
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }
      // toast.error(error.response.data.error);
    } else {
      dispatch({
        type: "ERROR",
        payload: { error: error.message },
      });
    }
  }
}

export const resendCode = (email, phone ) => async (dispatch) => {
  try{
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/users/${email}/phone/${phone}/resendCode`);
    if(res.data.success){
      await dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleSuccess({message : res.data.message}))
    }else{
      await dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError({message : res.data.message}))
    }
  }catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
}

export const verifyCode = (email, code, body) => async (dispatch) => {
  try{
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/${email}/verifyCode/${code}`, body);
    if(res.data.success){
      await dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleSuccess({message : res.data.message}))
    }else{
      await dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError({message : res.data.message}))
    }
    return res.data
  }catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
}

export const searchPrescriber = (userId = 0, prescriberName, body) => async (dispatch) => {
  try{
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/${userId}/prescriber/${prescriberName}`, body);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data
  }catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
}

export const checkEmailPhone = (EmailPhone) => async (dispatch) => {
  try{
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/users/${EmailPhone}/checkEmailPhone`);
    await dispatch({ type: "LOADING_COMPLETED" });
    if(!res.data.success){
      dispatch(handleError({message : res.data.message}))
    }
    return res.data
  }catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
}

export const sendVerificationCode = (email, phone) => async (dispatch) => {
  try{
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/users/${email}/phone/${phone}/verifyCode/generate`);
    await dispatch({ type: "LOADING_COMPLETED" });
    if(res.data.success){
      await dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleSuccess({message : res.data.message}))
    }else{
      await dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError({message : res.data.message}))
    }
    return res.data
  }catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
}