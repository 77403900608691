import React, {useState} from "react";
import {menuController} from "@ionic/core";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonLabel,
    IonRow,
    IonSpinner,
    IonImg,
} from "@ionic/react";
import passwordThanksImg from "../../images/password-thanks-img.jpg";
import "./forgotPasswordThanks.scss";

const ForgotPasswordThanks = (props) => {
  const state = props.location.state

    React.useEffect(() => {
        menuController.enable(false);
    }, []);

  const onSubmit = ()=>{
    // if(state.type === 'reset'){
    //   return null
    // }    
    //props.history.push({pathname : '/loginVerification', state:{email : state.email}})
    props.history.push("/");
  }

    return (
        <div className="forgotflow">
            <div className="space-container">
                <div className="login-page-inner">
                    <div className="theme-card">
                        <div className="card-body">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6">
                                            <div className="signin-left-img">
                                                <IonImg src={passwordThanksImg}/>
                                            </div>
                                        </IonCol>
                                        <IonCol size="12" size-md="5" offset-md="1">
                                            <div className="parentform-right">
                                                <div className="parentform-right-head">
                                                    <h2>Thanks!</h2>
                                                    <p>
                                                        An email has been sent to your email address,
                                                        {state.email}. Follow the directions in the email to
                                                        reset your password.
                                                    </p>
                                                </div>
                                                <div className="parentform-button">
                                                    <IonButton className="theme-btn button-blue" onClick={()=>onSubmit()}>
                                                        Done
                                                    </IonButton>
                                                </div>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordThanks;
