import React from "react";
import "./index.scss";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import bannerImage from "../../images/covid-vaccine-banner.png";
import medicineImage from "../../images/covid-medicine.png";
import onlineImage from "../../images/covid-vaccine-online.png";
import overThePhoneImage from "../../images/covid-vaccine-over-the-phone.png";
import textImage from "../../images/covid-vaccine-text.png";
import ellipse from "../../images/covid-vaccine-ellipse-li.png";
import { useHistory } from "react-router";

const CovidVaccine = () => {
  const history = useHistory();

  const handleScheduleButtonClick = () => {
    window.open("https://k8j5m.app.goo.gl/i5vL", "_blank");
  };

  function signUpClick() {
    history.push("/welcome");
  }

  return (
    <div className="covid-vaccine-page">
      <div className="top-banner">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className="banner-detail">
                  <div className="left-content">
                    <h3>Protect Yourself.<br />Protect Others. It's Up to Us!</h3>
                    {/* <h3>This is our shot™ <br />at getting back together</h3> */}
                    <p className="desc">
                      Schedule your FREE vaccination today.* <br />
                      Same-day and walk-in appointments now available.
                    </p>
                    <button className="schedule-button" onClick={() => handleScheduleButtonClick()}>
                      Schedule Vaccine
                    </button>
                  </div>
                  <div className="right-content">
                    <img alt="banner" className="banner-img" src={bannerImage}/>
                  </div>
                </div>
                <div className="notes">
                  <span className="content">*No cost to you. Vaccine either covered by insurance or government assistance.</span>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <div className="schedule">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-text-center">
                <h3 className="schedule-title">To schedule a vaccine appointment by phone, call or text Qwark at
                  <a href="tel:844-931-2345"> 844-931-2345</a>
                </h3>
              </IonCol>
              <IonCol size="12" size-md="5" className="left-content">
                <img src={medicineImage} alt="desc" />
              </IonCol>
              <IonCol size="12" size-md="7" className="right-content">
                <h3>New guidelines for immunocompromised people</h3>
                <p>
                  <strong>The CDC recommends an additional dose of COVID-19 vaccine for moderately to severely
                    immunocompromised people.</strong>
                  This includes people who have completed a vaccination series with Pfizer-BioNTech or Moderna and have:
                </p>
                <ul>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Been receiving active cancer treatment for tumors or cancers of the blood
                  </li>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Received an organ transplant and are taking medicine to suppress the immune system
                  </li>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Received a stem cell transplant within the last 2 years or are taking medicine to suppress the
                    immune system
                  </li>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Moderate or severe primary immunodeficiency (such as DiGeorge syndrome, Wiskott-Aldrich syndrome)
                  </li>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Advanced or untreated HIV infection
                  </li>
                  <li>
                    <img src={ellipse} alt="ellipse" />
                    Active treatment with high-dose corticosteroids or other drugs that may suppress your immune
                    response
                  </li>
                </ul>
                <p>
                  Your additional dose must be administered at least 28 days after your vaccination series has been
                  completed.
                  For eligible immunocompromised people, the CDC recommends <strong>the same vaccine as previous
                  doses</strong> and recipients must be ages 12+ for Pfizer-BioNTech or ages 18+ for Moderna.
                  <strong>Johnson & Johnson patients are not eligible for an additional dose at this time.</strong>
                </p>
                <p>
                  People should talk to their healthcare provider about their medical condition, and whether getting an
                  additional dose is appropriate for them.
                </p>
                <a href="https://k8j5m.app.goo.gl/i5vL" target="_blank" rel="noreferrer">Schedule appointment ›</a>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <div className="get-vaccine">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className="get-covid-header">
                  <h3>How to get your COVID-19 vaccine</h3>
                  <p>Walk-in appointments are now available. You can also schedule appointments at your convenience. New
                    appointments are made available throughout the day.</p>
                </div>
              </IonCol>
              <IonCol size="12" size-xs="12" size-lg="4">
                <div className="image-wrapper">
                  <img src={onlineImage} alt="desc" />
                </div>
                <div className="detail">
                  <h4>Online</h4>
                  <div>
                    <button className="schedule-button" onClick={() => handleScheduleButtonClick()}>
                      Schedule Vaccine
                    </button>
                  </div>
                </div>
              </IonCol>
              <IonCol size="12" size-xs="12" size-lg="4">
                <div className="image-wrapper">
                  <img src={overThePhoneImage} alt="desc" />
                </div>
                <div className="detail">
                  <h4>Over the phone</h4>
                  <div><p>Call Qwark at <a href="tel:844-931-2345"> 844-931-2345</a></p></div>
                </div>
              </IonCol>
              <IonCol size="12" size-xs="12" size-lg="4">
                <div className="image-wrapper">
                  <img src={textImage} alt="desc" />
                </div>
                <div className="detail">
                  <h4>Text</h4>
                  <div><p>Text Qwark at <a href="tel:844-931-2345"> 844-931-2345</a></p></div>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="get-covid-footer">
                  <p>Once you schedule your appointments, you'll receive a confirmation and vaccination consent form via
                    email or SMS (if you scheduled over the phone). Bring the completed vaccination consent form to your
                    scheduled appointment (or fill it out in store). A parent or guardian must be present at
                    appointments for all minors.</p>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <div className="sign-up">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className="covid-footer">
                  <h3>Start your savings today!</h3>
                  <button className="signup-button" onClick={() => signUpClick()}>Sign up</button>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </div>
  );
};

export default CovidVaccine;
