export const initialState = {
    isRegistered: "",
    isEmailVerified: "",
    isLoggedIn: "",
    message: "",
    authData: {},
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SIGNUP":
            return {
                ...state,
                isRegistered: action.payload.data.isRegistered,
                message: action.payload.data.message
            };
        case "EMAILVERIFICATION":
            return {
                ...state,
                isEmailVerified: action.payload.data.message,
                isRegistered: "",
            };
        case "VERIFY_EMAIL":
            return {
                ...state,
                message: action.payload.data.message
            };
        case "LOGIN":
            localStorage.setItem("userData", JSON.stringify(action.payload.data.user))
            localStorage.setItem("token", action.payload.data.user.token);
            return {
                ...state,
                message: action.payload.data.message,
                authData: action.payload.data.user,
                isLoggedIn: true,
            };
        case "CLEAR_LOGIN":
            return {
                ...state,
                message: "",
            };
        case 'LOGOUT_USER':
            const sessionPassword = localStorage.getItem("sessionPassword")
            localStorage.clear()
            localStorage.setItem("sessionPassword", sessionPassword)
            return {
                ...state,
                isRegistered: "",
                isEmailVerified: "",
                isLoggedIn: "",
                message: "",
                authData: {},
            }
        case 'REFRESH_AUTH_DATA':
            if (!action.payload) {
                return { ...state }
            }
            localStorage.setItem("userData", JSON.stringify(action.payload))
            return {
                ...state,
                authData: action.payload,
            };

        case "USERINFO":
            if (action.payload.data) {
                return {
                    ...state,
                    authData: action.payload.data,
                };
            }
            return {
                ...state
            };
        case "SOCIALLOGIN":
            return {
                ...state,
                isLoggedIn: !!action.payload.data.isFound,
                isFound: action.payload.data.isFound,
                loginType: action.payload.data.type,
                authData: action.payload.data.user,
            };
        default:
            return state;
    }
};
