export const initialState = {
  loading: false,
  errorData: {},
  errorMsg: "",
  errorFrom: null,
  successMsg: "",
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };
      case "SUCCESS":
    return {
      ...state,
      successMsg: action.payload?.successMsg,
    };
    case "ERROR":
      return {
        ...state,
        errorData: action.payload?.error?.errorData,
        errorMsg: action.payload?.errorMsg?.error || action.payload?.error || action.payload?.errorMsg,
        errorFrom: action?.errorFrom,
      };
    case "CLEAN_ERROR":
      return {
        ...state,
        errorData: {},
        errorMsg: "",
        successMsg:"",
        errorFrom: null,
      };
    default:
      return state;
  }
};
