import Menu from "./components/Menu";
import Page from "./pages/Page";
import Welcome from "./pages/welcome/welcome";
import Profile from "./pages/profile/profile";
import Prescriber from "./pages/prescriber/prescriber";
import Provider from "./pages/provider/provider";
import Insurance from "./pages/insurance/insurance";
import Confirmation from "./pages/confirmation/confirmation";
import ConfirmationDone from "./pages/confirmationDone/confirmationDone";
import AccountSummary from "./pages/accountSummary/accountSummary";
import ProfileAndSetting from "./pages/profileAndSetting/profileAndSetting";
import HealthNetwork from "./pages/healthNetwork/healthNetwork";
import Medication from "./pages/medication/medication";
import Login from "./pages/login/login";
import UnauthorizedUser from "./pages/unauthorizedUser/unathorizedUser";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ResetCodeVerification from "./pages/resetCodeVerification/resetCodeVerification";
import ResetPassword from "./pages/resetPassword/resetPassword";
import Blog from "./pages/blog/blog";
import pageDetail from "./pages/pageDetail/pageDetail";
import NotFound from "./pages/notFound/notFound";
import SwitchToQwark from "./pages/switchToQwark";
import SwitchToQwarkWelcome from "./pages/switchToQwark/welcome";
import SwitchToQwarkSpanish from "./pages/switchToQwark/spanish";

import Helmet from "react-helmet";

import React, { useEffect, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrivateGuard from "./Router/Private-Guard";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { authActions, productAction } from "./Redux/actions/index";

/* Theme variables */
import "./theme/variables.css";
import Header from "./components/header/header";
import "./App.scss";
import Footer from "./components/footer/footer";
import { isUserLoggedIn } from "./Utils/helper";
import MedicineDetail from "./pages/medicineDetail/medicineDetail";
import CheckoutConditions from "./pages/checkoutConditions/checkoutConditions";
import CheckoutPrescription from "./pages/checkoutPrescription/checkoutPrescription";
import CheckoutDelivery from "./pages/checkoutDelivery/checkoutDelivery";
import CheckoutPayment from "./pages/checkoutPayment/checkoutPayment";
import CheckoutReview from "./pages/checkoutReview/checkoutReview";
import ThankYou from "./pages/thankYou/thankYou";
import ShoppingCart from "./pages/shoppingCart/shoppingCart";
import ForgotPasswordThanks from "./pages/forgotPasswordThanks/forgotPasswordThanks";
import LoginVerification from "./pages/loginVerification/loginVerification";
import Landing from "./pages/landing/landing";
import About from "./pages/about/about";
import Page_2_99 from "./pages/page_2_99/page_2_99";
import SavingsCalculator from "./pages/savingsCalculator/savingsCalculator";
import { useLocation } from "react-router";
import { menuController } from "@ionic/core";
import CovidVaccine from "./pages/covid-vaccine";

// const App: React.FC = () => {
const App = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const contentRef = useRef(null);
  const authData = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    dispatch(authActions.isUser());
    if (authData.id) {
      dispatch(productAction.getCartItems(authData.id));
    } else {
      dispatch(productAction.getCartItems(authData.id));
    }
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView(true);
    }
  }, [pathname]);
  useEffect(() => {
    if (authData.id) {
      dispatch(productAction.getCartItems(authData.id));
    }
  }, [authData]);
  useEffect(() => {
    if (authData.id) {
      dispatch(productAction.saveSessionCartIntoDB(authData.id));
    }
  }, [authData]);

  useEffect(() => {
    menuController.enable(false);
  }, []);

  return (
    <IonSplitPane contentId="main">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Menu />
      <IonRouterOutlet id="main">
        <IonPage>
          <IonContent>
            <div ref={contentRef}>
              <Header />
              <Switch>
                <PrivateGuard
                  path="/savingscalculator"
                  component={SavingsCalculator}
                  exact
                />
                <PrivateGuard path="/meds-2-99" component={Page_2_99} exact />
                <PrivateGuard path="/about" component={About} exact />
                <PrivateGuard path="/landing" component={Landing} exact />
                <PrivateGuard
                  path="/shoppingCart"
                  component={ShoppingCart}
                  exact
                />
                <PrivateGuard
                  path="/loginVerification"
                  component={LoginVerification}
                  exact
                />
                <PrivateGuard
                  path="/forgotPasswordThanks"
                  component={ForgotPasswordThanks}
                  exact
                />
                <Route path="/blog" component={Blog} exact />
                <PrivateGuard
                  path="/page/:pageSlug"
                  component={pageDetail}
                  exact
                />
                <PrivateGuard
                  path="/checkout_review"
                  component={CheckoutReview}
                  exact
                />
                <PrivateGuard
                  path="/checkout_thankyou"
                  component={ThankYou}
                  exact
                />
                <PrivateGuard
                  path="/checkout_payment"
                  component={CheckoutPayment}
                  exact
                />
                <PrivateGuard
                  path="/checkout_delivery"
                  component={CheckoutDelivery}
                  exact
                />
                <PrivateGuard
                  path="/checkout_prescription"
                  component={CheckoutPrescription}
                  exact
                />
                <PrivateGuard
                  path="/checkout_conditions"
                  component={CheckoutConditions}
                  exact
                />
                <PrivateGuard
                  path="/medicine_detail/:productId"
                  component={MedicineDetail}
                  exact
                />
                <PrivateGuard
                  path="/confirmationDone"
                  component={ConfirmationDone}
                  exact
                />
                <PrivateGuard
                  path="/confirmation"
                  component={Confirmation}
                  exact
                />
                <PrivateGuard path="/insurance" component={Insurance} exact />
                <PrivateGuard path="/provider" component={Provider} exact />
                <PrivateGuard path="/prescriber" component={Prescriber} exact />
                <PrivateGuard path="/profile" component={Profile} exact />
                <PrivateGuard path="/welcome" component={Welcome} exact />
                <PrivateGuard path="/switch" component={SwitchToQwark} exact />
                <PrivateGuard
                  path="/switch-welcome"
                  component={SwitchToQwarkWelcome}
                  exact
                />
                <PrivateGuard
                  path="/switch-spanish"
                  component={SwitchToQwarkSpanish}
                  exact
                />
                <PrivateGuard path="/login" component={Login} exact />
                <PrivateGuard
                  path="/accountSummary"
                  component={AccountSummary}
                  exact
                />
                <PrivateGuard
                  path="/profileAndSetting"
                  component={ProfileAndSetting}
                  exact
                />
                <PrivateGuard
                  path="/healthNetwork"
                  component={HealthNetwork}
                  exact
                />
                <PrivateGuard path="/medication" component={Medication} exact />
                <PrivateGuard
                  path="/forgotPassword"
                  component={ForgotPassword}
                  exact
                />
                <PrivateGuard
                  path="/resetCode"
                  component={ResetCodeVerification}
                  exact
                />
                <PrivateGuard
                  path="/resetPassword"
                  component={ResetPassword}
                  exact
                />
                <Route
                  path="/unathorizedUser"
                  component={UnauthorizedUser}
                  exact
                />
                <Route path="/covid-vaccine" component={CovidVaccine} exact />
                <PrivateGuard path="/" component={Landing} exact />
                <Route path="*" component={NotFound} exact />

                {/*<Route path="/accountSummary" component={AccountSummary} exact/>
                                    <Route path="/profileAndSetting" component={ProfileAndSetting} exact/>
                                    <Route path="/healthNetwork" component={HealthNetwork} exact/>
                                    <Route path="/medication" component={Medication} exact/>*/}
                {/* <Redirect from="/" to="/welcome" exact /> */}
              </Switch>
              {/* {authData && Object.keys(authData).length !== 0 && <Footer />} */}
            </div>
            <Footer />
          </IonContent>
        </IonPage>
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default App;
