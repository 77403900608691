import Api from "../../Utils/Api";
import {handleError, handleSuccess} from "./commonAction";
import {authActions} from "./index";
import {body} from "ionicons/icons";
import axios from "axios";

export const getUserProfileData = (userId) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/userProfile`);
        if(res.data.success){
            await dispatch({ type: "LOADING_COMPLETED" });
            dispatch(handleSuccess({message : res.data.message}))
        }else{
            await dispatch({ type: "LOADING_FAILURE" });
            dispatch(handleError({message : res.data.message}))
        }
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
        if(e.toString().includes('401')){
            await dispatch(handleError({message : "Your Session has Expired. Please login again."}))
        }
    }
}

export const updateProfile = (userId, type, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/users/${userId}/userProfile/${type}`, body);
        if(res.data.success){
            await dispatch({ type: "LOADING_COMPLETED" });
            dispatch(handleSuccess({message : res.data.message}))
            if(['patientName','dob', 'email', 'gender', "phone",'password'].includes(type)){
                dispatch({
                    type:'REFRESH_AUTH_DATA',
                    payload : res.data.data
                })
            }
        }else{
            await dispatch({ type: "LOADING_FAILURE" });
            dispatch(handleError({message : res.data.message}))
        }
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}
export const getPrescriberAndPharmacyData = (userId) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/healthNetworks`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const savePrescriber = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/users/${userId}/prescriber`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const savePharmacy = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/users/${userId}/pharmacy`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const searchPharmacy = (userId, pharmacyName, body)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/users/${userId}/pharmacy/${pharmacyName}`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const searchAllergy = (userId, allergyName)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/allergy/${allergyName}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const searchMedicalCondition = (userId, medicalConditionName)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/medicalCondition/${medicalConditionName}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const searchMedication = (userId, medicationName)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/medication/${medicationName}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const GooglePlacesDetail = (userId, placeId)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/users/${userId}/googlePlace/${placeId}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const deletePrescriber = (userId, id) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.delete(`/api/v1/users/${userId}/prescriber/${id}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const deletePharmacy = (userId, id) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.delete(`/api/v1/users/${userId}/pharmacy/${id}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const switchToQwark = (body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/swithtoqwark/switch`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}
