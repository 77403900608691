import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/react";
import React from "react";
import "./confirmationDone.scss";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import confirmationImg from "../../images/welcome-confirmation.png";

const ConfirmationDone = (props) => {
  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  return (
    <div className="confirmationDone-page pagecard-height">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="3" size-lg="2">
              <CommonSidebar pageIndex={6} />
            </IonCol>
            <IonCol size="12" size-md="9" size-lg="10">
              <div className="theme-card">
                <div className="card-body">
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-lg="6">
                          <div className="parentform-heading">
                            <h2>
                              Your account is now verified.<br></br> Welcome to
                              Qwark!
                            </h2>
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={wallet} />
                                  </span>
                                  <p>Get up to 80% off your medications.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={medicine} />
                                  </span>
                                  <p>
                                    Thousands of meds, fullfilled by a licensed
                                    & accredited US-based pharmacy.
                                  </p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={time} />
                                  </span>
                                  <p>
                                    Order online and get FREE Delivery, and $5
                                    off your first order.
                                  </p>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                          <div className="parentform-left-btn">
                            <IonButton
                              className="theme-btn button-blue"
                              onClick={() => props.history.push("/login")}
                            >
                              <span>Done</span>
                            </IonButton>
                          </div>
                        </IonCol>

                        <IonCol size="12" size-lg="6">
                          <div className="confirmation-image">
                            <IonImg src={confirmationImg} />
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ConfirmationDone;
