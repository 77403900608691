import {
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import React from 'react';
import './switchToQwark.scss';
import { menuController } from "@ionic/core";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import AllAreDoneVector from "../../images/all-are-done-vector.svg";

const Welcome = () => {
  React.useEffect(() => {
      menuController.enable(false);
  }, []);

  return (
    // ************************************
    // START - You are all done! component
    // ************************************
    <div className="switchQwarkPage">
        <div className="space-container">
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-md="12" size-lg="12">
                        <div className="theme-card">
                            <div className="card-body">
                                <div className="parentform">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="12" size-lg="5" className="switchQwarkPage__vectorHolder">
                                                <div className="switchQwarkPage__vector">
                                                    <img src={AllAreDoneVector} alt="Qwark" />
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" size-lg="7">
                                                <div className="switchQwarkPage__content">
                                                    <h2 className="switchQwarkPage__contentTitle">
                                                    You are all done!
                                                    </h2>
                                                    <div className="switchQwarkPage__contentDesc">
                                                    Thank you for your business. We will be in touch shortly. Please keep an eye on your email/phone
                                                    </div>
                                                    <ul className="switchQwarkPage__contentList">
                                                        <li className="switchQwarkPage__contentListItem">
                                                            <span className="switchQwarkPage__contentIcon">
                                                                <img src={wallet} alt="Qwark" />
                                                            </span>
                                                            Get up to 80% off your medications.
                                                        </li>
                                                        <li className="switchQwarkPage__contentListItem">
                                                            <span className="switchQwarkPage__contentIcon">
                                                                <img src={medicine} alt="Qwark" />
                                                            </span>
                                                            Thousands of meds, fullfilled by a licensed & accredited US-based pharmacy.
                                                        </li>
                                                        <li className="switchQwarkPage__contentListItem">
                                                            <span className="switchQwarkPage__contentIcon">
                                                                <img src={time} alt="Qwark" />
                                                            </span>
                                                            Order online and get FREE delivery, and $5 off your first order.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </div>
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    </div>
    // ************************************
    // END - You are all done! component
    // ************************************
  );
};

export default Welcome;
