import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import React from "react";
import "./page_2_99.scss";
import medsImg from "../../images/product-img.png";
import { menuController } from "@ionic/core";

const Page_2_99 = (props) => {
  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const metforminURL = 'https://qwarkhealth.com/medicine_detail/metformin'
  const ampicillinURL = 'https://qwarkhealth.com/medicine_detail/ampicillin-(totacillin)'
  const lisinoprilURL = 'https://qwarkhealth.com/medicine_detail/lisinopril-(zestril,-prinivil)'
  const amlodipineURL = 'https://qwarkhealth.com/medicine_detail/amlodipine-(norvasc)'
  const amoxicillinURL = 'https://qwarkhealth.com/medicine_detail/amoxicillin-(amoxil)'

  return (
    <div className="page_2_99">
      <div className="space-container">
        <div className="meds-box">
          <div className="meds-box-head">
            <h2>No Subscriptions, No Membership. Just Honest Prices.</h2>
          </div>
          {/* meds-box-mid start */}
          <div className="meds-box-mid">
            <div className="meds-heading">
              <h4>
                Qwark offers several prescription medications for $2.99 -
                shipping included, for as long as your doctor prescribes them.
              </h4>
            </div>
            <div className="meds-list">
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Metformin</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <a href={metforminURL}>
                              <span>Add to Cart</span>
                            </a>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="12" size-md="12">
                      <div className="meds-brif">
                        <p>
                          A medication used to treat diabetes. 30-day supply of
                          generic immediate-release metformin.
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Lisinopril</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <a href={lisinoprilURL}>
                              <span>Add to Cart</span>
                            </a>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="12" size-md="12">
                      <div className="meds-brif">
                        <p>
                          An ACE inhibitor used to prevent, treat or improve
                          symptoms of high blood pressure, certain heart
                          conditions, diabetes and certain chronic kidney
                          conditions. 30-day supply.
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Amlodipine</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <a href={amlodipineURL}>
                              <span>Add to Cart</span>
                            </a>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="12" size-md="12">
                      <div className="meds-brif">
                        <p>
                          A calcium-channel blocker commonly used to treat high
                          blood pressure and angina (chest pain). 30-day supply
                          of 2.5-mg or 5-mg tablets. Amlodipine combination
                          products are excluded.
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
            </div>
          </div>
          {/* meds-box-mid end */}
          {/* meds-box-mid start */}
          <div className="meds-box-mid">
            <div className="meds-heading">
              <h4>
                Qwark also offers a 14-day supply of the following generic oral
                antibiotics for $2.99:
              </h4>
            </div>
            <div className="meds-list">
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Amoxicillin</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <a href={amoxicillinURL}>
                              <span>Add to Cart</span>
                            </a>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Ampicillin</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <a href={ampicillinURL}>
                              <span>Add to Cart</span>
                            </a>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Ciprofloxacin (excluding Ciprofloxacin XR)</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <span>Add to Cart</span>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Penicillin VK</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <span>Add to Cart</span>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
              {/* item start */}
              <div className="meds-item">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-sm="8" size-md="8">
                      <div className="meds-imgname">
                        <div className="meds-img">
                          <IonImg src={medsImg} />
                        </div>
                        <div className="meds-name">
                          <h3>Sulfamethoxazole/Trimethoprim (SMZ-TMP)</h3>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-sm="4" size-md="4">
                      <div className="meds-price-btn">
                        <div className="meds-price">
                          <p>$2.99</p>
                        </div>
                        <div className="meds-btn">
                          <IonButton className="theme-btn button-grey">
                            <span>Add to Cart</span>
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* item end */}
            </div>
          </div>
          {/* meds-box-mid end */}
        </div>
      </div>
    </div>
  );
};

export default Page_2_99;
