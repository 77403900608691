import { IonIcon, IonModal, IonButton } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import React, { useState } from "react";
import { getSubtotalPrice, isEmpty, showPriceOrInsurancePrice } from "../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";
import "./myCart.scss";
import {checkoutAction, productAction} from "../../Redux/actions";

const MyCart = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const deliveryOption = useSelector((state) => state.userReducer.deliveryOption);
  const state = useSelector((state) => state.userReducer.cartItems);
  const authData = useSelector((state) => state.authReducer.authData);

  // deleteModal
  const [deleteModal, setDeleteModal] = useState({
    modal:false,
    index : 0
  });

  const getCartSubtotal = () => {
    const subtotal = getSubtotalPrice(state);
    return parseFloat(subtotal.toString()).toFixed(2);
  };

  const getCartTotal = () => {
    let subtotal = parseFloat(getCartSubtotal());
    if (!isEmpty(deliveryOption)) {
      subtotal = subtotal + parseFloat(deliveryOption.cost);
    }
    let discount = applyDiscount();
    subtotal = subtotal - discount;
    return subtotal.toFixed(2);
  };

  const applyDiscount = () => {
    let subtotal = getCartSubtotal();

    if (isEmpty(state[0].discountPercent)) {
      return 0;
    }

    let discount = state[0].discountPercent;
    discount = (subtotal * discount) / 100;
    return parseFloat(discount).toFixed(2);
  };

  const saveCardChanges = async (newCartState) => {
    localStorage.setItem("cart", JSON.stringify(newCartState));
    await dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
    await dispatch(productAction.updateDeliveryCost());
  };

  const deleteItem = async (index) => {
    let cartState = state;
    await dispatch(productAction.deleteFromCart(cartState[index], authData.id || null));
    cartState.splice(index, 1);
    await saveCardChanges(cartState)
    await validateDiscountCoupon();
    setDeleteModal({modal:false,index:0});
  };

  const validateDiscountCoupon = async () => {
    if (isEmpty(state)) {
      return null;
    }
    let orderTotal = getCartSubtotal();
    const response = await dispatch(checkoutAction.validateAppliedCoupon(orderTotal, state[0].promoCode));

    if (!response.data?.isValid) {
      const updatedCart = state.map((item, index) => ({
        ...item,
        discountPercent: 0,
        promoCode: null,
      }));
      setErrorMessage(`Discount Coupon Removed : ${response.data.message}`);
      setTimeout(() => {setErrorMessage("")}, 3000);
      await saveCardChanges(updatedCart);
    }
  };

  return (
    <div className="mycart">
      <div className="theme-card">
        <div className="card-body">
          <div className="mycart-head">
            <h2>My Cart</h2>
          </div>

          <div className="mycart-mid">
            {isEmpty(state)
              ? (<p>No Item added</p>)
              : (
              state.map((cartItem, index) => (
                <div className="mycart-detail-item" key={index}>
                  <div className="mycart-detail">
                    <div className="mycart-left">
                      <h3>{cartItem.medicineName}</h3>
                    </div>
                    <div className="mycart-right">
                      <p className="mycart-price">
                        ${showPriceOrInsurancePrice(cartItem)}
                      </p>
                    </div>
                  </div>
                  <div className="mycart-detail">
                    <div className="mycart-left">
                      <p>
                        {cartItem.medicineForm} • {cartItem.medicineStrength}
                      </p>
                    </div>
                    <div className="mycart-right"/>
                  </div>
                  <div className="mycart-detail">
                    <div className="mycart-left">
                      <p>Package Size: {cartItem.activeQuantity}</p>
                    </div>
                    <div className="mycart-right"/>
                  </div>
                  <div className="mycart-detail">
                    <div className="mycart-left">
                    </div>
                    <div className="mycart-right">
                      <div className="deleteIcon">
                        <a
                          onClick={() => setDeleteModal({modal:true,index:index})}
                          // onClick={() => deleteItem(index)}
                        >
                          <IonIcon icon={trashOutline} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {!isEmpty(errorMessage) && <p className="errormsg">{errorMessage}</p>}

      {!isEmpty(state) && (
        <div className="mycart-bottom">
          {/* item */}
          <div className="mycart-detail-item">
            <div className="mycart-detail">
              <div className="mycart-left">
                <h3>Subtotal</h3>
              </div>
              <div className="mycart-right">
                <p className="mycart-price">${getCartSubtotal()}</p>
              </div>
            </div>

            {!isEmpty(applyDiscount()) && (
              <div className="mycart-detail">
                <div className="mycart-left">
                  <h3>Discount</h3>
                </div>
                <div className="mycart-right">
                  <p className="mycart-price">-${applyDiscount()}</p>
                </div>
              </div>
            )}

            {isEmpty(deliveryOption) ? (
              <div className="mycart-detail">
                <div className="mycart-left">
                  <h3>USPS First Class Shipping</h3>
                </div>
                <div className="mycart-right">
                  <p className="mycart-price">$0.00</p>
                </div>
              </div>
            ) : (
              <div className="mycart-detail">
                <div className="mycart-left">
                  <h3>{deliveryOption.name}</h3>
                </div>
                <div className="mycart-right">
                  <p className="mycart-price">${deliveryOption.cost}</p>
                </div>
              </div>
            )}

            <div className="mycart-detail">
              <div className="mycart-left">
                <h3 className="total-heading">Order total</h3>
              </div>
              <div className="mycart-right">
                <p className="mycart-price total-price">${getCartTotal()}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* modal delete start */}
      <IonModal
        isOpen={deleteModal.modal}
        cssClass="theme-modal delete-medicine-modal"
      >
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>Are you sure you want to delete??</h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-blue"
              onClick={() => setDeleteModal({modal:false,index:0})}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton className="theme-btn button-grey"
            onClick={() => deleteItem(deleteModal.index)}
            >
              <span>Delete</span>
            </IonButton>
          </div>
        </div>
      </IonModal>
      {/* modal delete end */}
    </div>
  );
};

export default MyCart;
