import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonCheckbox,
  IonButton,
} from '@ionic/react';
import {
  calendarClearOutline, searchOutline, closeOutline
} from "ionicons/icons";
import React, { useState } from 'react';
import Calendar from "react-calendar";
import './switchToQwark.scss';
import { Link } from 'react-router-dom';
import { menuController } from "@ionic/core";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import {  convertTimeStampToDateYYYYMMDD, maskPhoneNumber } from "../../Utils/helper";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { profileActions } from "../../Redux/actions";

const Spanish = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  const [state, setState] = useState({
    userId: authData.id,
    firstName: '',
    lastName:'',
    dob: '',
    phone:'',
    email: '',
    pharmacy:'',
    isPolicyAccepted: false,
  })
  const [errorState, setErrorState] = useState({
      firstName: [],
      lastName:[],
      dob: [],
      phone:[],
      email: [],
      pharmacy:[],
      isPolicyAccepted: [],
  })
  const [showCalendar, setShowCalendar] = useState(false);

  React.useEffect(() => {
      menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
      clearErrors(name)
      let value = (name === 'isPolicyAccepted') ? event.detail.checked : event.detail.value
      if (name === "phone") {
        value = value.replace(/\D/g, "")
        if (isNaN(value)) {
            setErrorState((prevState) => ({
                ...prevState,
                phone: ["¡El número de teléfono solo puede contener números!"],
            }));
        } else {
            setErrorState((prevState) => ({ ...prevState, phone: [] }));
        }
      }
      setState((prevState) => ({ ...prevState, [name]: value }));
      if (name === "dob") {
        setShowCalendar(false);
      }
  }

  const clearErrors = (name) => {
      dispatch({ type: "CLEAN_ERROR" })
      if (errorState[name].length !== 0) {
          setErrorState((prevState) => ({ ...prevState, [name]: [] }));
      }
  }

  const validateForm = () => {
      let isFormValid = true;
      let testError = {
        firstName: [],
        lastName:[],
        dob: [],
        phone:[],
        email: [],
        pharmacy:[],
        isPolicyAccepted: [],
      };
      if (state.firstName.trim().length === 0) {
        isFormValid = false;
        testError.firstName = ["El nombre no puede estar vacío!"];
      }
      if (state.lastName.trim().length === 0) {
        isFormValid = false;
        testError.lastName = ["El apellido no puede estar vacío!"];
      }
      if (state.dob.trim().length === 0) {
        isFormValid = false;
        testError.dob = ["La fecha de nacimiento no puede estar vacía"];
      }
      if (state.email.trim().length === 0) {
          isFormValid = false;
          testError.email = ["El correo electrónico no puede estar vacío!"];
      }
      if (state.phone.trim().length === 0) {
        isFormValid = false;
        testError.phone = ["El número de teléfono no puede estar vacío!"];
      }
      if (state.pharmacy.trim().length === 0) {
        isFormValid = false;
        testError.pharmacy = ["La farmacia no puede estar vacía!"];
      }
      if (state.email.trim().length > 0 &&
          /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(state.email) === false) {
          isFormValid = false;
          testError.email = ["El correo electrónico debe ser una dirección de correo electrónico válida"];
      }

      if (!state.isPolicyAccepted) {
          isFormValid = false;
          testError.isPolicyAccepted = ['Debe aceptar las Políticas antes de continuar']
      }

      setErrorState(testError);
      return isFormValid;
  }

  const onSubmit = async () => {
    if (!await validateForm()) {
        return null
    }

    const response = await dispatch(profileActions.switchToQwark(state))
    if (response.success) {
        props.history.push({ pathname: "/switch-welcome", state })
    } else {
        setTimeout(() => {
            dispatch({ type: "CLEAN_ERROR" })
        }, 5000)
    }
  }

  return (
      <div className="switchQwarkPage">
          <div className="space-container">
              <IonGrid>
                  <IonRow>
                      <IonCol size="12" size-md="12" size-lg="12">
                          <div className="theme-card">
                              <div className="card-body">
                                  <div className="parentform">
                                      <IonGrid>
                                          <IonRow>
                                              <IonCol size="12" size-lg="7">
                                                  <div className="switchQwarkPage__content">
                                                      <h2 className="switchQwarkPage__contentTitle">
                                                        Gracias por elegir Qwark
                                                      </h2>
                                                      <div className="switchQwarkPage__contentDesc">
                                                        Una farmacia a la medida de usted. Complete el formulario y nosotros nos encargamos del resto. Nos comunicaremos con usted por correo electrónico y mensaje de texto cuando se transfieran las recetas.                                                      </div>
                                                      <ul className="switchQwarkPage__contentList">
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={wallet} alt="Qwark" />
                                                              </span>
                                                            Obtenga hasta un 80% de descuento en sus medicamentos.
                                                          </li>
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={medicine} alt="Qwark" />
                                                              </span>
                                                            Miles de medicamentos, suministrados por una farmacia con licencia y acreditación con sede en Estados Unidos.                                                          </li>
                                                          <li className="switchQwarkPage__contentListItem">
                                                              <span className="switchQwarkPage__contentIcon">
                                                                  <img src={time} alt="Qwark" />
                                                              </span>
                                                            Haga su pedido en línea con envío GRATIS.
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </IonCol>

                                              <IonCol size="12" size-lg="5">
                                                  <div className="parentform-right switchQwarkPage__form">
                                                      <div className="switchQwarkPage__formRow">
                                                          <div className="switchQwarkPage__formCol6">
                                                            <div className="form-group">
                                                                <IonLabel>Nombre</IonLabel>
                                                                <IonInput className="form-control" type="text"
                                                                    placeholder="e.g. john"
                                                                    value={state.firstName}
                                                                    onIonChange={(e) => handleChange(e, 'firstName')}
                                                                />
                                                                {
                                                                    errorState.firstName.length !== 0 && errorState.firstName[0] &&
                                                                    <p className="errormsg">{errorState.firstName[0]}</p>
                                                                }
                                                            </div>
                                                          </div>
                                                          <div className="switchQwarkPage__formCol6">
                                                            <div className="form-group">
                                                                <IonLabel>Apellido</IonLabel>
                                                                <IonInput className="form-control" type="text"
                                                                    placeholder="e.g. Smith"
                                                                    value={state.lastName}
                                                                    onIonChange={(e) => handleChange(e, 'lastName')}
                                                                />
                                                                {
                                                                    errorState.lastName.length !== 0 && errorState.lastName[0] &&
                                                                    <p className="errormsg">{errorState.lastName[0]}</p>
                                                                }
                                                            </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group form-group--dob">
                                                        <IonLabel>Fecha de nacimiento</IonLabel>
                                                        <div className="icon-input">
                                                          <IonInput
                                                              className="form-control dob-input"
                                                              type="date"
                                                              placeholder="mm/dd/yyyy"
                                                              value={state.dob}
                                                              onIonChange={(e) => handleChange(e, 'dob')}
                                                          >
                                                            <a onClick={() => setShowCalendar(!showCalendar)} className="calendar-icon">
                                                              <IonIcon icon={showCalendar ? closeOutline : calendarClearOutline }/>
                                                            </a>
                                                          </IonInput>
                                                        </div>
                                                        {showCalendar && (
                                                          <div className="commonCalendar" style={{position: 'absolute', zIndex:99}}>
                                                            <Calendar
                                                              value={ state.dob ? new Date(state.dob) : new Date() }
                                                              maxDate={new Date()}
                                                              calendarType="US"
                                                              onChange={(e) => {
                                                                handleChange(
                                                                  {
                                                                    detail: {
                                                                      value: convertTimeStampToDateYYYYMMDD(e),
                                                                    },
                                                                  },
                                                                  "dob"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {
                                                            errorState.dob.length !== 0 && errorState.dob[0] &&
                                                            <p className="errormsg">{errorState.dob[0]}</p>
                                                        }
                                                      </div>
                                                      <div className="form-group">
                                                          <IonLabel>Número de teléfono</IonLabel>
                                                          <IonInput
                                                            className="form-control"
                                                            placeholder="e.g. (123)-456-7890"
                                                            value={maskPhoneNumber(state.phone.toString())}
                                                            onIonChange={(e) => handleChange(e, "phone")}
                                                        />
                                                          {
                                                            errorState.phone.length !== 0 && errorState.phone[0] &&
                                                            <p className="errormsg">{errorState.phone[0]}</p>
                                                          }
                                                      </div>
                                                      <div className="form-group">
                                                          <IonLabel>Correo electrónico</IonLabel>
                                                          <IonInput className="form-control" type="text"
                                                              placeholder="e.g. johnsmilth@gmail.com"
                                                              value={state.email}
                                                              onIonChange={(e) => handleChange(e, 'email')}
                                                          />
                                                          {
                                                              errorState.email.length !== 0 && errorState.email[0] &&
                                                              <p className="errormsg">{errorState.email[0]}</p>
                                                          }
                                                      </div>
                                                      <div className="form-group form-group--search">
                                                          <IonLabel>Buscar farmacias</IonLabel>
                                                        <div className="icon-input">
                                                            <GooglePlacesAutocomplete
                                                                autocompletionRequest={{
                                                                  componentRestrictions: {
                                                                    country: ['us'],
                                                                  }
                                                                }}
                                                                selectProps={{
                                                                  onChange: (selectedPlace) => {
                                                                    handleChange(
                                                                      { detail: { value: selectedPlace.label } },
                                                                      "pharmacy"                                                                    )
                                                                  },
                                                                  styles: {
                                                                    input: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    option: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                      zIndex:1,
                                                                    }),
                                                                    singleValue: (provided) => ({
                                                                      ...provided,
                                                                      color: 'black',
                                                                    }),
                                                                    container: (provided) => ({
                                                                      ...provided,
                                                                      zIndex:1051,
                                                                    }),
                                                                    control: (provided) => ({
                                                                      ...provided,
                                                                      borderColor: 'rgba(139,139,169,.3)',
                                                                      paddingLeft: 25
                                                                    }),
                                                                    dropdownIndicator: (provided) => ({
                                                                      ...provided,
                                                                      opacity:'0%',
                                                                    }),
                                                                    placeholder: (provided) => ({
                                                                      ...provided,
                                                                      color: '#c0c1d4',
                                                                      fontFamily: 'gothamlight',
                                                                      fontSize: 13,
                                                                    }),
                                                                    indicatorSeparator: (provided) => ({
                                                                      ...provided,
                                                                      display: 'none'
                                                                    }),
                                                                  },
                                                                }}
                                                                apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                                                                className="form-control"
                                                              />
                                                              <a href="#" onClick={e => e.preventDefault()}>
                                                                <IonIcon icon={searchOutline} />
                                                                </a>
                                                            {
                                                              errorState.pharmacy.length !== 0 && errorState.pharmacy[0] &&
                                                              <p className="errormsg">{errorState.pharmacy[0]}</p>
                                                            }
                                                        </div>
                                                      </div>
                                                      <div className="parentform-check">
                                                          <IonCheckbox checked={state.isPolicyAccepted}
                                                              onIonChange={(e) => handleChange(e, 'isPolicyAccepted')} />
                                                          <p>Acepto los
                                                          <Link to={'/page/terms_of_service'} target="_blank"> Términos de Servicio</Link>
                                                              {/* <a target='_blank' href="javascript:;" onClick={() => props.history.push("/page/terms_of_service")}>Terms of Service</a>, */}
                                                              ,<a target='_blank' href={'https://support.qwarkhealth.com/hc/en-us'}> Comunicación </a> y
                                                              <Link to={'/page/privacy_policy'} target="_blank"> Política de Privacidad.</Link>
                                                          </p>
                                                      </div>
                                                      {
                                                          errorState.isPolicyAccepted.length !== 0 && errorState.isPolicyAccepted[0] &&
                                                          <p className="errormsg">{errorState.isPolicyAccepted[0]}</p>
                                                      }

                                                      <div className="parentform-button">
                                                          <IonButton
                                                              className="theme-btn button-blue"
                                                              onClick={() => onSubmit()}><span>Enviar</span>
                                                              {/*{commonData.loading && <IonSpinner />}*/}
                                                          </IonButton>

                                                      </div>
                                                  </div>
                                              </IonCol>
                                          </IonRow>
                                      </IonGrid>
                                  </div>
                              </div>
                          </div>
                      </IonCol>
                  </IonRow>
              </IonGrid>
          </div>
      </div>
  );
};

export default Spanish;
