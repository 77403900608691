import React, {useState} from "react";
import {menuController} from "@ionic/core";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonLabel,
    IonRow,
    IonSpinner,
    IonImg,
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../Redux/actions/index";
import forgotPasswordImg from "../../images/forgot-password-img.png";
import "./forgotPassword.scss";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer.authData);
    const [state, setState] = useState({email: "", phone: ""});
    const [errorState, setErrorState] = useState("");
    const [spinnerState, setSpinnerState] = useState("");

    React.useEffect(() => {
        menuController.enable(false);
    }, []);

    const handleChange = (event, name) => {
        dispatch({type: "CLEAN_ERROR"});
        let {value} = event.detail;
        if (name === "phone" && value) {
            value = value.replace(/\D/g, "")
            if (isNaN(value)) {
                return;
            }
        }
        setState((prevState) => ({...prevState, [name]: value}));
    };

    const validateForm = () => {
        let isValid = true;
        let testError = "";
        if (state.email.length === 0) {
            isValid = false;
            testError = "This field is required!";
        }
        if (state.email.length !== 0 &&
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                state.email
            ) === false
        ) {
            isValid = false;
            testError = "Invalid email address!";
        }
        // if (state.phone.length !== 0 && state.phone.length < 10) {
        //     isValid = false;
        //     testError = "Phone must be 10 digits";
        // }
        setErrorState(testError);
        return isValid;
    };

    const onSubmit = async (type) => {
        if (!(await validateForm())) {
            return null;
        }
        setSpinnerState(type)
        const response = await dispatch(authActions.forgotPassword(type, state));
        if (response && response.success) {
            setTimeout(() => {
                dispatch({type: "CLEAN_ERROR"});
                props.history.push({pathname: type === 'reset' ? "/forgotPasswordThanks" : "/loginVerification", state: {type, email : state.email}});
            }, 4000);
        }else{
            setTimeout(() => {
                dispatch({type: "CLEAN_ERROR"});
            }, 5000);
        }
        setSpinnerState("")
    };

    return (
        <div className="forgotflow">
            <div className="space-container">
                <div className="login-page-inner">
                    <div className="theme-card">
                        <div className="card-body">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6" size-lg="5">
                                            <div className="signin-left-img">
                                                <IonImg src={forgotPasswordImg}/>
                                            </div>
                                        </IonCol>
                                        <IonCol
                                            size="12"
                                            size-md="5"
                                            offset-md="1"
                                            size-lg="5"
                                            offset-lg="2"
                                        >
                                            <div className="parentform-right">
                                                <div className="parentform-right-head">
                                                    <h2>Forgot Password</h2>
                                                    <p>
                                                        Don’t worry! Please enter the email address
                                                        associated with your account.
                                                    </p>
                                                </div>

                                                <div className="form-group">
                                                    <IonLabel>Email</IonLabel>
                                                    <IonInput
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="e.g. johnsmilth@gmail.com"
                                                        value={state.email}
                                                        onIonChange={(e) => handleChange(e, "email")}
                                                    />
                                                </div>
                                                {errorState && errorState.length !== 0 && (
                                                    <p className="errormsg">{errorState}</p>
                                                )}
                                              {commonData.errorMsg && (
                                                  <p className="errormsg">{commonData.errorMsg}</p>
                                              )}
                                              {commonData.successMsg && (
                                                  <p className="successmsg">
                                                    {commonData.successMsg}
                                                  </p>
                                              )}
                                                <div className="parentform-button">
                                                    <IonButton className="theme-btn button-blue" onClick={() => onSubmit('reset')}>
                                                        Reset Password
                                                        {/*{
                                                            commonData.loading && spinnerState === 'reset' &&
                                                            <IonSpinner/>
                                                        }*/}
                                                    </IonButton>
                                                    <div className="ortag">
                                                        <p>Or</p>
                                                    </div>
                                                    <IonButton className="theme-btn button-blue"
                                                               onClick={() => onSubmit('signin')}>
                                                        Sign in with verification code
                                                        {/*{
                                                            commonData.loading && spinnerState === 'signin' &&
                                                            <IonSpinner/>
                                                        }*/}
                                                    </IonButton>
                                                </div>

                                                <div className="have-account-text">
                                                    <p>
                                                        New to Qwark?
                                                        <a href="javascript:;" onClick={()=>props.history.push('/welcome')}>Sign up</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
