import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonLabel,
    IonRow,
    IonSpinner,
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import "./checkoutPayment.scss";
import {menuController} from "@ionic/core";
import {searchOutline,} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import CheckoutSidebar from "../../components/checkoutSideBar/checkOutSideBar";
import {checkoutAction, profileActions} from "../../Redux/actions";
import * as Helper from "../../Utils/helper";
import {convertArrayToCommaSeparatedString, isEmpty,} from "../../Utils/helper";
import MyCart from "../../components/myCart/myCart";
import GreyImg from "../../images/grey-img.png";
import Uspsimg from "../../images/usps-img.png";
import CCLogosImg from "../../images/credit_card_logos_horizontal_color_orig.png";

const CheckoutPayment = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer.authData);
    const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);
    const [
        isBillingShippingAddressSame,
        setIsBillingShippingAddressSame,
    ] = useState(false);
    const addressObject = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        id: "",
        type: "",
    };
    const [addressState, setAddressState] = useState(addressObject);
    const [errorState, setErrorState] = useState({
        firstName: [],
        lastName: [],
        addressLine1: [],
        addressLine2: [],
        city: [],
        state: [],
        zip: [],
        card: [],
    });
    const [enableGoogleSearch, setEnableGoogleSearch] = useState(true);
    const [predictions, setPredictions] = useState([]);
    const [spinnerState, setSpinnerState] = useState("");
    const [paymentResponse, setPaymentResponse] = useState({});
    const [savedCards, setSavedCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState({});
    const [activeCard, setActiveCard] = useState({
        card: [],
        activeViewMoreEnable: true
    });
    const authorizePayButtonRef = useRef(null);
    const [newPaymentOption, setNewPaymentOption] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * empty address prediction array
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPredictions([]);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    React.useEffect(() => {
        menuController.enable(false);
        loadScript("https://js.authorize.net/v3/AcceptUI.js");
        loadData();
    }, []);

    const loadScript = (src) => {
        var tag = document.createElement("script");
        tag.src = src;
        tag.id = "custom-response-script";
        tag.type = "text/javascript";
        document.body.appendChild(tag);
    };

    const loadData = async () => {
        setEnableGoogleSearch(false);
        const response = await dispatch(checkoutAction.getPaymentData(authData.id));
        if (response && response.data) {
            setAddressState(response.data.billingAddress);
            let defaultCardIndex = 0
            response.data.savedCards.map((card, index) => {
                if (card.is_default) {
                    defaultCardIndex = index
                }
            });

            let savedCardList = [...response.data.savedCards]
            if (defaultCardIndex !== 0) {
                let defaultCard = {...savedCardList[defaultCardIndex]}
                setSelectedCard(defaultCard)
                savedCardList[defaultCardIndex] = {...savedCardList[0]}
                savedCardList[0] = defaultCard
            }
            savedCardList = savedCardList.map((card) => ({...card, isSelected: !!card.is_default}))
            setSavedCards(savedCardList);
            await loadActiveCards(true, savedCardList);
        }
        setTimeout(() => {
            setEnableGoogleSearch(true);
        }, 500);

    };

    const loadActiveCards = async (firstLoad, cardDetails) => {
        let activeCards = []
        let activeViewMoreEnable = false;
        setSpinnerState("activeCard");
        if (firstLoad) {
            if (cardDetails.length > 2)
                activeViewMoreEnable = true
            activeCards = cardDetails.filter((card, cIndex)=>(cIndex<2))

        } else {
            activeCards = savedCards;
            activeViewMoreEnable = false;
        }
        setActiveCard({
            card: activeCards,
            activeViewMoreEnable: activeViewMoreEnable
        });
        setSpinnerState("");
    };

    const handleChange = (event, name, index) => {
        const {value} = event.detail;
        if (name === "checkBox") {
            setIsBillingShippingAddressSame(event.detail.checked);
            handleAddressCheckBox(event.detail.checked);
        } else if (name === "checkCard") {
            setErrorState((prevState) => ({...prevState, card: []}));
            if (event.detail.checked) {
              setActiveCard((prevState)=>({ card: [...activeCard.card.map((card) => ({...card, isSelected: false}))]}));
                setSelectedCard(activeCard.card[index]);
            } else {
                setSelectedCard({});
            }
            let allSavedCards = activeCard.card;
            allSavedCards[index].isSelected = event.detail.checked;
            setActiveCard({
              card: allSavedCards,
              activeViewMoreEnable: activeCard.activeViewMoreEnable
            })
        } else {
            clearErrors(name);
            setAddressState((prevState) => ({...prevState, [name]: value}));
            if (name === "addressLine1" && enableGoogleSearch) {
                if (value && value.length) {
                    handleSubmit(value);
                } else {
                    setPredictions([]);
                }
            }
        }
    };

    const clearErrors = (name) => {
        dispatch({type: "CLEAN_ERROR"});
        if (errorState[name].length !== 0) {
            setErrorState((prevState) => ({...prevState, [name]: []}));
        }
    };

    const handleAddressCheckBox = async (value) => {
        if (!value) {
            // return setAddressState(addressObject)
            await loadData();
        } else {
            setSpinnerState("shippingAddress");
            const response = await dispatch(
                checkoutAction.getDeliveryData(authData.id)
            );

            if (response && response.success) {
                let address = response.data.shippingAddress
                delete address.id
                setAddressState(address);
            }
            setSpinnerState("");
        }
    };

    const validateForm = () => {
        let isFormValid = true;
        let testError = {
            firstName: [],
            lastName: [],
            addressLine1: [],
            addressLine2: [],
            city: [],
            state: [],
            zip: [],
            card: [],
        };
        if (isEmpty(addressState.firstName)) {
            isFormValid = false;
            testError.firstName.push("This field is required!");
        }
        if (isEmpty(addressState.lastName)) {
            isFormValid = false;
            testError.lastName.push("This field is required!");
        }
        if (isEmpty(addressState.addressLine1)) {
            isFormValid = false;
            testError.addressLine1.push("This field is required!");
        }
        // if (isEmpty(addressState.addressLine2)) {
        //     isFormValid = false;
        //     testError.addressLine2.push("This field is required!");
        // }
        if (isEmpty(addressState.city)) {
            isFormValid = false;
            testError.city.push("This field is required!");
        }
        if (isEmpty(addressState.state)) {
            isFormValid = false;
            testError.state.push("This field is required!");
        }
        if (isEmpty(addressState.zip)) {
            isFormValid = false;
            testError.zip.push("This field is required!");
        }
        if (isEmpty(selectedCard)) {
            isFormValid = false;
            testError.card.push(
                "You cannot proceed without selecting payment option!"
            );
        }
        setErrorState(testError);
        return isFormValid;
    };

    const onSubmit = async () => {
        setDisableSubmitButton(true);
        let cartItem = JSON.parse(localStorage.getItem("cart"));
        if (isEmpty(cartItem) || cartItem == "null" || cartItem.length == 0) {
            props.history.push("/shoppingCart");
        } else {
            if (!(await validateForm())) {
                setDisableSubmitButton(false);
                return null;
            }
            const response = await dispatch(
                checkoutAction.savePaymentData(authData.id, {
                    billingAddress: addressState,
                    paymentResponse,
                    userInfo: authData,
                })
            );
            if (response && response.success) {
                let paymentDetail = selectedCard;
                if (response.data.card) {
                    paymentDetail = response.data.card;
                }
                props.history.push({
                    pathname: "/checkout_review",
                    state: {paymentDetail},
                });
            } else {
                setTimeout(() => {
                    dispatch({type: "CLEAN_ERROR"});
                }, 4000);
            }
        }
        setDisableSubmitButton(false);
    };

    const handleSubmit = async (searchValue) => {
        const results = await googleAutocomplete(searchValue);
        if (results) {
            setPredictions(results);
        }
    };

    const googleAutocomplete = async (text) =>
        new Promise((resolve, reject) => {
            if (!text) {
                return reject("Need valid text input");
            }
            if (typeof window === "undefined") {
                return reject("Need valid window object");
            }
            try {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                    {input: text, componentRestrictions: {country: ["us"]}},
                    resolve
                );
            } catch (e) {
                reject(e);
            }
        });

    const onPlaceSelected = async (place) => {
        setEnableGoogleSearch(false);
        const response = await dispatch(
            profileActions.GooglePlacesDetail(authData.id, place.place_id)
        );
        if (!response || !response.result) {
            return;
        }
        let autoCompletedAddress = {
            firstName: addressState.firstName,
            lastName: addressState.lastName,
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
            id: addressState.id,
            type: "shipping",
        };
        const placesArray = response.result.address_components;
        autoCompletedAddress.addressLine1 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["street_number", "route", "premise"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.addressLine2 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["sublocality", "neighborhood"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.city =
            placesArray.filter((places) =>
                places.types.includes("locality")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("locality")
                )[0].long_name
                : "";
        autoCompletedAddress.state =
            placesArray.filter((places) =>
                places.types.includes("administrative_area_level_1")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("administrative_area_level_1")
                )[0].long_name
                : "";
        autoCompletedAddress.zip =
            placesArray.filter((places) => places.types.includes("postal_code"))
                .length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("postal_code")
                )[0].long_name
                : "";
        setAddressState(autoCompletedAddress);
        setPredictions([]);
        setTimeout(() => setEnableGoogleSearch(true), 5000);
    };

    window.acceptUIResponseHandler = (response) => {
        setNewPaymentOption(false);
        setSavedCards([
            ...savedCards.map((card) => ({...card, isSelected: false})),
        ]);

        authorizeCardResponse(response);
        if (response.messages.resultCode === "Error") {
            var i = 0;
            while (i < response.messages.message.length) {
                console.log(
                    response.messages.message[i].code +
                    ": " +
                    response.messages.message[i].text
                );
                i = i + 1;
            }
        }
    };

    const authorizeCardResponse = (response) => {
        let responseMessage = response.messages;
        if (responseMessage?.resultCode === "Error") {
            console.log("error authorizeCardResponse ", responseMessage);
        } else {            
            let newCard = {
                card_number: response.encryptedCardData.cardNumber,
                exp_date: response.encryptedCardData.expDate,
                isSelected: true,
            };
            if (
                savedCards
                    .map((card) => {
                        return (
                            card.card_number === newCard.card_number &&
                            card.exp_date === newCard.exp_date
                        );
                    })
                    .includes(true)
            ) {
                return;
            }
            let allSavedCards = savedCards.map((card) => ({
                ...card,
                isSelected: false,
            }));
            allSavedCards.push(newCard);
            setSavedCards([...allSavedCards]);
            setSelectedCard(newCard);
            setPaymentResponse(response);

            loadActiveCards(true, allSavedCards);

        }
    };

    return (
        <div className="checkoutPayment-page">
            <div className="space-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" size-md="2">
                            <CheckoutSidebar pageIndex={4}/>
                        </IonCol>
                        <IonCol size="12" size-md="10">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6">
                                            <div className="parentform-heading">
                                                <span>{prescriptionRequired ? '4 OF 5' : '3 OF 4'}</span>
                                                <h2>You're (almost) done!</h2>
                                                <div className="cclogos-img">
                                                    <IonImg src={CCLogosImg}/>
                                                </div>
                                                <p>Now you can add your payment card.</p>
                                            </div>

                                            {/* shippingMethod */}
                                            <div className="methodBox">
                                                <div className="shippingMethod-heading">
                                                    <h3>Card Information</h3>
                                                </div>
                                                {/*<div className="shippingMethod-heading">
                                                    <h3>Pending</h3>
                                                </div>*/}
                                                {/* item */}

                                                <div className="shippingMethod-item-scroll">
                                                    {activeCard.card.length !== 0 &&
                                                    activeCard.card.map((card, index) => {
                                                        return(
                                                            <div className="shippingMethod-item" key={index}>
                                                                <div className="shippingMethod-left">
                                                                    <IonCheckbox
                                                                        checked={card.isSelected}
                                                                        onIonChange={(e) =>
                                                                            handleChange(e, "checkCard", index)
                                                                        }
                                                                    />
                                                                    <div className="usps-img">
                                                                        <IonImg src={Uspsimg}/>
                                                                    </div>
                                                                    <div className="shipping-detail">
                                                                        <h3>{card.card_number}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="shippingMethod-right">
                                                                    {/*<p>1/23</p>*/}
                                                                    <p>{card.exp_date}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="shippingMethod-more">
                                                        <IonButton className="theme-btn button-white"
                                                                   disabled={activeCard.activeViewMoreEnable == false}
                                                                   onClick={() => loadActiveCards(false, activeCard)}>
                                                            <span>View More</span>
                                                        </IonButton>
                                                    </div>
                                                </div>

                                                <div className="shippingMethod-item">
                                                    <div className="shippingMethod-left">
                                                        <IonCheckbox
                                                            id="paymentForm"
                                                            checked={newPaymentOption}
                                                            onIonChange={(e) => {
                                                                setNewPaymentOption(e.detail.checked);
                                                                if (
                                                                    authorizePayButtonRef.current &&
                                                                    e.detail.checked
                                                                ) {
                                                                    authorizePayButtonRef.current.click(e);
                                                                    setTimeout(
                                                                        () => setNewPaymentOption(false),
                                                                        2000
                                                                    );
                                                                }
                                                            }}
                                                        />

                                                        <div className="usps-img">
                                                            <IonImg src={GreyImg}/>
                                                        </div>
                                                        <div className="shipping-detail">
                                                            <h3>New Payment Method</h3>
                                                        </div>
                                                    </div>
                                                    <div className="shippingMethod-right"></div>
                                                </div>

                                                {
                                                    <form
                                                        id="paymentForm"
                                                        method="POST"
                                                        action="https://subsciety.azurewebsites.net"
                                                        style={{display: "none"}}
                                                    >
                                                        <input
                                                            type="hidden"
                                                            name="dataValue"
                                                            id="dataValue"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="dataDescriptor"
                                                            id="dataDescriptor"
                                                        />
                                                        <button
                                                            ref={authorizePayButtonRef}
                                                            type="button"
                                                            className="AcceptUI"
                                                            data-billingAddressOptions='{"show":true, "required":false}'
                                                            // data-apiLoginID="4r35nnM9WL3T"
                                                            // data-clientKey="4jK6EkqQSMrG5wkPZrP33HruB26XL2eV645zeKZvN7A459NA3y56LYFmsNhwYUca"
                                                            data-apiLoginID="8EGPZf588xM3"
                                                            data-clientKey="6a2qQ24zf9CJfv8Z4EDXPAm46767hJ65J5jBwpLuF2hG5Xwwe2ZjKRfa8rKKN9Yy"
                                                            data-acceptUIFormBtnTxt="Submit"
                                                            data-acceptUIFormHeaderTxt="Card Information"
                                                            data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                                                            data-responseHandler="acceptUIResponseHandler"
                                                        >
                                                            Authorize Pay
                                                        </button>
                                                    </form>
                                                }
                                                {errorState.card.length !== 0 && errorState.card[0] && (
                                                    <p className="errormsg">{errorState.card[0]}</p>
                                                )}
                                                {/*<div className="theme-card">
                                                    <div className="card-body">
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>Card Number</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. 411-1111-1111-1111"
                                                                        />
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>Expiration Date</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. 03/12"
                                                                        />
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>Security Code</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. 123"
                                                                        />
                                                                    </div>
                                                                </IonCol>

                                                                <IonCol size="12">
                                                                    <div>
                                                                        <IonButton className="theme-btn button-grey">
                                                                            <span>Cancel</span>
                                                                        </IonButton>
                                                                        <IonButton className="theme-btn button-blue">
                                                                            <span>Save</span>
                                                                        </IonButton>
                                                                    </div>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </div>
                                                </div>*/}
                                            </div>

                                            {/* Shipping Address */}
                                            <div className="theme-card-parent">
                                                <div className="theme-card-parent-heading">
                                                    <h3>Billing Address</h3>
                                                </div>
                                                <div className="theme-card">
                                                    <div className="card-body">
                                                        {/* billingAddress box */}
                                                        <div className="billingAddress-info">
                                                            <div className="billingAddress-info-top">
                                                                <h3>
                                                                    <IonCheckbox
                                                                        className="round-checkbox"
                                                                        checked={isBillingShippingAddressSame}
                                                                        onIonChange={(e) =>
                                                                            handleChange(e, "checkBox")
                                                                        }
                                                                    />{" "}
                                                                    Same as shipping address
                                                                </h3>
                                                            </div>
                                                            {spinnerState === "shippingAddress" &&
                                                            commonData.loading ? (
                                                                <IonSpinner/>
                                                            ) : (
                                                                isBillingShippingAddressSame && (
                                                                    <div className="billingAddress-info-body">
                                                                        <div className="billingAddress-detail">
                                                                            <IonLabel>Shipping Address</IonLabel>
                                                                            <p>
                                                                                {convertArrayToCommaSeparatedString([
                                                                                    addressState.addressLine1,
                                                                                    addressState.addressLine2,
                                                                                    addressState.city,
                                                                                    addressState.state,
                                                                                    addressState.zip,
                                                                                ])}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>

                                                        {/* billingAddress form */}
                                                        {!isBillingShippingAddressSame && (
                                                            <div
                                                                className="billingAddress-form"
                                                                ref={wrapperRef}
                                                            >
                                                                <IonGrid>
                                                                    <IonRow>
                                                                        <IonCol size="12" size-md="12">
                                                                            <div className="form-group">
                                                                                <IonLabel>First Name</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. John"
                                                                                    value={addressState.firstName}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "firstName")
                                                                                    }
                                                                                />
                                                                                {errorState.firstName.length !== 0 &&
                                                                                errorState.firstName[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.firstName[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-md="12">
                                                                            <div className="form-group">
                                                                                <IonLabel>Last Name</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. Smith"
                                                                                    value={addressState.lastName}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "lastName")
                                                                                    }
                                                                                />
                                                                                {errorState.lastName.length !== 0 &&
                                                                                errorState.lastName[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.lastName[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-md="12">
                                                                            <div className="form-group search-drop">
                                                                                <IonLabel>Address Line 1</IonLabel>
                                                                                <div className="left-icon-input">
                                                                                    <a>
                                                                                        {/* <IonSpinner/>  */}
                                                                                        <IonIcon icon={searchOutline}/>
                                                                                    </a>

                                                                                    <IonInput
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        placeholder="e.g. 123 Street"
                                                                                        value={addressState.addressLine1}
                                                                                        onIonChange={(e) =>
                                                                                            handleChange(e, "addressLine1")
                                                                                        }
                                                                                    />
                                                                                    {errorState.addressLine1.length !==
                                                                                    0 &&
                                                                                    errorState.addressLine1[0] && (
                                                                                        <p className="errormsg">
                                                                                            {errorState.addressLine1[0]}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                                {predictions.length !== 0 && (
                                                                                    <div className="search-drop-list">
                                                                                        {predictions?.map((prediction) => (
                                                                                            <a
                                                                                                key={prediction?.place_id}
                                                                                                onClick={() =>
                                                                                                    onPlaceSelected(prediction)
                                                                                                }
                                                                                            >
                                                                                                {prediction?.description ||
                                                                                                "Not found"}
                                                                                            </a>
                                                                                        ))}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-md="12">
                                                                            <div className="form-group">
                                                                                <IonLabel>Address Line 2</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. Apt123"
                                                                                    value={addressState.addressLine2}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "addressLine2")
                                                                                    }
                                                                                />
                                                                                {errorState.addressLine2.length !== 0 &&
                                                                                errorState.addressLine2[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.addressLine2[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-md="12">
                                                                            <div className="form-group">
                                                                                <IonLabel>City</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. San Francisco"
                                                                                    value={addressState.city}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "city")
                                                                                    }
                                                                                />
                                                                                {errorState.city.length !== 0 &&
                                                                                errorState.city[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.city[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-lg="6">
                                                                            <div className="form-group">
                                                                                <IonLabel>State</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. San Francisco"
                                                                                    value={addressState.state}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "state")
                                                                                    }
                                                                                />
                                                                                {errorState.state.length !== 0 &&
                                                                                errorState.state[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.state[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                        <IonCol size="12" size-lg="6">
                                                                            <div className="form-group">
                                                                                <IonLabel>Zip Number</IonLabel>
                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. 94016 "
                                                                                    value={addressState.zip}
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "zip")
                                                                                    }
                                                                                />
                                                                                {errorState.zip.length !== 0 &&
                                                                                errorState.zip[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.zip[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonGrid>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {commonData.errorMsg && (
                                                <p className="errormsg">{commonData.errorMsg}</p>
                                            )}

                                            <div className="parentform-button">
                                                <IonButton
                                                    className="theme-btn button-blue"
                                                    onClick={() => onSubmit()}
                                                    disabled={disableSubmitButton}
                                                >
                                                    <span>Next - Review</span>
                                                    {/* {commonData.loading && isEmpty(spinnerState) && (
                            <IonSpinner />
                          )} */}
                                                </IonButton>
                                                <IonButton
                                                    className="theme-btn button-blue-outline"
                                                    onClick={() => props.history.goBack()}
                                                >
                                                    <span>Previous - Delivery</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>

                                        <IonCol size="12" size-md="5" offset-md="1">
                                            <MyCart/>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default CheckoutPayment;
