import { 
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton,
 } from '@ionic/react';
import React, {useState} from 'react';
import './provider.scss';
import { menuController } from "@ionic/core";
import CommonSidebar from '../../components/commonSidebar/commonSidebar';
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import {useDispatch, useSelector} from "react-redux";
import {setWelcomeRegistrationData} from "../../Redux/actions/registrationAction";

const Provider = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.registrationReducer.formdata);
  const [state, setState] = useState(formData)
  const [errorState, setErrorState] = useState({
    pharmacyName: [],
    pharmacyAddress : []
  })


  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
    clearErrors(name)
    const {value} =  event.detail
    setState((prevState) => ({...prevState, [name]: value}));
  }

  const clearErrors = (name) => {
    dispatch({type: "CLEAN_ERROR"})
    if (errorState[name].length !== 0) {
      setErrorState((prevState) => ({...prevState, [name]: []}));
    }
  }

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      pharmacyName : [],
      pharmacyAddress : []
    };
    if (state.pharmacyName.trim().length === 0) {
      isFormValid = false;
      testError.pharmacyName = ["Pharmacy Name cannot be empty!"];
    }
    if (state.pharmacyAddress.trim().length === 0) {
      isFormValid = false;
      testError.pharmacyAddress = ["Pharmacy Addresss cannot be empty!"];
    }
    setErrorState(testError);
    return isFormValid;
  }

  const onSubmit = async (type) => {
    if(type === 'skip'){
      return props.history.push('/insurance')
    }
    await dispatch(setWelcomeRegistrationData(state))
    if (type === 'previous') {
      props.history.goBack()
    } else {
      if (await validateForm()) {
        props.history.push('/insurance')
      }
    }
  }

  return (
    <div className="provider-page pagecard-height">
    <div className="space-container">
    <IonGrid>
      <IonRow>
        <IonCol size="12" size-md="3" size-lg="2">
          <CommonSidebar pageIndex={4}/>
        </IonCol>
        <IonCol size="12" size-md="9" size-lg="10">

          <div className="theme-card">
            <div className="card-body">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-lg="7">
                      <div className="parentform-heading">
                        <span>4 OF 6</span>
                        <h2>What’s your old pharmacy?</h2>
                        <p>Let us know where you have been getting these medications filled and we’ll get them moved over to Qwark.</p>
                      </div>
                      <div className="parentform-left-detail">
                      <IonList>
                        <IonItem>
                          <div className="parentform-left-content">
                            <span><IonImg src={wallet}/></span>
                            <p>Get up to 80% off your medications.</p>  
                          </div>
                        </IonItem>
                        <IonItem>
                          <div className="parentform-left-content">
                            <span><IonImg src={medicine}/></span>
                            <p>Thousands of meds, fullfilled by a licensed & accredited US-based pharmacy.</p>  
                          </div>
                        </IonItem>
                        <IonItem>
                          <div className="parentform-left-content">
                            <span><IonImg src={time}/></span>
                            <p>Order online and get FREE Delivery, and $5 off your first order. </p>  
                          </div>
                        </IonItem>
                      </IonList>
                      </div>
                    </IonCol>

                    <IonCol size="12" size-lg="5">
                      <div className="parentform-right">
                        <div className="form-group">
                          <IonLabel>Pharmacy Name</IonLabel>
                          <IonInput className="form-control" type="text"
                                    placeholder="Enter the pharmacy name"
                                    value={state.pharmacyName}
                                    onIonChange={(e) => handleChange(e, 'pharmacyName')}
                          />
                          {
                            errorState.pharmacyName.length !== 0 && errorState.pharmacyName[0] &&
                            <p className="errormsg">{errorState.pharmacyName[0]}</p>
                          }
                        </div>
                        <div className="form-group">
                          <IonLabel>Pharmacy Address</IonLabel>
                          <IonInput className="form-control" type="text"
                                    placeholder="Enter the pharmacy address"
                                    value={state.pharmacyAddress}
                                    onIonChange={(e) => handleChange(e, 'pharmacyAddress')}
                          />
                          {
                            errorState.pharmacyAddress.length !== 0 && errorState.pharmacyAddress[0] &&
                            <p className="errormsg">{errorState.pharmacyAddress[0]}</p>
                          }
                        </div>

                        <div className="parentform-button">
                          <IonButton className="theme-btn button-blue" onClick={() => onSubmit('continue')}><span>Continue</span></IonButton>
                          <IonButton className="theme-btn button-blue-outline" onClick={() => onSubmit('skip')}><span>Skip</span></IonButton>
                          <IonButton className="theme-btn button-blue-outline" onClick={() => onSubmit('previous')}><span>Previous</span></IonButton>
                        </div>


                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>

        </IonCol>
      </IonRow>
    </IonGrid>
    </div>
  </div>
  );
};

export default Provider;