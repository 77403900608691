import React, { useState } from "react";
import { menuController } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonImg,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Redux/actions/index";
import signinImg from "../../images/signin-img.png";

const ResetCodeVerification = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const [code, setCode] = useState("");
  const [errorState, setErrorState] = useState("");
  const propsState = props.location.state;

  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const handleChange = (event) => {
    dispatch({ type: "CLEAN_ERROR" });
    if (errorState && errorState.length) {
      setErrorState("");
    }
    let { value } = event.detail;
    if (isNaN(+value)) {
      return;
    }
    setCode(value);
  };

  const validateForm = () => {
    let isValid = true;
    let testError = "";
    if (code.toString().length === 0) {
      isValid = false;
      testError = "Reset Code cannot be empty";
    } else if (code.toString().length !== 6) {
      isValid = false;
      testError = "Reset Code must be of 6 digits";
    }
    setErrorState(testError);
    return isValid;
  };

  const onSubmit = async () => {
    if (!(await validateForm())) {
      return null;
    }
    const response = await dispatch(authActions.verifyResetKey({ ...propsState, resetKey: code }));
    if (response && response.success) {
      const token = response.data;
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
        const param =
          propsState.email && propsState.email.length
            ? propsState.email
            : propsState.phone;
        props.history.push({
          pathname: `/resetPassword`,
          state: { token },
        });
      }, 2000);
    }
  };

  return (
    <div className="forgotflow">
      <div className="space-container">
        <div className="login-page-inner">
          <div className="theme-card">
            <div className="card-body">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="signin-left-img">
                        <IonImg src={signinImg} />
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="parentform-right">
                        <div className="parentform-right-head">
                          <h2>Reset Password Code</h2>
                          <p>
                            Enter code sent on your registered email/phone in
                            order to reset your password.
                          </p>
                        </div>

                        <div className="message-text">
                          {commonData.errorMsg && (
                            <p className="errormsg">{commonData.errorMsg}</p>
                          )}
                          {commonData.successMsg && (
                            <p className="successmsg">
                              {commonData.successMsg}
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <IonLabel>Code</IonLabel>
                          <IonInput
                            className="form-control"
                            type="text"
                            placeholder="e.g. 123456"
                            value={code}
                            onIonChange={(e) => handleChange(e)}
                          />
                        </div>
                        {errorState && errorState.length !== 0 && (
                          <p className="errormsg">{errorState}</p>
                        )}
                        <div className="parentform-button">
                          <IonButton
                            className="theme-btn button-blue"
                            onClick={() => onSubmit()}
                          >
                            Submit
                            {/*{commonData.loading && <IonSpinner />}*/}
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetCodeVerification;
