import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { commonReducer } from "./commonReducer";
import { registrationReducer } from "./registrationReducer";
import { userReducer } from "./userReducer";

const appReducer = combineReducers({
  authReducer,
  commonReducer,
  registrationReducer,
  userReducer
});

let rootReducer = null;
export default rootReducer = (state, action) => {
  return appReducer(state, action);
};
