export const startLoadig = () => async (dispatch) => {
    dispatch({ type: "LOADING_STARTED" });
  };
  
  export const stopLoadig = () => async (dispatch) => {
    dispatch({ type: "LOADING_COMPLETED" });
  };
  
  export const handleError = (error) => async (dispatch) => {
    console.log("ereerrrrrrrrr", error);
    dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 403) {
        localStorage.removeItem("userData")
        localStorage.removeItem("token")
          window.location.reload();
        dispatch({
          type: "ERROR",
          payload: { error: error?.response?.data?.message },
        });
      }
      else if (error.response.data?.errorData ) {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      } else if (error.response.data?.data ) {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data?.data.error  },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { errorMsg: error.response.data },
        });
        //   toast.error(error.response.data.error);
      }
    } else
      dispatch({
        type: "ERROR",
        payload: { errorMsg: error.message },
      });
  };

  export const handleSuccess = (data) => async (dispatch) => {
    dispatch({
      type: "SUCCESS",
      payload: { successMsg: data?.message },
    });
  };
