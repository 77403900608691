import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import React, { useState } from "react";
import "./thankYou.scss";
import { menuController } from "@ionic/core";
import thankYou from "../../images/thank-you.png";
import {
  convertArrayToCommaSeparatedString,
  isEmpty,
  parseCardExpireDate,
} from "../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";

const ThankYou = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(props.location.state);
  const deliveryOption = useSelector(
    (state) => state.userReducer.deliveryOption
  );

  console.log("state ", state);

  React.useEffect(() => {
    menuController.enable(false);
    removeOrderedItemsFromCart();
  }, []);

  const applyDiscount = () => {
    let subtotal = getCartSubtotal();

    if (isEmpty(state.orderItems[0].discountPercent)) {
      return 0;
    }
    let discount = state.orderItems[0].discountPercent;
    discount = (subtotal * discount) / 100;
    return parseFloat(discount).toFixed(2);
  };

  const getCartSubtotal = () => {
    let subtotal = 0;
    for (let cartItem of state.orderItems) {
      subtotal = subtotal + parseFloat(cartItem.price);
    }
    return parseFloat(subtotal).toFixed(2);
  };

  const convertTimeStampToDate = (timeStamp) => {
    const jsDate = new Date(timeStamp);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(jsDate);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(jsDate);
    const year = jsDate.getFullYear();
    let formattedDate = da + " " + mo + ", " + year + " ";
    return formattedDate;
  };
  const removeOrderedItemsFromCart = () => {
    localStorage.setItem("cart", null);
    dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
  };

  const getCartTotal = () => {
    let subtotal = parseFloat(getCartSubtotal());
    if (!isEmpty(state.deliveryOption)) {
      subtotal = subtotal + parseFloat(state.deliveryOption.cost);
    }
    let discount = applyDiscount();
    subtotal = subtotal - discount;
    return subtotal.toFixed(2);
  };

  return (
    <div className="thankYou-page">
      <div className="space-container">
        {/* thankYou-header */}
        <div className="thankYou-header">
          <h2>Thank you for your order!</h2>
          <p className="orderNumber">
            Order Number:<span>{state.orderNumber}</span>
          </p>
          <p>
            Your order has been placed and is being processed. You will receive
            an email with order details.
          </p>
        </div>

        <div className="theme-card">
          <div className="card-body">
            {/* parentform */}
            <div className="parentform">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="5">
                    {/* my cart */}
                    <div className="mycart">
                      <div className="mycart-header">
                        <h3>Order Summary:</h3>
                      </div>

                      <div className="theme-card">
                        <div className="card-body">
                          <div className="mycart-mid">
                            {/* item */}
                            {state.orderItems &&
                              state.orderItems.map((item, index) => (
                                <div className="mycart-detail-item" key={index}>
                                  <div className="mycart-detail">
                                    <div className="mycart-left">
                                      <h3>{item.medicineName}</h3>
                                    </div>
                                    <div className="mycart-right">
                                      <p className="mycart-price">
                                        ${parseFloat(item.price).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mycart-detail">
                                    <div className="mycart-left">
                                      <p>
                                        {item.medicineForm} •{" "}
                                        {item.medicineStrength} mg
                                        {/* •{" "}
                                        {convertTimeStampToCartDateFormat(
                                          item.orderTime
                                        )} */}
                                      </p>
                                    </div>
                                    <div className="mycart-right"></div>
                                  </div>
                                  <div className="mycart-detail">
                                    <div className="mycart-left">
                                      <p>Package Size: {item.activeQuantity}</p>
                                    </div>
                                    <div className="mycart-right"></div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="mycart-bottom">
                        {/* item */}
                        <div className="mycart-detail-item">
                          <div className="mycart-detail">
                            <div className="mycart-left">
                              <h3>Subtotal</h3>
                            </div>
                            <div className="mycart-right">
                              <p className="mycart-price">
                                ${parseFloat(state.subTotal).toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div className="mycart-detail">
                            <div className="mycart-left">
                              <h3>Discount</h3>
                            </div>
                            <div className="mycart-right">
                              <p className="mycart-price">
                                -${applyDiscount()}
                              </p>
                            </div>
                          </div>

                          {isEmpty(state.deliveryOption) ? (
                            <div className="mycart-detail">
                              <div className="mycart-left">
                                <h3>USPS First Class Shipping</h3>
                              </div>
                              <div className="mycart-right">
                                <p className="mycart-price">$0.00</p>
                              </div>
                            </div>
                          ) : (
                            <div className="mycart-detail">
                              <div className="mycart-left">
                                <h3>{state.deliveryOption.name}</h3>
                              </div>
                              <div className="mycart-right">
                                <p className="mycart-price">
                                  ${state.deliveryOption.cost}
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="mycart-detail">
                            <div className="mycart-left">
                              <h3 className="total-heading">Order total</h3>
                            </div>
                            <div className="mycart-right">
                              {/*<p className="mycart-price total-price">${isEmpty(state.deliveryOption) ? parseFloat(state.subTotal).toFixed(2) :  (parseFloat(state.subTotal)+parseFloat(state.deliveryOption.cost)).toFixed(2)}</p>*/}
                              <p className="mycart-price total-price">
                                ${getCartTotal()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6" offset-md="1">
                    <div className="thankyou-img">
                      <IonImg src={thankYou} />
                    </div>

                    {/* Patient Info */}
                    {/* <div className="methodBox thankyou-info">
                  <div className="theme-card">
                    <div className="card-body">
                     
                      <div className="shippingMethod-item">
                        <div className="shippingMethod-left">
                          <div className="shipping-detail">
                            <h3>Additional Health Information</h3>
                            <p>Allergies: None</p>
                            <p>Medical Conditions: None</p>
                            <p>Other Medications: None</p>
                          </div>
                        </div>
                      </div>

                    
                      <div className="shippingMethod-item">
                        <div className="shippingMethod-left">
                          <div className="shipping-detail">
                            <h3>Prescriber</h3>
                            <p>John Smilth</p>
                            <p>3188 Charack Road</p>
                            <p>Redwood City, CA 94065</p>
                          </div>
                        </div>
                      </div>

                     
                      <div className="shippingMethod-item">
                        <div className="shippingMethod-left">
                          <div className="shipping-detail">
                            <h3>Card Information</h3>
                            <p>VISA ending in 1234</p>
                            <p>Expires Apr 2023</p>
                          </div>
                        </div>
                      </div>

                    
                      <div className="shippingMethod-item">
                        <div className="shippingMethod-left">
                          <div className="shipping-detail">
                            <h3>Billing Address</h3>
                            <p>3188 Charack Road</p>
                            <p>Redwood City, CA 94065</p>
                          </div>
                        </div>
                      </div>

                     
                      <div className="shippingMethod-item">
                        <div className="shippingMethod-left">
                          <div className="shipping-detail">
                            <h3>Shipping Address</h3>
                            <p>3188 Charack Road</p>
                            <p>Redwood City, CA 94065</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                    {/* Patient Info */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            {/* orderflow */}
            <div className="orderflow">
              <div className="theme-card">
                <div className="card-body">
                  <div className="orderflow-inline">
                    {/* item */}
                    <div className="orderflow-item">
                      <div className="orderflow-item-top">
                        <h3>Order Date</h3>
                      </div>
                      <div className="orderflow-item-detail">
                        <p>{convertTimeStampToDate(state.orderDate)}</p>
                      </div>
                    </div>

                    {/* item */}
                    <div className="orderflow-item">
                      <div className="orderflow-item-top">
                        <h3>Order Number</h3>
                      </div>
                      <div className="orderflow-item-detail">
                        <p>{state.orderNumber}</p>
                      </div>
                    </div>

                    {/* item */}
                    <div className="orderflow-item">
                      <div className="orderflow-item-top">
                        <h3>Shipping Address</h3>
                      </div>
                      <div className="orderflow-item-detail">
                        <p>
                          {convertArrayToCommaSeparatedString([
                            state.shippingAddress.addressLine1,
                            state.shippingAddress.addressLine2,
                            state.shippingAddress.city,
                            state.shippingAddress.state,
                            state.shippingAddress.zip,
                          ])}
                        </p>
                      </div>
                    </div>

                    {/* item */}
                    <div className="orderflow-item">
                      <div className="orderflow-item-top">
                        <h3>Card Information</h3>
                      </div>
                      <div className="orderflow-item-detail">
                        {/*<p>VISA ending in 1234 Expires Apr 2023</p>*/}
                        <p>
                          {state.paymentDetail?.card_type?.toUpperCase()} ending
                          in {state.paymentDetail.card_number.slice(-4)}
                        </p>
                        <p>
                          Expires{" "}
                          {parseCardExpireDate(state.paymentDetail.exp_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
