import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import React, { useState } from "react";
import "./insurance.scss";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomeRegistrationData } from "../../Redux/actions/registrationAction";
import * as registrationAction from "../../Redux/actions/registrationAction";

const Insurance = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const formData = useSelector((state) => state.registrationReducer.formdata);
  const [state, setState] = useState(formData);
  const [errorState, setErrorState] = useState({
    memberId: [],
    rxbin: [],
    // effectiveDate: []
  });
  const [buttonSpinner, setButtonSpinner] = useState("");

  React.useEffect(() => {
    menuController.enable(false);
    dispatch({ type: "CLEAN_ERROR" });
  }, []);

  const handleChange = (event, name) => {
    // if(name === 'rxbin' && isNaN(parseInt(value))){
    //     return null
    // }
    clearErrors(name);
    const { value } = event.detail;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearErrors = (name) => {
    dispatch({ type: "CLEAN_ERROR" });
    if (name in errorState && errorState[name].length !== 0) {
      setErrorState((prevState) => ({ ...prevState, [name]: [] }));
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      memberId: [],
      rxbin: [],
      // effectiveDate: []
    };
    if (state.memberId.trim().length === 0) {
      isFormValid = false;
      testError.memberId = ["Member ID cannot be empty!"];
    }
    if (state.rxbin.trim().length === 0) {
      isFormValid = false;
      testError.rxbin = ["RXBIN cannot be empty!"];
    }

    if (isNaN(+state.rxbin)) {
      isFormValid = false;
      testError.rxbin = ["RXBIN can only contain number!"];
    }
    // if (state.effectiveDate.toString().trim().length === 0) {
    //     isFormValid = false;
    //     testError.effectiveDate = ["Effective Date cannot be empty!"];
    // }
    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async (type) => {
    setButtonSpinner(type);
    await dispatch(setWelcomeRegistrationData(state));
    if (type === "previous") {
      return props.history.goBack();
    } else if (type === "continue") {
      if (!(await validateForm())) {
        return null;
      }
    } else if (type === "skip") {
      const insuranceState = {
        memberId: "",
        rxbin: "",
        rxpcn: "",
      };
      await dispatch(
        registrationAction.setWelcomeRegistrationData({
          ...state,
          ...insuranceState,
        })
      );
    }
    // const response = await dispatch(signUp(state))
    // if (response.success) {
    //     props.history.push('/confirmation')
    // }
    props.history.push("/prescriber");
  };

  return (
    <div className="insurance-page pagecard-height">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="3" size-lg="2">
              <CommonSidebar pageIndex={3} />
            </IonCol>
            <IonCol size="12" size-md="9" size-lg="10">
              <div className="theme-card">
                <div className="card-body">
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-lg="7">
                          <div className="parentform-heading">
                            <span>3 OF 5</span>
                            <h2>Add insurance detail.</h2>
                            <p>Let us know what your insurance is.</p>
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={wallet} />
                                  </span>
                                  <p>Get up to 80% off your medications.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={medicine} />
                                  </span>
                                  <p>
                                    Thousands of meds, fullfilled by a licensed
                                    & accredited US-based pharmacy.
                                  </p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={time} />
                                  </span>
                                  <p>
                                    Order online and get FREE Delivery, and $5
                                    off your first order.
                                  </p>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                        </IonCol>

                        <IonCol size="12" size-lg="5">
                          <div className="parentform-right">
                            <div className="form-group">
                              <IonLabel>Member ID #</IonLabel>
                              <IonInput
                                className="form-control"
                                type="text"
                                placeholder="e.g. 123456789"
                                value={state.memberId}
                                onIonChange={(e) => handleChange(e, "memberId")}
                              />
                              {errorState.memberId.length !== 0 &&
                                errorState.memberId[0] && (
                                  <p className="errormsg">
                                    {errorState.memberId[0]}
                                  </p>
                                )}
                            </div>
                            <div className="form-group">
                              <IonLabel>RXBIN #</IonLabel>
                              <IonInput
                                className="form-control"
                                type="number"
                                placeholder="e.g. 123456 (must be 6 digits)"
                                value={state.rxbin}
                                onIonChange={(e) => handleChange(e, "rxbin")}
                              />
                              {errorState.rxbin.length !== 0 &&
                                errorState.rxbin[0] && (
                                  <p className="errormsg">
                                    {errorState.rxbin[0]}
                                  </p>
                                )}
                            </div>
                            <div className="form-group">
                              <IonLabel>RXPCN # (optional)</IonLabel>
                              <IonInput
                                className="form-control"
                                type="text"
                                placeholder="e.g. A1"
                                value={state.rxpcn}
                                onIonChange={(e) => handleChange(e, "rxpcn")}
                              />
                            </div>
                            {/*<div className="form-group">
                                                            <IonLabel>Effective Date</IonLabel>

                                                            <div className="dateicon-input">
                                                                <IonDatetime 
                                                                    className="form-control" 
                                                                    displayFormat="MM-DD-YYYY" 
                                                                    placeholder="Enter the Effective Date"
                                                                    value={state.effectiveDate}
                                                                    onIonChange={(e) => handleChange(e, 'effectiveDate')}>
                                                                </IonDatetime>
                                                                <a href="javascript:;"
                                                                ><IonIcon icon={calendarClearOutline}/></a>
                                                            </div>
                                                            {
                                                                errorState.effectiveDate.length !== 0 && errorState.effectiveDate[0] &&
                                                                <p className="errormsg">{errorState.effectiveDate[0]}</p>
                                                            }
                                                        </div>*/}
                            {commonData.errorMsg &&
                              commonData.errorMsg.length !== 0 && (
                                <p className="errormsg">
                                  {commonData.errorMsg}
                                </p>
                              )}

                            <div className="parentform-button">
                              <IonButton
                                className="theme-btn button-blue"
                                onClick={() => onSubmit("continue")}
                              >
                                <span>Continue</span>
                              </IonButton>
                              <IonButton
                                className="theme-btn button-blue-outline"
                                onClick={() => onSubmit("skip")}
                              >
                                <span>Skip</span>
                              </IonButton>
                              <IonButton
                                className="theme-btn button-blue-outline"
                                onClick={() => onSubmit("previous")}
                              >
                                <span>Previous</span>
                              </IonButton>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Insurance;
