import Api from "../../Utils/Api";
import {handleError, handleSuccess} from "./commonAction";


export const getMedicalData = (userId)=> async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/checkout/${userId}/medical_data`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const sendQwarkInfoToUser = (userId, type) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/checkout/${userId}/qwark_info/${type}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const verifyRx = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/checkout/${userId}/verify_rx`,body);
        if(res.data.success){
            dispatch(handleSuccess({ message: res.data.message }));
        }
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const getDeliveryData = (userId) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/checkout/${userId}/delivery`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const saveDeliveryData = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/checkout/${userId}/delivery`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}


export const savePaymentData = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/checkout/${userId}/payment`, body);
        await dispatch({ type: "LOADING_COMPLETED" });
        if(!res.data.success){
            dispatch(handleError({ message: res.data.message }));
        }
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const getPaymentData = (userId) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/checkout/${userId}/payment`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const getReviewData = (userId) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/checkout/${userId}/review`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const generateOrder = (userId, body) => async (dispatch) => {
    try{
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/checkout/${userId}/review`, body);
        if(!res.data.success){
            dispatch(handleError({ message: res.data.message }));
        }
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    }catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const validateAppliedCoupon = (total, couponCode) => async (
    dispatch
  ) => {
    console.log("params are", { total, couponCode });
    try {
      dispatch({ type: "LOADING_STARTED" });
  
      const res = await Api.get(`/api/v1/cart/coupon?total=${total}&code=${couponCode}`);
  
      dispatch({ type: "LOADING_COMPLETED" });
  
      console.log("validateAppliedCoupon from backend", res);
  
      return res;
    } catch (error) {
      console.log("error while validate applied coupon", error);
      dispatch(handleError(error));
    }
};

export const validateZipCode = (zipCode) => async (
    dispatch
  ) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(`/api/v1/cart/verify-shipping?zipcode=${zipCode}`);
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      console.log("error while validate applied coupon", error);
      dispatch(handleError(error));
    }
};
