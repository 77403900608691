import { IonButton, IonIcon } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../Redux/actions";
import { getPrescriptionData } from "../../Utils/helper";
import "./Accordion.scss";

const Accordion = () => {
  const [showAccordion, setShowAccordion] = useState(false);
  const authData = useSelector((state) => state.authReducer.authData);
  const [limit, setLimit] = React.useState(6);

  const [prescriptionData, setPrescriptionData] = React.useState([]);
  React.useEffect(() => {
    getPrescriptionData({
      user_id: authData.id,
      limit: limit,
      setPrescriptionData: setPrescriptionData,
    });
  }, [limit]);

  const filteredPrescriptionData = prescriptionData.reduce((acc, current) => {
    const x = acc.find(
      (item) =>
        `${item.name} ${item.unit_strength_qty} ${item.unit_strength_code}` ===
          `${current.name} ${current.unit_strength_qty} ${current.unit_strength_code}` &&
        item.order_time > current.order_time
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const dispatch = useDispatch();

  const cartStateMedicineName = useSelector(
    (state) => state.userReducer.cartItems
  ).map(
    (item) =>
      `${item.name} ${item.unit_strength_qty} ${item.unit_strength_code}`
  );

  const onSubmit = async (item) => {
    let calculatedPrice = item?.price;
    if (parseInt(item?.price) === 0) {
      calculatedPrice =
        ((item?.cost_per_dose * item?.quantity + item?.shipping_cost) *
          (1 + item?.cc_cost_variable / 100) +
          item?.cc_cost_fixed) *
        (1 + item?.margin / 100);
    }

    let defaulCalculatedPrice = item?.price;
    if (defaulCalculatedPrice == 0) {
      defaulCalculatedPrice =
        ((item?.cost_per_dose * parseInt(item?.qauntity) +
          item?.shipping_cost) *
          (1 + item?.cc_cost_variable / 100) +
          item?.cc_cost_fixed) *
        (1 + item?.margin / 100);
    }

    const itemToCart = {
      ...item,
      isAddedToCart: 1,
      medicineForm: item?.form,
      medicineStrength: item?.unit_strength_qty + item?.unit_strength_code,
      medicineName: item?.name,
      medicineId: item?.product_id,
      defaultPrice: defaulCalculatedPrice,
      medicineDescription: item?.description,
      activeMedicineFormIndex: 0,
      activeMedicineFormId: item?.active_medicine_form_id,
      activeMedicineStrengthIndex: 0,
      activeMedicineStrengthId: item?.products_strength_id,
      unitStrengthQty: item?.unit_strength_qty,
      unitStrengthCode: item?.unit_strength_code,
      activeQuantityIndex: 0,
      activeQuantity: item?.quantity,
      promoCode: null,
      discountPercent: item?.discount,
      medicineQuantity: item?.quantity,
      price: calculatedPrice,
    };

    await dispatch(productAction.addToCart(itemToCart, authData.id));

    await dispatch(productAction.updateDeliveryCost());
  };

  return (
    <>
      <div className="accountSummary-cardparent medicationAccordionMain">
        <div className="accountSummary-cardparent-heading">
          <h3 className="medicationAccordionHeadingMain">Your Prescriptions</h3>
        </div>
        {!prescriptionData?.length && (
          <div className="noMedication">
            Your medications details will appear here once you’ve placed your
            first order
          </div>
        )}

        {filteredPrescriptionData &&
          filteredPrescriptionData?.map((item, index) => {
            const medicineName = `${item.name} ${item.unit_strength_qty} ${item.unit_strength_code}`;
            return (
              <>
                <div className="theme-card" style={{ marginBottom: "15px" }}>
                  <div className="card-header accordionHead">
                    <div className="order-top">
                      <div className="order-number medicationAccordion">
                        {!item.rx_number && (
                          <span className="noMedication">
                            Details will be available soon, check back later
                          </span>
                        )}
                        <h3 className="medicationAccordionHeading">
                          {item.name} {item.dosage} {item.unit_strength_code}
                        </h3>
                      </div>
                      <div className="tracking-number">
                        <p className="medicationAccordionParagrph">
                          {" "}
                          Last Filled on: {item.last_filled}
                        </p>
                      </div>
                      <div className="order-date">
                        <p className="medicationAccordionParagrph">
                          {" "}
                          Refills Remaining: {item.refills_remaining}
                        </p>
                      </div>
                      <div className="order-date">
                        <p className="medicationAccordionParagrph">
                          {" "}
                          Status: {item.prescription_status}
                        </p>
                      </div>
                      <div className="order-date">
                        <p className="medicationAccordionParagrph">
                          Rx Number: {item.rx_number}
                        </p>
                      </div>
                    </div>
                    <div className="accordionRightCol">
                      <a
                        onClick={() =>
                          setShowAccordion((prev) =>
                            prev === index ? null : index
                          )
                        }
                        className="rightArrow"
                      >
                        <IonIcon
                          icon={
                            showAccordion === index
                              ? chevronUpOutline
                              : chevronDownOutline
                          }
                        />
                      </a>
                      <div className="meds-btn">
                        <IonButton
                          onClick={() => onSubmit(item)}
                          disabled={cartStateMedicineName.includes(
                            medicineName
                          )}
                          className="theme-btn button-grey medAddToCart"
                        >
                          <span>
                            {cartStateMedicineName.includes(medicineName)
                              ? "Added to Cart"
                              : "Add to Cart"}
                          </span>
                        </IonButton>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    {showAccordion === index && (
                      <div className="order-detail-box bg-grey" key={index}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="order-item-detail">
                                  <p className="medicationAccordionParagrph">
                                    Prescribed by: {item.prescribed_by}{" "}
                                  </p>
                                  <p className="medicationAccordionParagrph">
                                    Prescribed on: {item.prescribed_on}
                                  </p>
                                  <p className="medicationAccordionParagrph">
                                    Instruction: {item.instruction}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>
      {prescriptionData.length > 6 ? (
        <div className="showMoreBtn">
          <a onClick={() => setLimit(18)}>
            <IonButton className="theme-btn button-grey medAddToCart">
              <span>Show More</span>
            </IonButton>
          </a>
        </div>
      ) : prescriptionData.length === 18 ? (
        <span className="noMedication">
          {" "}
          Please check your order history for other medications ordered in the
          past or reach out to us via email or chat
        </span>
      ) : null}
    </>
  );
};

export default Accordion;
