import Api from "../../Utils/Api";
import { handleError,handleSuccess } from "./commonAction";
import {browserHistory} from "../../history";
import {isEmpty} from "../../Utils/helper";

export const loginUser = (body) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/login`, body);
    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data
  } catch (error) {
    await dispatch({ type: "LOADING_FAILURE" });
     if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("user")
    localStorage.removeItem("userData")
        // window.location.reload();
      } else {
        console.log('error ', error.response.data)
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }
      // toast.error(error.response.data.error);
    } else {
       console.log('error.message ', error.message)
      dispatch({type: "ERROR", payload: { error: error.message },});
    }
  }
};

export const forgotPassword = (type,body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/forgot-password/${type}`, body);
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.success === false) {
      dispatch(handleError({ message: res.data.message }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
      return res.data;
    }
  } catch (error) {
    console.log('error forgotPassword :', error)
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
    
  }
};

export const resetPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/reset_password`, body);
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.success === false) {
      dispatch(handleError({ message: res.data.message }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
    }
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
    
  }
};

export const isUser = () => async (dispatch) => {
  try {
    let token = await localStorage.getItem("userData");
    if (token) {
      token = JSON.parse(token);
      dispatch({
        type: "USERINFO",
        payload: { data: token },
      });
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/change-password`, body);
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.success === false) {
      dispatch(handleError({ response: {data : res.data.message} }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
      return res;
    }
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
};

export const updateProfileSettings = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    if(data.userId == null){
      // alert('No User Found')
      return
    }
    const imageFile = data.image
    delete data.image
    let formData = new FormData()
    formData.append('data', JSON.stringify(data))
    formData.append('image', imageFile)
    const res = await Api.post(`/api/v1/users/${data.userId}/updateProfileSettings`, formData);
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.isUpdated === false) {
      dispatch(handleError({ message: res.data.data }));
    } else {
      dispatch(handleSuccess({ message: res.data.data }));
      await dispatch({
        type: "REFRESH_AUTH_DATA",
        payload: { data: res.data },
      });

    }

  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
}

export const saveDeviceToken = (userId, body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/${userId}/token`, body);
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
};

export const matchtoken = () => async (dispatch) => {
  const userData = JSON.parse(await localStorage.getItem("userData"))
  try {
    if(isEmpty(userData)){
      return null
    }
    const res = await Api.get(`/api/v1/users/${userData.id}/matchToken?token=${userData.token}&email=${userData.email}`);
    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        await dispatch({ type: "LOGOUT_USER" })
        // await browserHistory.push('/login')
        // localStorage.removeItem("user")
        // localStorage.removeItem("userData")

      } else {
        console.log('error : ', error.response.data)
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }
      // toast.error(error.response.data.error);
    } else {
      console.log('error : ', error.message)
      dispatch({
        type: "ERROR",
        payload: { error: error.message },
      });
    }
  }
};

export const setRegistrationData = (data) => async (dispatch) => {
  await dispatch({
    type: "SET_REGISTRATION_DATA",
    payload : data
  })
}

export const logout = ()=>async (dispatch)=>{
  await dispatch({ type: "LOGOUT_USER" })
  if(window.gapi.auth2){
    let auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
    });
  }
  // await browserHistory.push('/login')
}

export const socialLogin = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/sociallogin`, body);
    dispatch({ type: "LOADING_COMPLETED" });

    dispatch({
      type: "SOCIALLOGIN",
      payload: { data: res.data },
    });

    dispatch({
      type: "SETUSERINFO",
      payload: res.data.user,
    });

  } catch (error) {
    dispatch(handleError(error));
  }
};

export const verifyResetKey = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/resetKey`, body);
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.success === false) {
      dispatch(handleError({ response: {data : res.data.message} }));
    } else {
      await dispatch({
        type: "LOGIN",
        payload: { data: res.data },
      });
      dispatch(handleSuccess({ message: res.data.message }));
    }
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
};