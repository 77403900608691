import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
} from "@ionic/react";
import { eyeOutline, closeOutline, checkmarkOutline } from "ionicons/icons";
import React, { useState } from "react";
import "./login.scss";
import { authActions } from "../../Redux/actions";
import { menuController } from "@ionic/core";
import googleIcon from "../../images/google-icon.png";
import signinImg from "../../images/signin-img.png";
import GoogleLogin from "../SocialLogin/googleLogin";

const Login = (props) => {
  //menu
  React.useEffect(() => {
    menuController.enable(false);
    dispatch({ type: "CLEAN_ERROR" });
  }, []);

  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    email: [],
    password: [],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidationState, setPasswordValidationState] = useState({
    length: "",
    uppercase: "",
    specialCharacter: "",
  });
  const [isButtonDisabled, setButtonDisable] = useState(true)

  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
    console.log('handleChange', event);
    const { value } = event.detail;
    if (name === "password") {
      let pValidation = {
        length: false,
        uppercase: false,
        specialCharacter: false,
      };
      const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      pValidation.length = value.length >= 8;
      pValidation.uppercase = new RegExp("[A-Z]").test(value);
      pValidation.specialCharacter = specialCharacterFormat.test(value);
      setPasswordValidationState(pValidation);
    }
    const updateState = {
      ...state,
      [name]: value,
    };
    setState(updateState);
    const isSubmitButtonDisabled = updateState.email.length === 0 || updateState.password.length === 0;
    setButtonDisable(isSubmitButtonDisabled);
    // validateForm(updateState);
  };

  const validateForm = (stateToCheck) => {
    if (!stateToCheck) {
      stateToCheck = state;
    }
    let isFormValid = true;
    let testError = {
      email: [],
      password: [],
    };
    if (stateToCheck.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["Email cannot be empty!"];
    }
    if (
      stateToCheck.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        stateToCheck.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }
    if (stateToCheck.password.trim().length === 0) {
      isFormValid = false;
      testError.password = ["Password cannot be empty!"];
    }
    if (!passwordValidationState.length) {
      isFormValid = false;
    }
    if (!passwordValidationState.specialCharacter) {
      isFormValid = false;
    }
    if (!passwordValidationState.uppercase) {
      isFormValid = false;
    }
    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    // if (!(validateForm())) {
    //   return null;
    // }
    const response = await dispatch(authActions.loginUser(state));
    if (response && response.success) {
      props.history.push("/accountSummary");
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }
  };

  return (
    <div className="login-page">
      <div className="space-container">
        <div className="login-page-inner">
          <div className="theme-card">
            <div className="card-body">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="7" size-lg="7">
                      <div className="signin-left-img">
                        <IonImg src={signinImg} />
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="5" size-lg="5">
                      <div className="parentform-right">
                        <div className="parentform-right-head">
                          <h2>Sign in</h2>
                        </div>
                        {commonData.errorMsg.error && (
                          <p className="errormsg">
                            {commonData.errorMsg.error}
                          </p>
                        )}
                        <div className="form-group">
                          <IonLabel>Email</IonLabel>
                          <IonInput
                            className="form-control"
                            type="text"
                            placeholder="e.g. johnsmilth@gmail.com"
                            onIonChange={(e) => handleChange(e, "email")}
                            value={state.email}
                          />
                          {errorState.email.length !== 0 &&
                            errorState.email[0] && (
                              <p className="errormsg">{errorState.email[0]}</p>
                            )}
                        </div>
                        <div className="form-group">
                          <div className="inline-label">
                            <IonLabel>Password</IonLabel>
                            <a
                              className="inline-link"
                              href="javascript:;"
                              onClick={() =>
                                props.history.push("/forgotPassword")
                              }
                            >
                              Forgot password?
                            </a>
                          </div>

                          <div className="icon-input">
                            <IonInput
                              className="form-control"
                              type={showPassword ? "text" : "password"}
                              placeholder="e.g. @Abcefghi"
                              onIonChange={(e) => handleChange(e, "password")}
                              value={state.password}
                            />
                            <a
                              href="javascript:;"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <IonIcon icon={eyeOutline} />
                            </a>
                            {/*state.password && <div>
                              <p
                                  className={
                                    passwordValidationState.length.length === 0
                                        ? "text-grey"
                                        : passwordValidationState.length
                                            ? "successmsg"
                                            : "errormsg"
                                  }
                              >
                                {" "}
                                {passwordValidationState.length.length ===
                                0 ? null : passwordValidationState.length ? (
                                    <IonIcon icon={checkmarkOutline} />
                                ) : (
                                    <IonIcon icon={closeOutline} />
                                )}{" "}
                                Use at least 8 characters
                              </p>
                              <p
                                  className={
                                    passwordValidationState.uppercase.length === 0
                                        ? "text-grey"
                                        : passwordValidationState.uppercase
                                            ? "successmsg"
                                            : "errormsg"
                                  }
                              >
                                {passwordValidationState.uppercase.length ===
                                0 ? null : passwordValidationState.uppercase ? (
                                    <IonIcon icon={checkmarkOutline} />
                                ) : (
                                    <IonIcon icon={closeOutline} />
                                )}{" "}
                                Have at least 1 upper case
                              </p>
                              <p
                                  className={
                                    passwordValidationState.specialCharacter
                                        .length === 0
                                        ? "text-grey"
                                        : passwordValidationState.specialCharacter
                                            ? "successmsg"
                                            : "errormsg"
                                  }
                              >
                                {passwordValidationState.specialCharacter
                                    .length ===
                                0 ? null : passwordValidationState.specialCharacter ? (
                                    <IonIcon icon={checkmarkOutline} />
                                ) : (
                                    <IonIcon icon={closeOutline} />
                                )}{" "}
                                Have at least 1 special character
                              </p>
                            </div>*/}
                            {errorState.password.length !== 0 &&
                              errorState.password[0] && (
                                <p className="errormsg">
                                  {errorState.password[0]}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="parentform-button">
                          <IonButton
                            className="theme-btn button-blue"
                            disabled={isButtonDisabled}
                            onClick={() => onSubmit()}
                          >
                            Sign in
                            {/*{commonData.loading && <IonSpinner />}*/}
                          </IonButton>
                          {/*<GoogleLogin/>*/}
                        </div>

                        <div className="have-account-text">
                          <p>
                            New to Qwark?{" "}
                            <a
                              href="javascript:;"
                              onClick={() => props.history.push("/welcome")}
                            >
                              Sign up
                            </a>
                          </p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
