import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { closeOutline, calendarClearOutline } from "ionicons/icons";
import React, { useState } from "react";
import "./profile.scss";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomeRegistrationData } from "../../Redux/actions/registrationAction";
import {
  convertTimeStampToDateYYYYMMDD,
  maskPhoneNumber,
} from "../../Utils/helper";
import * as registrationAction from "../../Redux/actions/registrationAction";
import Calendar from "react-calendar";

const Profile = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.registrationReducer.formdata);
  const commonData = useSelector((state) => state.commonReducer);
  const [state, setState] = useState(formData);
  const [showCalendar, setShowCalendar] = useState(false);

  const [errorState, setErrorState] = useState({
    firstName: [],
    lastName: [],
    dob: [],
    gender: [],
    phone: [],
  });

  const handleChange = (event, name) => {
    clearErrors(name);
    let { value } = event.detail;
    if (name === "dob") {
      setShowCalendar(false);
    }
    if (name === "phone" && value) {
      value = value.replace(/\D/g, "");
      if (isNaN(value)) {
        setErrorState((prevState) => ({
          ...prevState,
          phone: ["Phone number can only contain numbers !"],
        }));
      } else {
        setErrorState((prevState) => ({ ...prevState, phone: [] }));
      }
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearErrors = (name) => {
    dispatch({ type: "CLEAN_ERROR" });
    if (errorState[name].length !== 0) {
      setErrorState((prevState) => ({ ...prevState, [name]: [] }));
    }
  };

  React.useEffect(() => {
    menuController.enable(false);
    const localData = localStorage.getItem('user_sign_up')
    if (!localData) {
      props.history.push("/welcome");
    } else {
      const data = JSON.parse(localData)
      setState(state => ({ ...state, ...data }))
    }
  }, []);

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      firstName: [],
      lastName: [],
      dob: [],
      gender: [],
      phone: [],
    };
    if (state.firstName.trim().length === 0) {
      isFormValid = false;
      testError.firstName = ["First Name cannot be empty!"];
    }
    if (state.lastName.trim().length === 0) {
      isFormValid = false;
      testError.lastName = ["Last Name cannot be empty!"];
    }
    if (state.phone.toString().trim().length === 0) {
      isFormValid = false;
      testError.phone = ["Phone Number cannot be empty!"];
    }
    if (state.phone.toString().trim().length < 10) {
      isFormValid = false;
      testError.phone = ["Invalid Phone Number!"];
    }

    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async (type) => {
    await dispatch(setWelcomeRegistrationData(state));
    if (type === "previous") {
      props.history.push("/welcome");
    } else {
      if (await validateForm()) {
        const response = await dispatch(
          registrationAction.checkEmailPhone(state.phone)
        );
        if (response.success) {
          if (state.email && state.email.length !== 0) {
            const response = await dispatch(registrationAction.sendVerificationCode(state.email, state.phone));
            if (response && response.success) {
              await dispatch(setWelcomeRegistrationData(state));
              localStorage.setItem('user_sign_up', JSON.stringify(state))
              props.history.push("/confirmation");
            }
          }
        } else {
          setTimeout(() => {
            dispatch({ type: "CLEAN_ERROR" });
          }, 5000);
        }
      }
    }
  };

  return (
    <div className="profile-page pagecard-height">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="3" size-lg="2">
              <CommonSidebar pageIndex={2} />
            </IonCol>
            <IonCol size="12" size-md="9" size-lg="10">
              <div className="theme-card">
                <div className="card-body">
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-lg="7">
                          <div className="parentform-heading">
                            <span>2 OF 3</span>
                            <h2>Let us get to know you!</h2>
                            <p>Tell us a bit about you.</p>
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={wallet} />
                                  </span>
                                  <p>Get up to 80% off your medications.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={medicine} />
                                  </span>
                                  <p>
                                    Thousands of meds, fullfilled by a licensed
                                    & accredited US-based pharmacy.
                                  </p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={time} />
                                  </span>
                                  <p>
                                    Order online and get FREE Delivery, and $5
                                    off your first order.{" "}
                                  </p>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                        </IonCol>

                        <IonCol size="12" size-lg="5">
                          <div className="parentform-right">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="12" size-md="6" size-lg="6">
                                  <div className="form-group">
                                    <IonLabel>First Name</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. John"
                                      value={state.firstName}
                                      onIonChange={(e) =>
                                        handleChange(e, "firstName")
                                      }
                                    />
                                    {errorState.firstName.length !== 0 &&
                                    errorState.firstName[0] && (
                                      <p className="errormsg">
                                        {errorState.firstName[0]}
                                      </p>
                                    )}
                                  </div>
                                </IonCol>

                                <IonCol size="12" size-md="6" size-lg="6">
                                  <div className="form-group">
                                    <IonLabel>Last Name</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. Smith"
                                      value={state.lastName}
                                      onIonChange={(e) =>
                                        handleChange(e, "lastName")
                                      }
                                    />
                                    {errorState.lastName.length !== 0 &&
                                    errorState.lastName[0] && (
                                      <p className="errormsg">
                                        {errorState.lastName[0]}
                                      </p>
                                    )}
                                  </div>
                                </IonCol>

                                <IonCol size="12">
                                  <div className="form-group">
                                    <IonLabel>Date of Birth (optional)</IonLabel>
                                    <div className="icon-input">
                                      <IonInput
                                        className="form-control dob-input"
                                        type="date"
                                        placeholder="mm/dd/yyyy"
                                        value={state.dob}
                                        onIonChange={(e) => handleChange(e, "dob")}
                                      >
                                        <a onClick={() => setShowCalendar(!showCalendar)} className="calendar-icon">
                                          <IonIcon icon={showCalendar ? closeOutline : calendarClearOutline} />
                                        </a>
                                      </IonInput>
                                    </div>
                                    {showCalendar && (
                                      <div className="commonCalendar" style={{ position: "absolute", zIndex: 99 }}>
                                        <Calendar
                                          value={state.dob ? new Date(state.dob) : new Date()}
                                          maxDate={new Date()}
                                          calendarType="US"
                                          onChange={(e) => {
                                            handleChange(
                                              {
                                                detail: {
                                                  value: convertTimeStampToDateYYYYMMDD(e),
                                                },
                                              },
                                              "dob"
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                    {errorState.dob.length !== 0 &&
                                    errorState.dob[0] && (
                                      <p className="errormsg">
                                        {errorState.dob[0]}
                                      </p>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <IonLabel>Gender (optional)</IonLabel>
                                    <div className="form-group gender-group">
                                      <IonRadioGroup
                                        value={state.gender}
                                        onIonChange={(e) =>
                                          handleChange(e, "gender")
                                        }
                                      >
                                        <div className="radio-item">
                                          <IonRadio className="gender-radio" value="male" />
                                          <IonLabel>Male</IonLabel>
                                        </div>
                                        <div className="radio-item">
                                          <IonRadio className="gender-radio" value="female" />
                                          <IonLabel>Female</IonLabel>
                                        </div>
                                        <div className="radio-item">
                                          <IonRadio className="gender-radio" value="non-binary" />
                                          <IonLabel>Non-binary</IonLabel>
                                        </div>
                                      </IonRadioGroup>
                                    </div>
                                    {errorState.gender.length !== 0 &&
                                    errorState.gender[0] && (
                                      <p className="errormsg">
                                        {errorState.gender[0]}
                                      </p>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <IonLabel>Phone Number</IonLabel>
                                    <IonInput
                                      className="form-control"
                                      type="text"
                                      placeholder="e.g. (123)-456-7890"
                                      value={maskPhoneNumber(
                                        state.phone.toString()
                                      )}
                                      maxlength="10"
                                      onIonChange={(e) =>
                                        handleChange(e, "phone")
                                      }
                                    />
                                    {errorState.phone.length !== 0 &&
                                    errorState.phone[0] && (
                                      <p className="errormsg">
                                        {errorState.phone[0]}
                                      </p>
                                    )}
                                  </div>
                                  {commonData.errorMsg &&
                                  commonData.errorMsg.length !== 0 && (
                                    <p className="errormsg">
                                      {commonData.errorMsg}
                                    </p>
                                  )}

                                  <div className="parentform-button">
                                    <IonButton
                                      className="theme-btn button-blue"
                                      onClick={() => onSubmit("continue")}
                                    >
                                      <span>Continue</span>
                                      {/*{commonData.loading && <IonSpinner />}*/}
                                    </IonButton>

                                    <IonButton
                                      className="theme-btn button-blue-outline"
                                      onClick={() => onSubmit("previous")}
                                    >
                                      <span>Previous</span>
                                    </IonButton>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Profile;
