import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonIcon,
  IonCheckbox,
  IonButton,
  IonSpinner,
  IonSlides,
  IonSlide,
  IonRange,
  IonModal,
  IonTextarea,
} from "@ionic/react";
import {
  searchOutline,
  chevronBackOutline,
  chevronForwardOutline,
  chevronUpOutline,
  chevronDownOutline,
  closeOutline,
} from "ionicons/icons";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import "./savingsCalculator.scss";
import { menuController } from "@ionic/core";
import logo from "../../images/logo.png";
import CalculatorIcon from "../../images/icons8-lgbt-flag-96.png";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../Utils/helper";
import { savingActions } from "../../Redux/actions";
//import { searchSavingCalculatorProduct, showSavings } from "../../Redux/actions/savingAction";

const SavingsCalculator = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const commonData = useSelector((state) => state.commonReducer);
  //modal
  const [calculatorModal, setCalculatorModal] = useState(false);
  const [resultsModal, setResultsModal] = useState(false);
  const [noteSentModal, setNoteSentModal] = useState(false);

  const medicineObject = { medicine: {}, copay: 0, searchText: "" };
  const [state, setState] = useState([{ ...medicineObject }]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [spinnerState, setSpinnerState] = useState("");
  const [searchResult, setSearchResult] = useState([[]]);
  const [timeOutState, setTimeOutState] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [savingDetails, setSavingDetails] = useState({ showResult: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [noteDetail, setNoteDetail] = useState({
    email: "",
    message: "",
  });

  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const positiveResult = useRef(null);
  const negativeResult = useRef(null);

  function goToResult() {
    if (positiveResult && positiveResult.current) {
      positiveResult.current.scrollIntoView(true);
    }
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult([[]]);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleChange = (event, name, index) => {
    const { value } = event.target;
    let newState = [...state];
    if (name === "searchText") {
      if (state[index].medicine.medicine) {
        return null;
      }
      newState[index][name] = value;
      setState(newState);
      let searchResultState = [...searchResult];
      searchResultState[index] = [];
      setSearchResult([...searchResultState]);
      if (isEmpty(value)) {
        return null;
      }
      if (timeOutState) {
        clearInterval(timeOutState);
      }

      let result = setTimeout(async () => {
        await searchProduct(value, index);
      }, 800);

      setTimeOutState(result);
    } else {
      newState[index][name] = value;
      setState(newState);
    }
  };

  const searchProduct = async (value, index) => {
    setSpinnerState(index);
    const response = await dispatch(
      savingActions.searchSavingCalculatorProduct(value)
    );
    if (response && response.data) {
      let searchResultState = [...searchResult];
      searchResultState[index] = response.data;
      setSearchResult([...searchResultState]);
    }
    setSpinnerState("");
  };

  const addMedicine = async () => {
    if (state.length === 5) {
      return null;
    }
    let newState = [...state];
    newState.push({ ...medicineObject });
    await setState(newState);

    await validateForm(newState);
  };

  const deleteItem = async (index, type) => {
    if (type === "card" && state.length === 1) {
      return;
    }
    let newState = [...state];
    if (type === "card") {
      newState.splice(index, 1);
    } else {
      newState[index] = { ...medicineObject };
      setDisableSubmit(true);
    }
    await setState(newState);
    await validateForm(newState);
  };

  const validateForm = (newState) => {
    let isInvalid = false;
    for (let item of newState) {
      if (
        !item.medicine ||
        !item.medicine.medicine ||
        item.medicine.medicine.length === 0
      ) {
        isInvalid = true;
        break;
      }
    }
    setDisableSubmit(isInvalid);
  };

  const onProductSelected = async (medicine, index) => {
    let newState = [...state];
    newState[index] = {
      ...{ medicine, copay: medicine.average_copay, searchText: "" },
    };
    setState(newState);
    await validateForm(newState);
  };

  const handleSuggestedMedicineClick = async (value, index) => {
    setSpinnerState(index);
    const response = await dispatch(
      savingActions.searchSavingCalculatorProduct(value)
    );

    if (response.data && response.data.length) {
      onProductSelected(response.data[0], index);
    }
    setSpinnerState("");
  };

  const handleShowMySavings = async () => {
    let error = "";
    let isFormValid = true;
    if (userEmail.trim().length === 0) {
      isFormValid = false;
      error = "Please enter your email address to see savings";
    }
    if (
      userEmail.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        userEmail
      ) === false
    ) {
      isFormValid = false;
      error = "Please enter a valid email address";
    }

    if (isFormValid) {
      let postData = {
        email: userEmail,
        data: state,
      };
      const response = await dispatch(savingActions.showSavings(postData));
      setSavingDetails(response.data);
      setResultsModal(false);
      goToResult();
    } else {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const handleSendNote = async () => {
    let error = "";
    let isFormValid = true;
    if (noteDetail.email.trim().length === 0) {
      isFormValid = false;
      error = "Please enter your email address";
    }

    if (noteDetail.message.trim().length === 0) {
      isFormValid = false;
      error = "Please enter your message";
    }

    if (
      noteDetail.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        noteDetail.email
      ) === false
    ) {
      isFormValid = false;
      error = "Please enter a valid email address";
    }

    if (isFormValid) {
      const response = await dispatch(savingActions.sendUsNote(noteDetail));
      setNoteSentModal(true);
      setCalculatorModal(false);
    } else {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <div className="savings-calculator-page">
      <div className="savings-header">
        <div className="space-container">
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <div className="logo">
                  <a className="header-logo" href="/" target="_blank">
                    <IonImg className="logo-img" src={logo} />
                    <IonImg className="calculator-icon" src={CalculatorIcon} />
                  </a>
                </div>
              </IonCol>
              {/*<IonCol size="6">
                <div className="header-btn">
                  <IonButton type="button" className="theme-btn button-blue">
                    Share
                  </IonButton>
                </div>
              </IonCol>*/}
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <div className="savings-calculator-inner">
        <div className="space-container">
          <div className="savings-calculator-header">
            <h1>Prescription Savings Calculator</h1>
            <p>
              Add up to 5 meds, select your current copay/out of pocket cost,
              and hit submit to see your savings!
            </p><br/>
            <p className="notification-text">
              If you don't see your medication listed below, please text Price &#60;Medication Name&#62; to 860-792-4325 for a quick price check
            </p>
          </div>

          <div className="calculator-cards">
            {/* item */}
            {state.map((med, index) => (
              <div className="theme-card" key={index}>
                <div className="card-body">
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" size-md="6">
                        <div className="calculator-search">
                          <div className="form-group search-drop">
                            <IonLabel>Medication #{index + 1}</IonLabel>

                            {Object.keys(med.medicine).length !== 0 ? (
                              <div className="icon-input">
                                <IonInput
                                  className="form-control"
                                  type="text"
                                  placeholder="Search by drug name (E.g. Lipitor)"
                                  disabled
                                  value={med.medicine?.medicine}
                                />
                                <a
                                  onClick={() => deleteItem(index, "medicine")}
                                >
                                  <IonIcon icon={closeOutline} />
                                </a>
                              </div>
                            ) : (
                              <>
                                <div className="icon-input">
                                  <IonInput
                                    className="form-control"
                                    type="text"
                                    placeholder="Search by drug name (E.g. Lipitor)"
                                    value={med.searchText}
                                    onIonInput={(e) =>
                                      handleChange(e, "searchText", index)
                                    }
                                  />
                                  <a>
                                    {commonData.loading &&
                                      spinnerState == index ? (
                                      <IonSpinner />
                                    ) : (
                                      <IonIcon icon={searchOutline} />
                                    )}
                                  </a>
                                </div>
                                {!isEmpty(searchResult[index]) && (
                                  <div
                                    className="search-drop-list"
                                    ref={wrapperRef}
                                  >
                                    {searchResult[index].map(
                                      (product, pIndex) => (
                                        <a
                                          key={index}
                                          onClick={() =>
                                            onProductSelected(product, index)
                                          }
                                        >
                                          {product.medicine}
                                        </a>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            )}

                            <div className="calculator-search-inputpera">
                              <p>
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Atorvastatin",
                                      index
                                    )
                                  }
                                >
                                  Atorvastatin
                                </a>
                                , &nbsp;
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Metformin",
                                      index
                                    )
                                  }
                                >
                                  Metformin
                                </a>
                                , &nbsp;
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Levothyroxine",
                                      index
                                    )
                                  }
                                >
                                  Levothyroxine
                                </a>
                                , &nbsp;
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Omeprazole",
                                      index
                                    )
                                  }
                                >
                                  Omeprazole
                                </a>
                                , &nbsp;
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Sildenafil",
                                      index
                                    )
                                  }
                                >
                                  Sildenafil
                                </a>
                                , &nbsp;
                                <a
                                  onClick={() =>
                                    handleSuggestedMedicineClick(
                                      "Finasteride",
                                      index
                                    )
                                  }
                                >
                                  Finasteride
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="calculator-cost">
                          <div className="form-group">
                            <IonLabel>
                              Current Copay / Out of Pocket Cost
                            </IonLabel>
                            <p className="form-brif">
                              Average copay pre-selected. Please change as
                              needed.
                            </p>
                            <div className="calculator-cost-box">
                              <IonRange
                                min={0}
                                max={50}
                                step={2}
                                ticks={true}
                                pin={true}
                                onIonChange={(e) =>
                                  handleChange(e, "copay", index)
                                }
                                value={med.copay}
                              />
                              <div className="range-points">
                                <span>0</span>
                                <span>10</span>
                                <span>20</span>
                                <span>30</span>
                                <span>40</span>
                                <span>50</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                {state.length !== 1 && (
                  <div className="card-closer">
                    <a onClick={() => deleteItem(index, "card")}>
                      <IonIcon icon={closeOutline} />
                    </a>
                  </div>
                )}
              </div>
            ))}

            {state.length < 5 && (
              <div className="medication-add">
                <a href="javascript:;" onClick={() => addMedicine()}>
                  + Add a Medication
                </a>
              </div>
            )}

            <div className="show-savings">
              <IonButton
                class="theme-btn button-blue"
                onClick={() =>
                  userEmail !== ""
                    ? handleShowMySavings()
                    : setResultsModal(true)
                }
                disabled={disableSubmit}
              >
                <span>Show my savings</span>
              </IonButton>
              <p>
                Don’t see your med? Need pricing for a specific med?{" "}
                <a href="javascript:;" onClick={() => setCalculatorModal(true)}>
                  Send us a note
                </a>
              </p>
            </div>
          </div>

          {/* results-box */}
          {savingDetails.showResult ? (
            savingDetails.totalCost - savingDetails.totalQwarkPrice > 0 ? (
              <>
                <div className="results-box" ref={positiveResult}>
                  <div className="heading">
                    <h3>Results</h3>
                  </div>
                  <div className="results-mid">
                    <p>
                      Yay! Looks like Qwark can save you a total of $
                      {(
                        savingDetails.totalCost - savingDetails.totalQwarkPrice
                      ).toFixed(1)}{" "}
                      for these {savingDetails.totalMedicines} drugs.
                      <b>
                        That’s $
                        {(
                          (savingDetails.totalCost -
                            savingDetails.totalQwarkPrice) *
                          12
                        ).toFixed(1)}{" "}
                        a year!
                      </b>
                    </p>
                    <div className="results-cost">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12" size-md="6">
                            <div className="results-cost-point">
                              <p>
                                Your current cost (total): $
                                {savingDetails.totalCost.toFixed(1)}
                              </p>
                            </div>
                          </IonCol>
                          <IonCol size="12" size-md="6">
                            <div className="results-cost-point bg-light-green">
                              <p>
                                Qwark Price (total): $
                                {savingDetails.totalQwarkPrice}
                              </p>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                    <p>
                      Wait, that’s not all. With Qwark, you also get free home
                      delivery, refill support, and exceptional customer service
                      - always with a smile.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="results-box" ref={negativeResult}>
                  <div className="heading">
                    <h3>Results</h3>
                  </div>
                  <div className="results-mid">
                    <p>
                      Nice work. Looks like you have a great insurance that
                      covers most drugs fully!
                    </p>
                    <p>
                      But you can still switch to Qwark for free home delivery
                      and exceptional customer service - always with a smile.
                    </p>
                    <p>
                      Finally, you will help the environment by reducing car
                      trips to the pharmacy. And we appreciate that!
                    </p>
                  </div>
                </div>
              </>
            )
          ) : null}
        </div>
      </div>

      {/* modal */}
      {/* calculator start */}
      <IonModal
        isOpen={calculatorModal}
        cssClass="theme-modal calculator-modal"
      >
        <div className="modal-body">
          <div className="modal-closer">
            <a onClick={() => setCalculatorModal(false)}>
              <IonIcon icon={closeOutline}></IonIcon>
            </a>
          </div>

          <div className="form-group">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="3">
                  <IonLabel>Your email:</IonLabel>
                </IonCol>
                <IonCol size="12" size-md="9">
                  <IonInput
                    type="text"
                    className="form-control"
                    placeholder="John@gmail.com"
                    onIonInput={(e) =>
                      setNoteDetail((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  ></IonInput>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="form-group">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="3">
                  <IonLabel>Message:</IonLabel>
                </IonCol>
                <IonCol size="12" size-md="9">
                  <IonTextarea
                    className="form-control"
                    placeholder="Please leave your message here."
                    onIonInput={(e) =>
                      setNoteDetail((prevState) => ({
                        ...prevState,
                        message: e.target.value,
                      }))
                    }
                  ></IonTextarea>
                </IonCol>
              </IonRow>
            </IonGrid>
            {!isEmpty(errorMessage) && (
              <p className="errormsg">{errorMessage}</p>
            )}
          </div>
          <div className="calculator-modal-btns">
            <IonButton
              className="theme-btn button-grey"
              onClick={() => setCalculatorModal(false)}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-blue"
              onClick={() => handleSendNote()}
            >
              <span>Submit</span>
            </IonButton>
          </div>
        </div>
      </IonModal>

      {/* results start */}
      <IonModal isOpen={resultsModal} cssClass="theme-modal results-modal">
        <div className="modal-body">
          <div className="modal-closer">
            <a onClick={() => {
              setUserEmail("");
              setResultsModal(false);
            }}>
              <IonIcon icon={closeOutline}></IonIcon>
            </a>
          </div>
          <div className="results-modal-content">
            <h3>Please enter your email to see your results!</h3>
          </div>
          <div className="form-group">
            <IonInput
              type="email"
              className="form-control"
              placeholder="John@gmail.com"
              onIonInput={(e) => setUserEmail(e.target.value)}
            ></IonInput>
            {!isEmpty(errorMessage) && (
              <p className="errormsg">{errorMessage}</p>
            )}
          </div>

          <div className="calculator-modal-btns">
            <IonButton
              className="theme-btn button-grey"
              onClick={() => {
                setUserEmail("");
                setResultsModal(false);
              }}
            >
              <span>Cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-blue"
              onClick={() => handleShowMySavings()}
            >
              <span>Show my savings</span>
            </IonButton>
          </div>
        </div>
          <script dangerouslySetInnerHTML={{__html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '188044799920842');
          fbq('track', 'PageView');`}} />
          <noscript dangerouslySetInnerHTML={{__html: `<img height="1" width="1" src="https://www.facebook.com/tr?id=188044799920842&ev=PageView&noscript=1"/>`}} />
      </IonModal>

      {/* results start */}
      <IonModal isOpen={noteSentModal} cssClass="theme-modal results-modal">
        <div className="modal-body">
          <div className="modal-closer">
            <a onClick={() => setNoteSentModal(false)}>
              <IonIcon icon={closeOutline}></IonIcon>
            </a>
          </div>
          <div className="results-modal-content">
            <p>
              Your message has been submitted. We will email you a quote soon
            </p>
          </div>
          <div className="calculator-modal-btns">
            <IonButton
              className="theme-btn button-blue okay-btn"
              onClick={() => setNoteSentModal(false)}
            >
              <span>Ok</span>
            </IonButton>
          </div>
        </div>
      </IonModal>
    </div>
  );
};

export default SavingsCalculator;
