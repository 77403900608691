import {
  IonButtons,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonInput,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./footer.scss";
import LogoImg from "../../images/logo.png";
import GraphicsImg from "../../images/graphics-img.png";
import footerImg1 from "../../images/footer-1.png";
import footerImg2 from "../../images/footer-2.png";
import certificate from "../../images/certificate-label.png";
import footerImg from "../../images/footer-image.png";
import CCLogosImg from "../../images/credit_card_logos_horizontal_color_orig.png";
import { useHistory, useLocation } from "react-router";

const Footer = (props) => {
  const [showFooter, setShowFooter] = useState(true);
  const authData = useSelector((state) => state.authReducer.authData);
  const location = useLocation();
  const year = new Date().getFullYear();
  const history = useHistory();
  const publicRouteList = [
    "/",
    "/about",
    "/landing",
    "/resetPassword",
    "/shoppingCart",
    "/meds-2-99",
    "/page/privacy_policy",
    "/page/terms_of_service",
    "/blog",
  ];

  React.useEffect(() => {
    setShowFooter(
      (authData && Object.keys(authData).length !== 0) ||
        publicRouteList.includes(location.pathname) ||
        location.pathname.includes("/medicine_detail")
    );
  }, [authData, location]);

  return (
    // showFooter ? (
    // !localStorage.getItem("sessionPassword") || location.pathname === "/savingscalculator" ? null : (
    location.pathname === "/savingscalculator" ? null : (
      <IonFooter>
        <div className="footer">
          <div className="space-container">
            <IonGrid>
              <IonRow className="align-center">
                <IonCol size="12" size-md="12" size-lg="12">
                  <div className="footer-logo">
                    <IonImg src={LogoImg} />
                  </div>
                </IonCol>
                <IonCol size="12" size-md="8" size-lg="9">
                  <div className="footer-detail">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-md="6" size-lg="6">
                          <div className="footer-detail-inner">
                            <div className="footer-detail-item">
                              <h5>Questions?</h5>
                              <p>Call us at (844) 931-2345 </p>
                              <p>Email us at Support@Qwarkhealth.com</p>
                              <div className="footer-cclogos-img">
                              <IonImg src={CCLogosImg}/>
                            </div>
                            </div>
                          </div>
                        </IonCol>
                        <IonCol size="12" size-md="6" size-lg="6">
                          <div className="footer-detail-inner">
                            <div className="footer-detail-item">
                              <h5>Support Hours:</h5>
                              <p>
                                M-F: 8AM to 6PM PST<br></br>
                                Sa: 9AM to 12PM PST<br></br>
                                Su: Closed
                              </p>
                            </div>
                            <div className="footer-detail-item">
                              <h5>Location:</h5>
                              <p>Sunnyvale, CA</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="4" size-lg="3">
                  <div className="graphics-img">
                    <a href="http://legitscript.com/pharmacy/qwarkhealth.com" target="_blank" title="Verify LegitScript Approval">
                      <IonImg src="//static.legitscript.com/seals/9462959.png" alt="LegitScript approved" width="140" height="100" border="0"/>
                    </a>
                    <a
                      href="https://www.bbb.org/us/ca/sunnyvale/profile/online-pharmacy/qwark-inc-1216-1298231#bbbseal"
                      target="_blank"
                    >
                      <IonImg src={footerImg2} />
                    </a>
                    <a href="https://onetreeplanted.org/" target="_blank">
                      <IonImg src={certificate} />
                    </a>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="12" size-lg="12">
                  <div className="footer-nav">
                    {/*<ul>                      
                      <li><Link onClick={this.scrollToTop} to={"/page/privacy-policy"}>Privacy Policy</Link></li>
                      <li><Link onClick={this.scrollToTop} to={"/page/terms_of_service"}>Terms of Service</Link></li>
                  </ul> */}
                    <a
                      target="_blank"
                      href={"https://support.qwarkhealth.com/hc/en-us"}
                    >
                      FAQ
                    </a>

                    <a onClick={() => history.push("/page/privacy_policy")}>
                      Privacy Policy
                    </a>
                    <a onClick={() => history.push("/page/terms_of_service")}>
                      Terms of Service
                    </a>
                  </div>
                  <div className="footer-bottom">
                    <p>© {year} Qwark All Rights Reserved</p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonFooter>
    )
    // ): null
  );
};

export default Footer;
