import _ from "lodash";
import Api from "../Utils/Api";

export const convertTimeStampToDate = (timeStamp) => {
  const jsDate = new Date(timeStamp);
  return (
    jsDate.getMonth() + 1 + "/" + jsDate.getDate() + "/" + jsDate.getFullYear()
  );
};

export const isUserLoggedIn = () => {
  const authData = localStorage.getItem("userData");
  return !!(authData && Object.keys(authData).length);
};

export const convertTimeStampToDateYYYYMMDD = (timeStamp) => {
  const jsDate = getTimeStamp(timeStamp);
  if (!jsDate) return "";

  const year = jsDate.getFullYear();
  let month = jsDate.getMonth() + 1;
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  let date = jsDate.getDate();
  if (date.toString().length === 1) {
    date = "0" + date;
  }
  return year + "-" + month + "-" + date;
};

export const getTimeStamp = (timestamp) => {
  if (isNaN(timestamp) === false) {
    return new Date(timestamp);
  }
  return null;
};

export const maskPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");

export const convertArrayToCommaSeparatedString = (array) => {
  let commaSeparatedString = "";
  if (array.length !== 0) {
    for (let item of array) {
      if (item && item.length !== 0) {
        commaSeparatedString = commaSeparatedString + item + ", ";
      }
    }
    commaSeparatedString = commaSeparatedString.substring(
      0,
      commaSeparatedString.length - 2
    );
  }
  return commaSeparatedString;
};

export const handlePrescriberSearchResponse = async (result) => {
  let managedResult = [];
  if (!result || result.result_count === 0) {
    return managedResult;
  }
  for (let prescriber of result.results) {
    let prescriberData = {};
    if (prescriber.addresses && prescriber.addresses.length !== 0) {
      prescriberData.phone = prescriber?.addresses[0]?.telephone_number
        ? prescriber.addresses[0].telephone_number
            .replace("-", "")
            .replace("-", "")
        : "";
      prescriberData.fax = prescriber?.addresses[0]?.fax_number
        ? prescriber.addresses[0].fax_number.replace("-", "").replace("-", "")
        : "";
      // prescriberData.fax.replace('-','')
    }
    if (prescriber.basic && Object.keys(prescriber.basic).length !== 0) {
      prescriberData.name = prescriber.basic.name;
    }
    managedResult.push(prescriberData);
  }

  return managedResult;
};

export const isEmpty = (value) => {
  let isEmpty = false;
  if (!value) {
    isEmpty = true;
  } else if (typeof value == "undefined") {
    isEmpty = true;
  } else if (value.toString().trim().length === 0) {
    isEmpty = true;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    isEmpty = true;
  }
  return isEmpty;
};

// check if same item is already present in the cart
export const checkIfItemAlreadyAdded = (product, cartItems) => {
  const localCart =
    localStorage.getItem("cart") && localStorage.getItem("cart") !== "null"
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
  const merged = _.merge(
    _.keyBy(localCart, "medicineId"),
    _.keyBy(cartItems, "medicineId")
  );
  const newCartItems = _.values(merged);
  const allCartItems = _.uniqBy(newCartItems, "medicineId"); //removed if had duplicate id
  let isCodeApplied = false;

  const productExistsIndex = allCartItems.findIndex(
    (i) => i.medicineId === product.medicineId
  );
  if (
    !isEmpty(allCartItems) &&
    !isEmpty(allCartItems[0]?.promoCode) &&
    allCartItems[0]?.promoCode !== "null"
  ) {
    isCodeApplied = true;
  }
  if (productExistsIndex !== -1) {
    allCartItems[productExistsIndex] = {
      ...allCartItems[productExistsIndex],
      ...product,
    };
  } else {
    allCartItems.push({
      ...product,
      promoCode: isCodeApplied ? allCartItems[0]?.promoCode : null,
      discountPercent: isCodeApplied ? allCartItems[0]?.discountPercent : 0,
      medicineQuantity: parseInt(product.activeQuantity),
      orderTime: new Date(),
      activeQuantity: product.activeQuantity,
      activeQuantityIndex: product.activeQuantityIndex,
      price: product.price,
    });
  }

  return allCartItems;
};

// converts timestamp to date format e.g., 30Oct
export const convertTimeStampToCartDateFormat = (timeStamp) => {
  const jsDate = new Date(timeStamp);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(jsDate);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(jsDate);

  return da + mo;
};

export const handlePharmacySearchResponse = async (result) => {
  let managedResult = [];
  if (!result || result.result_count === 0) {
    return managedResult;
  }
  for (let prescriber of result.results) {
    let prescriberData = {};
    if (prescriber.addresses && prescriber.addresses.length !== 0) {
      prescriberData.phone = prescriber?.addresses[0]?.telephone_number
        ? prescriber.addresses[0].telephone_number
            .replace("-", "")
            .replace("-", "")
        : "";
      prescriberData.fax = prescriber?.addresses[0]?.fax_number
        ? prescriber.addresses[0].fax_number.replace("-", "").replace("-", "")
        : "";
      prescriberData.address = prescriber?.addresses[0]?.address_1
        ? convertArrayToCommaSeparatedString([
            prescriber?.addresses[0]?.address_1,
            prescriber?.addresses[0]?.address_2,
            prescriber?.addresses[0]?.city,
            prescriber?.addresses[0]?.state,
            prescriber?.addresses[0]?.postal_code,
          ])
        : "";
      // prescriberData.fax.replace('-','')
    }
    if (prescriber.basic && Object.keys(prescriber.basic).length !== 0) {
      prescriberData.name = prescriber.basic.organization_name
        ? prescriber.basic.organization_name
        : prescriber.basic.name;
    }
    prescriberData.npi = prescriber.number;
    managedResult.push(prescriberData);
  }
  return managedResult;
};

export const convertTimestampToMMDDYYYY = (date) => {
  const myDate = new Date(date);
  const year = myDate.getFullYear();
  let month = myDate.getMonth() + 1;
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  let date1 = myDate.getDate();
  if (date1.toString().length === 1) {
    date1 = "0" + date1;
  }
  return month + "/" + date1 + "/" + year;
};

const months = {
  1: "Jan",
  2: "Feb",
  3: "March",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "July",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
export const parseCardExpireDate = (expDate) => {
  const dateArray = expDate.split("/");
  return months[+dateArray[0]] + " " + 20 + dateArray[1];
};

export const getCartLocal = () => {
  const carts = localStorage.getItem("cart");
  let result;
  try {
    result = JSON.parse(carts);
    if (result === "null" || isEmpty(result)) {
      result = [];
    }
  } catch (e) {
    result = [];
  }

  return result;
};

export const showPriceOrInsurancePrice = (item) => {
  return item.insurance_price_selected && item.display_for_user
    ? parseFloat(item.insurance_price || "0").toFixed(2)
    : parseFloat(item.price).toFixed(2);
};

export const getSubtotalPrice = (items) => {
  let subtotal = 0;
  for (let {
    price,
    insurance_price_selected,
    insurance_price,
    display_for_user,
  } of items) {
    const newPrice =
      insurance_price_selected && display_for_user ? insurance_price : price;
    subtotal = subtotal + parseFloat(newPrice);
  }
  return subtotal;
};

export const getPrescriptionData = async ({ user_id, limit, setPrescriptionData }) => {
  if (user_id) {
    try {
      const res = await Api.get(`/api/v1/product/get_prescription_summary/`, {
        params: {
          offset: 0,
          limit: limit,
          user_id: user_id,
        },
      });
      if (res?.data) {
        return setPrescriptionData(res.data.data);
      }
    } catch (e) {
      console.log("error while fetching prescription data", e);
    }
  } else {
    console.log("No UserId");
  }
};
