import Api from "../../Utils/Api";
import { checkIfItemAlreadyAdded, isEmpty } from "../../Utils/helper";
import _ from "lodash";

export const searchProduct = (productName) => async (dispatch) => {
  try {
    // await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/product/search`, { productName: productName });
    // await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getProductDetail = (productSlug) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/product/${productSlug}`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const deleteFromCart = (product, user_id) => async () => {
  try {
    const res = await Api.post(`/api/v1/product/deleteFromCart`, { medicineName: product.medicineName, id: product.id, user_id });
    return res.data;
  } catch (e) {
  }
};

export const updateCartById = (data) => async () => {
  try {
    const res = await Api.post(`/api/v1/product/update-user-cart`, data);
    return res.data;
  } catch (e) {
  }
};

export const getCartItems = (user_id) => async (dispatch) => {
  if (user_id) {
    try {
      await dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/api/v1/product/getCartItems`, { user_id });
      if (res?.data) {
        await dispatch({ type: "CART_ITEM_COUNT_CHANGE", payload: res.data.data });
      }
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (e) {
      await dispatch({ type: "LOADING_FAILURE" });
    }
  } else {
    await dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
  }
};

export const addToCart = (product, userId) => async (dispatch) => {
  const userItems = await Api.post("api/v1/product/getCartItems", { user_id: userId });

  const updatedCart = checkIfItemAlreadyAdded(product, userItems?.data?.data);

  localStorage.setItem("cart", JSON.stringify(updatedCart));
  if (userId) {
    await Api.post("/api/v1/product/addItemIntoCart", {
      updatedCart,
      userId
    });
  }

  await dispatch({ type: "CART_ITEM_COUNT_CHANGE" });
};

export const saveSessionCartIntoDB = (userId) => async () => {
  const userItems = await Api.post("api/v1/product/getCartItems", { user_id: userId });
  let cartItemsSession = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
  if (cartItemsSession == null) {
    cartItemsSession = [];
  }
  const cartItemsDb = userItems?.data?.data ?? []
  const merged = _.merge(_.keyBy(cartItemsDb, 'medicineName'), _.keyBy(cartItemsSession, 'medicineName'));
  const newCartItems = _.values(merged);
  const updatedCart = _.uniqBy(newCartItems,'medicineName'); //removed if had duplicate id

  if (userId) {
    await Api.post("/api/v1/product/addItemIntoCart", {
      updatedCart,
      userId
    });
  }
};

export const getAccountSummary = (userId, type, offset = 0) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/${userId}/account_summary/${type}?offset=${offset}`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getItemsByOrderId = (userId, orderId) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/${userId}/order/${orderId}`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};
export const cancelOrder = (userId, orderId) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/${userId}/cancel_order/${orderId}`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};
export const getFormAndStrengthDetailsById = (productId) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/${productId}/form_strength`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const insertPriceMatch = (priceMatchState) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post("/api/v1/product/priceMatch", priceMatchState);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const resendCode = (email, name) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/product/resendCode`, { email: email, drug: name });
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const shopByCondition = () => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/list/shop_by_condition`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};
export const getSuggestion = (userId) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/${userId}/suggestions`);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};

export const updateDeliveryCost = () => async (dispatch) => {
  const cartItems = localStorage.getItem("cart").length !== 0 ? JSON.parse(localStorage.getItem("cart")) : [];
  const deliveryOption = (localStorage.getItem("delivery") !== null) ? JSON.parse(localStorage.getItem("delivery")) : {};
  if (!isEmpty(cartItems) && !isEmpty(deliveryOption)) {
    if (deliveryOption.id === 1) {
      deliveryOption.cost = 0;
    } else if (cartItems.length >= 2) {
      deliveryOption.cost = 0.99;
    } else {
      if (deliveryOption.id === 2) {
        deliveryOption.cost = 3.99;
      } else {
        deliveryOption.cost = 5.99;
      }
    }
    localStorage.setItem("delivery", JSON.stringify(deliveryOption));
  }
  await dispatch({ type: "DELIVERY_UPDATE" });
};

export const requestInsurancePrice = (data) => async (dispatch) => {
  try {
    await dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post("/api/v1/insurance-price/add", data);
    await dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (e) {
    await dispatch({ type: "LOADING_FAILURE" });
  }
};
