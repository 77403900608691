import {
  IonButtons,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonSpinner,
} from '@ionic/react';
import { cartOutline, chevronDownOutline, personOutline } from 'ionicons/icons';
import React, { useEffect, useState, useRef } from 'react';
import './header.scss';
import LogoImg from '../../images/logo.png';
import { useHistory, useLocation } from 'react-router';
import { isEmpty, isUserLoggedIn } from '../../Utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, productAction } from '../../Redux/actions';
import { getFaqList } from '../../Redux/actions/landingFaqAction';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isUserLogged, setIsUserLogged] = useState(isUserLoggedIn());
  const [searchText, setSearchText] = useState('');
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [spinnerState, setSpinnerState] = useState('');
  const [topThreeFAQ, setTopThreeFAQ] = useState('');
  const [helpShow, setHelpShow] = useState(false);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [timeOutState, setTimeOutState] = useState(null);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const wrapperHelp = useRef(null);
  useOutsideAlerterOfHelp(wrapperHelp);

  useEffect(() => {
    dispatch({ type: 'CLEAN_ERROR' });
    setIsUserLogged(isUserLoggedIn());
    dispatch(authActions.matchtoken());
    loadData();
  }, [location.pathname]);

  useEffect(() => {
    if (searchEmpty) {
      setSearchEmpty(true)
    } else {
      setSearchEmpty(false)
    }
  }, [searchEmpty])

  const loadData = async () => {
    const responseData = await dispatch(getFaqList(0));
    if (responseData.data?.data.length) {
      setTopThreeFAQ([...responseData.data?.data.splice(0, 3)]);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult([]);
          setSearchEmpty(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideAlerterOfHelp(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setHelpShow(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const logout = async () => {
    await dispatch(authActions.logout());
    history.push('/login');
  };

  const handleSearchChange = async (event) => {
    const { value } = event.target;
    setSearchResult([]);
    setSearchText(value);
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result = setTimeout(async () => {
      setSpinnerState('searchProduct');
      await searchProduct(value);
    }, 800);

    setTimeOutState(result);
  };

  const searchProduct = (value) => {
    if (isEmpty(value) || isEmpty(searchText)) {
      setSearchResult([]);
      setSpinnerState('');
      return null;
    }
    dispatch(productAction.searchProduct(value)).then((response) => {
      if (response.success) {
        setSearchResult(response.data);
        setSearchEmpty(false)
      } else {
        setSearchResult([]);
        setSearchEmpty(true)
      }
      setSpinnerState('');
    });
  };

  const onProductSelected = async (product) => {
    if (isEmpty(product.id)) {
      return null;
    }
    setSearchText('');
    setSearchResult([]);
    history.push(`/medicine_detail/${product.slug}`);
  };

  return (location.pathname === '/savingscalculator') ? null : (
    <IonHeader className="header">
      <div className="space-container" id="space-container">
        <IonButtons slot="start">
          <IonMenuButton/>
        </IonButtons>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-sm="12" size-md="4" size-lg="2">
              <a className="header-logo" onClick={() => history.push('/')}>
                <IonImg src={LogoImg}/>
              </a>
            </IonCol>
            <IonCol size="12" size-sm="12" size-md="8" size-lg="5">
              <div className="header-left">
                {/* 2.99 meds btn start */}
                <div className="meds-btn">
                  <IonButton
                    className="theme-btn button-grey"
                    onClick={() => history.push('/meds-2-99')}
                  >
                    <span>$2.99 Meds</span>
                  </IonButton>
                </div>
                {/* 2.99 meds btn end */}

                {/* help start */}
                <div className="header-help" ref={wrapperHelp}>
                  <div className="helpdrop">
                    <a
                      onClick={() => {
                        let temp = helpShow;
                        setHelpShow(!temp);
                      }}
                      className="helpdrop-top"
                    >
                      Help
                    </a>
                    {helpShow ? (
                      <div className="helpdrop-inner">
                        {/* questions-help */}
                        <div className="questions-help">
                          <div className="questions-help-heading">
                            <h3>Questions? Please call, text, or email.</h3>
                          </div>
                          <div className="questions-help-mid">
                            <a
                              className="theme-btn button-blue-outline"
                              href="tel:+844-931-2345"
                            >
                              Call/Text (844) 931-2345
                            </a>
                            <a
                              className="theme-btn button-blue-outline"
                              href="mailto:support@qwarkhealth.com"
                            >
                              Email support@qwarkhealth.com
                            </a>
                          </div>
                        </div>

                        {/* faq-help */}
                        <div className="faq-help">
                          <div className="faq-help-heading">
                            <h3>FAQs</h3>
                          </div>
                          <div className="faq-help-mid">
                            {
                              topThreeFAQ.map((faq, faqIndex) => (
                                <a
                                  key={faqIndex}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/landing',
                                      state: { scrollToFaq: true },
                                    });
                                    setHelpShow(false);
                                  }}
                                >
                                  {' '}
                                  {faq.name}
                                </a>
                              ))
                            }
                          </div>
                          <div className="faq-help-bottom">
                            <a
                              target="_blank"
                              className="theme-btn button-blue-outline"
                              href={'https://support.qwarkhealth.com/hc/en-us'}
                            >
                              See more Q&As
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* help end */}
                {/* header-searchbar start */}
                <div className="header-searchbar" ref={wrapperRef}>
                  <IonSearchbar
                    placeholder="Search medication"
                    onIonInput={(e) => handleSearchChange(e)}
                    value={searchText}
                  >
                    {spinnerState === 'searchProduct' && <IonSpinner/>}
                  </IonSearchbar>
                  {searchResult.length ? (
                    <div className="search-drop-list">
                      {searchResult.map((product, index) => (
                        <a
                          key={index}
                          onClick={() => onProductSelected(product)}
                        >
                          {product.title}
                        </a>
                      ))}
                    </div>
                  ): searchEmpty &&(
                    <div className="search-drop-list">
                      <p>
                        Sorry we can't find this drug. But please text us at
                        <a href="tel:844-931-2345" className="phone-or-email"> 844-931-2345 </a>
                        or <a href="https://support.qwarkhealth.com/hc/en-us/requests/new" className="phone-or-email"> email </a>
                        and we would be happy to help
                      </p>
                    </div>
                  )}
                </div>
                {/* header-searchbar end */}
              </div>
            </IonCol>
            <IonCol size="12" size-sm="12" size-md="12" size-lg="5">
              <div className="header-right">
                <div className="header-support">
                  <div className="supportdrop">
                    <a className="supportdrop-top">
                      More <IonIcon icon={chevronDownOutline}/>
                    </a>

                    <div className="supportdrop-inner">
                      <a onClick={() => history.push('/about')}>About Us</a>
                      <a onClick={() => history.push('/blog')}>Blog</a>
                      <a onClick={() => history.push('/covid-vaccine')}>Vaccine appointment</a>
                      {/* {isUserLogged && (
                        <a href="javascript:;" onClick={() => logout()}>
                          Logout
                        </a>
                      )} */}
                    </div>
                  </div>
                </div>
                {isUserLogged ? (
                  <div className="header-count-link">
                    {/* test start */}
                    <div className="header-coman-droupdown">
                      <div className="header-comandrop">
                        <a className="header-comandrop-top">
                          <IonIcon icon={personOutline}/>
                          Account
                        </a>
                        <div className="header-comandrop-inner">
                          <a onClick={() => history.push('/accountSummary')}>
                            Account Summary
                          </a>
                          <a onClick={() => history.push('/medication')}>
                            Medication
                          </a>
                          <a onClick={() => history.push('/profileAndSetting')}>
                            Profile and Setting
                          </a>
                          <a onClick={() => history.push('/healthNetwork')}>
                            Health Network
                          </a>
                          <IonButton
                            expand="block"
                            class="theme-btn button-blue-outline"
                            onClick={() => logout()}
                          >
                            <span>Sign out</span>
                          </IonButton>
                        </div>
                      </div>
                    </div>
                    {/* test end */}

                    {/* <a
                      href="javascript:;"
                      onClick={() => history.push("/profileAndSetting")}
                    >
                      <div className="icon-count">
                        <IonIcon icon={personOutline} />
                        <span>10</span>
                      </div>
                      Account{" "}
                    </a> */}
                    <a
                      className="cartdrop-link"
                      onClick={() => history.push('/shoppingCart')}
                    >
                      <div className="icon-count">
                        <IonIcon icon={cartOutline}/>
                        {!isEmpty(cartItems) && <span>{cartItems.length}</span>}
                      </div>
                      Cart{' '}
                    </a>
                  </div>
                ) : (
                  <div className="header-right-btns">
                    <div className="header-buttons">
                      <IonButton
                        className="theme-btn button-grey"
                        onClick={() => history.push('/login')}
                      >
                        <span>Sign in</span>
                      </IonButton>
                      <IonButton
                        className="theme-btn button-blue"
                        onClick={() => history.push('/welcome')}
                      >
                        <span>Sign up</span>
                      </IonButton>
                    </div>
                    <div className="header-count-link">
                      <a onClick={() => history.push('/shoppingCart')}>
                        <div className="icon-count">
                          <IonIcon icon={cartOutline}/>
                          {!isEmpty(cartItems) && (
                            <span>{cartItems.length}</span>
                          )}
                        </div>
                        Cart{' '}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <IonTitle>Header 41</IonTitle> */}
      </div>
    </IonHeader>
  );
};

export default Header;
