import {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonImg,
    IonLabel,
    IonInput,
    IonButton,
    IonSpinner,
    IonIcon,
} from "@ionic/react";
import {searchOutline} from "ionicons/icons";
import React, {useState} from "react";
import "./prescriber.scss";
import {menuController} from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import {useDispatch, useSelector} from "react-redux";
import * as registrationAction from "../../Redux/actions/registrationAction";
import {
    handlePrescriberSearchResponse,
    maskPhoneNumber,
} from "../../Utils/helper";

const Prescriber = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const formData = useSelector((state) => state.registrationReducer.formdata);
    const [state, setState] = useState(formData);
    const [errorState, setErrorState] = useState({
        prescriberName: [],
        prescriberPhone: [],
        prescriberFax: []
    });
    const [buttonSpinner, setButtonSpinner] = useState("");
    const [prescriberSearchResult, setPrescriberSearchResult] = useState([]);
    const [disableSearch, setDisableSearch] = useState(false);
    const [timeOutState, setTimeOutState] = useState(null);

    React.useEffect(() => {
        menuController.enable(false);
    }, []);

    const handleChange = async (event, name) => {
        clearErrors(name);
        let {value} = event.detail;
        if ((name === "prescriberPhone" || name === "prescriberFax") && value) {
            value = value.replace(/\D/g, "")
            if (isNaN(value)) {
                setErrorState((prevState) => ({
                    ...prevState,
                    [name]: ["Phone number can only contain numbers !"],
                }));
            } else {
                setErrorState((prevState) => ({...prevState, [name]: []}));
            }
        }

        // if (name === "prescriberName") {
        //   setState((prevState) => ({ ...prevState, [name]: value }));
        //   if (value && value.length !== 0 && !disableSearch) {

        //     if(timeOutState){
        //       clearInterval(timeOutState);
        //     }

        //     let result = setTimeout(async()=>{
        //       await searchPrescriber();
        //     },1000)

        //     setTimeOutState(result);

        //   } else {
        //     setPrescriberSearchResult([]);
        //   }
        // } else if (
        //   (name === "prescriberPhone" || name === "prescriberFax") &&
        //   value &&
        //   value.length !== 0
        // ) {
        //   value = parseInt(value);
        //   if (isNaN(value)) {
        //     return null;
        //   }
        // }
        setState((prevState) => ({...prevState, [name]: value}));
    };

    const searchPrescriber = async () => {
        if ("prescriberId" in state && state.prescriberId.length !== 0) {
            for (let prescriber of prescriberSearchResult) {
                if (
                    state.prescriberName ===
                    prescriber.first_name + " " + prescriber.last_name
                ) {
                    console.log("Not a new precriber");
                }
            }
            // setState((prevState) => ({...prevState, prescriberId: ''}));
        }
        if (state.prescriberName.length === 0) {
            setPrescriberSearchResult([]);
            return;
        }
        setButtonSpinner("prescriber");
        const response = await dispatch(
            registrationAction.searchPrescriber(0, state.prescriberName, {first_name: state.prescriberName, city: ''})
        );
        setPrescriberSearchResult(await handlePrescriberSearchResponse(response));
        setButtonSpinner("");
    };

    const clearErrors = (name) => {
        dispatch({type: "CLEAN_ERROR"});
        if (name in errorState && errorState[name].length !== 0) {
            setErrorState((prevState) => ({...prevState, [name]: []}));
        }
    };

    const validateForm = () => {
        let isFormValid = true;
        let testError = {
            prescriberName: [],
            prescriberPhone: [],
            prescriberFax: []
        };
        if (state.prescriberName.trim().length === 0) {
            isFormValid = false;
            testError.prescriberName = ["Prescriber Name cannot be empty!"];
        }

        if (state.prescriberPhone.toString().trim().length === 0) {
            isFormValid = false;
            testError.prescriberPhone = ["Prescriber Phone cannot be empty!"];
        }

        if (state.prescriberPhone.toString().trim().length !== 0 && state.prescriberPhone.toString().trim().length < 10) {
            isFormValid = false;
            testError.prescriberPhone = ["Invalid prescriberPhone Phone Number!"];
        }

        if (state.prescriberFax.toString().trim().length !== 0 && state.prescriberFax.toString().trim().length < 10) {
            isFormValid = false;
            testError.prescriberFax = ["Invalid prescriberFax Phone Number!"];
        }

        setErrorState(testError);
        return isFormValid;
    };

    const onSubmit = async (type) => {
        setButtonSpinner(type);
        let body = {};
        await dispatch(registrationAction.setWelcomeRegistrationData(state));
        if (type === "previous") {
            return props.history.goBack();
        } else if (type === "continue") {
            if (!(await validateForm())) {
                setButtonSpinner('')
                return null;
            }
            body = state;
        } else if (type === "skip") {
            const prescriberState = {
                prescriberName: "",
                prescriberPhone: "",
                prescriberFax: "",
                prescriberId: "",
            };

            await dispatch(
                registrationAction.setWelcomeRegistrationData({
                    ...state,
                    ...prescriberState,
                })
            );
            body = {...state, ...prescriberState};
        }

        // const response = await dispatch(registrationAction.signUp(body))
        // if (response.success) {
        //     props.history.push('/confirmation')
        // }
        if (state.email && state.email.length !== 0) {
            const response = await dispatch(registrationAction.sendVerificationCode(state.email, state.phone));
            if (response && response.success) {
                props.history.push("/confirmation");
            }
        }
        setButtonSpinner('')
    };

    const onPrescriberSelected = (prescriber) => {
        setDisableSearch(true);
        const prescriberState = {
            prescriberName: prescriber.name,
            prescriberPhone: prescriber.phone.toString(),
            prescriberFax: prescriber.fax.toString(),
            // prescriberId : prescriber.id.toString()
        };
        setState({...state, ...prescriberState});
        setPrescriberSearchResult([]);
        setTimeout(() => {
            setDisableSearch(false);
        }, 2000);
    };

    return (
        <div className="prescriber-page pagecard-height">
            <div className="space-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" size-md="3" size-lg="2">
                            <CommonSidebar pageIndex={4}/>
                        </IonCol>
                        <IonCol size="12" size-md="9" size-lg="10">
                            <div className="theme-card">
                                <div className="card-body">
                                    <div className="parentform">
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol size="12" size-lg="7">
                                                    <div className="parentform-heading">
                                                        <span>4 OF 5</span>
                                                        <h2>What’s your prescriber/doctor?</h2>
                                                        <p>
                                                            Let us know where you have been getting these
                                                            medications filled.
                                                        </p>
                                                    </div>
                                                    <div className="parentform-left-detail">
                                                        <IonList>
                                                            <IonItem>
                                                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={wallet}/>
                                  </span>
                                                                    <p>Get up to 80% off your medications.</p>
                                                                </div>
                                                            </IonItem>
                                                            <IonItem>
                                                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={medicine}/>
                                  </span>
                                                                    <p>
                                                                        Thousands of meds, fullfilled by a licensed
                                                                        & accredited US-based pharmacy.
                                                                    </p>
                                                                </div>
                                                            </IonItem>
                                                            <IonItem>
                                                                <div className="parentform-left-content">
                                  <span>
                                    <IonImg src={time}/>
                                  </span>
                                                                    <p>
                                                                        Order online and get FREE Delivery, and $5
                                                                        off your first order.{" "}
                                                                    </p>
                                                                </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </IonCol>

                                                <IonCol size="12" size-lg="5">
                                                    <div className="parentform-right">
                                                        {/* <div className="form-group search-drop"> */}
                                                        <div className="form-group">
                                                            <IonLabel>Prescriber Name</IonLabel>
                                                            {/* <div className="left-icon-input"> */}
                                                            {/* <a href="javascript:;">
                                  {buttonSpinner === "prescriber" ? (
                                    <IonSpinner />
                                  ) : (
                                    <IonIcon icon={searchOutline} />
                                  )}
                                </a> */}

                                                            <IonInput
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="e.g. John Smith"
                                                                value={state.prescriberName}
                                                                onIonChange={(e) =>
                                                                    handleChange(e, "prescriberName")
                                                                }
                                                            />
                                                            {errorState.prescriberName.length !== 0 &&
                                                            errorState.prescriberName[0] && (
                                                                <p className="errormsg">
                                                                    {errorState.prescriberName[0]}
                                                                </p>
                                                            )}
                                                        </div>


                                                        {/* {prescriberSearchResult &&
                                prescriberSearchResult.length !== 0 && (
                                  <div className="search-drop-list">
                                    {prescriberSearchResult.map(
                                      (prescriber, index) => (
                                        <a
                                          key={index}
                                          onClick={() =>
                                            onPrescriberSelected(prescriber)
                                          }
                                        >
                                          {prescriber.name}
                                        </a>
                                      )
                                    )}
                                  </div>
                                )} */}
                                                        {/* </div> */}
                                                        <div className="form-group">
                                                            <IonLabel>Prescriber Phone</IonLabel>
                                                            <IonInput
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="e.g. (123)-456-7890"
                                                                maxlength="10"
                                                                value={maskPhoneNumber(
                                                                    state.prescriberPhone.toString()
                                                                )}
                                                                onIonChange={(e) =>
                                                                    handleChange(e, "prescriberPhone")
                                                                }
                                                            />

                                                            {errorState.prescriberPhone.length !== 0 &&
                                                            errorState.prescriberPhone[0] && (
                                                                <p className="errormsg">
                                                                    {errorState.prescriberPhone[0]}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <IonLabel>Prescriber Fax (Optional)</IonLabel>
                                                            <IonInput
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="e.g. (123)-456-7890"
                                                                maxlength="10"
                                                                value={maskPhoneNumber(
                                                                    state.prescriberFax.toString()
                                                                )}
                                                                onIonChange={(e) =>
                                                                    handleChange(e, "prescriberFax")
                                                                }
                                                            />

                                                            {errorState.prescriberFax.length !== 0 &&
                                                            errorState.prescriberFax[0] && (
                                                                <p className="errormsg">
                                                                    {errorState.prescriberFax[0]}
                                                                </p>
                                                            )}
                                                        </div>
                                                        {/*<div className="form-group">
                                                            <IonLabel>Another Medication (optional)</IonLabel>
                                                            <IonInput className="form-control" type="text"
                                                                      placeholder="Enter the medication name"
                                                                      value={state.anotherMedication}
                                                                      onIonChange={(e) => handleChange(e, 'anotherMedication')}
                                                          />
                                                        </div>*/}

                                                        {commonData.errorMsg &&
                                                        commonData.errorMsg.length !== 0 && (
                                                            <p className="errormsg">
                                                                {commonData.errorMsg}
                                                            </p>
                                                        )}

                                                        <div className="parentform-button">
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                disabled={buttonSpinner === "continue"}
                                                                onClick={() => onSubmit("continue")}
                                                            >
                                                                <span>Continue</span>
                                                                {/*{commonData.loading &&
                                  buttonSpinner === "continue" && (
                                    <IonSpinner />
                                  )}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-blue-outline"
                                                                onClick={() => onSubmit("skip")}
                                                                disabled={buttonSpinner === "skip"}
                                                            >
                                                                <span>Skip</span>
                                                                {/*{commonData.loading &&
                                  buttonSpinner === "skip" && <IonSpinner />}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-blue-outline"
                                                                onClick={() => onSubmit("previous")}
                                                            >
                                                                <span>Previous</span>
                                                                {/*{commonData.loading &&
                                  buttonSpinner === "previous" && (
                                    <IonSpinner />
                                  )}*/}
                                                            </IonButton>
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default Prescriber;
