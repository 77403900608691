import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonModal,
} from "@ionic/react";
import {
  eyeOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import closeSvgIcon from "../../images/close-icon.svg";
import { authActions } from "../../Redux/actions";
import planIcon from '../../images/plan.png';
import './index.scss'

const LoginPopupFunc = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);

  const [isButtonDisabled, setButtonDisable] = useState(true)
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    email: [],
    password: [],
  });
  const { isOpen, onClose, onSuccessLogin } = props;

  const handleChange = async (event, name) => {
    const { value } = event.detail;
    if (name === "password") {
      let pValidation = {
        length: false,
        uppercase: false,
        specialCharacter: false,
      };
      const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      pValidation.length = value.length >= 8;
      pValidation.uppercase = new RegExp("[A-Z]").test(value);
      pValidation.specialCharacter = specialCharacterFormat.test(value);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
    setButtonDisable(!await validateForm())
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { email: [], password: [] };
    if (state.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["Email cannot be empty!"];
    }
    if (
      state.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        state.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }
    if (state.password.trim().length === 0) {
      isFormValid = false;
      testError.password = ["Password cannot be empty!"];
    }
    return isFormValid;
  };

  const onSubmit = async () => {
    if (!(await validateForm())) {
      return null;
    }
    const response = await dispatch(authActions.loginUser(state));
    if (response && response.success) {
      onSuccessLogin()
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }
  };

  return (
    <IonModal isOpen={isOpen} cssClass="theme-modal request-price-modal custom-height-ion"  onDidDismiss={onClose}>
      <div className="modal-body">
        <div className="request-price-content">
          <div className="login-page-inner">
            <div className="theme-card">
              <div className="card-body">
                <div className="cart-closer">
                  <img
                    onClick={onClose}
                    className="close-icon"
                    src={closeSvgIcon}
                    alt="Close"
                  />
                </div>
                <div className="parentform">
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" size-md="7" size-lg="7">
                        <div className="signin-left-img">
                          <span className="step-title">0 OF 2</span>
                          <h3>Sign in</h3>
                          <p className="sub-title">Please sign in/sign up to request insurance price.</p>
                          <ul>
                            <li>
                              <span className="circle"><img src={planIcon} alt="plan" className="plan-icon"/></span>
                              Step 1 : Enter your profile information</li>
                            <li>
                              <span className="circle"><img src={planIcon} alt="plan" className="plan-icon"/></span>
                              Step 2 : Send your prescription to Qwark.</li>
                          </ul>
                        </div>
                        <p className="footer-title">
                          Once we have your information, we will send you an alert when insurance price is ready
                        </p>
                      </IonCol>
                      <IonCol size="12" size-md="5" size-lg="5">
                        <div className="parentform-right">
                          <div className="parentform-right-head">
                          </div>
                          {commonData.errorMsg.error && (
                            <p className="errormsg">
                              {commonData.errorMsg.error}
                            </p>
                          )}
                          <div className="form-group">
                            <IonLabel>Email</IonLabel>
                            <IonInput
                              className="form-control"
                              type={"text"}
                              placeholder={"e.g. johnsmilth@gmail.com"}
                              onIonChange={(e) => handleChange(e, "email")}
                              value={state.email}
                            />
                            {errorState.email.length !== 0 &&
                            errorState.email[0] && (
                              <p className="errormsg">{errorState.email[0]}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="inline-label">
                              <IonLabel>Password</IonLabel>
                              <a
                                className="inline-link"
                                href="javascript:;"
                                onClick={() =>
                                  props.history.push("/forgotPassword")
                                }
                              >
                                Forgot password?
                              </a>
                            </div>
                            <div className="icon-input">
                              <IonInput
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                placeholder={"e.g. @Abcefghi"}
                                onIonChange={(e) => handleChange(e, "password")}
                                value={state.password}
                              />
                              <a href="javascript:;" onClick={() => setShowPassword(!showPassword)}>
                                <IonIcon icon={eyeOutline} />
                              </a>
                            </div>
                          </div>
                          <div className="parentform-button">
                            <IonButton className="theme-btn button-blue" disabled={isButtonDisabled} onClick={() => onSubmit()}>
                              Continue
                            </IonButton>
                          </div>
                          <div className="have-account-text">
                            <p>New to Qwark?{" "}<a href="javascript:;" onClick={() => props.history.push("/welcome")}>Sign up</a>
                            </p>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  )
}

export default withRouter(LoginPopupFunc)
