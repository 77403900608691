export const initialState = {
    formdata: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        gender: '',
        pharmacyName: '',
        pharmacyAddress: '',
        isPolicyAccepted : false,
        prescriberName : '',
        prescriberPhone : '',
        prescriberFax : '',
        confirmPassword :'',
        memberId: '',
        rxbin : '',
        rxpcn : '',
        prescriberId : '',
    }
};

const newFormData = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    gender: '',
    pharmacyName: '',
    pharmacyAddress: '',
    isPolicyAccepted : false,
    prescriberName : '',
    prescriberPhone : '',
    prescriberFax : '',
    confirmPassword :'',
    memberId: '',
    rxbin : '',
    rxpcn : '',
    prescriberId : '',
}

export const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_REGISTRATION_DATA":
            return {
                formdata: {
                    ...action.payload
                }

            };
        case "EMAILVERIFICATION":
            return {
                ...state,
                isEmailVerified: action.payload.data.message,
                isRegistered: "",
            };
        case "VERIFY_EMAIL":
            return {
                ...state,
                message: action.payload.data.message
            };
        case "LOGIN":
            localStorage.setItem("userData", JSON.stringify(action.payload.data.user))
            localStorage.setItem("token", action.payload.data.user.token);
            return {
                ...state,
                message: action.payload.data.message,
                authData: action.payload.data.user,
                isLoggedIn: true,
            };
        case "CLEAR_LOGIN":
            return {
                ...state,
                message: "",
            };
        case 'LOGOUT_USER':
            const sessionPassword = localStorage.getItem("sessionPassword")
            const cart = localStorage.getItem("cart")
            localStorage.clear()
            localStorage.setItem("sessionPassword", sessionPassword)
            localStorage.setItem("cart", cart)
            return {
                ...state,
                isRegistered: "",
                isEmailVerified: "",
                isLoggedIn: "",
                message: "",
                authData: {},
                formdata : newFormData
            }
        case 'REFRESH_AUTH_DATA':
            if (!action.payload.data) {
                return {
                    ...state
                }
            }

            localStorage.setItem("userData", JSON.stringify(action.payload.data))
            return {
                ...state,
                message: action.payload.message,
                authData: action.payload.data,
                isLoggedIn: true,
            };

        case "USERINFO":
            return {
                ...state,
                authData: action.payload.data,
            };
        default:
            return state;
    }
};
