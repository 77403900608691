import {
  IonList,
  IonItem,
} from "@ionic/react";
import React from "react";
import "./commonSidebar.scss";

const CommonSidebar = (props) => {
  const { pageIndex } = props;
  return (
    <div className="sidebar-content">
      <IonList>
        <IonItem>
          <a className="active">Welcome</a>
        </IonItem>
        <IonItem>
          <a className={pageIndex > 1 ? "active" : ""}>Profile</a>
        </IonItem>
        {/*<IonItem>
          <a className={pageIndex > 2 ? "active" : ""}>Insurance</a>
        </IonItem>
        <IonItem>
          <a className={pageIndex > 3 ? "active" : ""}>Prescriber</a>
        </IonItem>
        <IonItem>
          <a className={pageIndex > 3 ? "active" : ""}>Provider</a>
        </IonItem>*/}
        <IonItem>
          <a className={pageIndex > 2 ? "active" : ""}>Confirmation</a>
        </IonItem>
      </IonList>
    </div>
  );
};

export default CommonSidebar;
