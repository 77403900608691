import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";


class PrivateGuard extends React.Component {
  dontShowAfterLogin = [
    "/signup",
    "/signUp",
    "/login",
    "/forgotPassword",
    "/verifyEmail",
    "/welcome",
    "/profile",
    "/prescriber",
    "/confirmation",
    "/confirmationDone",
    "/insurance",
    "/resetCode",
    "/resetPassword",
    "/resetPassword/:params",
    "/loginVerification",
    "/forgotPasswordThanks",
  ];

  publicRouteList = [
    "/",
    "/about",
    "/landing",
    "/resetPassword",
    "/shoppingCart",
    "/meds-2-99",
    "/blog",
    "/page/privacy_policy",
    "/page/terms_of_service",
    "/page/hair_loss",
    "/page/birth_control",
    "/page/informed_consent",
    "/page/notice_of_privacy",
    "/savingscalculator",
    "/switch",
    "/switch-spanish",
    "/switch-welcome",
    "/covid-vaccine",
  ];
  checkUser = () => {
    return localStorage.getItem("token");
  };

  checkSession = () => {
    return localStorage.getItem("sessionPassword");
  };

  render() {
    const route = this.props.location?.pathname;
    let returnData = "";
    if (route === "/savingsCalculator") {
      returnData = (
        <Redirect
          to={{
            pathname: "/404_not_found"
          }}
        />
      );
      return returnData;
    }
    // if (route!=="/savingscalculator" && !this.checkSession()) {
    //     let password = prompt('Please enter password for continuing surfing')
    //     const expectedPassword = CryptoJS.AES.decrypt('U2FsdGVkX19mnfAyln0EVa1ijzZYN9qxaHW5ZjHpTIo=', 'sessionPasswordSecret$#').toString(CryptoJS.enc.Utf8)
    //     if (password !== expectedPassword) {
    //         returnData = (
    //             <Redirect
    //                 to={{
    //                     pathname: "/unathorizedUser"
    //                 }}
    //             />
    //         );
    //         return returnData;
    //     } else {
    //         const encryptedPassword = CryptoJS.AES.encrypt(password, 'sessionPasswordSecret$#').toString()
    //         localStorage.setItem("sessionPassword", encryptedPassword)
    //         window.location.reload()
    //     }
    // }
    if (this.checkUser()) {
      if (!this.dontShowAfterLogin.includes(route)) {
        // if (route.includes("/checkout") && (isEmpty(cartItem) || cartItem == "null" || cartItem.length == 0)) {
        //     returnData=(<Redirect
        //         to={{
        //             pathname: "/shoppingCart",
        //             state: { from: this.props.location },
        //         }}
        //     />
        //     )
        // } else {
        returnData = <Route {...this.props} />;
      } else {

        returnData = (
          <Redirect
            to={{
              pathname: "/",
              state: { from: this.props.location },
            }}
          />
        );
      }

    } else {
      if (this.dontShowAfterLogin.includes(route) || this.publicRouteList.includes(route) || route.includes("medicine_detail")) {
        returnData = <Route {...this.props} />;
      } else {
        console.log(this.props.location, "this.props.location");
        returnData = (
          <Redirect
            to={{
              pathname: "/",
              state: { from: this.props.location },
            }}
          />
        );
      }
    }

    return returnData;
  }
}


export default withRouter(PrivateGuard);
