import React, {useState} from "react";
import {menuController} from "@ionic/core";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonLabel,
    IonRow,
    IonSpinner,
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../Redux/actions/index";
import resetPasswordImg from "../../images/reset-password-img.png";
import {checkmarkOutline, closeOutline, eyeOutline} from "ionicons/icons";
import GoogleLogin from "../SocialLogin/googleLogin";
import {isEmpty} from "../../Utils/helper";

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer.authData);
    const token = props.location.search && !isEmpty(props.location.search) ? props.location?.search.split('=')[1] : '';
    const isResetPasswordParams = !!token;
    const [state, setState] = useState({
        newPassword: "",
        confirmNewPassword: "",
    });
    const [errorState, setErrorState] = useState({
        newPassword: [],
        confirmNewPassword: [],
    });
    const [passwordValidationState, setPasswordValidationState] = useState({
        length: "",
        uppercase: "",
        specialCharacter: "",
    });
    const [showPassword, setShowPassword] = useState({
        newPassword: "password",
        confirmNewPassword: "password",
    });


    React.useEffect(() => {
        menuController.enable(false);
    }, []);

    const handleChange = (event, name) => {
        dispatch({type: "CLEAN_ERROR"});
        let {value} = event.detail;
        setState((prevState) => ({...prevState, [name]: value}));
        if (name === "newPassword") {
            let pValidation = {
                length: false,
                uppercase: false,
                specialCharacter: false,
            };
            const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            pValidation.length = value.length >= 8;
            pValidation.uppercase = new RegExp("[A-Z]").test(value);
            pValidation.specialCharacter = specialCharacterFormat.test(value);
            setPasswordValidationState(pValidation);
        }
    };

    const validateForm = () => {
        let isFormValid = true;
        let testError = {
            newPassword: [],
            confirmNewPassword: [],
        };
        if (state.confirmNewPassword.length === 0) {
            isFormValid = false;
            testError.confirmNewPassword = ["Confirm New Password cannot be empty!"];
        }
        if (state.confirmNewPassword !== state.newPassword) {
            isFormValid = false;
            testError.confirmNewPassword = ["Password does not match!"];
        }
        if (
            !passwordValidationState.length ||
            !passwordValidationState.specialCharacter ||
            !passwordValidationState.uppercase
        ) {
            isFormValid = false;
        }
        setErrorState(testError);
        return isFormValid;
    };

    const onSubmit = async () => {
        if (!(await validateForm())) {
            return null;
        }
        const response = await dispatch(authActions.resetPassword({...state, token})
        );
        if (response && response.success) {
            setTimeout(() => {
                dispatch({type: "CLEAN_ERROR"});
                props.history.push("/login");
            }, 2000);
        }
    };

    const handleShowPassword = (name) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [name]: showPassword[name] === "password" ? "text" : "password",
        }));
    };

    return (
        <div className="forgotflow">
            <div className="space-container">
                <div className="login-page-inner">
                    <div className="theme-card">
                        <div className="card-body">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6" size-lg="6">
                                            <div className="signin-left-img">
                                                <IonImg src={resetPasswordImg}/>
                                            </div>
                                        </IonCol>

                                        <IonCol size="12" size-md="5" offset-md="1" size-lg="5" offset-lg="1">
                                            {/* reset password start */}
                                            <div className="parentform-right">
                                                <div className="parentform-right-head">
                                                    <h2>Reset Password</h2>
                                                </div>

                                                <div className="form-group">
                                                    <IonLabel>Password</IonLabel>
                                                    <div className="icon-input">
                                                        <IonInput
                                                            className="form-control"
                                                            type={showPassword.newPassword}
                                                            placeholder="e.g. @Abcefghi"
                                                            onIonChange={(e) => handleChange(e, "newPassword")}
                                                            value={state.newPassword}
                                                        />
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() =>
                                                                handleShowPassword("newPassword")
                                                            }
                                                        >
                                                            <IonIcon icon={eyeOutline}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                {errorState.newPassword.length !== 0 && errorState.newPassword[0] &&
                                                <p className="errormsg">{errorState.newPassword[0]}</p>
                                                }
                                                <div className="form-group">
                                                    <IonLabel>Confirm Password</IonLabel>
                                                    <div className="icon-input">
                                                        <IonInput
                                                            className="form-control"
                                                            type={showPassword.confirmNewPassword}
                                                            placeholder="e.g. @Abcefghi"
                                                            onIonChange={(e) => handleChange(e, "confirmNewPassword")}
                                                            value={state.confirmNewPassword}
                                                        />
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() =>
                                                                handleShowPassword("confirmNewPassword")
                                                            }
                                                        >
                                                            <IonIcon icon={eyeOutline}/>
                                                        </a>
                                                        <p className={passwordValidationState.length.length === 0 ? "text-grey" : passwordValidationState.length ? "successmsg" : "errormsg"}>{" "}
                                                            {passwordValidationState.length.length === 0 ? null : passwordValidationState.length ? (
                                                                    <IonIcon icon={checkmarkOutline}/>) :
                                                                (<IonIcon icon={closeOutline}/>)}{" "}
                                                            Use at least 8 characters
                                                        </p>
                                                        <p className={
                                                            passwordValidationState.uppercase.length === 0
                                                                ? "text-grey"
                                                                : passwordValidationState.uppercase
                                                                ? "successmsg"
                                                                : "errormsg"
                                                        }
                                                        >
                                                            {passwordValidationState.uppercase.length === 0 ? null : passwordValidationState.uppercase ? (
                                                                <IonIcon icon={checkmarkOutline}/>) : (
                                                                <IonIcon icon={closeOutline}/>)}{" "}
                                                            Have at least 1 upper case
                                                        </p>
                                                        <p className={passwordValidationState.specialCharacter.length === 0 ? "text-grey" : passwordValidationState.specialCharacter ? "successmsg" : "errormsg"}>
                                                            {passwordValidationState.specialCharacter.length === 0 ? null : passwordValidationState.specialCharacter ? (
                                                                <IonIcon icon={checkmarkOutline}/>
                                                            ) : (
                                                                <IonIcon icon={closeOutline}/>
                                                            )}{" "}
                                                            Have at least 1 special character
                                                        </p>
                                                        {errorState.confirmNewPassword.length !== 0 && errorState.confirmNewPassword[0] &&
                                                        <p className="errormsg">{errorState.confirmNewPassword[0]}</p>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="parentform-button">
                                                    <IonButton className="theme-btn button-blue"
                                                               onClick={() => onSubmit()}>
                                                        Reset Password
                                                        {/*{
                                                            commonData.loading && <IonSpinner/>
                                                        }*/}
                                                    </IonButton>
                                                </div>
                                                <div className="have-account-text">
                                                    <p>
                                                        New to Qwark?
                                                        <a href="javascript:;"
                                                           onClick={() => props.history.push('/welcome')}
                                                        >Sign up</a>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* reset password end */}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
