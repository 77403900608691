import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import "./checkoutReview.scss";
import { menuController } from "@ionic/core";
import { useDispatch, useSelector } from "react-redux";
import CheckoutSidebar from "../../components/checkoutSideBar/checkOutSideBar";
import MyCart from "../../components/myCart/myCart";
import {
  convertArrayToCommaSeparatedString, getCartLocal,
  isEmpty,
  parseCardExpireDate, showPriceOrInsurancePrice,
} from "../../Utils/helper";
import { checkoutAction } from "../../Redux/actions";

const CheckoutReview = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const deliveryOption = useSelector((state) => state.userReducer.deliveryOption);
  const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);
  const [state, setState] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { paymentDetail } = props.location.state;

  React.useEffect(() => {
    menuController.enable(false);
    loadData();
  }, []);

  const loadData = async () => {
    const response = await dispatch(checkoutAction.getReviewData(authData.id));
    if (response && response.data) {
      setState(response.data);
    }
  };

  const onSubmit = async () => {
    setDisableSubmitButton(true)
    let cartItem = getCartLocal();
    console.log({
      ...state,
      userInfo: authData,
      cartItems,
      paymentInfo: paymentDetail,
      deliveryOption
    });

    if (cartItem.length === 0) {
      props.history.push("/shoppingCart");
    } else {
      const response = await dispatch(
        checkoutAction.generateOrder(authData.id, {
          ...state,
          userInfo: authData,
          cartItems,
          paymentInfo: paymentDetail,
          deliveryOption
        })
      );
      if (response && response.success) {
        props.history.push({ pathname: "/checkout_thankyou", state: { ...response.data, paymentDetail }, });
      } else {
        setTimeout(() => {
          dispatch({ type: "CLEAN_ERROR" });
        }, 4000);
      }
    }
    setDisableSubmitButton(false)
  };

  const prescriptionData = () => {
    let isPrescriber = false
    let prescriberData = []
    let isPharmacy = false
    let pharmacyData = []
    for (let item of cartItems) {
      if (item.optionId === 2) {
        isPrescriber = true
        prescriberData.push(item.optionData)
      } else if (item.optionId === 3) {
        isPharmacy = true
        pharmacyData.push(item.optionData)
      }
    }

    return (
      <div>
        {isPrescriber &&
          <div className="shippingMethod-item">
            <div className="shippingMethod-left">
              <div className="shipping-detail">
                <h3>Prescriber</h3>
                {
                  prescriberData.map((p, pIndex) => (<p key={pIndex}>{p.prescriber_name}</p>))
                }
              </div>
            </div>
          </div>
        }

        {isPharmacy &&
          <div className="shippingMethod-item">
            <div className="shippingMethod-left">
              <div className="shipping-detail">
                <h3>Pharmacy</h3>
                {
                  pharmacyData.map((p, pIndex) => (<p key={pIndex}>{p.pharmacy_name}</p>))
                }
              </div>
            </div>
          </div>
        }
      </div>

    )

  }

  return (
    <div className="checkoutReview-page">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="2">
              <CheckoutSidebar pageIndex={5} />
            </IonCol>
            <IonCol size="12" size-md="10">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="parentform-heading">
                        <span>{ prescriptionRequired ? '5 OF 5' : '4 OF 4' }</span>
                        <h2>Review and Place Order</h2>
                        <p>
                          Your order will not be placed until you click “submit”.</p>
                      </div>
                      <div className="methodBox medication">
                        <div className="shippingMethod-heading">
                          <h3>Medication</h3>
                        </div>
                        <div className="theme-card">
                          <div className="card-body">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="12" size-md="12">
                                  {cartItems && cartItems.map((cartItem, index) => (
                                      <div className="shippingMethod-item" key={index}>
                                        <div className="shippingMethod-left">
                                          <div className="shipping-detail">
                                            <h3>{cartItem.medicineName}</h3>
                                            <p>
                                              {cartItem.medicineForm} •{" "}{cartItem.medicineStrength}
                                            </p>
                                            <p>
                                              Package Size: {cartItem.activeQuantity}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="shippingMethod-right">
                                          <p>
                                            ${showPriceOrInsurancePrice(cartItem)}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        </div>
                      </div>

                      {/* Patient Info */}
                      <div className="methodBox patientInfo">
                        <div className="shippingMethod-heading">
                          <h3>Patient Info</h3>
                        </div>

                        <div className="theme-card">
                          <div className="card-body">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="12" size-md="12">
                                  {/* item */}
                                  <div className="shippingMethod-item">
                                    <div className="shippingMethod-left">
                                      <div className="shipping-detail">
                                        <h3>Additional Health Information</h3>
                                        <p>
                                          Allergies:{""}
                                          {
                                            state.additionalHealthInfo && state.additionalHealthInfo.allergies && (state.additionalHealthInfo.allergies).length != 0
                                              ? convertArrayToCommaSeparatedString(state.additionalHealthInfo.allergies.map(allergy => allergy.allergy))
                                              : "None"
                                          }
                                        </p>
                                        <p>
                                          Medical Conditions:{" "}
                                          {state.additionalHealthInfo && state.additionalHealthInfo.medicalConditions && (state.additionalHealthInfo.medicalConditions).length != 0
                                            ? convertArrayToCommaSeparatedString(state.additionalHealthInfo.medicalConditions.map(medicalCondition => medicalCondition.medical_condition))
                                            : "None"}
                                        </p>
                                        <p>
                                          Other Medications:{" "}
                                          {state.additionalHealthInfo && state.additionalHealthInfo.medications && (state.additionalHealthInfo.medications).length != 0
                                            ? convertArrayToCommaSeparatedString(state.additionalHealthInfo.medications.map(medication => medication.medication))
                                            : "None"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {prescriptionData()}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        </div>
                      </div>

                      {/* Shipping*/}
                      <div className="methodBox shipping">
                        <div className="shippingMethod-heading">
                          <h3>Shipping</h3>
                        </div>
                        <div className="theme-card">
                          <div className="card-body">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="12" size-md="12">
                                  {/* item */}
                                  <div className="shippingMethod-item">
                                    <div className="shippingMethod-left">
                                      <div className="shipping-detail">
                                        <h3>Delivery </h3>
                                        {isEmpty(deliveryOption) ? (<p>USPS First Class Shipping</p>) : (<p>{deliveryOption.name}</p>)}
                                      </div>
                                    </div>
                                    <div className="shippingMethod-right">
                                      {isEmpty(deliveryOption) ? (<p>$0.00</p>) : (<p>${deliveryOption.cost}</p>)}
                                    </div>
                                  </div>
                                  <div className="shippingMethod-item">
                                    <div className="shippingMethod-left">
                                      <div className="shipping-detail">
                                        <h3>Shipping Address </h3>
                                        {state.shippingAddress && (
                                          <p>
                                            {convertArrayToCommaSeparatedString(
                                              [
                                                state.shippingAddress.addressLine1,
                                                state.shippingAddress.addressLine2,
                                                state.shippingAddress.city,
                                                state.shippingAddress.state,
                                                state.shippingAddress.zip,
                                              ]
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        </div>
                      </div>

                      {/* Payment*/}
                      <div className="methodBox payment">
                        <div className="shippingMethod-heading">
                          <h3>Payment</h3>
                        </div>
                        <div className="theme-card">
                          <div className="card-body">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="12" size-md="12">
                                  {/* item */}
                                  <div className="shippingMethod-item">
                                    <div className="shippingMethod-left">
                                      <div className="shipping-detail">
                                        <h3>Card Information </h3>
                                        <p>
                                          {paymentDetail?.card_type?.toUpperCase()}{" "} ending in{" "}
                                          {paymentDetail.card_number.slice(-4)}
                                        </p>
                                        <p>
                                          Expires{" "}
                                          {parseCardExpireDate(paymentDetail.exp_date)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* item */}
                                  <div className="shippingMethod-item">
                                    <div className="shippingMethod-left">
                                      <div className="shipping-detail">
                                        <h3>Billing Address</h3>
                                        {state.billingAddress && (
                                          <p>
                                            {convertArrayToCommaSeparatedString(
                                              [
                                                state.billingAddress.addressLine1,
                                                state.billingAddress.addressLine2,
                                                state.billingAddress.city,
                                                state.billingAddress.state,
                                                state.billingAddress.zip,
                                              ]
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        </div>
                      </div>

                      {commonData.errorMsg && (<p className="errormsg">{commonData.errorMsg}</p>)}

                      <div className="parentform-button">
                        <IonButton
                          className="theme-btn button-blue"
                          onClick={() => onSubmit()}
                          disabled={disableSubmitButton}
                        >
                          <span>Submit</span>
                        </IonButton>
                      </div>
                      <div className="parentform-button">
                        <IonButton
                          className="theme-btn button-blue-outline"
                          onClick={() => props.history.goBack()}
                        >
                          <span>Previous - Review</span>
                        </IonButton>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="5" offset-md="1">
                      <MyCart />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default CheckoutReview;
