import React, { useState } from "react";
import { menuController } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonImg,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Redux/actions/index";
import resetPasswordImg from "../../images/reset-password-img.png";
import "./loginVerification.scss";
import Calendar from "react-calendar";
import {
  isEmpty,
  convertTimeStampToDateYYYYMMDD,
  convertTimestampToMMDDYYYY,
} from "../../Utils/helper";

const LoginVerification = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const [state, setState] = useState({ code: "", dob: "" });
  const [errorState, setErrorState] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const { email } = props.location.state;

  React.useEffect(() => {
    menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
    dispatch({ type: "CLEAN_ERROR" });
    setErrorState({});

    let { value } = event.detail;

    if (name === "dob") {
      setShowCalendar(false);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let isValid = true;
    let testError = {
      code: "",
      dob: "",
    };
    if (state.code.toString().length === 0) {
      isValid = false;
      testError.code = "This field is required !";
    } else if (state.code.toString().length !== 6) {
      isValid = false;
      testError.code = "Reset Code must be of 6 digits";
    }
    if (isEmpty(state.dob)) {
      isValid = false;
      testError.dob = "This field is required !";
    }
    setErrorState(testError);
    return isValid;
  };

  const onSubmit = async () => {
    if (!(await validateForm())) {
      return null;
    }
    // ((jsDate.getMonth() + 1) + '/' + jsDate.getDate() + '/' + jsDate.getFullYear())
    // const year = (new Date(state.dobYear)).getFullYear()
    // let month = (new Date(state.dobMonth)).getMonth() + 1
    // if (month.toString().length === 1) {
    //     month = '0' + month
    // }
    // let date = (new Date(state.dobDay)).getDate()
    // if (date.toString().length === 1) {
    //     date = '0' + date
    // }
    // const dob = year + '-' + month + '-' + date
    const response = await dispatch(
      authActions.verifyResetKey({ code: state.code, dob: state.dob, email })
    );
    if (response.success) {
      props.history.push({ pathname: "/accountSummary" });
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 5000);
    }
  };

  return (
    <div className="forgotflow">
      <div className="space-container">
        <div className="login-page-inner">
          <div className="theme-card">
            <div className="card-body">
              <div className="parentform">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="signin-left-img">
                        <IonImg src={resetPasswordImg} />
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="5" offset-md="1">
                      <div className="parentform-right">
                        <div className="parentform-right-head">
                          <h2>Sign in</h2>
                          <p>We just sent a verification code to {email}.</p>
                        </div>

                        <div className="form-group">
                          <IonLabel>Verification Code</IonLabel>
                          <IonInput
                            className="form-control"
                            type="text"
                            placeholder="e.g. 1234"
                            value={state.code}
                            onIonChange={(e) => handleChange(e, "code")}
                          />
                          {!isEmpty(errorState.code) && (
                            <p className="errormsg">{errorState.code}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <IonLabel>Date of Birth</IonLabel>
                          {showCalendar ? (
                            <div className="commonCalendar">
                              <Calendar
                                value={
                                  state.dob ? new Date(state.dob) : new Date()
                                }
                                maxDate={new Date()}
                                calendarType="US"
                                onChange={(e) => {
                                  handleChange(
                                    {
                                      detail: {
                                        value: convertTimeStampToDateYYYYMMDD(
                                          e
                                        ),
                                      },
                                    },
                                    "dob"
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="icon-input">
                              <IonInput
                                className="form-control"
                                placeholder="mm/dd/yyyy"
                                type="text"
                                value={
                                  state.dob &&
                                  state.dob.toString().length &&
                                  convertTimestampToMMDDYYYY(state.dob)
                                }
                                onClick={() => setShowCalendar(true)}
                              >
                                <a
                                  href="javascript:;"
                                  onClick={() => setShowCalendar(true)}
                                >
                                  <IonIcon icon={calendarClearOutline} />
                                </a>
                              </IonInput>
                            </div>
                          )}
                          {/*<div className="inline-datefilds">
                                                        <div className="dateicon-input">
                                                            <IonDatetime
                                                                className="form-control"
                                                                displayFormat="MMM"
                                                                placeholder="Month"
                                                                value={state.dobMonth}
                                                                onIonChange={(e) => handleChange(e, 'dobMonth')}
                                                            />
                                                            <a href="javascript:;">
                                                                <IonIcon icon={chevronDownOutline}/>
                                                            </a>
                                                        </div>
                                                        <div className="dateicon-input">
                                                            <IonDatetime
                                                                className="form-control"
                                                                displayFormat="DD"
                                                                placeholder="Day"
                                                                value={state.dobDay}
                                                                onIonChange={(e) => handleChange(e, 'dobDay')}
                                                            />
                                                            <a href="javascript:;">
                                                                <IonIcon icon={chevronDownOutline}/>
                                                            </a>
                                                        </div>
                                                        <div className="dateicon-input">
                                                            <IonDatetime
                                                                className="form-control"
                                                                displayFormat="YYYY"
                                                                placeholder="Year"
                                                                value={state.dobYear}
                                                                onIonChange={(e) => handleChange(e, 'dobYear')}
                                                            />
                                                            <a href="javascript:;">
                                                                <IonIcon icon={chevronDownOutline}/>
                                                            </a>
                                                        </div> 
                                                    </div>*/}
                          {!isEmpty(errorState.dob) && (
                            <p className="errormsg">{errorState.dob}</p>
                          )}
                        </div>

                        {/*<div className="form-group">
                                                    <IonLabel>Zip Code</IonLabel>
                                                    <IonInput
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="e.g. 12345"
                                                    />
                                                </div>*/}
                        {commonData.errorMsg && (
                          <p className="errormsg">{commonData.errorMsg}</p>
                        )}
                        {commonData.successMsg && (
                          <p className="successmsg">{commonData.successMsg}</p>
                        )}

                        <div className="parentform-button">
                          <IonButton
                            className="theme-btn button-blue"
                            onClick={() => onSubmit()}
                          >
                            Sign in with single-use code
                            {/*{
                                                            commonData.loading && <IonSpinner/>
                                                        }*/}
                          </IonButton>
                        </div>
                        <div className="have-account-text">
                          <p>
                            New to Qwark?
                            <a
                              href="javascript:;"
                              onClick={() => props.history.push("/welcome")}
                            >
                              Sign up
                            </a>
                          </p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              {/*<div className="parentform-button">
                          <IonButton className="theme-btn button-blue">
                            Sign in with single-use code
                          </IonButton>
                        </div>
                        <div className="have-account-text">
                          <p>
                            New to Qwark?
                            <a href="javascript:;"> Sign up</a>
                          </p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginVerification;
