import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonIcon,
  IonCheckbox,
  IonButton,
} from "@ionic/react";
import {
  eyeOutline,
  closeOutline,
  checkmarkOutline
} from "ionicons/icons";
import React, { useState } from "react";
import "./welcome.scss";
import { Link } from "react-router-dom";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomeRegistrationData } from "../../Redux/actions/registrationAction";
import * as registrationAction from "../../Redux/actions/registrationAction";
import { isEmpty } from "../../Utils/helper";

const Welcome = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.registrationReducer.formdata);
  const commonData = useSelector((state) => state.commonReducer);
  const [state, setState] = useState(formData);
  const [showPassword, setShowPassword] = useState({ password: "password", confirmPassword: "password" });
  const [errorState, setErrorState] = useState({
    email: [],
    password: [],
    confirmPassword: [],
    isPolicyAccepted: [],
  });
  const [passwordValidationState, setPasswordValidationState] = useState({
    length: "",
    uppercase: "",
    specialCharacter: ""
  });

  React.useEffect(() => {
    checkPasswordValidation(state.password);
    menuController.enable(false);
  }, []);

  const handleChange = (event, name) => {
    clearErrors(name);
    const value = (name === "isPolicyAccepted") ? event.detail.checked : event.detail.value;
    if (name === "password" && state.password.length === 0 && isEmpty(value)) {
      return null;
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "password") {
      checkPasswordValidation(value);
    }
  };

  const checkPasswordValidation = (value) => {
    let pValidation = {
      length: false,
      uppercase: false,
      specialCharacter: false
    };
    const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    pValidation.length = value.length >= 8;
    pValidation.uppercase = new RegExp("[A-Z]").test(value);
    pValidation.specialCharacter = specialCharacterFormat.test(value);
    setPasswordValidationState(pValidation);
  };

  const clearErrors = (name) => {
    dispatch({ type: "CLEAN_ERROR" });
    if (errorState[name].length !== 0) {
      setErrorState((prevState) => ({ ...prevState, [name]: [] }));
    }
  };


  const handleShowPassword = (name) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [name]: showPassword[name] === "password" ? "text" : "password"
    }));
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      email: [],
      password: [],
      confirmPassword: [],
      isPolicyAccepted: [],
    };
    if (state.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["Email cannot be empty!"];
    }
    if (state.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(state.email) === false) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }
    if (state.password.trim().length === 0) {
      isFormValid = false;
      testError.password = ["Password cannot be empty!"];
    }
    if (state.password.trim().length > 0 && state.password.trim().length < 8) {
      isFormValid = false;
      testError.password = ["Password length must be at least 8 characters"];
    }
    if (state.confirmPassword.trim().length === 0) {
      isFormValid = false;
      testError.confirmPassword = ["Confirm Password cannot be empty!"];
    }
    if (state.confirmPassword !== state.password) {
      isFormValid = false;
      testError.confirmPassword = ["Confirm Password must match"];
    }

    if (!state.isPolicyAccepted) {
      isFormValid = false;
      testError.isPolicyAccepted = ["You must accept the Policies before proceeding"];
    }

    if (!passwordValidationState.length) {
      isFormValid = false;
    }
    if (!passwordValidationState.specialCharacter) {
      isFormValid = false;
    }
    if (!passwordValidationState.uppercase) {
      isFormValid = false;
    }
    console.log("testError ", testError);
    setErrorState(testError);
    return isFormValid;
  };

  const onSubmit = async () => {
    if (!await validateForm()) {
      console.log("form validation failed");
      return null;
    }
    const response = await dispatch(registrationAction.checkEmailPhone(state.email));
    if (response.success) {
      await dispatch(setWelcomeRegistrationData(state));
      localStorage.setItem('user_sign_up', JSON.stringify(state))
      props.history.push({ pathname: "/profile", state });
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 5000);
    }
  };

  return (
    <div className="welcome-page pagecard-height">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="3" size-lg="2">
              <CommonSidebar pageIndex={1} />
            </IonCol>
            <IonCol size="12" size-md="9" size-lg="10">

              <div className="theme-card">
                <div className="card-body">
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-lg="7">
                          <div className="parentform-heading">
                            <span>1 OF 3</span>
                            <h2>
                              Welcome!<br></br>Enter your email to get started.
                            </h2>
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={wallet} /></span>
                                  <p>Get up to 80% off your medications.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={medicine} /></span>
                                  <p>Thousands of meds, fullfilled by a licensed &
                                    accredited US-based pharmacy.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={time} /></span>
                                  <p>Order online and get FREE Delivery, and $5 off
                                    your first order. </p>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                        </IonCol>

                        <IonCol size="12" size-lg="5">
                          <div className="parentform-right">
                            <div className="form-group">
                              <IonLabel>Email</IonLabel>
                              <IonInput
                                className="form-control" type="text"
                                placeholder="e.g. johnsmilth@gmail.com"
                                value={state.email}
                                onIonChange={(e) => handleChange(e, "email")}
                              />
                              {
                                errorState.email.length !== 0 && errorState.email[0] &&
                                <p className="errormsg">{errorState.email[0]}</p>
                              }
                              {
                                commonData.errorMsg && commonData.errorMsg.length !== 0 &&
                                <p className="errormsg">{commonData.errorMsg}</p>
                              }
                            </div>

                            <div className="form-group">
                              <IonLabel>Password</IonLabel>
                              <div className="icon-input">
                                <IonInput
                                  className="form-control"
                                  type={showPassword.password}
                                  placeholder="e.g. @Abcefghi"
                                  value={state.password}
                                  onIonChange={(e) => handleChange(e, "password")}
                                />
                                <a href="javascript:;"
                                   onClick={() => handleShowPassword("password")}
                                ><IonIcon icon={eyeOutline} /></a>
                                <p
                                  className={state.password.length > 0 ? passwordValidationState.length.length === 0 ? "text-grey" : passwordValidationState.length ? "successmsg" : "errormsg" : "text-grey"}> {passwordValidationState.length.length === 0 ? null : passwordValidationState.length ?
                                  <IonIcon icon={checkmarkOutline} /> : <IonIcon icon={closeOutline} />} Use at least 8
                                  characters</p>
                                <p
                                  className={state.password.length > 0 ? passwordValidationState.uppercase.length === 0 ? "text-grey" : passwordValidationState.uppercase ? "successmsg" : "errormsg" : "text-grey"}>{passwordValidationState.uppercase.length === 0 ? null : passwordValidationState.uppercase ?
                                  <IonIcon icon={checkmarkOutline} /> : <IonIcon icon={closeOutline} />} Have at least 1
                                  upper case</p>
                                <p
                                  className={state.password.length > 0 ? passwordValidationState.specialCharacter.length === 0 ? "text-grey" : passwordValidationState.specialCharacter ? "successmsg" : "errormsg" : "text-grey"}>{passwordValidationState.specialCharacter.length === 0 ? null : passwordValidationState.specialCharacter ?
                                  <IonIcon icon={checkmarkOutline} /> : <IonIcon icon={closeOutline} />} Have at least 1
                                  special character</p>
                              </div>
                            </div>
                            <div className="form-group">
                              <IonLabel>Confirm Password</IonLabel>
                              <div className="icon-input">
                                <IonInput
                                  className="form-control"
                                  type={showPassword.confirmPassword}
                                  placeholder="e.g. @Abcefghi"
                                  value={state.confirmPassword}
                                  onIonChange={(e) => handleChange(e, "confirmPassword")}
                                />
                                <a href="javascript:;"
                                   onClick={() => handleShowPassword("confirmPassword")}
                                ><IonIcon icon={eyeOutline} /></a>
                                {
                                  errorState.confirmPassword.length !== 0 && errorState.confirmPassword[0] &&
                                  <p className="errormsg">{errorState.confirmPassword[0]}</p>
                                }
                              </div>
                            </div>

                            <div className="parentform-check">
                              <IonCheckbox
                                checked={state.isPolicyAccepted}
                                onIonChange={(e) => handleChange(e, "isPolicyAccepted")} />
                              <p>I accept our
                                <Link to={"/page/terms_of_service"} target="_blank"> Terms of Service </Link>
                                {/* <a target='_blank' href="javascript:;" onClick={() => props.history.push("/page/terms_of_service")}>Terms of Service</a>, */}
                                ,<a target="_blank"
                                    href={"https://support.qwarkhealth.com/hc/en-us"}> Communication </a> and
                                <Link to={"/page/privacy_policy"} target="_blank"> Privacy Policy</Link>
                              </p>
                            </div>
                            {
                              errorState.isPolicyAccepted.length !== 0 && errorState.isPolicyAccepted[0] &&
                              <p className="errormsg">{errorState.isPolicyAccepted[0]}</p>
                            }

                            <div className="parentform-button">
                              <IonButton
                                className="theme-btn button-blue"
                                onClick={() => onSubmit()}><span>Continue</span>
                                {/*{commonData.loading && <IonSpinner />}*/}
                              </IonButton>

                            </div>
                            <div className="have-account-text">
                              <p>Have an account? <a href="javascript:;" onClick={() => props.history.push("/login")}>Sign
                                in</a></p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Welcome;
