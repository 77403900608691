import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import {
  notificationsOutline,
  ellipseOutline,
  checkmarkCircleOutline,
  chevronUpOutline,
  chevronDownOutline,
} from "ionicons/icons";
import React, { useRef, useState } from "react";
import "./accountSummary.scss";
import { menuController } from "@ionic/core";
import DashboardTab from "../../components/dashboardTab/dashboardTab";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../Redux/actions";
import { convertTimestampToMMDDYYYY, isEmpty } from "../../Utils/helper";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";

const AccountSummary = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [state, setState] = useState({
    activeOrders: [],
    pastOrders: [],
    activeViewMoreEnable: 0,
  });
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    prevOffset: 0,
  });
  const [spinnerState, setSpinnerState] = useState({
    activeOrders: true,
    pastOrders: true,
  });
  const [cancelOrderState, setCancelOrderState] = useState({
    openModal: false,
    orderNumber: "",
  });
  const deliveryOption = useSelector(
    (state) => state.userReducer.deliveryOption
  );
  const [disablePastViewOrders, setDisablePastViewOrders] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);


  React.useEffect(() => {
    menuController.enable(false);
    loadData();
  }, []);

  const getCartSubtotal = () => {
    let subtotal = 0;
    if (isEmpty(cartItems) || cartItems === "null") {
      return parseFloat(subtotal).toFixed(2);
    }
    for (let item of cartItems) {
      subtotal = subtotal + parseFloat(item.price);
    }
    return parseFloat(subtotal).toFixed(2);
  };

  const getItemsSubtotal = (items) => {
    let subtotal = 0;
    if (!items || items === "null" || isEmpty(items)) {
      return subtotal;
    }

    for (let item of items) {
      subtotal = subtotal + parseFloat(item.price_per_unit);
    }
    return parseFloat(subtotal).toFixed(2);
  };

  const loadData = async () => {
    loadActiveOrders(true);
    loadPastOrders(true);
  };

  const loadActiveOrders = async (firstLoad) => {
    setSpinnerState((prevState) => ({ ...prevState, activeOrders: true }));
    const response = await dispatch(productAction.getAccountSummary(authData.id, "active", firstLoad ? 0 : 2));

    if (response && response.success) {
      setState((prevState) => ({
        ...prevState,
        activeOrders: response.data,
        activeViewMoreEnable: firstLoad ? response.isViewMore : 0,
      }));
    }
    setSpinnerState((prevState) => ({ ...prevState, activeOrders: false }));
  };

  const toggleShowItem = async (index, category) => {
    if (!state[category][index].showItem) {
      fetchItemsForOrder(index, category);
    }
    let newState = state;
    newState[category][index].showItem = !state[category][index].showItem;
    setState({ ...newState });
  };

  const fetchItemsForOrder = async (index, category) => {
    const response = await dispatch(
      productAction.getItemsByOrderId(authData.id, state[category][index].id)
    );
    if (response && response.success) {
      let newState = state;
      newState[category][index].items = response.data;
      setState({ ...newState });
    }
  };

  const loadPastOrders = async (firstLoad) => {
    setSpinnerState((prevState) => ({ ...prevState, pastOrders: true }));
    let response;
    if (paginationState.offset !== paginationState.prevOffset || firstLoad) {
      response = await dispatch(productAction.getAccountSummary(authData.id, "past", firstLoad ? 0 : paginationState.offset));

      if (response && response.success) {
        if (response.data.length > 0) {
          setState((prevState) => ({
            ...prevState,
            pastOrders: firstLoad ? [...response.data] : [...prevState.pastOrders, ...response.data],
          }));

          if (firstLoad) {
            setPaginationState((prevState) => {
              return {
                ...prevState,
                prevOffset: prevState.offset,
                offset: prevState.offset + 2,
              };
            });
          } else {
            setState((prevState) => ({
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 5,
            }));
            setPaginationState((prevState) => {
              return {
                ...prevState,
                prevOffset: prevState.offset,
                offset: prevState.offset + 5,
              };
            });
          }
        } else {
          console.log("no more data, disable infinite scroll");
          setDisablePastViewOrders(true);
        }
      }
    }
    setSpinnerState((prevState) => ({ ...prevState, pastOrders: false }));
  };

  const onCancelOrder = async () => {
    setCancelOrderState((prevState) => ({ ...prevState, openModal: false }));
    const response = await dispatch(productAction.cancelOrder(authData.id, cancelOrderState.orderNumber));
    if (response && response.success) {
      await loadData();
    }
  };

  const applyDiscount = () => {
    let subtotal = getCartSubtotal();

    if (isEmpty(cartItems) || isEmpty(cartItems[0].discountPercent)) {
      return 0;
    }
    let discount = cartItems[0].discountPercent;
    discount = (subtotal * discount) / 100;
    return parseFloat(discount).toFixed(2);
  };

  const getCartTotal = () => {
    let subtotal = parseFloat(getCartSubtotal());
    if (!isEmpty(deliveryOption)) {
      subtotal = subtotal + parseFloat(deliveryOption.cost);
    }
    let discount = applyDiscount();
    subtotal = subtotal - discount;
    return subtotal.toFixed(2);
  };

  return (
    <div className="accountSummary-page">
      {/* notification-bar start */}
      {!isEmpty(cartItems) && cartItems != "null" && (
        <div className="notification-bar">
          <div className="space-container">
            <p>
              <span>
                <IonIcon icon={notificationsOutline} />
              </span>
              You have a new prescription. Please click{" "}
              <a onClick={() => props.history.push("/checkout_conditions")}>
                here
              </a>{" "}
              to start the checkout process.
            </p>
          </div>
        </div>
      )}

      {/* notification-bar start */}

      <div className="accountSummary-page-inner">
        <div className="space-container">
          <DashboardTab />

          <div className="accountSummary-cardparent">
            <div className="accountSummary-cardparent-heading">
              <h3>Cart</h3>
            </div>
            <div className="theme-card">
              {/* green-label */}
              <div className="card-body">
                <IonGrid>
                  <IonRow>
                    {cartItems && cartItems.length !== 0 ? (
                      cartItems.map((item, index) => (
                        <IonCol size="12" size-lg="6" key={index}>
                          {/* Medicines card start */}
                          <div className="order-detail-box bg-grey">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="order-item-detail">
                                      <h3>{item.medicineName} </h3>
                                      <p>
                                        {item.medicineForm} •{" "}
                                        {item.medicineStrength}
                                      </p>
                                      <p>Package Size: {item.activeQuantity}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item-price">
                                      <p>
                                        ${parseFloat(item.price).toFixed(2)}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* Medicines card end */}
                        </IonCol>
                      ))
                    ) : (
                      <div className="empty-text">
                        <p>Your cart is empty!</p>
                      </div>
                    )}
                  </IonRow>

                  {cartItems && cartItems.length !== 0 && (
                    <IonRow>
                      <IonCol size="12" size-lg="6" offset-lg="6">
                        {/* Subtotal start */}
                        <div className="order-detail-box">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="order-item-detail">
                                    <h3>Subtotal</h3>
                                  </div>
                                </td>
                                <td>
                                  <div className="order-item-price">
                                    <p>${getCartSubtotal()}</p>
                                  </div>
                                </td>
                              </tr>
                              {!isEmpty(cartItems) &&
                                !isEmpty(cartItems[0].discountPercent) && (
                                  <tr>
                                    <td>
                                      <div className="order-item-detail">
                                        <h3>Discount</h3>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="order-item-price">
                                        <p>-${applyDiscount()}</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td>
                                  <div className="order-item-detail">
                                    <h3>USPS First Class Shipping</h3>
                                    <p className="small-text">
                                      You can choose expedited shipping during
                                      checkout
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="order-item-price">
                                    <p>$0.00</p>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="order-item-detail">
                                    <h3 className="text-blue">Order total</h3>
                                  </div>
                                </td>
                                <td>
                                  <div className="order-item-price">
                                    <p className="text-blue">
                                      ${getCartTotal()}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="order-detail-btn">
                            <IonButton
                              className="theme-btn button-blue"
                              disabled={isEmpty(cartItems)}
                              onClick={() =>
                                setReminderModal(true)
                              }
                            >
                              Proceed to checkout
                            </IonButton>
                          </div>
                        </div>
                        {/* Subtotal end */}
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </div>
            </div>
          </div>

          {/* Active Orders Box start */}
          <div className="accountSummary-cardparent">
            <div className="accountSummary-cardparent-heading">
              <h3>Active Orders</h3>
            </div>

            <div>
              {commonData.loading && spinnerState.activeOrders ? (
                <IonSpinner />
              ) : state.activeOrders && state.activeOrders.length !== 0 ? (
                state.activeOrders.map((order, index) => (
                  <div className="theme-card" key={index}>
                    <div className="card-header">
                      <div className="carddrop-icon">
                        <a
                          onClick={() => toggleShowItem(index, "activeOrders")}
                        >
                          <IonIcon
                            icon={
                              order.showItem
                                ? chevronUpOutline
                                : chevronDownOutline
                            }
                          />
                        </a>
                      </div>
                      <div className="order-top">
                        <div className="order-number">
                          <h3>
                            Order No.{order.order_number}{" "}
                            <span>
                              <IonIcon className="blue" icon={ellipseOutline} />{" "}
                              {order.status}
                            </span>
                          </h3>
                        </div>
                        <div className="tracking-number">
                          <p>
                            Tracking Number:{" "}
                            <span>
                              {
                                isEmpty(order.tracking_id)
                                  ? "-"
                                  :
                                  !isEmpty(order.tracking_url)
                                    ?
                                    <a href={order.tracking_url} target="_blank">
                                      {order.tracking_id}
                                    </a>
                                    : order.tracking_id
                              }
                            </span>
                          </p>
                        </div>
                        <div className="order-date">
                          <p>
                            {convertTimestampToMMDDYYYY(order.created_at)} - $
                            {parseFloat(order.total).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="cancel-order-btn">
                        <IonButton
                          className="theme-btn button-grey"
                          disabled={order.status === "shipped"}
                          onClick={() =>
                            setCancelOrderState({
                              openModal: true,
                              orderNumber: order.order_number,
                            })
                          }
                        >
                          <span>Cancel Order</span>
                        </IonButton>
                      </div>
                    </div>
                    {commonData.loading &&
                      isEmpty(order.items) &&
                      order.showItem ? (
                      <div className="card-body pt-0">
                        <IonSpinner />
                      </div>
                    ) : (
                      order.showItem && (
                        <div className="card-body pt-0">
                          <IonGrid>
                            <IonRow>
                              {order.items.map((item, itemIndex) => (
                                <IonCol size="12" size-lg="6">
                                  {/* Medicines card start */}
                                  <div
                                    className="order-detail-box bg-grey"
                                    key={itemIndex}
                                  >
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="order-item-detail">
                                              <h3>{item.name} </h3>
                                              <p>
                                                {item.form} • {item.dosage} mg
                                              </p>
                                              <p>
                                                Package Size: {item.quantity}
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="order-item-price">
                                              <p>
                                                $
                                                {parseFloat(
                                                item.price_per_unit
                                              ).toFixed(2)}
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  {/* Medicines card end */}
                                </IonCol>
                              ))}
                            </IonRow>

                            <IonRow>
                              <IonCol size="12" size-lg="6" offset-lg="6">
                                {/* Subtotal start */}
                                <div className="order-detail-box">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3>Subtotal</h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p>
                                              ${getItemsSubtotal(order.items)}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3>Discount</h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p>${order.discount}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3>
                                              {!isEmpty(order.delivery_plan)
                                                ? order.delivery_plan
                                                : "Usps First Class"}
                                            </h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p>
                                              $
                                              {!isEmpty(order.shipping_amount)
                                                ? order.shipping_amount
                                                : 0.0}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3 className="text-blue">
                                              Order total
                                            </h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p className="text-blue">
                                              ${order.total}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* Subtotal end */}
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      )
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <p>No active orders found!</p>
                </div>
              )}
            </div>

            {
              state.activeViewMoreEnable !== 0 && <div className="accountSummary-cardparent-bottom">
                <IonButton
                  expand="block"
                  className="theme-btn button-white"
                  // disabled={state.activeViewMoreEnable === 0}
                  onClick={() => loadActiveOrders(false)}
                >
                  <span>View More</span>
                </IonButton>
              </div>
            }
          </div>
          {/* Active Orders Box end */}

          {/* Past Orders box start */}
          <div className="accountSummary-cardparent">
            <div className="accountSummary-cardparent-heading">
              <h3>Past Orders</h3>
            </div>
            <div>
              {commonData.loading && spinnerState.pastOrders ? (
                <IonSpinner />
              ) : state.pastOrders && state.pastOrders.length !== 0 ? (
                state.pastOrders.map((order, index) => (
                  <div className="theme-card" key={index}>
                    <div className="card-header">
                      <div className="carddrop-icon">
                        <a onClick={() => toggleShowItem(index, "pastOrders")}>
                          <IonIcon
                            icon={
                              order.showItem
                                ? chevronUpOutline
                                : chevronDownOutline
                            }
                          />
                        </a>
                      </div>
                      <div className="order-top">
                        <div className="order-number">
                          <h3>
                            Order No.{order.order_number}{" "}
                            <span>
                              <IonIcon
                                className="green"
                                icon={checkmarkCircleOutline}
                              />{" "}
                              {order.status}
                            </span>
                          </h3>
                        </div>
                        <div className="tracking-number">
                          <p>
                            Tracking Number:{" "}
                            <span>
                              {
                                isEmpty(order.tracking_id)
                                  ? "-"
                                  :
                                  !isEmpty(order.tracking_url)
                                    ?
                                    <a href={order.tracking_url} target="_blank">
                                      {order.tracking_id}
                                    </a>
                                    : order.tracking_id
                              }
                            </span>
                          </p>
                        </div>
                        <div className="order-date">
                          <p>
                            {convertTimestampToMMDDYYYY(order.created_at)} - $
                            {parseFloat(order.total).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      {commonData.loading &&
                        isEmpty(order.items) &&
                        order.showItem ? (
                        <IonSpinner />
                      ) : (
                        order.showItem && (
                          <IonGrid>
                            <IonRow>
                              {order.items.map((item, itemIndex) => (
                                <IonCol size="12" size-lg="6">
                                  {/* Medicines card start */}
                                  <div
                                    className="order-detail-box bg-grey"
                                    key={itemIndex}
                                  >
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="order-item-detail">
                                              <h3>{item.name} </h3>
                                              <p>
                                                {item.form} • {item.dosage} mg
                                              </p>
                                              <p>
                                                Package Size: {item.quantity}
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="order-item-price">
                                              <p>
                                                $
                                                {parseFloat(
                                                item.price_per_unit
                                              ).toFixed(2)}
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  {/* Medicines card end */}
                                </IonCol>
                              ))}
                            </IonRow>

                            <IonRow>
                              <IonCol size="12" size-lg="6" offset-lg="6">
                                {/* Subtotal start */}
                                <div className="order-detail-box">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3>Subtotal</h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p>
                                              ${getItemsSubtotal(order.items)}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3>
                                              {!isEmpty(order.delivery_plan)
                                                ? order.delivery_plan
                                                : "Usps First Class"}
                                            </h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p>
                                              $
                                              {!isEmpty(order.shipping_amount)
                                                ? order.shipping_amount
                                                : 0.0}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="order-item-detail">
                                            <h3 className="text-blue">
                                              Order total
                                            </h3>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item-price">
                                            <p className="text-blue">
                                              ${order.total}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* Subtotal end */}
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        )
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <p>No active orders found!</p>
                </div>
              )}
            </div>

            <div className="accountSummary-cardparent-bottom">
              <IonButton
                className="theme-btn button-white"
                expand="block"
                disabled={disablePastViewOrders}
                onClick={() => loadPastOrders(false)}
              >
                <span>View More</span>
              </IonButton>
            </div>

            {/* <IonInfiniteScroll
              ref={ionInfiniteScrollRef}
              threshold="15%"
              position="bottom"
              onIonInfinite={() => {
                if (ionInfiniteScrollRef.current) {
                  ionInfiniteScrollRef.current.disabled = true;
                }
                loadPastOrders(false);
              }}
            >
              {paginationState.offset !== paginationState.prevOffset && (
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading data..."
                ></IonInfiniteScrollContent>
              )}
            </IonInfiniteScroll> */}
          </div>
          {/* Past Orders box end */}
        </div>
      </div>

      {/* modal cancel order */}
      <IonModal
        isOpen={cancelOrderState.openModal}
        cssClass="theme-modal delete-medicine-modal accountSummary-delete-modal"
      >
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>Are you sure you want to cancel?</h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-blue"
              onClick={() =>
                setCancelOrderState({ openModal: false, orderNumber: "" })
              }
            >
              <span>No, don’t cancel</span>
            </IonButton>
            <IonButton
              className="theme-btn button-grey"
              onClick={() => onCancelOrder()}
            >
              <span>Yes, cancel</span>
            </IonButton>
          </div>
        </div>
      </IonModal>
      {/* modal cancel order */}


      {/* modal reminder start */}
      <IonModal
        isOpen={reminderModal}
        cssClass="theme-modal quick-reminder-modal"
      >
        <div className="modal-body">
          <div className="delete-medicine-content">
            <h3>
              Quick reminder, Qwark delivers to only California, Arizona, and
              Massachusetts at this time. We are coming to more states very
              soon!
            </h3>
          </div>
          <div className="delete-medicine-btns">
            <IonButton
              className="theme-btn button-grey"
              onClick={() => setReminderModal(false)}
            >
              <span>Go Back</span>
            </IonButton>
            <IonButton className="theme-btn button-blue" onClick={() => props.history.push("/checkout_conditions")}>
              <span>Continue</span>
            </IonButton>
          </div>
        </div>
      </IonModal>

    </div>
  );
};

export default AccountSummary;
