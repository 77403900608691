import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPageContent = (pageSlug) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/page/${pageSlug}`
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('error while getting page content', error);
        dispatch(handleError(error));
    }
}