import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonCheckbox,
  IonSpinner,
} from "@ionic/react";
import {
  notificationsOutline,
  addOutline,
  searchOutline,
  trashOutline
} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import "./healthNetwork.scss";
import { menuController } from "@ionic/core";
import DashboardTab from "../../components/dashboardTab/dashboardTab";
import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "../../Redux/actions";
import index from "../../Redux/reducers";
import {
  handlePharmacySearchResponse,
  handlePrescriberSearchResponse, isEmpty,
  maskPhoneNumber,
} from "../../Utils/helper";
import * as registrationAction from "../../Redux/actions/registrationAction";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const HealthNetwork = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const cartItems = useSelector((state) => state.userReducer.cartItems);
  const [state, setState] = useState({
    prescriber: [],
    pharmacy: [],
  });
  const [spinnerState, setSpinnerState] = useState("");
  const [searchResultState, setSearchResultState] = useState({
    prescriber: [],
    pharmacy: [],
  });
  let enableSearch = true;
  const [errorState, setErrorState] = useState({
    prescriber: [],
    pharmacy: [],
  });
  const [editState, setEditState] = useState({
    prescriber: [],
    pharmacy: [],
  });

  React.useEffect(() => {
    menuController.enable(false);
    loadPrescriberData();
  }, []);

  const loadPrescriberData = async () => {
    const response = await dispatch(
      profileActions.getPrescriberAndPharmacyData(authData.id)
    );
    if (response && response.data) {
      setState(response.data);
      setErrorState((prevState) => ({
        ...prevState,
        pharmacy: response.data.pharmacy.map((p) => []),
        prescriber: response.data.prescriber.map((p) => []),
      }));
    }
  };

  const addItem = (name) => {
    let newState = state;
    let newEditState = editState
    if (name === "prescriber") {
      const prescriberObject = {
        prescriber_name: "",
        phone: "",
        fax: "",
        isEdit: true,
      }
      newState.prescriber.unshift({...prescriberObject});
      newEditState.prescriber.unshift({...prescriberObject});

    } else {
      const newPharmacyObject = {
        pharmacy_name: "",
        address: "",
        phone: "",
        npi: "",
        fax: "",
        isEdit: true,
      }
      newState.pharmacy.unshift({...newPharmacyObject});
      newEditState.pharmacy.unshift({...newPharmacyObject});
    }
    setState({ ...newState });
    setEditState({ ...newEditState });
    let newErrorState = errorState;
    if (name === "prescriber") {
      newErrorState.prescriber.push([]);
    } else {
      newErrorState.pharmacy.push([]);
    }
    setErrorState({ ...newErrorState });
  };

  const setEditable = (name, index) => {
    let newState = JSON.parse(JSON.stringify({pharmacy :state.pharmacy, prescriber : state.prescriber}));
    newState[name][index].isEdit = true;

    setEditState((prevState => ({...prevState,[name] : [...newState[name].map((data, i)=>(i===index ? {...data} : {} ))]})))
    newState[name][index]['isEdit1'] = true;
    setState({ ...newState });
  };

  useEffect(()=>{ console.log('editState  ', editState, state)},[editState])
    useEffect(()=>{console.log('state  ', state)},[state])


  const handleChange = (event, name, index, category) => {
    let { value } = event.detail;
    clearError(name, index, category);
    if(isEmpty(editState[category][index][name]) && isEmpty(value)){
      return null
    }
    if (name === "prescriber_name" || name === "pharmacy_name") {
      if (value === editState[category][index][name]) {
        enableSearch = false;
      } else {
        enableSearch = true;
      }
    }
    if ((name === "phone" || name === "fax") && value) {
      // let numValue = parseInt(value.replace(/[^0-9\.]/g, ""), 10);
      let numValue = value.replace(/\D/g, "")
      if (isNaN(numValue)) {
        let newErrorState = errorState;
        newErrorState[category][index] = [
          `${name} number can only contain numbers !`,
        ];
        setErrorState({ ...newErrorState });
      } else {
        value = numValue;
      }
    }
    let newState = editState;
    newState[category][index][name] = value;
    setEditState({ ...newState });
    /*if (name === "prescriber_name" || name === "pharmacy_name") {
      if (value && value.length !== 0) {
        setSpinnerState(name);
        searchName(category, index);
      } else {
        setSearchResultState((prevState) => ({ ...prevState, [category]: [] }));
      }
    }*/
  };

  const clearError = (name, index, category) => {
    dispatch({ type: "CLEAN_ERROR" });
    let newErrorState = errorState;
    newErrorState[category][index] = [];
    setErrorState({ ...newErrorState });
  };

  const searchName = async (name, index) => {
    if (!enableSearch) {
      return null;
    }
    let searchResult = [];
    if (name === "prescriber") {
      if (!editState.prescriber[index].prescriber_name) {
        return null;
      } else {
        const response = await dispatch(
          registrationAction.searchPrescriber(
              authData.id,
              editState.prescriber[index].prescriber_name,
            { first_name: editState.prescriber[index].prescriber_name, city: "" }
          )
        );
        searchResult = await handlePrescriberSearchResponse(response);
      }
    } else if (name === "pharmacy") {
      if (!editState.pharmacy[index].pharmacy_name) {
        return null;
      } else {
        const response = await dispatch(
          profileActions.searchPharmacy(
            authData.id,
              editState.pharmacy[index].pharmacy_name,
            { pharmacyName: editState.pharmacy[index].pharmacy_name, zip: "" }
          )
        );
        searchResult = await handlePharmacySearchResponse(response);
      }
    }
    setSearchResultState((prevState) => ({
      ...prevState,
      [name]: searchResult,
    }));
  };

  const onSelectFromResult = (object, type, index) => {
    let newState = editState;
    newState[type][index] = object;
    if (type === "prescriber") {
      newState[type][index].prescriber_name = object.name;
    } else {
      newState[type][index].pharmacy_name = object.name;
    }
    newState[type][index].isEdit = true;
    setEditState({ ...newState });
    setSearchResultState((prevState) => ({ ...prevState, [type]: [] }));
  };

  const saveItem = async (object, type, index) => {
    setSpinnerState(`save${type}${index}`);
    if (!(await validateForm(type, index))) {
      setSpinnerState("");
      return null;
    }
    let response = {};
    if (type === "prescriber") {
      response = await dispatch(profileActions.savePrescriber(authData.id, object));
    } else if (type === "pharmacy") {
      response = await dispatch(profileActions.savePharmacy(authData.id, object));
    }
    if (response && response.success) {
      // await loadPrescriberData();
      let newEditState = editState
      newEditState[type][index].isEdit = false
      if(response.data && !isEmpty(response.data) && response.data !==0){
        newEditState[type][index].id = response.data
      }
      setEditState({...newEditState})

      let newState = state
      newState[type][index] = {...newEditState[type][index]}
      setState({...newState})
    }
    setSpinnerState("");
  };

  const cancelItem = async (object, type, index) => {
    setSpinnerState(`cancel${type}${index}`);
    // await loadPrescriberData();
    let newEditState = editState
    let newState = state
    if(object.id){
      newEditState[type][index] = {...state[type][index], isEdit: false}
      newState[type][index].isEdit = false
    }else{
      newEditState[type].splice(index, 1)
      newState[type].splice(index, 1)
    }
    setEditState({...newEditState})
    setState({...newState})
    setSpinnerState("");
  };

  const deleteItem = async (type, id, arrayIndex) => {
    let response = {};
    if (type === "prescriber") {
      response = await dispatch(profileActions.deletePrescriber(authData.id, id));
    } else if (type === "pharmacy") {
      response = await dispatch(profileActions.deletePharmacy(authData.id, id));
    }
    if (response && response.success) {
      let newState = state
      newState[type].splice(arrayIndex, 1)
      setState({
        ...newState
      })
    }
  }

  const validateForm = (type, index) => {
    let isFormValid = true;
    let testError = [];
    if (type === "prescriber") {
      if (
        editState.prescriber[index].prescriber_name.toString().trim().length === 0
      ) {
        isFormValid = false;
        testError.push(["Prescriber Name cannot be empty!"]);
      }
    } else {
      if (editState.pharmacy[index].pharmacy_name.toString().trim().length === 0) {
        isFormValid = false;
        testError.push(["Pharmacy Name cannot be empty!"]);
      }
    }
    let newErrorState = errorState;
    newErrorState[type][index] = testError;
    setErrorState({ ...newErrorState });
    return isFormValid;
  };

  const setAsDefault = async (name, index) => {
    setSpinnerState(`default${name}${index}`);
    setState((prevState) => ({
      ...prevState,
      [name]: prevState[name].map((p, pIndex) => ({
        ...p,
        is_default: pIndex === index ? 1 : 0,
      })),
    }));
    if (name === "prescriber") {
      await dispatch(profileActions.savePrescriber(authData.id, {...state.prescriber[index], is_default: 1,}));
    } else {
      await dispatch(profileActions.savePharmacy(authData.id, {...state.pharmacy[index], is_default: 1,}));
    }

    setSpinnerState("");
  };

  return (
    <div className="healthNetwork-page">
      {/* notification-bar start */}
      {!isEmpty(cartItems) && cartItems != "null" &&     
      <div className="notification-bar">
        <div className="space-container">
          <p>
            <span>
              <IonIcon icon={notificationsOutline} />
            </span>
            You have a new prescription. Please click{" "}
            <a href="javascript:;" onClick={()=>props.history.push("/checkout_conditions")}>here</a> to start the checkout process.
          </p>
        </div>
      </div>
    }
      {/* notification-bar end */}

      <div className="profileAndSetting-page-inner">
        <div className="space-container">
          {/* DashboardTab */}
          <DashboardTab />
          {/* DashboardTab */}

          {/* theme-card start */}
          <div className="parent-box">
            <div className="parent-box-heading">
              <h3>
                Prescriber Profile{" "}
                <a href="javascript:;" onClick={() => addItem("prescriber")}>
                  <IonIcon icon={addOutline} />
                </a>
              </h3>
            </div>
            {commonData.loading && spinnerState === "" ? (
              <IonSpinner />
            ) : state.prescriber && state.prescriber.length === 0 ? (
              <div>
                <p className="profile-detail-pera">No Prescriber Added</p>
              </div>
            ) : (
              state.prescriber.map((prescriber, index) => {
                if (prescriber.isEdit) {
                  prescriber = editState.prescriber[index]
                  return (
                    <div className="theme-card" key={index}>
                      <div className="card-body">
                        {/* profile-edit-form */}
                        <div className="profile-edit-form">
                          <IonGrid className="p-0">
                            <IonRow>
                              <IonCol className="p-0" size="12" size-md="6">
                                <IonGrid className="p-0">
                                  <IonRow className="p-0">
                                    <IonCol size="12" size-md="12">
                                      <div className="form-group search-drop">
                                        <IonLabel>Name</IonLabel>
                                        {/*<div className="left-icon-input">*/}
                                         {/* <a href="javascript:;">
                                            {commonData.loading && spinnerState === "prescriber_name" ? (<IonSpinner />) : (
                                              <IonIcon icon={searchOutline} />)}
                                          </a>*/}
                                          <IonInput
                                            className="form-control"
                                            type="text"
                                            value={editState.prescriber[index].prescriber_name}
                                            onIonChange={(e) => handleChange(e, "prescriber_name", index, "prescriber")}
                                          />
                                        {/*</div>*/}
                                        {searchResultState.prescriber &&
                                          searchResultState.prescriber
                                            .length !== 0 &&
                                        editState.prescriber[index].prescriber_name.length !==
                                            0 && (
                                            <div className="search-drop-list">
                                              {searchResultState.prescriber.map(
                                                (prescriber, indexP) => (
                                                  <a
                                                    href="javascript:;"
                                                    key={indexP}
                                                    onClick={() =>
                                                      onSelectFromResult(
                                                        prescriber,
                                                        "prescriber",
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {prescriber.name}
                                                  </a>
                                                )
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </IonCol>

                                    <IonCol size="12" size-md="6">
                                      <div className="form-group">
                                        <IonLabel>Phone</IonLabel>
                                        <IonInput
                                          className="form-control"
                                          type="text"
                                          value={editState.prescriber[index].phone && maskPhoneNumber(editState.prescriber[index].phone.toString())}
                                          onIonChange={(e) =>
                                            handleChange(
                                              e,
                                              "phone",
                                              index,
                                              "prescriber"
                                            )
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                    <IonCol size="12" size-md="6">
                                      <div className="form-group">
                                        <IonLabel>FAX</IonLabel>
                                        <IonInput
                                          className="form-control"
                                          type="text"
                                          value={
                                            editState.prescriber[index].fax &&
                                            maskPhoneNumber(editState.prescriber[index].fax.toString())
                                          }
                                          onIonChange={(e) =>
                                            handleChange(
                                              e,
                                              "fax",
                                              index,
                                              "prescriber"
                                            )
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                                {errorState.prescriber[index][0] &&
                                  errorState.prescriber[index][0].length !==
                                    0 && (
                                    <p className="errormsg">
                                      {errorState.prescriber[index][0]}
                                    </p>
                                  )}

                                <div className="profileedit-btn-inline">
                                  <IonButton
                                    className="theme-btn button-blue"
                                    disabled={spinnerState ===`saveprescriber${index}`}
                                    onClick={() =>
                                      saveItem(editState.prescriber[index], "prescriber", index)
                                    }
                                  >
                                    <span>Save</span>
                                    {/*{commonData.loading &&
                                      spinnerState ===`saveprescriber${index}` && (
                                        <IonSpinner />
                                      )}*/}
                                  </IonButton>
                                  <IonButton
                                    className="theme-btn button-grey"
                                    onClick={() =>
                                      cancelItem(
                                        prescriber,
                                        "prescriber",
                                        index
                                      )
                                    }
                                  >
                                    <span>Cancel</span>
                                    {/*{commonData.loading &&
                                      spinnerState ===
                                        `cancelprescriber${index}` && (
                                        <IonSpinner />
                                      )}*/}
                                  </IonButton>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="theme-card" key={index}>
                      <div className="card-body">
                        <IonGrid className="p-0">
                          <IonRow>
                            <IonCol className="p-0 flex-items-center" size="2" size-md="1">
                              <a
                                className="item-delete-icon"
                                onClick={() => deleteItem('prescriber', prescriber.id, index)}
                              >
                                <IonIcon icon={trashOutline} />
                              </a>
                            </IonCol>
                            <IonCol className="p-0" size="10" size-md="6">
                              <div className="profile-detail">
                                <h3>
                                  {prescriber.prescriber_name}
                                  {prescriber.is_default === 1 && (
                                    <span>DEFAULT</span>
                                  )}
                                </h3>
                              </div>
                              <IonGrid className="p-0">
                                <IonRow className="p-0">
                                  <IonCol className="p-0" size="12" size-lg="6">
                                    <div className="form-group">
                                      <IonLabel>Phone</IonLabel>
                                      <p>
                                        {prescriber.phone
                                          ? maskPhoneNumber(
                                              prescriber.phone.toString()
                                            )
                                          : "---"}
                                      </p>
                                      {/* <IonInput className="form-control" type="text"></IonInput> */}
                                    </div>
                                  </IonCol>
                                  <IonCol className="p-0" size="12" size-lg="6">
                                    <div className="form-group">
                                      <IonLabel>FAX</IonLabel>
                                      <p>
                                        {prescriber.fax
                                          ? maskPhoneNumber(
                                              prescriber.fax.toString()
                                            )
                                          : "---"}
                                      </p>
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCol>
                            <IonCol className="p-0" size="12" size-md="5">
                              <div className="profile-edit-btn">
                                <IonButton
                                  className="theme-btn button-grey"
                                  onClick={() =>
                                    setEditable("prescriber", index)
                                  }
                                >
                                  <span>Edit</span>
                                </IonButton>
                                <IonButton
                                  className="theme-btn button-grey"
                                  disabled={prescriber.is_default}
                                  onClick={() =>
                                    setAsDefault("prescriber", index)
                                  }
                                >
                                  <span>Set as Default</span>
                                  {/*{commonData.loading &&
                                    spinnerState ===
                                      `defaultprescriber${index}` && (
                                      <IonSpinner />
                                    )}*/}
                                </IonButton>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </div>
                  );
                }
              })
            )}
          </div>
          {/* theme-card end */}

          {/* theme-card start */}
          <div className="parent-box">
            <div className="parent-box-heading">
              <h3>
                Past pharmacy{" "}
                <a href="javascript:;" onClick={() => addItem("pharmacy")}>
                  <IonIcon icon={addOutline} />
                </a>
              </h3>
            </div>
            {commonData.loading && spinnerState === "" ? (
              <IonSpinner />
            ) : state.pharmacy && state.pharmacy.length === 0 ? (
              <div>
                <p className="profile-detail-pera">No Pharmacy Added</p>
              </div>
            ) : (
              state.pharmacy.map((pharmacy, index) => {
                if (pharmacy.isEdit) {
                  return (
                    <div className="theme-card" key={index}>
                      <div className="card-body">
                        <div className="profile-edit-form">
                          <IonGrid className="p-0">
                            <IonRow>
                              <IonCol className="p-0" size="12" size-md="6">
                                <IonGrid className="p-0">
                                  <IonRow className="p-0">
                                    <IonCol size="12" size-md="12">
                                      <div className="form-group search-drop">
                                        <IonLabel>Name</IonLabel>
                                        {/*<div className="left-icon-input">
                                          <a href="javascript:;">
                                            {commonData.loading &&
                                            spinnerState === "pharmacy_name" ? (
                                              <IonSpinner />
                                            ) : (
                                              <IonIcon icon={searchOutline} />
                                            )}
                                          </a>*/}
                                          <IonInput
                                            className="form-control"
                                            type="text"
                                            value={editState.pharmacy[index].pharmacy_name}
                                            onIonChange={(e) => handleChange(e, "pharmacy_name", index, "pharmacy")}
                                          />
                                        {/*</div>*/}
                                        {searchResultState.pharmacy.length !== 0 && editState.pharmacy[index].pharmacy_name.length !== 0 && (
                                            <div className="search-drop-list">
                                              {searchResultState.pharmacy.map(
                                                (pharmacy, indexSearch) => (
                                                  <a
                                                    href="javascript:;"
                                                    key={indexSearch}
                                                    onClick={() =>
                                                      onSelectFromResult(
                                                        pharmacy,
                                                        "pharmacy",
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {pharmacy.name}
                                                  </a>
                                                )
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </IonCol>

                                    <IonCol size="12" size-md="12">
                                      <div className="form-group">
                                        <IonLabel>Address1</IonLabel>
                                        <GooglePlacesAutocomplete
                                          placeholder='Search'
                                          autocompletionRequest={{
                                            componentRestrictions: {
                                              country: ['us'],
                                            }
                                          }}
                                          selectProps={{
                                            onChange: (selectedPlace) => {
                                              handleChange(
                                                { detail: { value: selectedPlace.label } },
                                                "address",
                                                index,
                                                "pharmacy"
                                              )
                                            },
                                            placeholder:'Search Pharmacy',
                                            styles: {
                                              input: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                              }),
                                              option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                                zIndex:1,
                                              }),
                                              singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                              }),
                                              container: (provided) => ({
                                                ...provided,
                                                zIndex:1051,
                                              }),
                                              control: (provided) => ({
                                                ...provided,
                                                borderColor: 'rgba(139,139,169,.3)',
                                              }),
                                              dropdownIndicator: (provided) => ({
                                                ...provided,
                                                opacity:'0%',
                                              }),
                                              placeholder: (provided) => ({
                                                ...provided,
                                                color: '#c0c1d4',
                                                fontFamily: 'gothamlight',
                                                fontSize: 13,
                                              }),
                                              indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: 'none'
                                              }),
                                            },
                                          }}
                                          apiKey="AIzaSyBn6w23qRD7HXgmen3WC-nOT4HSdrbzvq"
                                        />
                                      </div>
                                    </IonCol>
                                    <IonCol size="12" size-md="6">
                                      <div className="form-group">
                                        <IonLabel>NPI</IonLabel>
                                        <IonInput
                                          className="form-control"
                                          type="number"
                                          value={editState.pharmacy[index].npi}
                                          onIonChange={(e) =>
                                            handleChange(
                                              e,
                                              "npi",
                                              index,
                                              "pharmacy"
                                            )
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                    <IonCol size="12" size-md="6">
                                      <div className="form-group">
                                        <IonLabel>Phone</IonLabel>
                                        <IonInput
                                          className="form-control"
                                          type="text"
                                          value={
                                            editState.pharmacy[index].phone &&
                                            maskPhoneNumber(
                                                editState.pharmacy[index].phone.toString()
                                            )
                                          }
                                          onIonChange={(e) =>
                                            handleChange(
                                              e,
                                              "phone",
                                              index,
                                              "pharmacy"
                                            )
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                    <IonCol size="12" size-md="6">
                                      <div className="form-group">
                                        <IonLabel>FAX</IonLabel>
                                        <IonInput
                                          className="form-control"
                                          type="text"
                                          value={
                                            editState.pharmacy[index].fax &&
                                            maskPhoneNumber(
                                                editState.pharmacy[index].fax.toString()
                                            )
                                          }
                                          onIonChange={(e) =>
                                            handleChange(
                                              e,
                                              "fax",
                                              index,
                                              "pharmacy"
                                            )
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                                {errorState.pharmacy[index][0] &&
                                  errorState.pharmacy[index][0].length !==
                                    0 && (
                                    <p className="errormsg">
                                      {errorState.pharmacy[index][0]}
                                    </p>
                                  )}

                                <div className="profileedit-btn-inline">
                                  <IonButton
                                    className="theme-btn button-blue"
                                    disabled={spinnerState ===`savepharmacy${index}`}
                                    onClick={() =>
                                      saveItem(editState.pharmacy[index], "pharmacy", index)
                                    }
                                  >
                                    <span>Save</span>
                                    {/*{commonData.loading &&
                                      spinnerState ===
                                        `savepharmacy${index}` && (
                                        <IonSpinner />
                                      )}*/}
                                  </IonButton>
                                  <IonButton
                                    className="theme-btn button-grey"
                                    onClick={() =>
                                      cancelItem(pharmacy, "pharmacy", index)
                                    }
                                  >
                                    <span>Cancel</span>
                                    {/*{commonData.loading &&
                                      spinnerState ===
                                        `cancelpharmacy${index}` && (
                                        <IonSpinner />
                                      )}*/}
                                  </IonButton>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="theme-card" key={index}>
                      <div className="card-body">
                        <IonGrid className="p-0">
                          <IonRow>
                            <IonCol className="p-0 flex-items-center" size="2" size-md="1">
                              <a
                                className="item-delete-icon"
                                onClick={() => deleteItem('pharmacy', pharmacy.id, index)}
                              >
                                <IonIcon icon={trashOutline} />
                              </a>
                            </IonCol>
                            <IonCol className="p-0" size="10" size-md="5">
                              <div className="profile-detail">
                                <h3>
                                  {pharmacy.pharmacy_name}
                                  {pharmacy.is_default === 1 && (
                                    <span>DEFAULT</span>
                                  )}
                                </h3>
                              </div>
                              <IonGrid className="p-0">
                                <IonRow className="p-0">
                                  <IonCol className="p-0" size="12" size-md="6">
                                    <div className="form-group">
                                      <IonLabel>Address</IonLabel>
                                      <p>
                                        {pharmacy.address.length !== 0
                                          ? pharmacy.address
                                          : "---"}
                                      </p>
                                    </div>
                                  </IonCol>
                                  <IonCol className="p-0" size="12" size-md="6">
                                    <div className="form-group">
                                      <IonLabel>NPI</IonLabel>
                                      <p>
                                        {pharmacy.npi ? pharmacy.npi : "---"}
                                      </p>
                                    </div>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="p-0">
                                  <IonCol className="p-0" size="12" size-md="6">
                                    <div className="form-group">
                                      <IonLabel>Phone</IonLabel>
                                      <p>
                                        {pharmacy.phone
                                          ? maskPhoneNumber(
                                              pharmacy.phone.toString()
                                            )
                                          : "---"}
                                      </p>
                                    </div>
                                  </IonCol>
                                  <IonCol className="p-0" size="12" size-md="6">
                                    <div className="form-group">
                                      <IonLabel>FAX</IonLabel>
                                      <p>
                                        {pharmacy.fax
                                          ? maskPhoneNumber(
                                              pharmacy.fax.toString()
                                            )
                                          : "---"}
                                      </p>
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCol>
                            <IonCol className="p-0" size="12" size-md="6">
                              <div className="profile-edit-btn">
                                <IonButton
                                  className="theme-btn button-grey"                                  
                                  onClick={() => setEditable("pharmacy", index)}
                                >
                                  <span>Edit</span>
                                </IonButton>
                                <IonButton
                                  className="theme-btn button-grey"
                                  disabled={pharmacy.is_default}
                                  onClick={() =>
                                    setAsDefault("pharmacy", index)
                                  }
                                >
                                  <span>Set as Default</span>
                                  {/*{commonData.loading &&
                                    spinnerState ===
                                      `defaultpharmacy${index}` && (
                                      <IonSpinner />
                                    )}*/}
                                </IonButton>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </div>
                  );
                }
              })
            )}
          </div>
          {/* theme-card end */}
        </div>
      </div>
    </div>
  );
};

export default HealthNetwork;
