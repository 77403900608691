import {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonImg,
    IonLabel,
    IonInput,
    IonIcon,
    IonCheckbox,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonSpinner,
} from "@ionic/react";
import {eyeOutline} from "ionicons/icons";
import React, {useEffect, useRef, useState} from "react";
import "./checkoutDelivery.scss";
import {menuController} from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import Uspsimg from "../../images/usps-img.png";
import homeDelivery from "../../images/homeDelivery.png";
import sameDayHomeDelivery from "../../images/sameDayHomeDelivery.png";
import localPickupImg from "../../images/localpickup-img.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import {searchOutline} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import CheckoutSidebar from "../../components/checkoutSideBar/checkOutSideBar";
import MyCart from "../../components/myCart/myCart";
import {checkoutAction, profileActions} from "../../Redux/actions";
import * as Helper from "../../Utils/helper";
import {isEmpty} from "../../Utils/helper";

const CheckoutDelivery = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer.authData);
    const [shippingMethodState, setShippingMethodState] = useState({});
    const [shippingAddressState, setShippingAddresstate] = useState({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        id: "",
        type: "shipping",
    });
    const [predictions, setPredictions] = useState([]);
    const [enableGoogleSearch, setEnableGoogleSearch] = useState(true);
    const [errorState, setErrorState] = useState({
        firstName: [],
        lastName: [],
        addressLine1: [],
        addressLine2: [],
        city: [],
        state: [],
        zip: [],
    });
    const wrapperRef = useRef(null);
    const deliveryOption = useSelector((state) => state.userReducer.deliveryOption);
    const prescriptionRequired = useSelector((state) => state.userReducer.prescriptionRequired);

    //console.log(deliveryOption);
    const [deliveryState, setDeliveryState] = useState({
        id : isEmpty(deliveryOption) ? 1 : deliveryOption.id,
        name : isEmpty(deliveryOption) ? '' : deliveryOption.name,
        cost : isEmpty(deliveryOption) ? '' : deliveryOption.cost,
        })
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const [showNextDayDelivery, setShowNextDayDelivery] = useState(false)

    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPredictions([]);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    React.useEffect(() => {
        menuController.enable(false);
        loadData();
    }, []);

    const loadData = async () => {
        setEnableGoogleSearch(false);
        const response = await dispatch(checkoutAction.getDeliveryData(authData.id));
        if (response && response.data) {
            setShippingAddresstate(response.data.shippingAddress);
        }
        setTimeout(() => {
            setEnableGoogleSearch(true);
        }, 500);
    };

    const zipFieldChanged = async (zipCode) => {
        if (zipCode) {
            const response = await dispatch(checkoutAction.validateZipCode(zipCode))
            if(response && response.status === 200 && response.data && response.data.status === 'success') {
                setShowNextDayDelivery(true)
                return
            }
        }
        setShowNextDayDelivery(false)
        setDeliveryState({...deliveryState, id: 1})
    }

    const handleChange = (event, name) => {
        clearErrors(name);
        const {value} = event.detail;
        setShippingAddresstate((prevState) => ({...prevState, [name]: value}));
        if (name === "addressLine1" && enableGoogleSearch) {
            if (value && value.length) {
                handleSubmit(value);
            } else {
                setPredictions([]);
            }
        }
        if (name === 'zip') {
            zipFieldChanged(event.target.value)
        }
    };

    const clearErrors = (name) => {
        dispatch({type: "CLEAN_ERROR"});
        if (errorState[name].length !== 0) {
            setErrorState((prevState) => ({...prevState, [name]: []}));
        }
    };

    const handleSubmit = async (searchValue) => {
        const results = await googleAutocomplete(searchValue);
        if (results) {
            setPredictions(results);
        }
    };

    const googleAutocomplete = async (text) =>
        new Promise((resolve, reject) => {
            if (!text) {
                return reject("Need valid text input");
            }
            // for use in things like GatsbyJS where the html is generated first
            if (typeof window === "undefined") {
                return reject("Need valid window object");
            }
            try {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                    {input: text, componentRestrictions: {country: ["us"]}},
                    resolve
                );
            } catch (e) {
                reject(e);
            }
        });

    const onPlaceSelected = async (place) => {
        setEnableGoogleSearch(false);
        const response = await dispatch(
            profileActions.GooglePlacesDetail(authData.id, place.place_id)
        );
        if (!response || !response.result) {
            return;
        }
        let autoCompletedAddress = {
            firstName: shippingAddressState.firstName,
            lastName: shippingAddressState.lastName,
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
            id: shippingAddressState.id,
            type: "shipping",
        };
        const placesArray = response.result.address_components;
        autoCompletedAddress.addressLine1 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["street_number", "route", "premise"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.addressLine2 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["sublocality", "neighborhood"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.city =
            placesArray.filter((places) =>
                places.types.includes("locality")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("locality")
                )[0].long_name
                : "";
        autoCompletedAddress.state =
            placesArray.filter((places) =>
                places.types.includes("administrative_area_level_1")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("administrative_area_level_1")
                )[0].long_name
                : "";
        autoCompletedAddress.zip =
            placesArray.filter((places) => places.types.includes("postal_code"))
                .length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("postal_code")
                )[0].long_name
                : "";
        setShippingAddresstate(autoCompletedAddress);
        setPredictions([]);
        setTimeout(() => setEnableGoogleSearch(true), 5000);
    };

    const validateForm = () => {
        let isFormValid = true;
        let testError = {
            firstName: [],
            lastName: [],
            addressLine1: [],
            addressLine2: [],
            city: [],
            state: [],
            zip: [],
        };
        if (isEmpty(shippingAddressState.firstName)) {
            isFormValid = false;
            testError.firstName.push("This field is required!");
        }
        if (isEmpty(shippingAddressState.lastName)) {
            isFormValid = false;
            testError.lastName.push("This field is required!");
        }
        if (isEmpty(shippingAddressState.addressLine1)) {
            isFormValid = false;
            testError.addressLine1.push("This field is required!");
        }
        // if (isEmpty(shippingAddressState.addressLine2)) {
        //     isFormValid = false;
        //     testError.addressLine2.push("This field is required!");
        // }
        if (isEmpty(shippingAddressState.city)) {
            isFormValid = false;
            testError.city.push("This field is required!");
        }
        if (isEmpty(shippingAddressState.state)) {
            isFormValid = false;
            testError.state.push("This field is required!");
        }
        if (isEmpty(shippingAddressState.zip)) {
            isFormValid = false;
            testError.zip.push("This field is required!");
        }
        setErrorState(testError);
        return isFormValid;
    };

    const onSubmit = async () => {
        setDisableSubmitButton(true);
        let cartItem = JSON.parse(localStorage.getItem("cart"));  
        if (isEmpty(cartItem) || cartItem == "null" || cartItem.length == 0) {
            props.history.push("/shoppingCart");
        }
        else{
            if (!(await validateForm())) {
                setDisableSubmitButton(false);
                return null;
            }
            const response = await dispatch(
                checkoutAction.saveDeliveryData(authData.id, {shippingAddress: shippingAddressState, shippingMethod: shippingMethodState,})
            );
            await loadData();
            if (response && response.success) {
                props.history.push("/checkout_payment");
            }
        }
        setDisableSubmitButton(false);
    };

    let cartItems = JSON.parse(localStorage.getItem("cart"))?.length;

    const deliveryData = {
        1 : {
            id : 1,
            name : 'usps First Class',
            cost : 0.00
        },
        2 : {
            id : 2,
            name : 'Usps Priority',
            cost : (cartItems >= 2) ? 0.99 : 3.99
        },
        3 : {
            id : 3,
            name : 'Next Day Home Delivery',
            cost : (cartItems >= 2) ? 0.99 : 5.99
        },
    }

    const handleDeliveryOption = (event, id)=>{
        const {checked} = event.detail
        if(checked){
            localStorage.setItem("delivery", JSON.stringify(deliveryData[id]))
            dispatch({ type: "DELIVERY_UPDATE" });
            setDeliveryState(deliveryData[id])
        }
    }

    return (
        <div className="checkoutDelivery-page">
            <div className="space-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" size-md="2">
                            <CheckoutSidebar pageIndex={3}/>
                        </IonCol>
                        <IonCol size="12" size-md="10">
                            <div className="parentform">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="6">
                                            <div className="parentform-heading">
                                                <span>{prescriptionRequired ? '3 OF 5' : '2 OF 4'}</span>
                                                <h2>Help us help you get your meds!</h2>
                                                <p>Almost done. Please provide your delivery address.</p>
                                            </div>
                                            {commonData.errorMsg && (
                                                <p className="errormsg">{commonData.errorMsg}</p>
                                            )}

                                            {/* Shipping Address */}
                                            <div className="theme-card-parent">
                                                <div className="theme-card-parent-heading">
                                                    <h3>Shipping Address</h3>
                                                </div>
                                                <div className="theme-card">
                                                    <div className="card-body">
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="12" size-md="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>First Name</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. John"
                                                                            value={shippingAddressState.firstName}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "firstName")
                                                                            }
                                                                        />
                                                                        {errorState.firstName.length !== 0 &&
                                                                        errorState.firstName[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.firstName[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>Last Name</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. Smith"
                                                                            value={shippingAddressState.lastName}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "lastName")
                                                                            }
                                                                        />
                                                                        {errorState.lastName.length !== 0 &&
                                                                        errorState.lastName[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.lastName[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="12">
                                                                    <div className="form-group">
                                                                        {/**/}
                                                                        <div
                                                                            className="form-group search-drop"
                                                                            ref={wrapperRef}
                                                                        >
                                                                            <IonLabel>Address Line 1</IonLabel>
                                                                            <div className="left-icon-input">
                                                                                <a href="javascript:;">
                                                                                    {/* <IonSpinner/>  */}
                                                                                    <IonIcon icon={searchOutline}/>
                                                                                </a>

                                                                                <IonInput
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="e.g. 123 Street"
                                                                                    value={
                                                                                        shippingAddressState.addressLine1
                                                                                    }
                                                                                    onIonChange={(e) =>
                                                                                        handleChange(e, "addressLine1")
                                                                                    }
                                                                                />
                                                                                {errorState.addressLine1.length !== 0 &&
                                                                                errorState.addressLine1[0] && (
                                                                                    <p className="errormsg">
                                                                                        {errorState.addressLine1[0]}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                            {predictions.length !== 0 && (
                                                                                <div className="search-drop-list">
                                                                                    {predictions?.map((prediction) => (
                                                                                        <a
                                                                                            key={prediction?.place_id}
                                                                                            onClick={() =>
                                                                                                onPlaceSelected(prediction)
                                                                                            }
                                                                                        >
                                                                                            {prediction?.description ||
                                                                                            "Not found"}
                                                                                        </a>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>Address Line 2</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. Apt123"
                                                                            value={shippingAddressState.addressLine2}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "addressLine2")
                                                                            }
                                                                        />
                                                                        {errorState.addressLine2.length !== 0 &&
                                                                        errorState.addressLine2[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.addressLine2[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="12">
                                                                    <div className="form-group">
                                                                        <IonLabel>City</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. San Francisco"
                                                                            value={shippingAddressState.city}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "city")
                                                                            }
                                                                        />
                                                                        {errorState.city.length !== 0 &&
                                                                        errorState.city[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.city[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="6">
                                                                    <div className="form-group">
                                                                        <IonLabel>State</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. San Francisco"
                                                                            value={shippingAddressState.state}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "state")
                                                                            }
                                                                        />
                                                                        {errorState.state.length !== 0 &&
                                                                        errorState.state[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.state[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                                <IonCol size="12" size-md="6">
                                                                    <div className="form-group">
                                                                        <IonLabel>Zip Number</IonLabel>
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="e.g. 94016 "
                                                                            value={shippingAddressState.zip}
                                                                            onIonChange={(e) =>
                                                                                handleChange(e, "zip")
                                                                            }
                                                                        />
                                                                        {errorState.zip.length !== 0 &&
                                                                        errorState.zip[0] && (
                                                                            <p className="errormsg">
                                                                                {errorState.zip[0]}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* shippingMethod */}
                                            <div className="methodBox">
                                                <div className="shippingMethod-heading">
                                                    <h3>Shipping Method</h3>
                                                </div>
                                                {/* item */}
                                                <div className="shippingMethod-item">
                                                    <div className="shippingMethod-left">
                                                        <IonCheckbox checked={deliveryState.id === 1} onIonChange={(e)=>handleDeliveryOption(e,1)}/>
                                                        <div className="usps-img">
                                                            <IonImg src={Uspsimg}/>
                                                        </div>
                                                        <div className="shipping-detail">
                                                            <h3>USPS 1st Class</h3>
                                                            <p>7 to 10 Days</p>
                                                        </div>
                                                    </div>
                                                    <div className="shippingMethod-right">
                                                      <p>Free</p>
                                                    </div>
                                                </div>
                                                <div className="shippingMethod-item">
                                                    <div className="shippingMethod-left">
                                                        <IonCheckbox checked={deliveryState.id === 2} onIonChange={(e)=>handleDeliveryOption(e,2)}/>
                                                        <div className="usps-img">
                                                            <IonImg src={Uspsimg}/>
                                                        </div>
                                                        <div className="shipping-detail">
                                                            <h3>USPS Priority</h3>
                                                            <p>2 to 3 Days</p>
                                                        </div>
                                                    </div>
                                                    <div className="shippingMethod-right">
                                                        <p>{(cartItems >= 2) ? `$0.99` : `$3.99`}</p>
                                                    </div>
                                                </div>
                                                {showNextDayDelivery && <div className="shippingMethod-item">
                                                    <div className="shippingMethod-left">
                                                        <IonCheckbox checked={deliveryState.id === 3} onIonChange={(e)=>handleDeliveryOption(e,3)}/>
                                                        <div className="usps-img">
                                                            <IonImg src={homeDelivery}/>
                                                        </div>
                                                        <div className="shipping-detail">
                                                            <h3>Next Day Home Delivery</h3>
                                                        </div>
                                                    </div>
                                                    <div className="shippingMethod-right">
                                                        <p>{ (cartItems >= 2) ? `$0.99` : `$5.99`}</p>
                                                    </div>
                                                </div>}
                                                <div className="shippingMethod-item shippingMethod-localPickup">
                                                    <div className="shippingMethod-left">
                                                        <IonCheckbox checked={deliveryState.id === 4} onIonChange={(e)=>handleDeliveryOption(e,4)}/>
                                                        <div className="usps-img">
                                                            <IonImg src={localPickupImg}/>
                                                        </div>
                                                        <div className="shipping-detail">
                                                            <h3>Local Pickup (Coming Soon)</h3>
                                                        </div>
                                                    </div>
                                                    <div className="shippingMethod-right">
                                                      <p>Free</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="parentform-button">
                                                <IonButton
                                                    className="theme-btn button-blue"
                                                    disabled={disableSubmitButton}
                                                    onClick={() => onSubmit()}                                                    
                                                >
                                                    <span>Next - Payment Info</span>
                                                </IonButton>
                                                <IonButton
                                                    className="theme-btn button-blue-outline"
                                                    onClick={() => {
                                                        prescriptionRequired
                                                          ? props.history.push('/checkout_prescription')
                                                          : props.history.push('/checkout_conditions');
                                                    }}
                                                >
                                                    <span>
                                                        {
                                                            prescriptionRequired
                                                              ? 'Previous - Prescription Info'
                                                              : 'Previous - Condition Info'
                                                        }
                                                    </span>
                                                </IonButton>
                                            </div>
                                        </IonCol>

                                        <IonCol size="12" size-md="5" offset-md="1">
                                            <MyCart/>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default CheckoutDelivery;
