import {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
} from '@ionic/react';
import React from 'react';
import './dashboardTab.scss';
import {useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";

const DashboardTab = (props) => {
    const history = useHistory();
    const location = useLocation()
    const authData = useSelector((state) => state.authReducer.authData);

    const navigateTo = async (path)=>{
        history.push(path)
    }

    return (
        <div className="dashboardTab-content">
            <div className="dashboardTab-heading">
                <h2>Welcome to Qwark, {authData.first_name}!</h2>
            </div>
            <div className="dashboardTab-inner">
                <a className={location.pathname === "/accountSummary" ? "active" :null}
                   onClick={()=>navigateTo('/accountSummary')}
                >Account Summary</a>
                <a className={location.pathname === "/medication" ? "active" :null}
                   onClick={()=>navigateTo('/medication')}
                >Medication</a>
                <a className={location.pathname === "/profileAndSetting" ? "active" :null}
                   onClick={()=>navigateTo('/profileAndSetting')}
                >Profile and Setting</a>
                <a className={location.pathname === "/healthNetwork" ? "active" :null}
                   onClick={()=>navigateTo('/healthNetwork')}
                >Health network</a>
            </div>
        </div>
    );
};

export default DashboardTab;