import {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonImg,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    IonDatetime,
    IonRadioGroup,
    IonRadio,
    IonSpinner,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import {
    calendarClearOutline,
    eyeOutline,
    notificationsOutline,
    chevronDownOutline,
    addOutline,
    searchOutline,
    closeOutline,
    checkmarkOutline,
} from "ionicons/icons";
import React, {useEffect, useRef, useState} from "react";
import "./profileAndSetting.scss";
import {menuController} from "@ionic/core";
import DashboardTab from "../../components/dashboardTab/dashboardTab";
import {useDispatch, useSelector} from "react-redux";
import {commonReducer, authReducer} from "../../Redux/reducers/index";
import {
    authActions,
    commonActions,
    profileActions,
} from "../../Redux/actions/index";
import * as Helper from "../../Utils/helper";
import {
    convertArrayToCommaSeparatedString,
    convertTimeStampToDateYYYYMMDD,
    getTimeStamp,
    isEmpty, maskPhoneNumber, parseCardExpireDate,
} from "../../Utils/helper";
import Calendar from "react-calendar";

const ProfileAndSetting = (props) => {
    const dispatch = useDispatch();
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer.authData);
    const cartItems = useSelector((state) => state.userReducer.cartItems);
    const [state, setState] = useState({
        profileScore: "",
        patientName: "",
        dob: "",
        gender: "",
        address: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
        },
        email: "",
        password: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        insurance: "",
        paymentInfo: [],
        billingAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
        },
        additionalHealthInfo: {
            allergies: [],
            medicalConditions: [],
            medications: [],
        },
        phone : ""
    });
    const [editState, setEditState] = useState({
        patientName: false,
        dob: false,
        gender: false,
        address: false,
        email: false,
        password: false,
        insurance: false,
        paymentInfo: false,
        billingAddress: false,
        additionalHealthInfo: false,
        phone : false
    });
    const [errorState, setErrorState] = useState({
        patientName: [],
        dob: [],
        gender: [],
        address: [],
        email: [],
        password: [],
        insurance: [],
        paymentInfo: [],
        billingAddress: [],
        additionalHealthInfo: [],
        phone: [],
    });
    const [showPassword, setShowPassword] = useState({
        oldPassword: "password",
        newPassword: "password",
        confirmPassword: "password",
    });
    const [buttonSpinner, setButtonSpinner] = useState("");
    const [profileBar, setProfileBar] = useState({});
    const [radioButtonState, setRadioButtonState] = useState({
        allergies: false,
        medicalConditions: false,
        medications: false,
    });
    const [searchState, setSearchState] = useState({
        allergies: null,
        medicalConditions: null,
        medications: null,
    });
    /*const [searchResultState, setSearchResultState] = useState({
          allergies: [],
          medicalConditions: [],
          medications: [],
      });*/
    const [passwordValidationState, setPasswordValidationState] = useState({
        length: "",
        uppercase: "",
        specialCharacter: "",
    });
    const [predictions, setPredictions] = useState({
        address: [],
        billingAddress: [],
    });
    const [enableGoogleSearch, setEnableGoogleSearch] = useState(true);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPredictions({address: [], billingAddress: []});
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    React.useEffect(() => {
        menuController.enable(false);
        loadProfileData();
    }, []);

    const capitalize = (name) => {
        if (typeof name !== 'string') return ''
        return name.charAt(0).toUpperCase() + name.slice(1)
    }

    const loadProfileData = async () => {
        const response = await dispatch(
            profileActions.getUserProfileData(authData.id)
        );
        if (!response || !response.data) {
            return null;
        }
        setState(response.data);
        await ProfileBarFunction(response.data.profileScore);
        setRadioButtonState({
            allergies: response.data.additionalHealthInfo.allergies.length !== 0,
            medicalConditions:
                response.data.additionalHealthInfo.medicalConditions.length !== 0,
            medications: response.data.additionalHealthInfo.medications.length !== 0,
        });
    };
    const setEditable = (name) => {
        const prevValue = editState[name];
        setEditState((prevState) => ({...prevState, [name]: !prevValue}));
    };

    const handleChange = (event, name, subcategory = "") => {
        let {value} = event.detail;
        if (subcategory.length !== 0) {
            clearErrors(subcategory);
            if (value === " " && state[subcategory][name].length === 0) {
                return null
            }

            let newState = state;
            newState[subcategory][name] = value;
            setState({...newState});
        } else {
            clearErrors(name);
            if (value === " " && state[name].length === 0) {
                return null
            } else if (name === "phone" && value) {
                value = value.replace(/\D/g, "")
                if (isNaN(value)) {
                    setErrorState((prevState) => ({
                        ...prevState,
                        phone: ["Phone number can only contain numbers !"],
                    }));
                } else {
                    setErrorState((prevState) => ({ ...prevState, phone: [] }));
                }
            }
            setState((prevState) => ({...prevState, [name]: value}));
        }
        if (name === "newPassword") {
            let pValidation = {
                length: false,
                uppercase: false,
                specialCharacter: false,
            };
            const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            pValidation.length = value.length >= 8;
            pValidation.uppercase = new RegExp("[A-Z]").test(value);
            pValidation.specialCharacter = specialCharacterFormat.test(value);
            setPasswordValidationState(pValidation);
        }
        if (name === "addressLine1" && enableGoogleSearch) {
            if (value && value.length) {
                handleSubmit(value, subcategory);
            } else {
                setPredictions((prevState) => ({...prevState, [subcategory]: []}));
            }
        }
    };

    const handleSearchChange = (event, name) => {
        const {value} = event.detail;
        setSearchState((prevState) => ({...prevState, [name]: value}));
        /*if (value.length !== 0) {
          searchMedicalData(name, value);
        }*/
    };

    const clearErrors = (name) => {
        dispatch({type: "CLEAN_ERROR"});
        if (errorState[name].length !== 0) {
            setErrorState((prevState) => ({...prevState, [name]: []}));
        }
    };

    const searchMedicalData = async (name, value) => {
        setButtonSpinner(`search${name}`);
        let response;
        if (name === "allergies") {
            response = await dispatch(
                profileActions.searchAllergy(authData.id, value)
            );
        } else if (name === "medicalConditions") {
            response = await dispatch(
                profileActions.searchMedicalCondition(authData.id, value)
            );
        } else if (name === "medications") {
            response = await dispatch(
                profileActions.searchMedication(authData.id, value)
            );
        }
        // setSearchResultState((prevState) => ({...prevState, [name]: response.data,}));
        setButtonSpinner("");
    };

    const handleShowPassword = (name) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [name]: showPassword[name] === "password" ? "text" : "password",
        }));
    };

    const onCancel = async (name) => {
        setButtonSpinner("cancel" + name);
        await loadProfileData();
        setEditable(name);
        setButtonSpinner("");
    };

    const onSubmit = async (name) => {
        setButtonSpinner("save" + name);
        if (!(await validateForm(name))) {
            setButtonSpinner("");
            return null;
        }
        const response = await dispatch(
            profileActions.updateProfile(authData.id, name, state)
        );
        if (!response.success) {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: [response.message],
            }));
        } else {
            if (["patientName", "dob", "email", "password", "gender", "phone"].includes(name)) {
                await dispatch(authActions.isUser());
            }
            await loadProfileData();
            setEditable(name);
        }
        setButtonSpinner("");
    };

    const validateForm = (name) => {
        let isFormValid = true;
        let errorMessage = [];
        const requiredFields = ["patientName", "email"];
        if (requiredFields.includes(name) && state[name].length === 0) {
            isFormValid = false;
            errorMessage.push("This Field cannot be empty");
        }
        if (name === "password") {
            const specialCharacterFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (
                state.password.oldPassword.length === 0 ||
                state.password.newPassword.length === 0 ||
                state.password.confirmPassword.length === 0
            ) {
                isFormValid = false;
                errorMessage.push("Password cannot be empty");
            } else if (state.password.newPassword.length < 8) {
                isFormValid = false;
                errorMessage.push("Password must be 8 characters long");
            } else if (!specialCharacterFormat.test(state.password.newPassword)) {
                isFormValid = false;
                errorMessage.push("Password must contain a special character");
            } else if (!new RegExp("[A-Z]").test(state.password.newPassword)) {
                isFormValid = false;
                errorMessage.push("Password must contain an upper case");
            } else if (
                state.password.newPassword !== state.password.confirmPassword
            ) {
                isFormValid = false;
                errorMessage.push("Confirm password does not match");
            }
        } else if (name === "email") {
            if (
                state.email.trim().length > 0 &&
                /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                    state.email
                ) === false
            ) {
                isFormValid = false;
                errorMessage.push("Email must be a valid email address");
            }
        } else if (name === "insurance") {
            if (!state.insurance.memberId || state.insurance.memberId.length === 0) {
                isFormValid = false;
                errorMessage.push("Member ID cannot be empty!");
            } else if (
                !state.insurance.rxbin ||
                state.insurance.rxbin.toString().length === 0
            ) {
                isFormValid = false;
                errorMessage.push("RXBIN cannot be empty!");
            }
        } else if (name === "address") {
            if (
                !state.address.addressLine1 ||
                state.address.addressLine1.length === 0
            ) {
                isFormValid = false;
                errorMessage.push("Address cannot be empty!");
            }
        } else if (name === "billingAddress") {
            if (
                !state.billingAddress.addressLine1 ||
                state.billingAddress.addressLine1.length === 0
            ) {
                isFormValid = false;
                errorMessage.push("Billing Address cannot be empty!");
            }
        } else if (name === "dob") {
            const datesList = state[name].split('-')
            for (let date of datesList) {
                if (isEmpty(date) || parseInt(date) === 0) {
                    isFormValid = false;
                    errorMessage.push("Invalid Date");
                }
            }
        }
        setErrorState((prevState) => ({...prevState, [name]: [...errorMessage]}));
        return isFormValid;
    };

    const ProfileBarFunction = (profileScore) => {
        const profileIncomplete = 10 - profileScore;
        let htmlCode = ``;
        for (let i = 1; i <= profileScore; i++) {
            htmlCode = htmlCode + `<div class="greybox bg-light-green"></div>`;
        }
        for (let j = 1; j <= profileIncomplete; j++) {
            htmlCode = htmlCode + `<div class="greybox"></div>`;
        }
        setProfileBar({__html: htmlCode});
    };

    const handleKeyCheck = (event, name) => {
        if (event.key === "Enter") {
            onItemSelected(searchState[name], name);
        }
    };

    const onItemSelected = (object, name) => {
        if (!isEmpty(object)) {
            let newState = state;
            newState.additionalHealthInfo[name].push(object);
            setState({...newState});
        }

        setSearchState((prevState) => ({...prevState, [name]: null}));
        // setSearchResultState((prevState) => ({...prevState, [name]: []}));
    };

    const removeItem = (object, name) => {
        let newState = state;
        newState.additionalHealthInfo[name].splice(
            state.additionalHealthInfo[name].indexOf(object),
            1
        );
        setState({...newState});
    };

    const handleRadioChange = (event, name) => {
        const {value} = event.detail;
        setRadioButtonState((prevState) => ({...prevState, [name]: value}));
        if (!value) {
            let newState = state;
            newState.additionalHealthInfo[name] = [];
            setState({...newState});
        }
    };

    const googleAutocomplete = async (text) =>
        new Promise((resolve, reject) => {
            if (!text) {
                return reject("Need valid text input");
            }

            // for use in things like GatsbyJS where the html is generated first
            if (typeof window === "undefined") {
                return reject("Need valid window object");
            }

            try {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                    {input: text, componentRestrictions: {country: ["us"]}},
                    resolve
                );
            } catch (e) {
                reject(e);
            }
        });

    const handleSubmit = async (searchValue, subcategory) => {
        const results = await googleAutocomplete(searchValue, subcategory);
        if (results) {
            setPredictions((prevState) => ({...prevState, [subcategory]: results}));
        }
    };

    const onPlaceSelected = async (place, category, e) => {
        e.preventDefault()
        setEnableGoogleSearch(false);
        const response = await dispatch(profileActions.GooglePlacesDetail(authData.id, place.place_id));
        if (!response || !response.result) {
            return;
        }
        let autoCompletedAddress = {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
            id: category === "address" ? state.address.id : state.billingAddress.id,
            type: category === "address" ? "residential" : "billing",
        };
        const placesArray = response.result.address_components;
        console.log('placesArray ------------- ', placesArray)
        autoCompletedAddress.addressLine1 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["street_number", "route", "premise"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.addressLine2 = Helper.convertArrayToCommaSeparatedString(
            placesArray.map((places) =>
                places.types.some((type) =>
                    ["sublocality", "neighborhood"].includes(type)
                )
                    ? places.long_name
                    : null
            )
        );
        autoCompletedAddress.city =
            placesArray.filter((places) =>
                places.types.includes("locality")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("locality")
                )[0].long_name
                : "";
        autoCompletedAddress.state =
            placesArray.filter((places) =>
                places.types.includes("administrative_area_level_1")
            ).length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("administrative_area_level_1")
                )[0].long_name
                : "";
        autoCompletedAddress.zip =
            placesArray.filter((places) => places.types.includes("postal_code"))
                .length !== 0
                ? placesArray.filter((places) =>
                    places.types.includes("postal_code")
                )[0].long_name
                : "";
        setState((prevState) => ({
            ...prevState,
            [category]: autoCompletedAddress,
        }));
        setPredictions((prevState) => ({...prevState, [category]: []}));
        setTimeout(() => setEnableGoogleSearch(true), 5000);
    };

    const renderDefaultCard = () => {
        if (isEmpty(state.paymentInfo)) {
            return (<p>None</p>)
        }
        let defaultCard = state.paymentInfo.filter((c) => c.isDefault)
        if (isEmpty(defaultCard)) {
            defaultCard = [state.paymentInfo[0]]
        }
        return (
            <div className="profile-detail">
                <h3>Card Information</h3>
                <p className="profile-detail-pera">{defaultCard[0].cardType} ending
                    in {defaultCard[0].cardNumber.slice(-4)}</p>
                <p className="profile-detail-pera">
                    Expires {parseCardExpireDate(defaultCard[0].expirationDate)}
                </p>
            </div>
        )
    }

    const removeCard = (cardIndex) => {
        let allCards = state.paymentInfo
        allCards.splice(cardIndex, 1)
        setState((prevState) => ({...prevState, paymentInfo: allCards}))
    }

    const setDefaultCard = (cardIndex) => {
        let allCards = state.paymentInfo.map((c, index) => ({...c, isDefault: index === cardIndex ? 1 : 0}))
        setState((prevState) => ({...prevState, paymentInfo: allCards}))
    }


    return (
        <div className="profileAndSetting-page">
            {/* notification-bar start */}
            {!isEmpty(cartItems) && cartItems != "null" &&
            <div className="notification-bar">
                <div className="space-container">
                    <p>
            <span>
              <IonIcon icon={notificationsOutline}/>
            </span>
                        You have a new prescription. Please click{" "}
                        <a onClick={() => props.history.push("/checkout_conditions")}>here</a> to start the checkout
                        process.
                    </p>
                </div>
            </div>
            }
            {/* notification-bar end */}

            <div className="profileAndSetting-page-inner">
                <div className="space-container">
                    {/* DashboardTab */}
                    <DashboardTab/>
                    {/* DashboardTab */}

                    {/* patient-profile start */}
                    <div className="patient-profile">
                        <div className="patient-profile-heading">
                            <h3>Patient Profile</h3>
                        </div>
                        {commonData.errorMsg && (
                            <p className="errormsg">{commonData.errorMsg}</p>
                        )}
                        {Object.keys(profileBar).length !== 0 ? (
                            <div
                                className="patient-profile-bar"
                                dangerouslySetInnerHTML={profileBar}
                            ></div>
                        ) : (
                            commonData.loading && <IonSpinner/>
                        )}
                        {state.profileScore && (
                            <div className="patient-profile-status">
                                <p>Your profile is {state.profileScore}0% complete.</p>
                            </div>
                        )}
                    </div>
                    {/* patient-profile end */}

                    {/* theme-card start */}
                    <div className="theme-card">
                        <div className="card-body">
                            <IonGrid>
                                <div className="profile-row-space">
                                    {/* profile-detail start */}
                                    {editState.patientName ? (
                                        // editable paitent name start
                                        <div className="profile-edit-form">
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Patient Name</h3>
                                                    </div>
                                                    <div className="form-group">
                                                        <IonLabel>Name</IonLabel>
                                                        <IonInput
                                                            className="form-control"
                                                            placeholder=""
                                                            value={state.patientName}
                                                            onIonChange={(e) =>
                                                                handleChange(e, "patientName")
                                                            }
                                                        />
                                                        {errorState.patientName.length !== 0 &&
                                                        errorState.patientName[0] && (
                                                            <p className="errormsg">
                                                                {errorState.patientName[0]}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="profileedit-btn-inline">
                                                        <IonButton
                                                            className="theme-btn button-blue"
                                                            onClick={() => onSubmit("patientName")}
                                                        >
                                                            <span>Save</span>
                                                            {/*{commonData.loading && buttonSpinner === "savepatientName" && (<IonSpinner/>)}*/}
                                                        </IonButton>
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => onCancel("patientName")}
                                                        >
                                                            <span>Cancel</span>
                                                            {/*{commonData.loading && buttonSpinner === "cancelpatientName" && (<IonSpinner/>)}*/}
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    ) : (
                                        // editable patient name end
                                        // default patient name start
                                        <IonRow>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-detail">
                                                    <h3>Patient Name</h3>
                                                    {state.patientName && state.patientName.length && (
                                                        <p className="profile-detail-pera">
                                                            {state.patientName}
                                                        </p>
                                                    )}
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-edit-btn">
                                                    <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => setEditable("patientName")}
                                                    >
                                                        <span>Edit</span>
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        // default patient name end
                                    )}
                                </div>
                                <div className="profile-row-space">
                                    {
                                        editState.dob ? (
                                            // editable DOB start
                                            <div className="profile-edit-form">
                                                <IonRow>
                                                    <IonCol size="12" size-md="6">
                                                        <div className="profile-detail">
                                                            <h3>Date of Birth</h3>
                                                        </div>
                                                        <div className="form-group">
                                                            {/*<div className="dateicon-input">*/}
                                                            {/*    <IonDatetime*/}
                                                            {/*        className="form-control"*/}
                                                            {/*        displayFormat="MM-DD-YYYY"*/}
                                                            {/*        placeholder="Select the date"*/}
                                                            {/*        value={state.dob}*/}
                                                            {/*        onIonChange={(e) => handleChange(e, 'dob')}>*/}
                                                            {/*    </IonDatetime>*/}
                                                            {/*    <a href="javascript:;"*/}
                                                            {/*    ><IonIcon icon={calendarClearOutline}/></a>*/}

                                                            {/*</div>*/}
                                                            <IonLabel>Date of Birth</IonLabel>
                                                            <div className="commonCalendar">
                                                                <Calendar
                                                                    value={getTimeStamp(state.dob)}
                                                                    maxDate={new Date()}
                                                                    calendarType="US"
                                                                    onChange={(e) => {
                                                                        handleChange({detail: {value: convertTimeStampToDateYYYYMMDD(e)}}, "dob")
                                                                    }}
                                                                />
                                                            </div>
                                                            {/*<IonInput
                                className="form-control"
                                type="date"
                                value={
                                  state.dob && state.dob.toString().length !== 0
                                    ? convertTimeStampToDateYYYYMMDD(state.dob)
                                    : ""
                                }
                                max={convertTimeStampToDateYYYYMMDD(new Date())}
                                onIonChange={(e) => handleChange(e, "dob")}
                              />*/}
                                                            {errorState.dob.length !== 0 &&
                                                            errorState.dob[0] && (
                                                                <p className="errormsg">
                                                                    {errorState.dob[0]}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="profileedit-btn-inline">
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                onClick={() => onSubmit("dob")}
                                                            >
                                                                <span>Save</span>
                                                                {/*{commonData.loading && buttonSpinner === "savedob" && <IonSpinner/>}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-grey"
                                                                onClick={() => onCancel("dob")}
                                                            >
                                                                <span>Cancel</span>
                                                                {/*{commonData.loading && buttonSpinner === "canceldob" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        ) : (
                                            // editable DOB end
                                            // default DOB start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Date of Birth</h3>
                                                        <p className="profile-detail-pera">
                                                            {state.dob && state.dob.toString().length
                                                                ? Helper.convertTimestampToMMDDYYYY(state.dob)
                                                                : "None"}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("dob")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        // default DOB  start
                                    }
                                </div>

                                {/* gender */}
                                <div className="profile-row-space">
                                    {
                                        editState.gender ? (
                                            // editable gender start
                                            <div className="profile-edit-form">
                                                <IonRow>
                                                    <IonCol size="12" size-md="6">
                                                        <div className="profile-detail">
                                                            <h3>Gender</h3>
                                                            <div className="form-group">
                                                                <IonRadioGroup
                                                                    value={state.gender}
                                                                    onIonChange={(e) => handleChange(e, "gender")}
                                                                >
                                                                    <div className="radio-item">
                                                                        <IonRadio value="male"/>
                                                                        <IonLabel>Male</IonLabel>
                                                                    </div>
                                                                    <div className="radio-item">
                                                                        <IonRadio value="female"/>
                                                                        <IonLabel>Female</IonLabel>
                                                                    </div>
                                                                    <div className="radio-item">
                                                                        <IonRadio value="non-binary"/>
                                                                        <IonLabel>Non-binary</IonLabel>
                                                                    </div>
                                                                </IonRadioGroup>
                                                            </div>
                                                        </div>
                                                        <div className="profileedit-btn-inline">
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                onClick={() => onSubmit("gender")}
                                                            >
                                                                <span>Save</span>
                                                                {/*{commonData.loading && buttonSpinner === "savegender" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-grey"
                                                                onClick={() => onCancel("gender")}
                                                            >
                                                                <span>Cancel</span>
                                                                {/*{commonData.loading && buttonSpinner === "cancelgender" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        ) : (
                                            // editable gender end
                                            // default gender start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Gender</h3>
                                                        <p className="profile-detail-pera">
                                                            {state.gender && state.gender.length
                                                                ? capitalize(state.gender)
                                                                : "None"}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("gender")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        // default gender end
                                    }
                                </div>

                                {/* phone */}
                                <div className="profile-row-space">
                                    {/* profile-detail start */}
                                    {editState.phone ? (
                                        // editable phone start
                                        <div className="profile-edit-form">
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Phone Number</h3>
                                                    </div>
                                                    <div className="form-group">
                                                        <IonLabel>Phone Number</IonLabel>
                                                        <IonInput
                                                            className="form-control"
                                                            placeholder="e.g. (123)-456-7890"
                                                            value={maskPhoneNumber(state.phone.toString())}
                                                            onIonChange={(e) => handleChange(e, "phone")}
                                                        />
                                                        {errorState.phone.length !== 0 &&
                                                        errorState.phone[0] && (
                                                            <p className="errormsg">
                                                                {errorState.phone[0]}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="profileedit-btn-inline">
                                                        <IonButton
                                                            className="theme-btn button-blue"
                                                            onClick={() => onSubmit("phone")}
                                                        >
                                                            <span>Save</span>
                                                            {/*{commonData.loading && buttonSpinner === "savephone" && (<IonSpinner/>)}*/}
                                                        </IonButton>
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => onCancel("phone")}
                                                        >
                                                            <span>Cancel</span>
                                                            {/*{commonData.loading && buttonSpinner === "cancelphone" && (<IonSpinner/>)}*/}
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    ) : (
                                        // editable phone end
                                        // default phone start
                                        <IonRow>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-detail">
                                                    <h3>Phone Number</h3>
                                                    {state.phone && (
                                                        <p className="profile-detail-pera">
                                                            {maskPhoneNumber(state.phone.toString())}
                                                        </p>
                                                    )}
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-edit-btn">
                                                    <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => setEditable("phone")}
                                                    >
                                                        <span>Edit</span>
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        // default phone end
                                    )}
                                </div>

                                {/* address */}
                                <div className="profile-row-space" ref={wrapperRef}>
                                    {
                                        editState.address ? (
                                            // editable address start
                                            <div className="profile-edit-form">
                                                <div className="profile-detail">
                                                    <h3>Address</h3>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group search-drop">
                                                                <IonLabel>Address Line 1</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. 123 Street"
                                                                    value={
                                                                        state.address && state.address.addressLine1
                                                                    }
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "addressLine1", "address")
                                                                    }
                                                                />
                                                                {predictions.address.length !== 0 && (
                                                                    <div className="search-drop-list">
                                                                        {predictions.address?.map((prediction) => (
                                                                            <a
                                                                                key={prediction?.place_id}
                                                                                onClick={(e) =>
                                                                                    onPlaceSelected(prediction, "address", e)
                                                                                }
                                                                            >
                                                                                {prediction?.description || "Not found"}
                                                                            </a>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group">
                                                                <IonLabel>Address Line 2</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. Apt123"
                                                                    value={
                                                                        state.address && state.address.addressLine2
                                                                    }
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "addressLine2", "address")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group">
                                                                <IonLabel>City</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. San Francisco"
                                                                    value={state.address && state.address.city}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "city", "address")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="3">
                                                            <div className="form-group">
                                                                <IonLabel>State</IonLabel>
                                                                {/*<div className="select-input">
                                                                    <IonSelect
                                                                        interface="popover"
                                                                        placeholder="e.g. John"
                                                                        className="form-control">
                                                                        <IonSelectOption
                                                                            value="brown">Brown</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="blonde">Blonde</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="black">Black</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="red">Red</IonSelectOption>
                                                                    </IonSelect>
                                                                    <a
                                                                    ><IonIcon icon={chevronDownOutline}/></a>
                                                                </div>*/}
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. San Francisco"
                                                                    value={state.address && state.address.state}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "state", "address")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="12" size-md="3">
                                                            <div className="form-group">
                                                                <IonLabel>Zip Number</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="e.g. 94016"
                                                                    value={state.address && state.address.zip}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "zip", "address")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                </div>
                                                {errorState.address.length !== 0 &&
                                                errorState.address[0] && (
                                                    <p className="errormsg">{errorState.address[0]}</p>
                                                )}
                                                <div className="profileedit-btn-inline">
                                                    <IonButton
                                                        className="theme-btn button-blue"
                                                        onClick={() => onSubmit("address")}
                                                    >
                                                        <span>Save</span>
                                                        {/*{commonData.loading && buttonSpinner === "saveaddress" && <IonSpinner/>}*/}
                                                    </IonButton>
                                                    <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => onCancel("address")}
                                                    >
                                                        <span>Cancel</span>
                                                        {/*{commonData.loading && buttonSpinner === "canceladdress" && (<IonSpinner/>)}*/}
                                                    </IonButton>
                                                </div>
                                            </div>
                                        ) : (
                                            // editable address end
                                            // default address start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Address</h3>
                                                        <p className="profile-detail-pera">
                                                            {state.address &&
                                                            state.address.addressLine1 &&
                                                            state.address.addressLine1.length
                                                                ? convertArrayToCommaSeparatedString([
                                                                    state.address.addressLine1,
                                                                    state.address.addressLine2,
                                                                    state.address.city,
                                                                    state.address.state,
                                                                    state.address.zip,
                                                                ])
                                                                : "None"}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("address")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        // default address end
                                    }
                                </div>

                                {/*  email */}
                                <div className="profile-row-space">
                                    {editState.email ? (
                                        <div className="profile-edit-form">
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Email</h3>
                                                        <div className="form-group">
                                                            <IonInput
                                                                className="form-control"
                                                                placeholder=""
                                                                value={state.email}
                                                                onIonChange={(e) => handleChange(e, "email")}
                                                            />
                                                            {errorState.email.length !== 0 &&
                                                            errorState.email[0] && (
                                                                <p className="errormsg">
                                                                    {errorState.email[0]}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="profileedit-btn-inline">
                                                        <IonButton
                                                            className="theme-btn button-blue"
                                                            onClick={() => onSubmit("email")}
                                                        >
                                                            <span>Save</span>
                                                            {/*{commonData.loading && buttonSpinner === "saveemail" && <IonSpinner/>}*/}
                                                        </IonButton>
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => onCancel("email")}
                                                        >
                                                            <span>Cancel</span>
                                                            {/*{commonData.loading && buttonSpinner === "cancelemail" && (<IonSpinner/>)}*/}
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    ) : (
                                        <IonRow>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-detail">
                                                    <h3>Email</h3>
                                                    <p className="profile-detail-pera">{state.email}</p>
                                                </div>
                                            </IonCol>
                                            <IonCol size="12" size-md="6">
                                                <div className="profile-edit-btn">
                                                    <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => setEditable("email")}
                                                    >
                                                        <span>Edit</span>
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </div>

                                {/* password */}
                                <div className="profile-row-space">
                                    {
                                        editState.password ? (
                                            // editable password start
                                            <div className="profile-edit-form">
                                                {authData.isSocialLogin ? (
                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="profile-detail">
                                                                <h3>Password</h3>
                                                                <p className="profile-detail-pera">
                                                                    You can't change password from here as you
                                                                    used social login feature. Kindly logout and
                                                                    choose our Forgot password feature in order to
                                                                    reset your password!
                                                                </p>
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                ) : (
                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="profile-detail">
                                                                <h3>Password</h3>
                                                                <div className="form-group">
                                                                    <IonLabel>Old Password</IonLabel>
                                                                    <div className="icon-input">
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type={showPassword.oldPassword}
                                                                            placeholder="e.g. @Abcefghi"
                                                                            value={state.password.oldPassword}
                                                                            onIonChange={(e) =>
                                                                                handleChange(
                                                                                    e,
                                                                                    "oldPassword",
                                                                                    "password"
                                                                                )
                                                                            }
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                handleShowPassword("oldPassword")
                                                                            }
                                                                        >
                                                                            <IonIcon icon={eyeOutline}/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <IonLabel>New Password</IonLabel>
                                                                    <div className="icon-input">
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type={showPassword.newPassword}
                                                                            placeholder="e.g. @Abcefghi"
                                                                            value={state.password.newPassword}
                                                                            onIonChange={(e) =>
                                                                                handleChange(
                                                                                    e,
                                                                                    "newPassword",
                                                                                    "password"
                                                                                )
                                                                            }
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                handleShowPassword("newPassword")
                                                                            }
                                                                        >
                                                                            <IonIcon icon={eyeOutline}/>
                                                                        </a>
                                                                        <p
                                                                            className={
                                                                                passwordValidationState.length
                                                                                    .length === 0
                                                                                    ? "text-grey"
                                                                                    : passwordValidationState.length
                                                                                    ? "successmsg"
                                                                                    : "errormsg"
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            {passwordValidationState.length.length ===
                                                                            0 ? null : passwordValidationState.length ? (
                                                                                <IonIcon icon={checkmarkOutline}/>
                                                                            ) : (
                                                                                <IonIcon icon={closeOutline}/>
                                                                            )}{" "}
                                                                            Use at least 8 characters
                                                                        </p>
                                                                        <p
                                                                            className={
                                                                                passwordValidationState.uppercase
                                                                                    .length === 0
                                                                                    ? "text-grey"
                                                                                    : passwordValidationState.uppercase
                                                                                    ? "successmsg"
                                                                                    : "errormsg"
                                                                            }
                                                                        >
                                                                            {passwordValidationState.uppercase
                                                                                .length ===
                                                                            0 ? null : passwordValidationState.uppercase ? (
                                                                                <IonIcon icon={checkmarkOutline}/>
                                                                            ) : (
                                                                                <IonIcon icon={closeOutline}/>
                                                                            )}{" "}
                                                                            Have at least 1 upper case
                                                                        </p>
                                                                        <p
                                                                            className={
                                                                                passwordValidationState.specialCharacter
                                                                                    .length === 0
                                                                                    ? "text-grey"
                                                                                    : passwordValidationState.specialCharacter
                                                                                    ? "successmsg"
                                                                                    : "errormsg"
                                                                            }
                                                                        >
                                                                            {passwordValidationState.specialCharacter
                                                                                .length ===
                                                                            0 ? null : passwordValidationState.specialCharacter ? (
                                                                                <IonIcon icon={checkmarkOutline}/>
                                                                            ) : (
                                                                                <IonIcon icon={closeOutline}/>
                                                                            )}{" "}
                                                                            Have at least 1 special character
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <IonLabel>Confirm New Password</IonLabel>
                                                                    <div className="icon-input">
                                                                        <IonInput
                                                                            className="form-control"
                                                                            type={showPassword.confirmPassword}
                                                                            placeholder="e.g. @Abcefghi"
                                                                            value={state.password.confirmPassword}
                                                                            onIonChange={(e) =>
                                                                                handleChange(
                                                                                    e,
                                                                                    "confirmPassword",
                                                                                    "password"
                                                                                )
                                                                            }
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                handleShowPassword("confirmPassword")
                                                                            }
                                                                        >
                                                                            <IonIcon icon={eyeOutline}/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                {errorState.password.length !== 0 &&
                                                                errorState.password[0] && (
                                                                    <p className="errormsg">
                                                                        {errorState.password[0]}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="profileedit-btn-inline">
                                                                <IonButton
                                                                    className="theme-btn button-blue"
                                                                    onClick={() => onSubmit("password")}
                                                                >
                                                                    <span>Save</span>
                                                                    {/*{commonData.loading && buttonSpinner === "savepassword" && (<IonSpinner/>)}*/}
                                                                </IonButton>
                                                                <IonButton
                                                                    className="theme-btn button-grey"
                                                                    onClick={() => onCancel("password")}
                                                                >
                                                                    <span>Cancel</span>
                                                                    {/*{commonData.loading && buttonSpinner === "cancelpassword" && (<IonSpinner/>)}*/}
                                                                </IonButton>
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </div>
                                        ) : (
                                            // editable password end
                                            //default password start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Password</h3>
                                                        <p className="profile-detail-pera">**********</p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("password")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        //default password end
                                    }
                                </div>

                                {/* insurance */}
                                <div className="profile-row-space">
                                    {
                                        editState.insurance ? (
                                            //editable insurance start
                                            <div className="profile-edit-form">
                                                <IonRow>
                                                    <IonCol size="12" size-md="6">
                                                        <div className="profile-detail">
                                                            <h3>Insurance</h3>
                                                            <div className="form-group">
                                                                <IonLabel>Member ID/Policy Number</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={state.insurance.memberId}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "memberId", "insurance")
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <IonLabel>RXBIN</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={state.insurance.rxbin}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "rxbin", "insurance")
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <IonLabel>RXPCN</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={state.insurance.rxpcn}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "rxpcn", "insurance")
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {errorState.insurance.length !== 0 &&
                                                        errorState.insurance[0] && (
                                                            <p className="errormsg">
                                                                {errorState.insurance}
                                                            </p>
                                                        )}

                                                        <div className="profileedit-btn-inline">
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                onClick={() => onSubmit("insurance")}
                                                            >
                                                                <span>Save</span>
                                                                {/*{commonData.loading && buttonSpinner === "saveinsurance" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-grey"
                                                                onClick={() => onCancel("insurance")}
                                                            >
                                                                <span>Cancel</span>
                                                                {/*{commonData.loading && buttonSpinner === "cancelinsurance" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        ) : (
                                            //editable insurance end
                                            //default insurance start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Insurance</h3>
                                                        <p className="profile-detail-pera">{`Member ID/Policy Number: ${
                                                            state.insurance.memberId &&
                                                            state.insurance.memberId.length !== 0
                                                                ? state.insurance.memberId
                                                                : "None"
                                                        }`}</p>
                                                        <p className="profile-detail-pera">{`RXBIN: ${
                                                            state.insurance.rxbin &&
                                                            state.insurance.rxbin.length !== 0
                                                                ? state.insurance.rxbin
                                                                : "None"
                                                        }`}</p>
                                                        <p className="profile-detail-pera">{`RXPCN: ${
                                                            state.insurance.rxpcn &&
                                                            state.insurance.rxpcn.length !== 0
                                                                ? state.insurance.rxpcn
                                                                : "None"
                                                        }`}</p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("insurance")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        //default insurance end
                                    }
                                </div>

                                <div className="profile-row-space">
                                    {
                                        editState.paymentInfo ? (
                                            //editable Card Information start
                                            <div className="profile-edit-form">
                                                <IonRow>
                                                    <IonCol size="12" size-md="6">
                                                        <div className="profile-detail">
                                                            <h3>Card Information</h3>

                                                            {/* Visa card start */}
                                                            {
                                                                state.paymentInfo.map((card, cIndex) => (
                                                                    <div className="grey-card" key={cIndex}>
                                                                        <div className="grey-card-body">
                                                                            <p className="profile-detail-pera">{card.cardType} ending
                                                                                in {card.cardNumber.slice(-4)}</p>
                                                                            <p className="profile-detail-pera">
                                                                                Expires {parseCardExpireDate(card.expirationDate)}
                                                                            </p>
                                                                            {
                                                                                card.isDefault === 1 &&
                                                                                <p className="profile-detail-pera text-blue">
                                                                                    Default
                                                                                </p>
                                                                            }

                                                                        </div>
                                                                        <div className="grey-card-bottom">
                                                                            <div className="profileedit-btn-inline">
                                                                                <IonButton class="theme-btn button-grey"
                                                                                           onClick={() => removeCard(cIndex)}>
                                                                                    <span>Delete</span>
                                                                                </IonButton>
                                                                                <IonButton class="theme-btn button-grey"
                                                                                           disabled={card.isDefault === 1}
                                                                                           onClick={() => setDefaultCard(cIndex)}>
                                                                                    <span>Set as Default</span>
                                                                                </IonButton>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            {/* Visa card end */}
                                                        </div>
                                                        <div className="profileedit-btn-inline">
                                                            {/*<IonButton className="theme-btn button-blue">
                                <span>Add Card</span>
                              </IonButton>*/}
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                onClick={() => onSubmit("paymentInfo")}
                                                            >
                                                                <span>Save</span>
                                                                {/*{commonData.loading && buttonSpinner === "savepaymentInfo" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-grey"
                                                                onClick={() => onCancel("paymentInfo")}
                                                            >
                                                                <span>Cancel</span>
                                                                {/*{commonData.loading && buttonSpinner === "cancelpaymentInfo" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        ) : (
                                            //editable payment information end
                                            //default payment information start

                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    {renderDefaultCard()}

                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("paymentInfo")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        //default payment information end
                                    }
                                </div>

                                <div className="profile-row-space">
                                    {
                                        editState.billingAddress ? (
                                            //editable billing address start
                                            <div className="profile-edit-form">
                                                <div className="profile-detail">
                                                    <h3>Billing Address</h3>
                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group search-drop">
                                                                <IonLabel>Address Line 1</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="e.g. 123 Street"
                                                                    value={state.billingAddress.addressLine1}
                                                                    onIonChange={(e) =>
                                                                        handleChange(
                                                                            e,
                                                                            "addressLine1",
                                                                            "billingAddress"
                                                                        )
                                                                    }
                                                                />
                                                                {predictions.billingAddress.length !== 0 && (
                                                                    <div className="search-drop-list" ref={wrapperRef}>
                                                                        {predictions.billingAddress?.map((prediction) => (
                                                                            <a
                                                                                key={prediction?.place_id}
                                                                                onClick={(e) =>
                                                                                    onPlaceSelected(prediction, "billingAddress", e)
                                                                                }
                                                                            >
                                                                                {prediction?.description || "Not found"}
                                                                            </a>
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                {/*{predictions.billingAddress.length !== 0 && (
                                  <div className="search-drop-list">
                                    {predictions.billingAddress?.map(
                                      (prediction) => (
                                        <a
                                          key={prediction?.place_id}
                                          onClick={(e) => onPlaceSelected(prediction, "billingAddress",e)}
                                        > check
                                          {prediction?.description ||
                                            "Not found"}
                                        </a>
                                      )
                                    )}
                                  </div>
                                )}*/}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group">
                                                                <IonLabel>Address Line 2</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="e.g. 123 Street"
                                                                    value={state.billingAddress.addressLine2}
                                                                    onIonChange={(e) =>
                                                                        handleChange(
                                                                            e,
                                                                            "addressLine2",
                                                                            "billingAddress"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="6">
                                                            <div className="form-group">
                                                                <IonLabel>City</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. San Francisco"
                                                                    value={state.billingAddress.city}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "city", "billingAddress")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>

                                                    <IonRow>
                                                        <IonCol size="12" size-md="3">
                                                            <div className="form-group">
                                                                <IonLabel>State</IonLabel>
                                                                {/*<div className="select-input">
                                                                    <IonSelect
                                                                        interface="popover"
                                                                        placeholder="e.g. John"
                                                                        className="form-control">
                                                                        <IonSelectOption
                                                                            value="brown">Brown</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="blonde">Blonde</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="black">Black</IonSelectOption>
                                                                        <IonSelectOption
                                                                            value="red">Red</IonSelectOption>
                                                                    </IonSelect>
                                                                    <a
                                                                    ><IonIcon icon={chevronDownOutline}/></a>
                                                                </div>*/}
                                                                <IonInput
                                                                    className="form-control"
                                                                    placeholder="e.g. San Francisco"
                                                                    value={state.billingAddress.state}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "state", "billingAddress")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="12" size-md="3">
                                                            <div className="form-group">
                                                                <IonLabel>Zip Number</IonLabel>
                                                                <IonInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="e.g. 94016"
                                                                    value={state.billingAddress.zip}
                                                                    onIonChange={(e) =>
                                                                        handleChange(e, "zip", "billingAddress")
                                                                    }
                                                                />
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                </div>
                                                {errorState.billingAddress.length !== 0 &&
                                                errorState.billingAddress[0] && (
                                                    <p className="errormsg">
                                                        {errorState.billingAddress[0]}
                                                    </p>
                                                )}
                                                <div className="profileedit-btn-inline">
                                                    <IonButton
                                                        className="theme-btn button-blue"
                                                        onClick={() => onSubmit("billingAddress")}
                                                    >
                                                        <span>Save</span>
                                                        {/*{commonData.loading && buttonSpinner === "savebillingAddress" && (<IonSpinner/>)}*/}
                                                    </IonButton>
                                                    <IonButton
                                                        className="theme-btn button-grey"
                                                        onClick={() => onCancel("billingAddress")}
                                                    >
                                                        <span>Cancel</span>
                                                        {/*{commonData.loading && buttonSpinner === "cancelbillingAddress" && (<IonSpinner/>)}*/}
                                                    </IonButton>
                                                </div>
                                            </div>
                                        ) : (
                                            //editable billing address end
                                            //default billing address start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Billing Address</h3>
                                                        <p className="profile-detail-pera">
                                                            {state.billingAddress.addressLine1 &&
                                                            state.billingAddress.addressLine1.length
                                                                ? convertArrayToCommaSeparatedString([
                                                                    state.billingAddress.addressLine1,
                                                                    state.billingAddress.addressLine2,
                                                                    state.billingAddress.city,
                                                                    state.billingAddress.state,
                                                                    state.billingAddress.zip,
                                                                ])
                                                                : "None"}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() => setEditable("billingAddress")}
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        //default billing address end
                                    }
                                </div>

                                <div className="profile-row-space">
                                    {
                                        editState.additionalHealthInfo ? (
                                            //editable additional Health Info start
                                            <div className="profile-edit-form">
                                                <IonRow>
                                                    <IonCol size="12" size-md="6">
                                                        <div className="profile-detail">
                                                            <div className="form-group">
                                                                <h3>Additional Health Information</h3>
                                                            </div>

                                                            {/* Health Information */}
                                                            <div className="health-information-group">
                                                                <h3>Do you have any allergies?</h3>
                                                                <div className="form-group">
                                                                    <IonRadioGroup
                                                                        value={radioButtonState.allergies}
                                                                        onIonChange={(e) =>
                                                                            handleRadioChange(e, "allergies")
                                                                        }
                                                                    >
                                                                        <div className="radio-item">
                                                                            <IonRadio value={true}/>
                                                                            <IonLabel>Yes</IonLabel>
                                                                        </div>
                                                                        <div className="radio-item">
                                                                            <IonRadio value={false}/>
                                                                            <IonLabel>No</IonLabel>
                                                                        </div>
                                                                    </IonRadioGroup>
                                                                </div>
                                                                {radioButtonState.allergies && (
                                                                    <div>
                                                                        <div className="allergy-list">
                                                                            {state.additionalHealthInfo.allergies.map(
                                                                                (allergy, index) => (
                                                                                    <a
                                                                                        key={index}
                                                                                        onClick={() =>
                                                                                            removeItem(allergy, "allergies")
                                                                                        }
                                                                                    >
                                                                                        {allergy}
                                                                                        <IonIcon icon={closeOutline}/>
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                        </div>

                                                                        <div className="form-group search-drop">
                                                                            {searchState.allergies !== null && (
                                                                                <div className="left-icon-input">
                                                                                    {/*<a href="javascript:;">
                                                                                        {commonData.loading &&
                                                                                        buttonSpinner ===
                                                                                        "searchallergies" ? (
                                                                                            <IonSpinner/>
                                                                                        ) : (
                                                                                            <IonIcon
                                                                                                icon={searchOutline}/>
                                                                                        )}
                                                                                    </a>*/}
                                                                                    <IonInput
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        value={searchState.allergies}
                                                                                        onIonChange={(e) =>
                                                                                            handleSearchChange(e, "allergies")
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                            handleKeyCheck(e, "allergies")
                                                                                        }
                                                                                    />
                                                                                    <IonButton
                                                                                        className="theme-btn button-blue"
                                                                                        onClick={() =>
                                                                                            onItemSelected(
                                                                                                searchState.allergies,
                                                                                                "allergies"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                    </IonButton>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {searchState.allergies === null && <IonButton
                                                                            className="theme-btn button-grey"
                                                                            onClick={() =>
                                                                                setSearchState((prevState) => ({
                                                                                    ...prevState,
                                                                                    allergies: "",
                                                                                }))
                                                                            }
                                                                        >
                                                                            <span>+ Add Allergy</span>
                                                                        </IonButton>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* Health Information */}

                                                            <div className="health-information-group">
                                                                <h3>Do you have any medical conditions?</h3>
                                                                <div className="form-group">
                                                                    <IonRadioGroup
                                                                        value={radioButtonState.medicalConditions}
                                                                        onIonChange={(e) =>
                                                                            handleRadioChange(e, "medicalConditions")
                                                                        }
                                                                    >
                                                                        <div className="radio-item">
                                                                            <IonRadio value={true}/>
                                                                            <IonLabel>Yes</IonLabel>
                                                                        </div>
                                                                        <div className="radio-item">
                                                                            <IonRadio value={false}/>
                                                                            <IonLabel>No</IonLabel>
                                                                        </div>
                                                                    </IonRadioGroup>
                                                                </div>
                                                                {radioButtonState.medicalConditions && (
                                                                    <div>
                                                                        <div className="allergy-list">
                                                                            {state.additionalHealthInfo.medicalConditions.map(
                                                                                (medicalCondition, index) => (
                                                                                    <a
                                                                                        key={index}
                                                                                        onClick={() =>
                                                                                            removeItem(
                                                                                                medicalCondition,
                                                                                                "medicalConditions"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {medicalCondition}
                                                                                        <IonIcon icon={closeOutline}/>
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                        </div>

                                                                        <div className="form-group search-drop">
                                                                            {searchState.medicalConditions !==
                                                                            null && (
                                                                                <div className="left-icon-input">
                                                                                    {/*<a href="javascript:;">
                                                                                        {commonData.loading &&
                                                                                        buttonSpinner ===
                                                                                        "searchmedicalConditions" ? (
                                                                                            <IonSpinner/>
                                                                                        ) : (
                                                                                            <IonIcon
                                                                                                icon={searchOutline}/>
                                                                                        )}
                                                                                    </a>*/}
                                                                                    <IonInput
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        value={
                                                                                            searchState.medicalConditions
                                                                                        }
                                                                                        onIonChange={(e) =>
                                                                                            handleSearchChange(
                                                                                                e,
                                                                                                "medicalConditions"
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                            handleKeyCheck(
                                                                                                e,
                                                                                                "medicalConditions"
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <IonButton
                                                                                        className="theme-btn button-blue"
                                                                                        onClick={() =>
                                                                                            onItemSelected(
                                                                                                searchState.medicalConditions,
                                                                                                "medicalConditions"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                    </IonButton>
                                                                                </div>
                                                                            )}
                                                                            {/*{searchResultState.medicalConditions
                                                                                .length !== 0 && (
                                                                                <div className="search-drop-list">
                                                                                    {searchResultState.medicalConditions.map(
                                                                                        (medicalCondition, index) => (
                                                                                            <a
                                                                                                href="javascript:;"
                                                                                                key={index}
                                                                                                onClick={() =>
                                                                                                    onItemSelected(
                                                                                                        medicalCondition.medical_condition,
                                                                                                        "medicalConditions"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    medicalCondition.medical_condition
                                                                                                }
                                                                                            </a>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}*/}
                                                                        </div>
                                                                        {searchState.medicalConditions === null &&
                                                                        <IonButton
                                                                            className="theme-btn button-grey"
                                                                            onClick={() =>
                                                                                setSearchState((prevState) => ({
                                                                                    ...prevState,
                                                                                    medicalConditions: "",
                                                                                }))
                                                                            }
                                                                        >
                                                                            <span>+ Add Medical Condition</span>
                                                                        </IonButton>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="health-information-group">
                                                                <h3>Do you take other medications?</h3>
                                                                <div className="form-group">
                                                                    <IonRadioGroup
                                                                        value={radioButtonState.medications}
                                                                        onIonChange={(e) =>
                                                                            handleRadioChange(e, "medications")
                                                                        }
                                                                    >
                                                                        <div className="radio-item">
                                                                            <IonRadio value={true}/>
                                                                            <IonLabel>Yes</IonLabel>
                                                                        </div>
                                                                        <div className="radio-item">
                                                                            <IonRadio value={false}/>
                                                                            <IonLabel>No</IonLabel>
                                                                        </div>
                                                                    </IonRadioGroup>
                                                                </div>
                                                                {radioButtonState.medications && (
                                                                    <div>
                                                                        <div className="allergy-list">
                                                                            {state.additionalHealthInfo.medications.map(
                                                                                (medication, index) => (
                                                                                    <a
                                                                                        key={index}
                                                                                        onClick={() =>
                                                                                            removeItem(
                                                                                                medication,
                                                                                                "medications"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {medication}
                                                                                        <IonIcon icon={closeOutline}/>
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                        </div>

                                                                        <div className="form-group search-drop">
                                                                            {searchState.medications !== null && (
                                                                                <div className="left-icon-input">
                                                                                    {/* <a href="javascript:;">
                                                                                        {commonData.loading &&
                                                                                        buttonSpinner ===
                                                                                        "searchmedications" ? (
                                                                                            <IonSpinner/>
                                                                                        ) : (
                                                                                            <IonIcon
                                                                                                icon={searchOutline}/>
                                                                                        )}
                                                                                    </a>*/}
                                                                                    <IonInput
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        value={searchState.medications}
                                                                                        onIonChange={(e) =>
                                                                                            handleSearchChange(
                                                                                                e,
                                                                                                "medications"
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                            handleKeyCheck(e, "medications")
                                                                                        }
                                                                                    />
                                                                                    <IonButton
                                                                                        className="theme-btn button-blue"
                                                                                        onClick={() =>
                                                                                            onItemSelected(
                                                                                                searchState.medications,
                                                                                                "medications"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                    </IonButton>
                                                                                </div>
                                                                            )}
                                                                            {/*{searchResultState.medications.length !==
                                                                            0 && (
                                                                                <div className="search-drop-list">
                                                                                    {searchResultState.medications.map(
                                                                                        (medication, index) => (
                                                                                            <a
                                                                                                href="javascript:;"
                                                                                                key={index}
                                                                                                onClick={() =>
                                                                                                    onItemSelected(
                                                                                                        medication.medication,
                                                                                                        "medications"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {medication.medication}
                                                                                            </a>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}*/}
                                                                        </div>
                                                                        {searchState.medications === null && <IonButton
                                                                            className="theme-btn button-grey"
                                                                            onClick={() =>
                                                                                setSearchState((prevState) => ({
                                                                                    ...prevState,
                                                                                    medications: "",
                                                                                }))
                                                                            }
                                                                        >
                                                                            <span>+ Add Medication</span>
                                                                        </IonButton>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="profileedit-btn-inline">
                                                            <IonButton
                                                                className="theme-btn button-blue"
                                                                onClick={() => onSubmit("additionalHealthInfo")}
                                                            >
                                                                <span>Save</span>
                                                                {/*{commonData.loading && buttonSpinner === "saveadditionalHealthInfo" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                            <IonButton
                                                                className="theme-btn button-grey"
                                                                onClick={() => onCancel("additionalHealthInfo")}
                                                            >
                                                                <span>Cancel</span>
                                                                {/*{commonData.loading && buttonSpinner === "canceladditionalHealthInfo" && (<IonSpinner/>)}*/}
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        ) : (
                                            //editable additional Health Info end
                                            //default additional Health Info start
                                            <IonRow>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-detail">
                                                        <h3>Additional Health Information</h3>
                                                        <p className="profile-detail-pera">
                                                            Allergies:{" "}
                                                            {state.additionalHealthInfo.allergies.length !== 0
                                                                ? convertArrayToCommaSeparatedString(
                                                                    state.additionalHealthInfo.allergies
                                                                )
                                                                : "None"}
                                                        </p>
                                                        <p className="profile-detail-pera">
                                                            Medical Conditions:{" "}
                                                            {state.additionalHealthInfo.medicalConditions &&
                                                            state.additionalHealthInfo.medicalConditions
                                                                .length !== 0
                                                                ? convertArrayToCommaSeparatedString(
                                                                    state.additionalHealthInfo.medicalConditions
                                                                )
                                                                : "None"}
                                                        </p>
                                                        <p className="profile-detail-pera">
                                                            Other Medications:{" "}
                                                            {state.additionalHealthInfo.medications &&
                                                            state.additionalHealthInfo.medications.length !==
                                                            0
                                                                ? convertArrayToCommaSeparatedString(
                                                                    state.additionalHealthInfo.medications
                                                                )
                                                                : "None"}
                                                        </p>
                                                    </div>
                                                </IonCol>
                                                <IonCol size="12" size-md="6">
                                                    <div className="profile-edit-btn">
                                                        <IonButton
                                                            className="theme-btn button-grey"
                                                            onClick={() =>
                                                                setEditable("additionalHealthInfo")
                                                            }
                                                        >
                                                            <span>Edit</span>
                                                        </IonButton>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        )
                                        //default additional Health Info end
                                    }
                                </div>
                            </IonGrid>
                        </div>
                    </div>
                    {/* theme-card end */}
                </div>
            </div>
        </div>
    );
};

export default ProfileAndSetting;
