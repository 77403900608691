import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  IonInput,
  IonButton, IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./confirmation.scss";
import { menuController } from "@ionic/core";
import CommonSidebar from "../../components/commonSidebar/commonSidebar";
import wallet from "../../images/wallet-icon.png";
import medicine from "../../images/medicine-icon.png";
import time from "../../images/time-icon.png";
import { useDispatch, useSelector } from "react-redux";
import * as registrationAction from "../../Redux/actions/registrationAction";

const Confirmation = (props) => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const formData = useSelector((state) => state.registrationReducer.formdata);
  const [state, setState] = useState({});
  const [errorState, setErrorState] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState("");

  const handleChange = (event) => {
    if (errorState.length !== 0) {
      setErrorState("");
    }
    const { value: code } = event.detail;
    console.log(code, 'code');
    setState(state => ({ ...state, code }));
  };

  const resendCode = async () => {
    setButtonSpinner("resend");
    await dispatch(registrationAction.resendCode(state.email, state.phone));
    setButtonSpinner("");
    setTimeout(() => {
      dispatch({ type: "CLEAN_ERROR" });
    }, 8000);
  };

  const onSubmit = async (type) => {
    setButtonSpinner("verify");
    if (type === "previous") {
      return props.history.goBack();
    }
    if (state.code.length === 0) {
      setErrorState("Verification Code cannot be Empty!");
      return null;
    }
    const response = await dispatch(registrationAction.verifyCode(state.email, state.code, state));
    if (response.success) {
      props.history.push("/confirmationDone");
    } else {
      setTimeout(() => {
        dispatch({ type: "CLEAN_ERROR" });
      }, 8000);
    }

    setButtonSpinner("");
  };

  React.useEffect(() => {
    menuController.enable(false);
    dispatch({ type: "CLEAN_ERROR" });
    const localData = localStorage.getItem('user_sign_up')
    if (!localData) {
      props.history.push("/welcome");
    } else {
      const data = JSON.parse(localData)
      setState(state => ({ ...state, ...formData, ...data }))
    }
  }, []);

  return (
    <div className="confirmation-page pagecard-height">
      <div className="space-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="3" size-lg="2">
              <CommonSidebar pageIndex={3} />
            </IonCol>
            <IonCol size="12" size-md="9" size-lg="10">
              <div className="theme-card">
                <div className="card-body">
                  <div className="parentform">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-lg="7">
                          <div className="parentform-heading">
                            <span>3 OF 3</span>
                            <h2>Please enter the 4-digit verification code sent via email
                              and text.</h2>
                            <p>Please check your inbox. A unique code has been sent to the
                              email address you provided.</p>
                          </div>
                          <div className="parentform-left-detail">
                            <IonList>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={wallet} /></span>
                                  <p>Get up to 80% off your medications.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={medicine} /></span>
                                  <p>Thousands of meds, fullfilled by a licensed &
                                    accredited US-based pharmacy.</p>
                                </div>
                              </IonItem>
                              <IonItem>
                                <div className="parentform-left-content">
                                  <span><IonImg src={time} /></span>
                                  <p>Order online and get FREE Delivery, and $5 off
                                    your first order.</p>
                                </div>
                              </IonItem>
                            </IonList>
                          </div>
                        </IonCol>

                        <IonCol size="12" size-lg="5">
                          <div className="parentform-right">
                            <div className="form-group">
                              <IonLabel>Verification Code</IonLabel>
                              <IonInput
                                className="form-control"
                                type="number"
                                placeholder="e.g. 1234"
                                value={state.code}
                                onIonChange={(e) => handleChange(e)}
                              />
                              {
                                errorState.length !== 0 &&
                                <p className="errormsg">{errorState}</p>
                              }
                              <div className="form-link">
                                <a href="javascript:;" onClick={() => resendCode()}>Resend code</a>
                                {
                                  commonData.loading && buttonSpinner === "resend" &&
                                  <IonSpinner />
                                }
                              </div>
                            </div>
                            {
                              commonData.errorMsg && commonData.errorMsg.length !== 0 &&
                              <p className="errormsg">{commonData.errorMsg}</p>
                            }
                            {
                              commonData.successMsg && commonData.successMsg.length !== 0 &&
                              <p className="successmsg">{commonData.successMsg}</p>
                            }
                            <div className="parentform-button">
                              <IonButton
                                className="theme-btn button-blue"
                                onClick={() => onSubmit("verify")}><span>Verify</span>
                                {/*{
                                    commonData.loading && buttonSpinner === 'verify' &&
                                    <IonSpinner/>
                                }*/}
                              </IonButton>
                              <IonButton
                                className="theme-btn button-blue-outline"
                                onClick={() => onSubmit("previous")}
                              ><span>Previous</span>
                              </IonButton>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Confirmation;
