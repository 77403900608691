import React from "react";
import {menuController} from "@ionic/core";
import './blog.scss';

const Blog = () => {

    React.useEffect(() => {
        menuController.enable(false);
     //   window.location.reload(false);
     setTimeout(() => {
    const script = document.createElement("script");

    script.src = "https://dropinblog.com/js/embed.js";
    script.async = true;

    document.body.appendChild(script);
}, 100);
    }, []);

    return (
        <div className="space-container">
            <div id="dib-posts">
            </div>
        </div>
    )
}
export default Blog