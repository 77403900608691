import {
  IonButtons,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonInput,
} from "@ionic/react";
import React from "react";
import "./askToJoin.scss";
import { useDispatch, useSelector } from "react-redux";

const AskToJoin = (props) => {
  const authData = useSelector((state) => state.authReducer.authData);

  return (
    <div className="ask-to-join">
      <div className="space-container">
        <h3>Start your savings today!</h3>
        {authData.id == undefined && (
          <IonButton
            type="button"
            className="theme-btn button-blue"
            onClick={() => props.onlogin()}
          >
            Sign up
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default AskToJoin;
