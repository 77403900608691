import _ from 'lodash';
export const initialState = {
    cartItems: [],
    deliveryOption: localStorage.getItem("delivery") ? JSON.parse(localStorage.getItem("delivery")) : '',
    prescriptionRequired: false
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CART_ITEM_COUNT_CHANGE":
            const cartItemsDb = action.payload ? action.payload : [];
            let cartItemsSession = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
            if (cartItemsSession == null) {
                cartItemsSession = [];
            }
            const merged = _.merge(_.keyBy(cartItemsSession, 'medicineName'), _.keyBy(cartItemsDb, 'medicineName'));
            const newCartItems = _.values(merged);
            const updatedCart = _.uniqBy(newCartItems,'medicineName'); //removed if had duplicate id
            const prescriptionRequired = updatedCart.filter(i => i.prescriptionRequired === 1).length
            localStorage.setItem('cart', JSON.stringify(updatedCart))

            return {
                ...state,
                cartItems: updatedCart,
                prescriptionRequired: prescriptionRequired > 0
            };
        case "DELIVERY_UPDATE":
            const deliveryOption = localStorage.getItem("delivery") ? JSON.parse(localStorage.getItem("delivery")) : []
            return {
                ...state,
                deliveryOption
            };
        default:
            return state;
    }
}
