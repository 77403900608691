import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonButton,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import React, { useState, useRef } from "react";
import "./about.scss";
import { menuController } from "@ionic/core";
import aboutBanner from "../../images/about-banner.png";
import valueImg from "../../images/values-img.png";
import trust from "../../images/trust.png";
import integrity from "../../images/integrity.png";
import empathy from "../../images/empathy.png";
import socialResponsibility from "../../images/social-responsibility.png";
import teamImg1 from "../../images/our-team-member-1.png";
import teamImg2 from "../../images/our-team-member-2.png";
import teamImg3 from "../../images/our-team-member-3.png";
import pressImg from "../../images/about-us-press.png";
import comingSoonImg from "../../images/coming-soon.png";

import AskToJoin from "../../components/askToJoin/askToJoin";

const About = (props) => {
  const contentRef = useRef(null);
  const slidesRef = useRef(null);
  const [loadState, setLoadState] = useState(false);

  const handleNext = () => slidesRef.current?.slideNext();
  const handlePreview = () => slidesRef.current?.slidePrev();

  React.useEffect(() => {
    menuController.enable(false);
    contentRef.current.scrollIntoView();
    setTimeout(() => {
      setLoadState(true);
    }, 100);
  }, []);

  //team slider
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
    // spaceBetween: 50,
    allowSlideNext: true,
    allowSlidePrev: true,
    allowTouchMove: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };

  const onSignUp = () => {
    props.history.push("/welcome");
  };

  return (
    <div ref={contentRef} className="about-page">
      {/* about-banner start */}
      <div className="about-banner">
        <div className="space-container">
          <div className="about-banner-head">
            <h2>
              Improving the Pharmacy Experience. Because Patients Deserve
              Better!
            </h2>
          </div>
          <div className="about-banner-img">
            <IonImg src={aboutBanner} />
          </div>
        </div>
      </div>
      {/* landing-banner end */}

      {/* about-story start */}
      <div className="about-story">
        <div className="space-container">
          <div className="about-story-head">
            <h2 className="head">Our Story</h2>
            <p>
              “Let’s create a smarter pharmacy with a local
              pharmacy feel.”
            </p>

          </div>
          <div className="our-story-content">
            <p>
              There was a time when the pharmacy business was more personal. When the friendly
              neighborhood pharmacy greeted you by your name, answered your questions with full attention
              and assurance, and truly cared for your holistic wellbeing. And when one was too sick to get out
              of bed, the young assistant for the pharmacy would hop over to deliver that much needed flu
              medication
            </p>
            <p>
              With consolidation of drugstores, we now have pharmacy chains where hapless pharmacists
              and technicians that are heads-down filling medications with barely a glance back at you. You
              stand in the line for 20-30 minutes before you get to the counter. You have a few questions but
              you see how busy the pharmacist is, so you bite your lip and mutter under your breath “I’ll just
              Google it, I guess”.
            </p>
            <p>
              And over time, these chains and middlemen (aka Pharmacy Benefit Manager - PBMs) have
              added layers and layers of complexity to the healthcare system to extract larger profits that
              makes the price of relatively cheap generic medication vary as much as by a 1000% from one
              pharmacy to the other and for one patient vs. the other.
            </p>
            <p>
              We found all these inefficiencies and complications of a fairly simple business model just
              absolutely ridiculous. And around the same time, we learned about one of our coworkers who
              suffered greatly as she could not fill her prescription in time because of exorbitant drug prices,
              and the pharmacy did not check on her or talk to her about alternatives. That made it very real
              for us, and hit us close to home.
            </p>
            <p>
              That’s when we said “Let’s create a smarter pharmacy with a local pharmacy feel”. This is a
              pharmacy that brings back the personal attention and care for the patients, that uses modern
              technology to allow our pharmacists to be humans and focus on patients rather than being pill
              filling machines, and that makes the affordable medication actually affordable.
            </p>
            <p>
              We hope that our customers find this passion of ours reflected in our values and our everyday
              interactions with the customers. We look forward to being your new human-first pharmacy!
            </p>
          </div>
        </div>
      </div>
      {/* about-story end */}

      {/* Our Team start */}
      <div className="our-team">
        <div className="space-container">
          <div className="our-team-head">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <div className="our-team-heading">
                    <h2>Our Team</h2>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="6">
                  <div className="condition-pagination">
                    <IonButton
                      onClick={handlePreview}
                      className="theme-icon-btn button-dark-grey-icon"
                      type="button"
                    >
                      <IonIcon icon={chevronBackOutline} />
                    </IonButton>
                    <IonButton
                      onClick={handleNext}
                      className="theme-icon-btn button-dark-grey-icon active"
                      type="button"
                    >
                      <IonIcon icon={chevronForwardOutline} />
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="our-team-slider">
            {(() => {
              let sliderjsx = [];
              sliderjsx.push(
                <IonSlides ref={slidesRef} pager="false" options={slideOpts}>
                  <IonSlide>
                    <div className="team-img">
                      <IonImg className="team-image" src={teamImg1} />
                      <div className="team-img-info">
                        <p>
                          Abhi, Co-Founder.
                        </p>
                        <p className="member-info">
                          15 years in drug distribution and retail pharmacy.
                          Passionate about addressing the inefficiencies in the current pharmacy model.
                        </p>
                      </div>
                    </div>
                  </IonSlide>
                  <IonSlide>
                    <div className="team-img">
                      <IonImg className="team-image" src={teamImg2} />
                      <div className="team-img-info">
                        <p>
                          Abhi’s Co-Founder.
                        </p>
                        <p className="member-info">
                          Over a decade in healthcare and lifesciences.
                          Passionate about making the pharmacy experience customer-centric and frictionless.
                        </p>
                      </div>
                    </div>
                  </IonSlide>
                  <IonSlide>
                    <div className="team-img">
                      <IonImg className="team-image" src={teamImg3} />
                      <div className="team-img-info">
                        <p>
                          Sreevani, Pharmacy Director.
                        </p>
                        <p className="member-info">
                          15 years as a practicing pharmacist.
                          Passionate about caring for patients one on one.
                        </p>
                      </div>
                    </div>
                  </IonSlide>
                </IonSlides>
              );
              if (loadState) return sliderjsx;
            })()}
          </div>
        </div>
      </div>
      {/* Our Team end */}

      {/* our-values start */}
      <div className="our-values">
        <div className="space-container">
          <div className="our-values-head">
            <div className="our-values-heading">
              <h2>Our Values: T.I.E.S.</h2>
            </div>
            <div className="our-values-head-img">
              <IonImg src={valueImg} />
            </div>
          </div>
          <div className="our-values-mid">
            {/* Item */}
            <div className="our-values-box">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="4">
                    <div className="our-values-img">
                      <IonImg src={trust} />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="8">
                    <div className="our-values-detail">
                      <h3>Trust</h3>
                      <p>
                        We value and build trust in every relationship. We seek to consistently build trust in every
                        interaction with our customers, employees and partners by conducting ourselves in a
                        professional and honest manner
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            {/* Item */}
            <div className="our-values-box">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="4">
                    <div className="our-values-img">
                      <IonImg src={integrity} />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="8">
                    <div className="our-values-detail">
                      <h3>Integrity</h3>
                      <p>
                        Integrity to us is being truthful and honest in all areas of life, in our professional work
                        and in our personal lives. When faced with difficult decisions, we do the right thing, even when
                        no one is watching.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            {/* Item */}
            <div className="our-values-box">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="4">
                    <div className="our-values-img">
                      <IonImg src={empathy} />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="8">
                    <div className="our-values-detail">
                      <h3>Empathy</h3>
                      <p>
                        We strive to listen to others and consider their thoughts and feelings before passing
                        judgement or acting. This approach has allowed us to foster stronger relationships with one
                        another as Qwarkers and create impactful connections with our customers.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            {/* Item */}
            <div className="our-values-box">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="4">
                    <div className="our-values-img">
                      <IonImg src={socialResponsibility} />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="8">
                    <div className="our-values-detail">
                      <h3>Social Responsibility</h3>
                      <p>
                        We responsibly manage our resources and apply the talents of our
                        employees to positively benefit society and our communities. We act sustainably to meet the
                        needs of our customers in accordance with our collective responsibility for people, progress and
                        the environment.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
      </div>
      {/* our-values end */}

      {/* Press start */}
      {/* <div className="press">
        <div className="space-container">
          <div className="press-head">
            <h2>Press</h2>
          </div>
          <div className="press-body">
            <IonGrid>
              <IonRow>
                <IonImg src={pressImg} />
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div> */}
      {/* Press end */}

      {/* Press start */}
      <div className="press">
        <div className="space-container">
          <div className="press-head">
            <h2>Press</h2>
          </div>
          <div className="press-body">
            <IonGrid>
              <IonRow>
                {/* item */}
                <IonCol size="12" size-md="4">
                  <div className="press-box">
                    <div className="press-img">
                      <IonImg src={comingSoonImg} />
                    </div>
                  </div>
                </IonCol>
                {/* item */}
                <IonCol size="12" size-md="4">
                  <div className="press-box">
                    <div className="press-img">
                      <IonImg src={comingSoonImg} />
                    </div>
                  </div>
                </IonCol>
                {/* item */}
                <IonCol size="12" size-md="4">
                  <div className="press-box">
                    <div className="press-img">
                      <IonImg src={pressImg} />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>
      {/* Press end */}

      {/* ask-to-join */}
      <div>
        <AskToJoin onlogin={onSignUp} />
      </div>
      {/* ask-to-join */}
    </div>
  );
};

export default About;
