
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import "./googleLogin.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { socialLogin } from '../../Redux/actions/authAction';
import google from "../../images/google-icon.png";
import {REACT_APP_GOOGLE_CLIENT_ID} from "../../Utils/config";
import {IonButton, IonImg, IonSpinner} from "@ionic/react";
import googleIcon from "../../images/google-icon.png";
const googleClientID = REACT_APP_GOOGLE_CLIENT_ID;


const GoogleLogin = (props) => {
  let auth2;

  React.useEffect(() => {
    setTimeout(() => {
      loadFunction()
    }, 1000)
  }, []);

  const [idToken, setIdToken] = useState("");

  React.useEffect(() => {
    if (props.isLoggedIn) {
      console.log("isLogged is true now")
      localStorage.setItem("userId", props.authData ?.id);
      localStorage.setItem("userData", JSON.stringify(props.authData));
      localStorage.setItem("token", props.authData ?.token);
      localStorage.removeItem("companyId");
      props.history.push("/profile");
    }
  }, [props.isLoggedIn, props.isacceptedtnc]);



  /* global gapi */
  const loadFunction = () => {
    console.log("inside load function");
    const successCallback = onSuccess.bind(this);
    const errorCallback = oneError.bind(this)
    window.gapi.load('auth2', () => {
      auth2 = window.gapi.auth2.init({
        client_id: googleClientID,
      })

      // this.auth2.attachClickHandler(document.querySelector('#loginButton'), {}, this.onLoginSuccessful.bind(this))

      auth2.then(() => {
        console.log('on init');
      }).catch(error => console.log("error is", error));
    });
    window.onbeforeunload = function(e){
      window.gapi.auth2.getAuthInstance().signOut();
    };
    window.gapi.load('signin2', function () {
      // Method 3: render a sign in button
      // using this method will show Signed In if the user is already signed in
      var opts = {
        // width: 200,
        // height: 50,
        client_id: googleClientID,
        onsuccess: onSuccess,
        onfailure: errorCallback
      }
      window.gapi.signin2.render('loginButton', opts)
    })
  }


  const getGoogleIdToken = async (event) => {
    for (const [key, value] of Object.entries(event)) {
      if (Object.keys(value).includes('id_token') === true) {
        return value.id_token;
      }
    }
  }


  const onSuccess = async (event) => {

    console.log("hey success", event)
    let id_token = await getGoogleIdToken(event);

    if (id_token !== undefined) {
      setIdToken(id_token);
      await props.socialLogin({ idToken: id_token, type: "google" });
    }

  }


  const oneError = (event) => {
    console.log("error google", event)
  }


  return (
    <IonButton
        id="loginButton"
     className="theme-btn button-blue-outline google-sign-btn">
       {/* <span> <IonImg src={google}/> Sign in with Google</span> */}
    </IonButton>


  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ socialLogin }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleLogin));