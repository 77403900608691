import Api from "../../Utils/Api";
// import { handleError, handleSuccess } from "./commonAction";
// import { checkIfItemAlreadyAdded } from "../../Utils/helper";

// export const getSavingsCalculatorProducts = () => async (dispatch) => {
//     try {
//         // await dispatch({ type: "LOADING_STARTED" });
//         const res = await Api.get(`/api/v1/saving/get_products`);
//         // await dispatch({ type: "LOADING_COMPLETED" });
//         return res.data
//     } catch (e) {
//         await dispatch({ type: "LOADING_FAILURE" });
//     }
// }

export const showSavings = (data) => async (dispatch) => {
    try {
        // await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/saving/showSavings`, data);
        // await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    } catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const searchSavingCalculatorProduct = (productName) => async (dispatch) => {
    try {
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.get(`/api/v1/saving/getProductsByName/${productName}`);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    } catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}

export const sendUsNote = (data) => async (dispatch) => {
    try {
        await dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/saving/sendUsNote`, data);
        await dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    } catch (e) {
        await dispatch({ type: "LOADING_FAILURE" });
    }
}